import {
  ActionIcon,
  Menu,
  rem,
  useComputedColorScheme,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconBlendMode,
  IconCircleCheckFilled,
  IconMoon,
  IconSun,
} from "@tabler/icons-react";
import { Fragment } from "react";
import MobileMenuIcon from "./MobileMenu/MobileMenuIcon.js";

import { useResponsiveDesign } from "../../hooks/useResponsiveDesign.js";

type MantineColorScheme = "light" | "dark" | "auto";

export const ColorSwitcherMenu = () => {
  const { colorScheme, setColorScheme } = useMantineColorScheme();
  const { isAboveSmall } = useResponsiveDesign();
  const dark = useComputedColorScheme() === "dark";

  const leftIconProps = isAboveSmall ? { size: 16 } : {};
  const menuItems: {
    label: string;
    scheme: MantineColorScheme;
    icon: JSX.Element;
  }[] = [
    { label: "Light", scheme: "light", icon: <IconSun {...leftIconProps} /> },
    { label: "Dark", scheme: "dark", icon: <IconMoon {...leftIconProps} /> },
    {
      label: "Auto",
      scheme: "auto",
      icon: <IconBlendMode {...leftIconProps} />,
    },
  ];

  if (typeof isAboveSmall === "undefined") {
    return null;
  }

  const ContentComponent = isAboveSmall ? Menu.Dropdown : Fragment;

  return (
    <Menu shadow="md">
      {isAboveSmall && (
        <Menu.Target>
          <ActionIcon variant="default" size="lg">
            {dark ? (
              <IconMoon style={{ width: rem(20), height: rem(20) }} />
            ) : (
              <IconSun style={{ width: rem(20), height: rem(20) }} />
            )}
          </ActionIcon>
        </Menu.Target>
      )}
      <ContentComponent>
        {menuItems.map((item) => (
          <Menu.Item
            key={item.scheme}
            onClick={() => setColorScheme(item.scheme)}
            leftSection={item.icon}
            rightSection={
              colorScheme === item.scheme ? (
                <MobileMenuIcon
                  Icon={IconCircleCheckFilled}
                  style={{ color: "#00ba7c" }}
                />
              ) : null
            }
          >
            {item.label}
          </Menu.Item>
        ))}
      </ContentComponent>
    </Menu>
  );
};
