import { TimestampField } from "../types/field";

export const accessTokenExpiresAtField: TimestampField<{
  name: "access_token_expires_at";
  required: true;
}> = {
  label: "Access Token Expires At",
  name: "access_token_expires_at",
  required: true,
  type: "timestamp",
};
