import { useState } from "react";
import { Link } from "react-router-dom";
import {
  ActionIcon,
  Badge,
  Card,
  Divider,
  Group,
  Menu,
  Stack,
  Text,
} from "@mantine/core";
import {
  IconAutomaticGearbox,
  IconDoorExit,
  IconExternalLink,
} from "@tabler/icons-react";

import { getUserName } from "../../../common/entities/user.js";
import { isSuperAdmin } from "../../../services/accessControl/superAdmin.js";
import { useAdmin } from "../../contexts/AdminContext.js";
import { adminMenuItems } from "./adminMainMenuUtils.js";

export function AdminMainMenu() {
  const [menuOpened, setMenuOpened] = useState(false);
  const { closeAdmin, user } = useAdmin();
  return (
    <Menu opened={menuOpened} onChange={setMenuOpened}>
      <Menu.Target>
        <Group gap="md">
          <ActionIcon variant="default" size="lg">
            <IconAutomaticGearbox size={20} />
          </ActionIcon>
          <Text size="sm" fw={600}>
            {user.email}
          </Text>
          {isSuperAdmin(user) && (
            <Badge color="blue" size="sm">
              Super Admin
            </Badge>
          )}
        </Group>
      </Menu.Target>

      <Menu.Dropdown pt={0} style={{ zIndex: 300000 }}>
        <Card>
          <Card.Section p="md">
            <Text size="sm">Hello, {getUserName(user)}!</Text>
          </Card.Section>
        </Card>
        <Divider />
        {adminMenuItems.map((item) => (
          <Menu.Item
            component={Link}
            key={item.to}
            target={item.target}
            to={item.to}
          >
            <Group justify="space-between">
              <Group>
                {item.icon(24)}
                <Stack gap={1}>
                  <Text>{item.label}</Text>
                  <Text size="xs" c="dimmed">
                    {item.subtitle}
                  </Text>
                </Stack>
              </Group>
              {item.target === "_blank" && <IconExternalLink size={16} />}
            </Group>
          </Menu.Item>
        ))}
        <Divider />
        <Menu.Item component={Link} to="/" onClick={closeAdmin}>
          <Group justify="space-between">
            <span>Exit Admin</span>
            <IconDoorExit size={16} />
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
