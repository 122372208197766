import { Group } from "@mantine/core";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { useFavicon } from "../../hooks/useFavicon.js";
import { useResponsiveDesign } from "../../hooks/useResponsiveDesign.js";
import CustomerMenuMain from "./CustomerMenu/CustomerMenuMain.js";
import CustomerMenuSecond from "./CustomerMenu/CustomerMenuSecond.js";

export const CustomerMenu = () => {
  const { customer: activeCustomer, serviceProvider } = useActiveStates();

  // Use active customer if available, otherwise use service provider
  const activeOrganization: OrganizationEntity = activeCustomer?.id
    ? activeCustomer
    : serviceProvider;

  // Look for the favicon switching from LedgerAI to the
  // active organization's avatar when the init call completes
  useFavicon(activeOrganization);

  const { isAboveSmall } = useResponsiveDesign();

  return (
    <Group gap={20}>
      <CustomerMenuMain />
      {isAboveSmall && <CustomerMenuSecond />}
    </Group>
  );
};
