import {
  IconBuildingCommunity,
  IconCloudNetwork,
  IconHeartBroken,
  IconLockAccess,
  IconRoad,
  IconRobot,
  IconServer,
  IconUsers,
} from "@tabler/icons-react";

interface Tile {
  label: string;
  to: string;
  icon: (size: number) => React.ReactNode;
  subtitle: string;
  target?: string;
}

export const adminMenuItems: Tile[] = [
  {
    label: "Roadmap",
    to: "https://ledgercollective.slack.com/docs/T070H6253ST/F0816SW5PPA",
    icon: (size) => (
      <IconRoad color="var(--mantine-color-gray-5)" size={size} />
    ),
    subtitle: "Roadmap on Slack in #engineering",
    target: "_blank",
  },
  {
    label: "Bug tracker",
    to: "https://ledgercollective.slack.com/lists/T070H6253ST/F080J5AFB4J?view_id=View089JLFJBHN",
    icon: (size) => (
      <IconHeartBroken color="var(--mantine-color-gray-5)" size={size} />
    ),
    subtitle: "Bug tracker on Slack in #engineering",
    target: "_blank",
  },
  {
    label: "AI Providers & Bots",
    to: "/ai",
    icon: (size) => <IconRobot size={size} />,
    subtitle: "View AI providers and bots",
  },
  {
    label: "API Access Control",
    to: "/docs/api/access-control",
    icon: (size) => <IconLockAccess size={size} />,
    subtitle: "View API access control documentation",
  },
  {
    label: "API Documentation",
    to: "/docs/api",
    icon: (size) => <IconCloudNetwork size={size} />,
    subtitle: "View API documentation",
  },

  {
    label: "Builds",
    to: "/builds",
    icon: (size) => <IconServer size={size} />,
    subtitle: "View build history",
  },
  {
    label: "Users",
    to: "/users",
    icon: (size) => <IconUsers size={size} />,
    subtitle: "Manage users and their entitlements, by org.",
  },
  {
    label: "Organizations",
    to: "/organizations",
    icon: (size) => <IconBuildingCommunity size={size} />,
    subtitle: "Manage organization entitlements and integration tokens.",
  },
];
