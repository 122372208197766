import { css } from "../../utils/css.js";

interface ClassNames {
  root: string;
  content: string;
  toolbar: string;
}

export default css<ClassNames>`
  .root {
    min-height: 150px;
  }
  .content {
    font-size: var(--mantine-font-size-sm);
  }
  .toolbar {
    padding: var(--mantine-spacing-xs);
  }
`;
