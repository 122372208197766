export const FEATURE_NOTES = "notes";

export const knownFeatures = [
  FEATURE_NOTES,
  // Add other feature flags here
];

export const knownFeatureDescriptions = {
  [FEATURE_NOTES]: "Rich text notes",
};
