import { Table } from "@mantine/core";

import { useCallback, useMemo } from "react";
import UserRow from "./UserRow.js";
import {
  OrganizationUserWithUser,
  UsersTableProps,
} from "./useUpdateUserEntitlements.js";

export function UsersTable({
  hasManageEntitlement,
  onDelete,
  onSendInvitation,
  users,
  deletingUserIds,
  sendingInvitationUserIds,
  updateUsersTable,
}: UsersTableProps) {
  const activeUserCount = useMemo(() => {
    return users.filter((u) => typeof u.archived_at !== "string").length;
  }, [users]);

  const isLastActiveUser = useCallback(
    (orgUser: OrganizationUserWithUser) => {
      return !orgUser.is_invitation && activeUserCount < 2;
    },
    [activeUserCount]
  );

  return (
    <Table verticalSpacing="sm" highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          <Table.Th pl="lg">User</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Email</Table.Th>
          <Table.Th>Entitlements</Table.Th>
          {hasManageEntitlement ? <Table.Th ta="right" /> : null}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {users.map((orgUser) => {
          return (
            <UserRow
              key={orgUser.User.id}
              orgUser={orgUser}
              hasManageEntitlement={hasManageEntitlement}
              sendingInvitationUserIds={sendingInvitationUserIds}
              deletingUserIds={deletingUserIds}
              isLastActiveUser={isLastActiveUser}
              onDelete={onDelete}
              onSendInvitation={onSendInvitation}
              updateUsersTable={updateUsersTable}
            />
          );
        })}
      </Table.Tbody>
    </Table>
  );
}
