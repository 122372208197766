import { useCallback, useEffect, useMemo, useState } from "react";
import { useActiveStates } from "../contexts/ActiveStatesContext.js";
import { useKvStorage } from "./useKvStorage.js";

const PRODUCTION_URL = "https://app.ledger.ai";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface BuildData {
  env?: {
    VERCEL_BRANCH_URL?: string;
    [key: string]: any;
  };
  // other build data properties...
}

interface Environment {
  label: string;
  value: string; // The URL
}

export function useBuildEnvironments() {
  const [environments, setEnvironments] = useState<Environment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentEnvironment, setCurrentEnvironment] = useState<string | null>(
    null
  );

  const { serviceProvider } = useActiveStates();
  const { full: kvStore } = useKvStorage("ledgerai-builds", {
    user: { id: 1 }, // Assuming system user ID 1
    provider: { id: serviceProvider?.id ?? 1 }, // Default to 1 if no provider
    customer: { id: -1 }, // Assuming customer ID -1
  });

  const loadBuilds = useCallback(async () => {
    if (!serviceProvider?.id) {
      setLoading(false);
      // Don't set an error, just might not have builds yet
      console.warn("useBuildEnvironments: No serviceProvider ID available.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const listResult = await kvStore.list();

      if (!listResult.success || !listResult.data) {
        const errorMsg = !listResult.success
          ? "Failed to fetch build list from storage"
          : "Failed to fetch build list: No data returned";
        console.error("useBuildEnvironments: kvStore.list failed:", listResult);
        setError(errorMsg);
        setEnvironments([
          { label: "Main (app.ledger.ai)", value: PRODUCTION_URL },
        ]); // Default to main
        return;
      }

      if (
        !Array.isArray(listResult.data) ||
        !listResult.data.every(
          (item) =>
            item &&
            typeof item === "object" &&
            typeof item.key === "string" &&
            typeof item.value === "string"
        )
      ) {
        console.error(
          "useBuildEnvironments: Invalid data format received:",
          listResult.data
        );
        setError("Invalid build data format.");
        setEnvironments([
          { label: "Main (app.ledger.ai)", value: PRODUCTION_URL },
        ]); // Default to main
        return;
      }

      const uniqueUrls = new Set<string>();
      listResult.data.forEach((item) => {
        if (!item || !item.value) return;
        try {
          const buildData = JSON.parse(item.value) as BuildData;
          const branchUrl = buildData.env?.VERCEL_BRANCH_URL;
          if (branchUrl && typeof branchUrl === "string") {
            // Basic validation to ensure it looks like a URL
            if (
              branchUrl.startsWith("https://") ||
              branchUrl.startsWith("http://")
            ) {
              uniqueUrls.add(branchUrl);
            } else {
              console.warn(`Skipping invalid VERCEL_BRANCH_URL: ${branchUrl}`);
            }
          }
        } catch (parseError) {
          console.error(
            `useBuildEnvironments: Failed to parse build data for key: ${item.key}`,
            parseError
          );
        }
      });

      const envOptions: Environment[] = Array.from(uniqueUrls).map((url) => {
        const hostname = url.replace("https://", "").split(".")[0]; // Extract subdomain/identifier
        return { label: `Branch (${hostname})`, value: url };
      });

      // Add production environment
      envOptions.push({ label: "Main (app.ledger.ai)", value: PRODUCTION_URL });

      // Sort: Main first, then branches alphabetically by label
      envOptions.sort((a, b) => {
        if (a.value === PRODUCTION_URL) return -1;
        if (b.value === PRODUCTION_URL) return 1;
        return a.label.localeCompare(b.label);
      });

      setEnvironments(envOptions);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to load build environments"
      );
      console.error("useBuildEnvironments: Error loading builds:", err);
      setEnvironments([
        { label: "Main (app.ledger.ai)", value: PRODUCTION_URL },
      ]); // Default to main on error
    } finally {
      setLoading(false);
    }
  }, [kvStore, serviceProvider?.id]);

  useEffect(() => {
    loadBuilds();
  }, [loadBuilds]);

  useEffect(() => {
    // Determine current environment after environments are loaded
    if (typeof window !== "undefined") {
      const currentOrigin = window.location.origin;
      setCurrentEnvironment(currentOrigin);
    }
  }, []); // Run once on mount

  const currentEnvironmentOption = useMemo(() => {
    return (
      environments.find((env) => env.value === currentEnvironment) ||
      environments.find((env) => env.value === PRODUCTION_URL)
    );
  }, [environments, currentEnvironment]);

  return {
    environments,
    loading,
    error,
    currentEnvironment: currentEnvironmentOption?.value ?? PRODUCTION_URL,
  };
}
