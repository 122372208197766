import { Box, Grid, Loader } from "@mantine/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toConversation } from "../../../common/core/messages.js";
import { OrganizationUserEntity } from "../../../common/entities/organizationUser.js";
import { userEntity, UserEntity } from "../../../common/entities/user.js";
import { idField } from "../../../common/fields/id.js";
import { userIdFieldRequired } from "../../../common/fields/user_id.js";
import { virtualCustomerIdField } from "../../../common/fields/virtual_customer_id.js";
import { virtualProviderIdField } from "../../../common/fields/virtual_provider_id.js";
import {
  conversationClient,
  organizationUserClient,
  userClient,
} from "../../../common/utils/entityClient.js";
import { addUsersToCache } from "../../components/Excelente/userProfileUtils.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { clientError } from "../../utils/clientError.js";
import {
  PennyConversation,
  PennyConversationState,
} from "./PennyConversation.js";
import { PennyConversationList } from "./PennyConversationList.js";

import { customerIdField } from "../../../common/fields/customer_id.js";
import { useResponsiveDesign } from "../../hooks/useResponsiveDesign.js";

import classes from "./PennyConversationList.css.js";

export function PennyConversationRoute() {
  const { id } = useParams();

  const lastId = useRef(id);

  const [pennyState, setPennyState] = useState<PennyConversationState>({
    conversation: undefined,
  });

  const { user, serviceProvider, customer } = useActiveStates();
  const organization = customer || serviceProvider;

  const navigate = useNavigate();

  useEffect(() => {
    if (id === "create") {
      // Create a new conversation before redirecting
      const newConversation = {
        id: "new" as unknown as number,
        threads: [],
        created_at: new Date(),
        updated_at: new Date(),
        title: "New Conversation",
      };
      setPennyState({
        conversation: newConversation,
      });
      navigate("/penny/conversations/new");
      return;
    }
    if (lastId.current !== id) {
      setPennyState({
        conversation: undefined,
      });
    }
    lastId.current = id;
  }, [id, navigate]);

  useEffect(() => {
    async function load() {
      if (id === "new") {
        setPennyState({
          conversation: {
            id: "new" as unknown as number,
            threads: [],
            created_at: new Date(),
            updated_at: new Date(),
            title: "New Conversation",
          },
        });
        return;
      }
      const integerId = typeof id === "string" ? parseInt(id) : NaN;
      if (isNaN(integerId)) {
        return;
      }
      const response = await conversationClient(clientError)
        .item(integerId)
        .get();
      if (response.success && "data" in response && "data" in response.data) {
        const conversation = await toConversation(
          clientError,
          response.data.data
        );
        if (id !== lastId.current) {
          return;
        }
        setPennyState({
          conversation,
        });
      }
    }
    load();
  }, [id, customer?.id, serviceProvider?.id]);

  // Add effect to handle conversation updates
  useEffect(() => {
    if (
      pennyState.conversation &&
      typeof pennyState.conversation.id === "number"
    ) {
      // Update the URL without navigation
      window.history.replaceState(
        {},
        "",
        `/penny/conversations/${pennyState.conversation.id}`
      );
    }
  }, [pennyState.conversation]);

  const [providerUsers, setProviderUsers] = useState<UserEntity[]>([]);
  const [customerUsers, setCustomerUsers] = useState<UserEntity[]>([]);

  const fetchUsers = useCallback(async () => {
    const [response, customerUsersResponse] = await Promise.all([
      userClient(clientError).list({
        [virtualProviderIdField.name]: serviceProvider?.id,
        [virtualCustomerIdField.name]: customer?.id,
      }),
      typeof customer?.id === "number"
        ? organizationUserClient(clientError).list(
            {
              [customerIdField.name]: customer.id,
              [virtualProviderIdField.name]: serviceProvider?.id,
            },
            {
              join: userEntity.name,
              from: idField.name,
              to: userIdFieldRequired.name,
            }
          )
        : undefined,
    ]);
    if (response.success && "data" in response && "data" in response.data) {
      setProviderUsers(response.data.data as UserEntity[]);
      addUsersToCache(response.data.data as UserEntity[]);
    }
    if (
      customerUsersResponse &&
      customerUsersResponse.success &&
      "data" in customerUsersResponse &&
      "data" in customerUsersResponse.data
    ) {
      setCustomerUsers(
        (
          customerUsersResponse.data.data as (OrganizationUserEntity & {
            User: UserEntity;
          })[]
        ).map(
          (x: OrganizationUserEntity & { User: UserEntity }) => x.User
        ) as UserEntity[]
      );
    }
  }, [organization?.id, customer?.id, serviceProvider?.id]);

  const users = useMemo(() => {
    return customerUsers.concat(providerUsers);
  }, [providerUsers, customerUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  const conversationElement = useMemo(() => {
    if (pennyState.conversation) {
      return (
        <PennyConversation
          key={id}
          state={pennyState}
          setState={(
            callback: (old: PennyConversationState) => PennyConversationState
          ) =>
            setPennyState((state: PennyConversationState) => callback(state))
          }
          user={user!}
          users={users}
          organization={organization}
          customer={customer}
          serviceProvider={serviceProvider}
        />
      );
    }
    return (
      <Box
        style={{
          alignContent: "flex-end",
          alignItems: "center",
          display: "flex",
          height: "fit-content",
          justifyContent: "center",
          overflowY: "auto",
          padding: "100px",
        }}
      >
        <Loader />
      </Box>
    );
  }, [
    pennyState,
    setPennyState,
    id,
    customer?.id,
    serviceProvider?.id,
    user,
    users,
  ]);

  const { isAboveSmall } = useResponsiveDesign();

  return (
    <Layout key={`${serviceProvider?.id}-${customer?.id}`}>
      <PageTitle title={pennyState.conversation?.title ?? "New Chat"} />
      <Grid style={{ "--grid-col-padding": "0", "--grid-gutter": "0" }}>
        {isAboveSmall && (
          <Grid.Col
            span={{ base: 12, sm: 3, md: 3, lg: 3, xl: 2 }}
            className={classes.container}
          >
            <PennyConversationList conversationState={pennyState} />
          </Grid.Col>
        )}
        <Grid.Col
          span={{ base: 12, sm: 9, md: 9, lg: 9, xl: 10 }}
          style={{
            maxHeight: "calc(100dvh - var(--app-shell-header-offset))",
            overflowY: "auto",
            position: "relative",
          }}
        >
          {conversationElement}
        </Grid.Col>
      </Grid>
    </Layout>
  );
}
