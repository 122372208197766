import { css } from "../../utils/css.js";

interface ClassNames {
  file: string;
  fileInline: string;
  fileName: string;
  fileSize: string;
}

export default css<ClassNames>`
  .file {
    width: 100%;
    max-width: 300px;

    @media (max-width: 768px) {
      max-width: 200px;
    }
  }
  .fileInline {
    width: fit-content;
  }

  .fileName {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;

    @media (max-width: 768px) {
      max-width: 100px;
    }
  }

  .fileSize {
    white-space: nowrap;
  }
`;
