import { archivedAtField } from "../fields/archived_at";
import { conversationIdField } from "../fields/conversation_id";
import { createdAtField } from "../fields/created_at";
import { customerIdField } from "../fields/customer_id";
import { idField } from "../fields/id";
import { parentMessageIdField } from "../fields/parent_message_id";
import { parentThreadIdField } from "../fields/parent_thread_id";
import { providerIdField } from "../fields/provider_id";
import { titleField } from "../fields/title";
import { updatedAtField } from "../fields/updated_at";
import { userIdFieldRequired } from "../fields/user_id";
import { EntityDocs } from "../types/docs";
import {
  entity,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";

// Thread fields
export const fields = [
  archivedAtField,
  conversationIdField,
  createdAtField,
  customerIdField,
  idField,
  parentMessageIdField,
  parentThreadIdField,
  providerIdField,
  titleField,
  updatedAtField,
  userIdFieldRequired,
] satisfies FieldList;

// Message primary key fields
export const primaryKeyFields = [idField] satisfies PrimaryKeyFieldTuple;

// Docs
export const docs: EntityDocs = {
  collection: {
    summary: "List threads, which are branches of a conversation.",
  },
  item: { summary: "Perform operations on a thread." },
};

export const threadEntity = entity({
  api: {
    collectionPath: "threads",
    itemPath: "threads/:id",
  },
  docs,
  name: "Thread",
  fields,
  primaryKeyFields,
  tableName: "threads",
});

export type ThreadEntity = EntityType<typeof fields, typeof primaryKeyFields>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleThread: ThreadEntity = {
  conversation_id: 123,
  id: 0,
  title: "My thread",
  customer_id: 456,
  provider_id: 789,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  user_id: 543,
};
