import { Box, Button, Flex, Stack } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconDatabase } from "@tabler/icons-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

import { clearCacheByPattern } from "../../../common/utils/network.js";
import { DeleteConfirmationModal } from "../../components/FileExplorer/DeleteConfirmationModal.js";
import { ExplorerToolbar } from "../../components/FileExplorer/ExplorerToolbar.js";
import { FileCard } from "../../components/FileExplorer/FileCard.js";
import { FileExplorerHeader } from "../../components/FileExplorer/FileExplorerHeader.js";
import { FileList } from "../../components/FileExplorer/FileList.js";
import { NewFolderModal } from "../../components/FileExplorer/NewFolderModal.js";
import { RenameModal } from "../../components/FileExplorer/RenameModal.js";
import { UploadContent } from "../../components/FileExplorer/UploadContent.js";
import { IconSizes } from "../../components/FileExplorer/types.js";
import { Layout } from "../../components/Layout.js";
import { StandardEmpty } from "../../components/Standard/StandardEmpty.js";
import { StandardError } from "../../components/Standard/StandardError.js";
import { StandardLoader } from "../../components/Standard/StandardLoader.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { useAside } from "../../hooks/useAside.js";
import { clearKvCache, useKvStorage } from "../../hooks/useKvStorage.js";
import { filesClient } from "../../utils/filesClient.js";

// File properties type
interface FileItem {
  name: string;
  type: "file" | "folder";
  size?: number;
  updatedAt?: Date;
  uploadedBy?: string;
  modifiedBy?: string;
  url?: string;
  path: string;
}

// File upload item type
interface UploadItem {
  id: string;
  file: File;
  progress: number;
  status: "queued" | "uploading" | "completed" | "error";
  error?: string;
}

let lastValues: {
  currentPath: string;
  customerId: number;
  navigate: NavigateFunction;
  serviceProviderId: number;
} | null = null;

function FileExplorerContent(): React.ReactNode {
  const { customer, serviceProvider, user, setAttachedFiles } =
    useActiveStates();
  const { openPennyChat, openContent, updateFolderPath, isPennyChatOpen } =
    useAside();
  const navigate = useNavigate();
  const location = useLocation();

  // Current path state (from URL)
  const [currentPath, setCurrentPath] = useState<string>("");
  // Search term
  const [searchTerm, setSearchTerm] = useState<string>("");
  // View mode (grid/list)
  const [viewMode, setViewMode] = useState<"grid" | "list">("grid");
  // Icon size
  const [iconSize, setIconSize] = useState<IconSizes>("large");
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const kv = useKvStorage("explorer", {
    user,
    provider: serviceProvider,
    customer,
  });

  // Track if we've already loaded the icon size to prevent duplicate requests
  const iconSizeLoadedRef = useRef<boolean>(false);
  // Track last set value to prevent duplicate requests
  const lastIconSizeRef = useRef<string | null>(null);
  // Track if the user has manually changed the icon size
  const userChangedIconSizeRef = useRef<boolean>(false);
  // Track if we've already loaded the view mode to prevent duplicate requests
  const viewModeLoadedRef = useRef<boolean>(false);
  // Track last set value for view mode to prevent duplicate requests
  const lastViewModeRef = useRef<string | null>(null);
  // Track if the user has manually changed the view mode
  const userChangedViewModeRef = useRef<boolean>(false);
  // Files and folders
  const [items, setItems] = useState<FileItem[]>([]);
  // Storage usage
  const [storage, setStorage] = useState({
    used: 0,
    total: 5 * 1024 * 1024 * 1024,
  }); // 5GB total
  // Loading state
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingIconSize, setIsLoadingIconSize] = useState(true);
  const [isLoadingViewMode, setIsLoadingViewMode] = useState(true);
  // Error state
  const [error, setError] = useState<string | null>(null);
  // File input ref
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  // State for folder creation modal
  const [newFolderModalOpen, setNewFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [creatingFolder, setCreatingFolder] = useState(false);

  // New states for rename modal
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [itemToRename, setItemToRename] = useState<FileItem | null>(null);
  const [newName, setNewName] = useState("");
  const [isRenaming, setIsRenaming] = useState(false);

  // New states for delete confirmation
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<FileItem | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // Ref to track the latest request path to prevent race conditions
  const latestRequestPathRef = useRef<string>("");

  useEffect(() => {
    async function load(): Promise<void> {
      if (initialLoadDone || iconSizeLoadedRef.current) {
        return;
      }

      iconSizeLoadedRef.current = true;
      try {
        const savedIconSize = await kv.minimal.getItem("iconSize");
        if (savedIconSize && savedIconSize !== iconSize) {
          // Update the lastIconSizeRef with the loaded value to prevent a save cycle
          lastIconSizeRef.current = savedIconSize;
          setIconSize(savedIconSize as IconSizes);
        } else {
          // If no saved value, initialize lastIconSizeRef with the default
          lastIconSizeRef.current = iconSize;
        }
      } catch (error: any) {
        if (error.message === "Not Found") {
          // Initialize lastIconSizeRef with the default on error too
          lastIconSizeRef.current = iconSize;
          return;
        }
        console.error("Error loading icon size:", error);
      } finally {
        setInitialLoadDone(true);
        setIsLoadingIconSize(false);
      }
    }
    load();
  }, [kv, initialLoadDone]);

  useEffect(() => {
    async function load(): Promise<void> {
      if (initialLoadDone || viewModeLoadedRef.current) {
        return;
      }

      viewModeLoadedRef.current = true;
      try {
        const savedViewMode = await kv.minimal.getItem("viewMode");
        if (savedViewMode && savedViewMode !== viewMode) {
          // Update the lastViewModeRef with the loaded value to prevent a save cycle
          lastViewModeRef.current = savedViewMode;
          setViewMode(savedViewMode as "grid" | "list");
        } else {
          // If no saved value, initialize lastViewModeRef with the default
          lastViewModeRef.current = viewMode;
        }
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (error.message === "Not Found") {
          // Initialize lastViewModeRef with the default on error too
          lastViewModeRef.current = viewMode;
          return;
        }
        console.error("Error loading view mode:", error);
      } finally {
        setIsLoadingViewMode(false);
      }
    }
    load();
  }, [kv, initialLoadDone]);

  useEffect(() => {
    async function save(): Promise<void> {
      if (!initialLoadDone) {
        return;
      }

      // Only set the value if it's different from the last one AND if the user changed it
      if (
        viewMode !== lastViewModeRef.current &&
        userChangedViewModeRef.current
      ) {
        lastViewModeRef.current = viewMode;
        await kv.minimal.setItem("viewMode", viewMode);
      }
    }
    save();
  }, [viewMode, kv, initialLoadDone]);

  useEffect(() => {
    async function save(): Promise<void> {
      if (!initialLoadDone) {
        return;
      }

      // Only set the value if it's different from the last one AND if the user changed it
      if (
        iconSize !== lastIconSizeRef.current &&
        userChangedIconSizeRef.current
      ) {
        lastIconSizeRef.current = iconSize;
        await kv.minimal.setItem("iconSize", iconSize);
      }
    }
    save();
  }, [iconSize, kv, initialLoadDone]);

  // Handle file/folder actions
  const handleOpenItem = (item: FileItem) => {
    if (item.type === "folder") {
      navigate(`/files/${item.path}`);
    } else if (item.url) {
      window.open(item.url, "_blank");
    }
  };

  const handleDownload = (item: FileItem) => {
    if (item.type === "file" && item.url) {
      // Create a temporary anchor to trigger download
      const a = document.createElement("a");
      a.href = item.url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      notifications.show({
        title: "Download started",
        message: `Downloading ${item.name}`,
        color: "blue",
      });
    }
  };

  const handleRenameClick = (item: FileItem) => {
    setItemToRename(item);
    setNewName(item.name);
    setRenameModalOpen(true);
  };

  const handleDeleteClick = (item: FileItem) => {
    setItemToDelete(item);
    setDeleteModalOpen(true);
  };

  const performRename = async () => {
    if (!itemToRename || !newName.trim() || newName === itemToRename.name) {
      setRenameModalOpen(false);
      return;
    }

    setIsRenaming(true);

    try {
      // TODO: Implement the actual rename functionality
      // This is a placeholder for future implementation

      notifications.show({
        title: "Not implemented",
        message: "Rename functionality is not yet implemented",
        color: "orange",
      });

      // Close the modal after success or failure
      setRenameModalOpen(false);
      setItemToRename(null);
      setNewName("");
    } catch (error) {
      notifications.show({
        title: "Error",
        message:
          error instanceof Error ? error.message : "Failed to rename item",
        color: "red",
      });
    } finally {
      setIsRenaming(false);
    }
  };

  const performDelete = async () => {
    if (!itemToDelete) {
      setDeleteModalOpen(false);
      return;
    }

    setIsDeleting(true);

    try {
      if (itemToDelete.type === "file" && itemToDelete.url) {
        // Call delete API
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result = await (filesClient as any).deleteFile(itemToDelete.url);

        if (result.success) {
          notifications.show({
            title: "Success",
            message: `${itemToDelete.name} has been deleted`,
            color: "green",
          });

          // Refresh the file list
          refreshFiles();
        } else {
          throw new Error(result.error || "Delete failed");
        }
      } else if (itemToDelete.type === "folder") {
        // TODO: Implement folder deletion
        notifications.show({
          title: "Not implemented",
          message: "Folder deletion is not yet implemented",
          color: "orange",
        });
      }

      // Close modal
      setDeleteModalOpen(false);
      setItemToDelete(null);
    } catch (error) {
      notifications.show({
        title: "Error",
        message:
          error instanceof Error ? error.message : "Failed to delete item",
        color: "red",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  // Function to load files with retry logic for eventual consistency
  const loadFiles = useCallback(
    async (folderPath: string, afterUpload = false) => {
      console.log("Loading files for path:", folderPath);

      // Only show loading state if we don't have any items yet
      if (items.length === 0) {
        setIsLoading(true);
      }
      setError(null);

      // Store the requested path in the ref to track the latest request
      const path = currentPath || "";
      latestRequestPathRef.current = path;

      console.log(
        "Starting load for path:",
        path,
        "| afterUpload:",
        afterUpload
      );

      try {
        if (!serviceProvider?.id) {
          setError("Service provider ID is required");
          return;
        }

        // Track if this request is after an upload
        console.log(
          "Loading files for path:",
          path,
          afterUpload ? "(after upload)" : ""
        );

        // Use filesClient to get the list of files with required parameters
        const result = await filesClient.listFiles(
          path,
          serviceProvider.id,
          customer?.id,
          afterUpload // Pass whether this is after an upload to handle eventual consistency
        );

        // Check if this response is for the most recent request
        // If not, ignore it to prevent race conditions
        if (latestRequestPathRef.current !== path) {
          console.log(
            "Ignoring stale response for path:",
            path,
            "| Current path:",
            latestRequestPathRef.current
          );
          return; // Don't update state with old data
        }

        if (!result.success) {
          throw new Error(result.error || "Failed to load files");
        }

        // Handle empty results that might be due to eventual consistency
        if (afterUpload && result.data?.shouldRetry) {
          console.log(
            "Vercel Blob has eventual consistency - retrying list after delay"
          );
          // Wait a moment and retry once more
          setTimeout(() => {
            // Only retry if this is still the active path
            if (latestRequestPathRef.current === path) {
              loadFiles(folderPath, false); // Retry without afterUpload flag to prevent loops
            }
          }, 2000);
        }

        // Check one more time before updating state
        if (latestRequestPathRef.current !== path) {
          console.log("Preventing stale data update");
          return;
        }

        // Safely handle potentially undefined data
        if (result.data) {
          // Set the items in state
          setItems(result.data.items || []);
          setStorage({
            used: result.data.storageUsed || 0,
            total: 5 * 1024 * 1024 * 1024, // 5GB
          });
          setIsLoading(false);
        } else {
          setItems([]);
          setError("No data returned from server");
          setIsLoading(false);
        }

        console.log("Files reloaded", afterUpload ? "after upload" : "");
      } catch (error) {
        // Only apply error state if this is still the current path
        if (latestRequestPathRef.current !== path) {
          return;
        }

        // Handle error and redirect if needed
        console.error("Error loading files:", error);
        setItems([]);
        setError(
          error instanceof Error ? error.message : "Failed to load files"
        );

        // If there was an error and we're not at root, redirect to root
        if (currentPath) {
          notifications.show({
            title: "Error",
            message: "Failed to load the specified folder. Returning to root.",
            color: "red",
          });
          navigate("/files");
        }

        // Error states should remove loading state immediately
        setIsLoading(false);
      }
    },
    [currentPath, customer?.id, navigate, serviceProvider?.id, items.length]
  );

  // Handle upload complete event
  const handleUploadComplete = () => {
    // Reload the file list
    loadFiles(currentPath, true); // true = after upload, handle eventual consistency
  };

  // Refresh file list
  const refreshFiles = () => {
    loadFiles(currentPath, false); // false = normal refresh, not after upload
  };

  // Load files on path or service provider change
  useEffect(() => {
    const pathMatch = location.pathname.match(/^\/files(?:\/(.*))?$/);
    const path = pathMatch ? pathMatch[1] || "" : "";

    // If PennyChat is open, update the folder path
    if (isPennyChatOpen) {
      console.log("Updating folder path on load:", path);
      updateFolderPath(path);
    }

    setCurrentPath(path);
    loadFiles(path, false);
  }, [
    location.pathname,
    loadFiles,
    serviceProvider?.id,
    customer?.id,
    updateFolderPath,
  ]);

  // Navigate to a folder
  const handleFolderClick = (path: string) => {
    // Clear both caches before navigation
    clearKvCache("explorer", serviceProvider?.id, customer?.id);
    clearCacheByPattern("/api/kv");

    // Update folder path in the aside context if chat is open
    if (isPennyChatOpen) {
      console.log("Updating folder path in aside context:", path);
      updateFolderPath(path);
    }

    // Navigate to the folder
    navigate(`/files/${path}`);
  };

  // Handle search
  const handleSearch = () => {
    if (searchTerm) {
      setIsLoading(true);
      setError(null);

      // Store the current search state to track this request
      const searchPath = currentPath || "";
      const currentSearchTerm = searchTerm;
      latestRequestPathRef.current = `${searchPath}:search:${currentSearchTerm}`;

      console.log(
        "Starting search for term:",
        currentSearchTerm,
        "in path:",
        searchPath
      );

      // Search for files in the current path
      filesClient
        .listFiles(searchPath, serviceProvider?.id, customer?.id)
        .then((result) => {
          // Check if this is still the active search
          if (
            latestRequestPathRef.current !==
            `${searchPath}:search:${currentSearchTerm}`
          ) {
            console.log("Ignoring stale search results");
            return;
          }

          if (result.success && result.data) {
            // Filter items by search term
            const filtered = result.data.items.filter((item) =>
              item.name.toLowerCase().includes(currentSearchTerm.toLowerCase())
            );
            setItems(filtered);
          } else {
            setError(result.error || "Search failed");
          }
        })
        .catch((error) => {
          // Only show error if this is still the active search
          if (
            latestRequestPathRef.current !==
            `${searchPath}:search:${currentSearchTerm}`
          ) {
            return;
          }

          setError(error instanceof Error ? error.message : "Search failed");
        })
        .finally(() => {
          // Only update loading state if this is still the active search
          if (
            latestRequestPathRef.current !==
            `${searchPath}:search:${currentSearchTerm}`
          ) {
            return;
          }

          setIsLoading(false);
        });
    } else {
      // If search term is empty, just reload files
      loadFiles(currentPath, false);
    }
  };

  // Generate breadcrumbs from current path
  const generateBreadcrumbs = () => {
    const breadcrumbItems = [
      { title: <IconDatabase size={16} />, href: "/files" },
    ];

    if (currentPath) {
      const segments = currentPath.split("/").filter(Boolean);
      let accPath = "";

      segments.forEach((segment) => {
        accPath += `${segment}/`;
        breadcrumbItems.push({
          title: <span>{segment}</span>,
          href: `/files/${accPath}`,
        });
      });
    }

    return breadcrumbItems.map((item, index) => (
      <Button
        key={index}
        variant="subtle"
        size="xs"
        onClick={() => navigate(item.href)}
      >
        {item.title}
      </Button>
    ));
  };

  // Calculate used storage percentage
  const usedStoragePercentage = Math.min(
    Math.round((storage.used / storage.total) * 100),
    100
  );

  // Handle upload button click
  const handleUpload = () => {
    console.log(
      "Upload handler - opening upload panel with path:",
      currentPath
    );

    // Always make sure the current folder path is updated in the aside context
    if (isPennyChatOpen) {
      updateFolderPath(currentPath);
    }

    openContent(
      <UploadContent
        currentPath={currentPath}
        onUploadComplete={handleUploadComplete}
      />,
      true // preserve folder path
    );
  };

  // New folder handler to open the modal
  const handleNewFolder = () => {
    setNewFolderName("");
    setNewFolderModalOpen(true);
  };

  // Create folder implementation
  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      notifications.show({
        title: "Error",
        message: "Folder name cannot be empty",
        color: "red",
      });
      return;
    }

    // Check for service provider ID first
    if (!serviceProvider?.id) {
      notifications.show({
        title: "Error",
        message: "Service provider ID is required to create folders",
        color: "red",
      });
      return;
    }

    setCreatingFolder(true);

    try {
      // Construct the path for the new folder - append to current path if we're in a folder
      const folderPath = currentPath
        ? `${currentPath}/${newFolderName}`
            .replace(/^\/+|\/+$/g, "") // Remove leading/trailing slashes
            .replace(/\/+/g, "/") // Replace multiple slashes with single slash
        : newFolderName
            .replace(/^\/+|\/+$/g, "") // Remove leading/trailing slashes
            .replace(/\/+/g, "/"); // Replace multiple slashes with single slash

      // Log important variables before making the request
      console.log("Creating folder with:", {
        folderPath,
        rawPath: currentPath
          ? `${currentPath}/${newFolderName}`
          : newFolderName,
        normalizedPath: folderPath,
        serviceProviderId: serviceProvider.id,
        customerId: customer?.id,
      });

      // Use the filesClient to create the folder with the correct type
      const result = await filesClient.createFolder(
        folderPath,
        serviceProvider.id,
        customer?.id
      );

      console.log("Create folder result:", result);

      if (!result.success) {
        console.error("Create folder error details:", {
          error: result.error,
          success: result.success,
          data: result.data,
        });
        throw new Error(result.error || "Failed to create folder");
      }

      // Close the modal and refresh the file list
      setNewFolderModalOpen(false);

      // Show success notification
      notifications.show({
        title: "Success",
        message: `Folder "${newFolderName}" created successfully`,
        color: "green",
      });

      // Refresh files to show the new folder
      refreshFiles();
    } catch (error) {
      console.error("Error creating folder:", error);

      // Extract error message safely and ensure it's a string
      const errorMessage =
        error instanceof Error ? error.message : "Failed to create folder";

      notifications.show({
        title: "Error",
        message: errorMessage,
        color: "red",
      });
    } finally {
      setCreatingFolder(false);
    }
  };

  // Handle adding file to chat
  const handleAddToChat = (item: FileItem) => {
    if (item.type === "file" && item.url) {
      // Add to attachedFiles
      setAttachedFiles((prev) => {
        // Don't add if already exists
        if (prev.some((f) => f.url === item.url)) {
          return prev;
        }
        return [
          ...prev,
          {
            url: item.url!,
            name: item.name,
            size: item.size || 0,
          },
        ];
      });

      // Open PennyChat if not already open
      if (!isPennyChatOpen) {
        openPennyChat(currentPath);
      }

      notifications.show({
        title: "File added to chat",
        message: `${item.name} has been added to the chat`,
        color: "blue",
      });
    }
  };

  return (
    <Stack gap={0}>
      <FileExplorerHeader
        storageUsed={storage.used}
        storageTotal={storage.total}
        usedStoragePercentage={usedStoragePercentage}
        onUpload={handleUpload}
        currentPath={currentPath}
      />

      {/* Toolbar */}
      <ExplorerToolbar
        breadcrumbs={generateBreadcrumbs()}
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        onSearch={handleSearch}
        onNewFolder={handleNewFolder}
        viewMode={viewMode}
        onViewModeChange={(value) => {
          userChangedViewModeRef.current = true;
          setViewMode(value);
        }}
        iconSize={iconSize}
        onIconSizeChange={(value) => {
          userChangedIconSizeRef.current = true;
          setIconSize(value);
        }}
        isLoading={isLoading}
        isLoadingIconSize={isLoadingIconSize}
        isLoadingViewMode={isLoadingViewMode}
      />

      {/* Files and folders grid/list */}
      {isLoading ? (
        <StandardLoader title="Loading files..." />
      ) : error ? (
        <StandardError title="Error loading files" error={error} />
      ) : items.length === 0 ? (
        <StandardEmpty title="No files found" />
      ) : (
        <Box p="md">
          {viewMode === "grid" ? (
            <Flex wrap="wrap" gap="md">
              {items.map((item, index) => (
                <FileCard
                  key={`${item.path}-${item.name}-${index}`}
                  item={item}
                  size={iconSize}
                  onClick={() => handleFolderClick(item.path)}
                  onOpen={() => handleOpenItem(item)}
                  onDownload={() => handleDownload(item)}
                  onRename={() => handleRenameClick(item)}
                  onDelete={() => handleDeleteClick(item)}
                  onAddToChat={() => handleAddToChat(item)}
                />
              ))}
            </Flex>
          ) : (
            <FileList
              items={items}
              onItemClick={(item) => handleFolderClick(item.path)}
              onOpen={handleOpenItem}
              onDownload={handleDownload}
              onRename={handleRenameClick}
              onDelete={handleDeleteClick}
              onAddToChat={handleAddToChat}
            />
          )}
        </Box>
      )}

      {/* New Folder Modal */}
      <NewFolderModal
        opened={newFolderModalOpen}
        onClose={() => setNewFolderModalOpen(false)}
        folderName={newFolderName}
        onFolderNameChange={setNewFolderName}
        onCreate={handleCreateFolder}
        isCreating={creatingFolder}
      />

      {/* Rename Modal */}
      <RenameModal
        opened={renameModalOpen}
        onClose={() => setRenameModalOpen(false)}
        item={itemToRename}
        newName={newName}
        onNameChange={setNewName}
        onRename={performRename}
        isRenaming={isRenaming}
      />

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        opened={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        item={itemToDelete}
        onDelete={performDelete}
        isDeleting={isDeleting}
      />
    </Stack>
  );
}

// Wrap with Layout
export function FileExplorer() {
  return (
    <Layout>
      <FileExplorerContent />
    </Layout>
  );
}
