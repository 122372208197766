import { useEffect, useState } from "react";
import { Group, Text } from "@mantine/core";

import { OrganizationRelationshipUserEntity } from "../../../common/entities/organizationRelationshipUser.js";
import { getUserName, UserEntity } from "../../../common/entities/user.js";
import { OrganizationUserEntity } from "../../../common/entities/organizationUser.js";
import { fetchUserCached } from "./userProfileUtils.js";

import { UserAvatar } from "../Avatars/UserAvatar.js";

export function UserProfile({
  user,
  withAvatar = false,
  withArchived = false,
}: {
  user:
    | UserEntity
    | (OrganizationUserEntity & { User?: UserEntity }) // User is a join field
    | OrganizationRelationshipUserEntity;
  withAvatar?: boolean;
  withArchived?: boolean;
}) {
  const id_to_fetch = (() => {
    if ("user_id" in user) {
      return user.user_id!;
    } else if ("id" in user) {
      return user.id!;
    } else {
      throw new Error("User has no id or user_id");
    }
  })();
  const [userData, setUserData] = useState<UserEntity | undefined>(
    "User" in user ? user.User : undefined
  );

  const userId =
    (user as OrganizationRelationshipUserEntity)?.user_id ??
    (user as UserEntity)?.id;

  useEffect(() => {
    fetchUserCached(id_to_fetch, userData, setUserData);
  }, [userId, setUserData]);

  if (!userData) {
    return (
      <div>
        Loading{" "}
        {"id" in user ? user.id : "user_id" in user ? user.user_id : "N/A"}...
      </div>
    );
  }

  if (withAvatar) {
    if (userData.archived_at) {
      return <Text>Removed</Text>;
    }
    return (
      <Group gap="sm">
        <UserAvatar user={userData} size={45} radius={80} />
        <div>
          <Text fw={700}> {getUserName(userData)}</Text>
          <Text fz="sm" c="dimmed">
            {user.archived_at ? "Added on" : "Member since"}{" "}
            {new Date(user.created_at ?? Date.now()).toLocaleDateString(
              undefined,
              {
                month: "short",
                day: "numeric",
                year: "numeric",
              }
            )}
          </Text>
          {withArchived && user.archived_at && (
            <Text fz="sm" c="dimmed">
              Removed on{" "}
              {new Date(user.archived_at).toLocaleDateString(undefined, {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </Text>
          )}
        </div>
      </Group>
    );
  }

  return <div>{getUserName(userData)}</div>;
}
