import { css } from "../../utils/css.js";

interface NoteClasses {
  notesContainer: string;
}

export default css<NoteClasses>`
  .notesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1rem;
    grid-auto-rows: 20px;
  }
`;
