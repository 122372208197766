import {
  Anchor,
  Box,
  Button,
  Divider,
  Group,
  List,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { useEffect, useState } from "react";

import type {
  APIDocumentation,
  HttpMethod,
} from "../../../common/types/apiDocumentation.js";

import { entitiesByName } from "../../../common/entities/entitiesByName.js";
import { lastUpdated } from "../../../common/last-updated.js";
import { network } from "../../../common/utils/network.js";
import { StandardError } from "../../components/Standard/StandardError.js";
import { StandardLoader } from "../../components/Standard/StandardLoader.js";
import { MarkdownRenderer } from "../../pages/penny/MarkdownRenderer.js";
import { clientError } from "../../utils/clientError.js";
import { AdminLayout } from "../layouts/AdminLayout.js";
import { RouteAccessControlSummary } from "./RouteAccessControlSummary.js";

import classes from "./APIDocumentation.css.js";

export function APIDocumentationComponent() {
  const [doc, setDoc] = useState<APIDocumentation | null>(null);
  const [reload, setReload] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | string | null>(null);

  useEffect(() => {
    async function load() {
      try {
        setIsLoading(true);
        setError(null);
        const docResponse = await network.get<
          | {
              success: true;
              data: APIDocumentation;
            }
          | { success: false; error: string }
        >(`/api/docs/api`, {}, clientError);
        if (docResponse.success) {
          if (docResponse.data && docResponse.data.success) {
            setDoc(docResponse.data.data);
          } else {
            setError(docResponse.data.error ?? "No data returned");
          }
        } else {
          setError(docResponse.error ?? "No data returned");
        }
      } catch (e: any) {
        console.error(e);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }
    load().catch((e) => console.error(e));
  }, [reload]);

  return (
    <AdminLayout>
      {isLoading ? (
        <StandardLoader title="Loading documentation" />
      ) : (
        <>
          {error && (
            <StandardError error={error} title="Error loading documentation" />
          )}
          <Group className={classes.printHidden}>
            <Tooltip
              label="Reload documentation"
              position="left"
              withArrow
              zIndex={301}
            >
              <Button
                disabled={isLoading}
                size="sm"
                variant="outline"
                style={{
                  bottom: "11px",
                  flexShrink: "0",
                  paddingInline: "4px",
                  position: "fixed",
                  right: "24px",
                }}
                onClick={() => setReload((x) => x + 1)}
              >
                <IconRefresh />
              </Button>
            </Tooltip>
          </Group>
          {doc ? <APIDocumentationRenderer doc={doc} /> : null}
        </>
      )}
    </AdminLayout>
  );
}

function smoothScrollToId(id: string) {
  return (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      (element.firstElementChild ?? element).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };
}

function APIDocumentationRenderer({ doc }: { doc: APIDocumentation }) {
  const accessControlledRouteMethods = Object.values(doc.routes).reduce(
    (acc, routes) => {
      return (
        acc +
        routes.reduce(
          (acc, route) =>
            acc +
            route.methods.filter(
              (k) => route.accessControl[k as HttpMethod]?.result !== undefined
            ).length,
          0
        )
      );
    },
    0
  );

  const totalRouteMethods = Object.values(doc.routes).reduce((acc, routes) => {
    return acc + routes.reduce((acc, route) => acc + route.methods.length, 0);
  }, 0);

  const fractionOfRouteMethodsWithAccessControl =
    accessControlledRouteMethods / totalRouteMethods;

  return (
    <>
      <Box id="toc">
        <Title order={1} px="md" mt="lg">
          LedgerAI API Documentation
        </Title>
        <Text px="md" mt="lg">
          This documentation was last updated on{" "}
          {new Date(lastUpdated * 1000).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
          .
        </Text>
        <Text px="md" mt="lg">
          Access control is applied to {accessControlledRouteMethods} (
          {Math.round(fractionOfRouteMethodsWithAccessControl * 100)}%) of the{" "}
          {totalRouteMethods} routes.
        </Text>
        <Title order={4} px="md" mt="lg">
          Table of Contents
        </Title>
        <List p="xs" px="md">
          <List.Item>
            <Anchor
              href="#entity-apis-directory"
              onClick={smoothScrollToId("entity-apis-directory")}
            >
              Entity APIs Directory
            </Anchor>
          </List.Item>
          <List.Item>
            <Anchor
              href="#entity-apis"
              onClick={smoothScrollToId("entity-apis")}
            >
              Entity APIs
            </Anchor>
          </List.Item>
        </List>
      </Box>
      <Box id="entity-apis-directory">
        <Title order={2} px="md" mt="xl" mb="lg">
          Entity APIs Directory
        </Title>
        {Object.entries(doc.routes).map(([group, routes]) => (
          <Box key={group} mt="md" px="md">
            <Box id={group}>
              <Title order={3}>🗃️ {group}</Title>
              <List p="xs">
                {routes.map((route) => (
                  <List.Item key={route.path}>
                    <Anchor
                      href={`#${route.path}`}
                      onClick={smoothScrollToId(route.path)}
                    >
                      {route.title}
                    </Anchor>
                  </List.Item>
                ))}
              </List>
            </Box>
          </Box>
        ))}
      </Box>
      <Title order={2} px="md" mt="xl" mb="lg" id="entity-apis">
        Entity APIs
      </Title>
      {Object.entries(doc.routes).map(([group, routes]) => (
        <Box id={group} key={group}>
          <Divider my="xl" />
          <Text c="dimmed" size="sm" tt="uppercase" mb={0} px="md">
            ENTITY
          </Text>

          <Title order={3} px="md">
            🗃️ {group}
          </Title>

          {routes.map((route) => (
            <Box key={route.path} mt="md" px="md">
              <Box id={route.path}>
                <Group>
                  <Anchor
                    className={classes.printHidden}
                    href="#toc"
                    size="sm"
                    onClick={smoothScrollToId("toc")}
                  >
                    ⮝ Back to table of contents
                  </Anchor>
                </Group>

                <Title order={3} my="md">
                  {route.title}
                </Title>

                {typeof route.docs.summary === "string" && (
                  <MarkdownRenderer content={route.docs.summary} />
                )}

                {route.methods.map((method, i) => (
                  <Box
                    key={method}
                    ml="xl"
                    mt="md"
                    pl="md"
                    pt="xs"
                    style={{
                      borderLeft: "4px solid var(--mantine-color-gray-5)",
                    }}
                  >
                    {typeof route.description[method] === "string" && (
                      <MarkdownRenderer content={route.description[method]} />
                    )}
                    <Box mt="md">
                      <RouteAccessControlSummary
                        accessControl={route.accessControl[method]}
                        forEntity={
                          entitiesByName[group as keyof typeof entitiesByName]
                        }
                      />
                    </Box>
                    <Box pos="relative">
                      <Text
                        left="-55px"
                        pos="absolute"
                        style={{ transform: "scale(1.65)" }}
                        top="-27px"
                      >
                        {"❶❷❸❹❺❻❼⓼"[i]}
                      </Text>
                    </Box>

                    <Title order={4} px="md" my="md">
                      {method} /api/{route.path}
                    </Title>

                    {typeof route.params[method] === "string" && (
                      <MarkdownRenderer content={route.params[method]} />
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </>
  );
}
