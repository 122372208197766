import { css } from "../../../utils/css.js";

export interface SettingsMenuClasses {
  drawerContent: string;
  drawerContentDark: string;
  drawerBody: string;
  drawerHeader: string;
  menuItem: string;
  drawerContentFixedSize: string;
  drawerCloseButton: string;
}

export default css<SettingsMenuClasses>`
  .menuItem {
    background-color: transparent;
  }
  .drawerContent {
    border-top-right-radius: var(--mantine-radius-lg);
    border-top-left-radius: var(--mantine-radius-lg);
    height: fit-content;
  }
  .drawerBody {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
  }
  .drawerContentFixedSize {
    border-top-right-radius: var(--mantine-radius-lg);
    border-top-left-radius: var(--mantine-radius-lg);
  }
  .drawerContentDark {
    background-color: var(--mantine-color-gray-8);
  }
  .drawerHeader {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: unset;
    height: 0px;
    top: 20px;
  }
  .drawerCloseButton {
    height: 20px !important;
    width: 20px !important;
  }
`;
