import {
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Indicator,
  Stack,
  Text,
  Title,
  useMantineColorScheme,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useCallback } from "react";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import { ENTITLEMENT_MANAGE_ORGANIZATION_SETTINGS } from "../../../common/fields/entitlements.js";
import { DEFAULT_THEME_PRIMARY_COLOR } from "../../../common/fields/theme_primary_color.js";
import { EntityServiceResult } from "../../../common/types/entity.js";
import { organizationClient } from "../../../common/utils/entityClient.js";
import {
  useActiveStates,
  useOrganizationUserHasEntitlement,
} from "../../contexts/ActiveStatesContext.js";
import { useOrganizationModal } from "../../hooks/useOrganizationModal.js";
import { PrimaryColor } from "../../theme.js";
import { clientError } from "../../utils/clientError.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";
import { UpdateEntitlements } from "./OrganizationEntitlement.js";
import { OrganizationEntitlements } from "./OrganizationEntitlements.js";
import { MarkdownRenderer } from "../../pages/penny/MarkdownRenderer.js";

interface CompanyOverviewProps {
  activeOrganization: OrganizationEntity;
}

export function CompanyOverview({ activeOrganization }: CompanyOverviewProps) {
  const { customer, setCustomer, setServiceProvider } = useActiveStates();
  const theme = useMantineColorScheme();
  const backgroundColor =
    theme.colorScheme === "dark" ? "org_primary.9" : "org_primary.0";

  const isCustomer = activeOrganization.id === customer?.id;

  const handleUpdateSubmit = useCallback(
    async (result: EntityServiceResult<OrganizationEntity>) => {
      if (result.success && result.data) {
        if (isCustomer) {
          setCustomer(result.data);
        } else {
          setServiceProvider(result.data);
        }
      } else {
        throw new Error(
          result.success === false
            ? result.errors.join(";")
            : "missing result data"
        );
      }
    },
    [setCustomer, setServiceProvider, isCustomer]
  );

  const formattedDate = new Date(
    activeOrganization.created_at ?? new Date()
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const handleUpdateEntitlements = useCallback<UpdateEntitlements>(
    async (operations) => {
      const patch = {
        entitlements: (
          (activeOrganization.entitlements as unknown as number[]) ?? []
        )
          .slice(0)
          .filter((x) => operations.remove_entitlement !== x),
        extend_entitlements: (
          (activeOrganization.extend_entitlements as unknown as number[]) ?? []
        )
          .slice(0)
          .filter((x) => operations.remove_extend_entitlement !== x),
      };
      if (typeof operations.add_entitlement === "number") {
        if (!patch.entitlements.includes(operations.add_entitlement)) {
          patch.entitlements.push(operations.add_entitlement);
        }
      }
      if (typeof operations.add_extend_entitlement === "number") {
        if (
          !patch.extend_entitlements.includes(operations.add_extend_entitlement)
        ) {
          patch.extend_entitlements.push(operations.add_extend_entitlement);
        }
      }
      const updated = await organizationClient(clientError)
        .item(activeOrganization.id!)
        .update(patch as unknown as Partial<OrganizationEntity>);
      if (updated.success && updated.data.success) {
        if (activeOrganization.id === customer?.id) {
          setCustomer(updated.data.data);
        } else {
          setServiceProvider(updated.data.data);
        }
      }
    },
    [activeOrganization, setCustomer, setServiceProvider, customer?.id]
  );

  const hasEntitlement = useOrganizationUserHasEntitlement();

  const has_entitlement_manage_organization_settings = hasEntitlement(
    ENTITLEMENT_MANAGE_ORGANIZATION_SETTINGS
  );

  const { modal: organizationModal, setModalOpened: setOrganizationModalOpen } =
    useOrganizationModal({
      editMode: true,
      onUpdateSuccess: handleUpdateSubmit,
      organization: activeOrganization,
      serviceProvider: activeOrganization,
    });

  return (
    <>
      <Card radius={0} px={0} pb="xl">
        {/* Color Banner */}
        <Card.Section bg={backgroundColor} h={150} />

        {/* Profile Section */}
        <Card.Section px="xl">
          <Group
            justify="space-between"
            align="flex-start"
            style={{ marginTop: -60 }}
          >
            <Group>
              <OrganizationAvatar
                style={{ border: "2px solid var(--mantine-color-body)" }}
                organization={activeOrganization}
                variant="filled"
                color={PrimaryColor}
                size={120}
                radius="md"
              />
              <Stack gap="xs" mt={-5}>
                <Title order={3} fw={700} mb="lg">
                  {activeOrganization.name ?? "Unnamed Organization"}
                </Title>
                <Group gap="xs">
                  <Indicator
                    color={
                      activeOrganization.theme_primary_color ??
                      DEFAULT_THEME_PRIMARY_COLOR
                    }
                  />
                  <Text size="sm" c="dimmed">
                    {activeOrganization.theme_primary_color ??
                      DEFAULT_THEME_PRIMARY_COLOR}
                  </Text>
                </Group>
              </Stack>
            </Group>
            {has_entitlement_manage_organization_settings ? (
              <Button
                leftSection={<IconEdit size={16} />}
                onClick={() => setOrganizationModalOpen(true)}
                variant="default"
              >
                Edit
              </Button>
            ) : null}
          </Group>
        </Card.Section>
        <Divider my="lg" />
        <Card.Section px="xl">
          {/* Organization Details */}
          <Grid>
            <Grid.Col span={12}>
              <Stack gap="xs">
                <Text fw={500} size="lg">
                  Organization Details
                </Text>
                <Text size="sm" c="dimmed">
                  Manage your organization's basic information
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Text size="sm" fw={500} c="dimmed">
                Name
              </Text>
              <Text>{activeOrganization.name ?? "Unnamed Organization"}</Text>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Text size="sm" fw={500} c="dimmed">
                Handle
              </Text>
              <Text>@{activeOrganization.slug ?? "no-handle"}</Text>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Text size="sm" fw={500} c="dimmed">
                Domain
              </Text>
              <Text>
                {activeOrganization.primary_domain ?? "No domain set"}
              </Text>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Text size="sm" fw={500} c="dimmed">
                Location
              </Text>
              <Text>
                {activeOrganization.primary_location ?? "No location set"}
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Text size="sm" fw={500} c="dimmed">
                Description
              </Text>
              <MarkdownRenderer
                content={
                  activeOrganization.description ?? "No description provided"
                }
              />
            </Grid.Col>
          </Grid>
        </Card.Section>

        <Divider my="lg" />
        <Card.Section px="xl">
          {/* Metadata Section */}
          <Grid>
            <Grid.Col span={12}>
              <Stack gap="xs">
                <Text fw={500} size="lg">
                  Organization Metadata
                </Text>
                <Text size="sm" c="dimmed">
                  System-generated information about your organization
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Text size="sm" fw={500} c="dimmed">
                Created Date
              </Text>

              <Text>{formattedDate ?? "Date not available"}</Text>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Text size="sm" fw={500} c="dimmed">
                Organization ID
              </Text>
              <Text>{activeOrganization.id ?? "ID not available"}</Text>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Text size="sm" fw={500} c="dimmed">
                Type
              </Text>
              <Text>{isCustomer ? "Client" : "Service Provider"}</Text>
            </Grid.Col>
          </Grid>
        </Card.Section>
        <Card.Section>
          <Divider my="lg" />
          <OrganizationEntitlements
            organization={activeOrganization}
            onUpdateEntitlements={handleUpdateEntitlements}
          />
        </Card.Section>
      </Card>
      {organizationModal}
    </>
  );
}
