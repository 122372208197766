import { Box } from "@mantine/core";

import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { useKvStorage } from "../../hooks/useKvStorage.js";
import { Notes } from "./Notes.js";

export function NotesOverview() {
  const { user, customer, serviceProvider } = useActiveStates();

  const notesStorage = useKvStorage("notes", {
    user,
    customer,
    provider: serviceProvider,
  });

  return (
    <Layout>
      <PageTitle title="Notes" />
      <Box p="md">
        <Notes kv={notesStorage.full} />
      </Box>
    </Layout>
  );
}
