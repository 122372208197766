import { Container, Stack, Text, Title } from "@mantine/core";

import classes from "./welcome/welcome.css.js";

export function GeneralError({
  error,
  message,
  children,
}: {
  error: string;
  message?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className={classes.wrapper}>
      <Container size="xl">
        <Stack>
          <Title className={classes.title} order={1}>
            {error ?? "We're sorry :("}
          </Title>
          <Text className={classes.description}>
            {message ??
              "An error occurred while loading the page. Please try again later."}
          </Text>
          {children}
        </Stack>
      </Container>
    </div>
  );
}
