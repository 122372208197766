import { createContext, MutableRefObject, useContext } from "react";
import { DataRouterContextObject } from "react-router/dist/lib/context.js";

import { OrganizationEntity } from "../../common/entities/organization.js";
import { OrganizationUserEntity } from "../../common/entities/organizationUser.js";
import { UserEntity } from "../../common/entities/user.js";

export const globalAdminRouterRef: MutableRefObject<DataRouterContextObject | null> =
  { current: null };

export interface AdminContextValue {
  adminIsOpen: boolean;
  closeAdmin: () => void;
  organization: OrganizationEntity;
  organizationUser: OrganizationUserEntity;
  user: UserEntity;
}

export const AdminContext = createContext<AdminContextValue | undefined>(
  undefined
);

export function useAdmin() {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error("useAdmin must be used within an AdminContext.Provider");
  }
  return context;
}
