import { Box } from "@mantine/core";
import { useEffect, useRef } from "react";
import { parse, ValuesData, View } from "vega";
import { compile } from "vega-lite";

interface VegaLiteChartProps {
  spec: any;
  width?: number | "container";
  height?: number | "container";
}

export function VegaLiteChart({
  spec,
  width = "container",
  height = 300,
}: VegaLiteChartProps) {
  const chartRef = useRef<HTMLDivElement>(null);
  const viewRef = useRef<View | null>(null);

  console.log("VegaLiteChart rendering with:", {
    hasSpec: !!spec,
    spec: JSON.stringify(spec, null, 2),
    width,
    height,
    hasRef: !!chartRef.current,
    containerDimensions: chartRef.current
      ? {
          width: chartRef.current.clientWidth,
          height: chartRef.current.clientHeight,
          offsetWidth: chartRef.current.offsetWidth,
          offsetHeight: chartRef.current.offsetHeight,
        }
      : null,
  });

  useEffect(() => {
    console.log("VegaLiteChart useEffect triggered with props:", {
      spec: JSON.stringify(spec, null, 2),
      width,
      height,
      chartRef: !!chartRef.current,
      containerDimensions: chartRef.current
        ? {
            width: chartRef.current.clientWidth,
            height: chartRef.current.clientHeight,
            offsetWidth: chartRef.current.offsetWidth,
            offsetHeight: chartRef.current.offsetHeight,
          }
        : null,
    });

    if (!chartRef.current || !spec) {
      console.log("No chart ref or spec available, skipping chart creation");
      return;
    }

    try {
      // Clean up previous view if it exists
      if (viewRef.current) {
        console.log("Cleaning up previous view");
        viewRef.current.finalize();
        viewRef.current = null;
      }

      console.log("Starting chart creation process");
      console.log("Compiling Vega-Lite spec:", JSON.stringify(spec, null, 2));

      // Compile Vega-Lite spec with responsive width
      const responsiveSpec = {
        ...spec,
        width: "container",
        autosize: {
          type: "fit" as const,
          contains: "padding" as const,
        },
        background: "transparent",
      };

      const compiledSpec = compile(responsiveSpec).spec;

      const specData: ValuesData[] = compiledSpec.data as ValuesData[];

      // Ensure the data is properly formatted
      if (specData?.[0]?.values) {
        console.log(
          "Processing data values:",
          JSON.stringify(specData[0].values, null, 2)
        );
        if (Array.isArray(specData[0].values)) {
          specData[0].values = specData[0].values?.map((item: any) => ({
            Month: item.Month || item.category,
            Income: item.Income || item.value,
          }));
        }
        console.log(
          "Processed data values:",
          JSON.stringify(specData[0].values, null, 2)
        );
      }

      // Add interactivity to the spec
      const interactiveSpec = {
        ...compiledSpec,
        signals: [
          ...(compiledSpec.signals || []),
          {
            name: "hover",
            value: null,
            on: [
              { events: "*:mouseover", update: "datum", force: true },
              { events: "*:mouseout", update: "null", force: true },
            ],
          },
        ],
        marks: compiledSpec.marks?.map((mark: any) => ({
          ...mark,
          encode: {
            ...mark.encode,
            update: {
              ...mark.encode.update,
              opacity: [
                {
                  test: "hover && hover.Month === datum.Month",
                  value: 1,
                },
                {
                  value: 0.7,
                },
              ],
              cursor: { value: "pointer" },
            },
          },
        })),
      };

      console.log("Creating Vega view");
      const view = new View(parse(interactiveSpec), {
        renderer: "canvas",
        container: chartRef.current,
        hover: true,
        logLevel: 3,
      });

      viewRef.current = view;

      // Set initial dimensions
      if (chartRef.current) {
        const containerWidth = chartRef.current.getBoundingClientRect().width;
        view.width(containerWidth);
      }

      console.log("Running view");
      view.runAsync();

      // Handle window resize
      const handleResize = () => {
        if (viewRef.current) {
          viewRef.current.run();
        }
      };

      window.addEventListener("resize", handleResize);

      console.log("Chart creation successful");

      return () => {
        console.log("Cleaning up chart");
        window.removeEventListener("resize", handleResize);
        if (viewRef.current) {
          viewRef.current.finalize();
          viewRef.current = null;
        }
      };
    } catch (error) {
      console.error("Error creating chart:", error);
      console.error("Chart spec:", JSON.stringify(spec, null, 2));
      console.error("Chart container:", chartRef.current);
      console.error(
        "Chart container dimensions:",
        chartRef.current
          ? {
              width: chartRef.current.clientWidth,
              height: chartRef.current.clientHeight,
              offsetWidth: chartRef.current.offsetWidth,
              offsetHeight: chartRef.current.offsetHeight,
            }
          : null
      );
    }
  }, [spec, width, height]);

  return (
    <Box
      ref={chartRef}
      style={{
        backgroundColor: "#f0f0f0",
        width: "100%",
        padding: "10px",
        borderRadius: "10px",
        display: "block",
        position: "relative",
        overflow: "hidden",
        minWidth: 0, // Important for flex contexts
      }}
    />
  );
}
