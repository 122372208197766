import { Divider, Title } from "@mantine/core";
import { AdminLayout } from "../layouts/AdminLayout.js";
import { AdminAIProvidersList } from "./AdminAIProvidersList.js";

export function AdminAI() {
  return (
    <AdminLayout>
      <Title order={2} p="xs">
        AI Providers
      </Title>
      <Divider />
      <AdminAIProvidersList />
    </AdminLayout>
  );
}
