import { archivedAtField } from "../fields/archived_at";
import { createdAtField } from "../fields/created_at";
import { entitlementsField } from "../fields/entitlements";
import { isInvitationField } from "../fields/is_invitation";
import { isOrganizationOwnerField } from "../fields/is_organization_owner";
import { organizationIdField } from "../fields/organization_id";
import { userIdFieldRequired } from "../fields/user_id";
import { virtualCustomerIdField } from "../fields/virtual_customer_id";
import { virtualProviderIdField } from "../fields/virtual_provider_id";
import { EntityDocs } from "../types/docs";
import { entity, EntityType } from "../types/entity";

// Docs
export const docs: EntityDocs = {
  collection: {
    summary:
      "List organization users, which are the users which are members of the specified organization, whether it is a customer (or client) organization, or a provider (or service provider) organization.",
  },
  item: { summary: "Perform operations on an organization user." },
};

export const fields = [
  archivedAtField,
  createdAtField,
  entitlementsField,
  isInvitationField,
  isOrganizationOwnerField,
  organizationIdField,
  userIdFieldRequired,
  virtualCustomerIdField,
  virtualProviderIdField,
] as const;

export const primaryKeyFields = [
  organizationIdField,
  userIdFieldRequired,
] as const;

export const organizationUserEntity = entity({
  api: {
    collectionPath: "organization-users",
    itemPath: "organization-users/:organization_id/:user_id",
  },
  docs,
  name: "OrganizationUser",
  fields,
  primaryKeyFields,
  tableName: "organization_users",
});

export type OrganizationUserEntity = EntityType<
  typeof organizationUserEntity.fields,
  typeof organizationUserEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleOrganizationUser: OrganizationUserEntity = {
  archived_at: new Date().toISOString(),
  created_at: new Date().toISOString(),
  entitlements: [],
  is_organization_owner: false,
  organization_id: 765,
  user_id: 123,
};
