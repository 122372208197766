import { createContext } from "react";

import { UserEntity } from "../../common/entities/user.js";

export interface StoredAuthProfile {
  profile: UserEntity;
  sessionId: number;
}

type AuthContextType = {
  account: StoredAuthProfile | undefined;
  allAccounts: StoredAuthProfile[];
  deleteAccount: (account: StoredAuthProfile) => void;
  updateAccount: (account: StoredAuthProfile) => void;
};

export const AuthContext = createContext<AuthContextType>({
  account: undefined,
  allAccounts: [],
  deleteAccount: () => {},
  updateAccount: () => {},
});
