import { createContext, ReactNode } from "react";

// Type definition for the context value
export type AsideContextType = {
  closeAside: () => void;
  closePennyChat: () => void;
  currentContent: ReactNode | null;
  currentFolderPath?: string;
  isPennyChatOpen: boolean;
  openContent: (content: ReactNode, preserveFolderPath?: boolean) => void;
  openPennyChat: (folderPath?: string) => void;
  opened: boolean;
  togglePennyChat: (folderPath?: string) => void;
  updateFolderPath: (folderPath?: string) => void;
};

// Create the context with undefined as default value
export const AsideContext = createContext<AsideContextType | undefined>(
  undefined
);
