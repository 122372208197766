import { Button, Group, Stack, Switch, Title } from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { knownFeatureDescriptions } from "../config/features.js";
import { useActiveStates } from "../contexts/ActiveStatesContext.js";

// Cache known features at startup
const getKnownFeatures = () => {
  const features: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith("feature.")) {
      features.push(key.replace(/^feature\./, ""));
    }
  }
  return features;
};

export const useFeaturePanel = (initialFeatures: string[] = []) => {
  const [features, setFeatures] = useState<Record<string, boolean>>({});
  const [lastSavedFeatures, setLastSavedFeatures] = useState<
    Record<string, boolean>
  >({});
  useEffect(() => {
    const knownFeatures = [
      ...new Set([...initialFeatures, ...getKnownFeatures()]),
    ];
    const initialState: Record<string, boolean> = {};
    knownFeatures.forEach((feature) => {
      initialState[feature] =
        localStorage.getItem(`feature.${feature}`) === "enabled";
    });
    setFeatures(initialState);
    setLastSavedFeatures(initialState);
  }, []);

  const revertChanges = useCallback(() => {
    setFeatures(lastSavedFeatures);
  }, [lastSavedFeatures]);

  const { shutdown } = useActiveStates();

  const handleSave = useCallback(() => {
    Object.entries(features).forEach(([feature, enabled]) => {
      localStorage.setItem(
        `feature.${feature}`,
        enabled ? "enabled" : "disabled"
      );
    });
    shutdown({
      title: "Features Updated",
      message: "The application will now restart to apply changes",
      color: "blue",
    });
  }, [features, shutdown]);

  const hasChanges = useMemo(() => {
    return Object.entries(features).some(
      ([feature, enabled]) => lastSavedFeatures[feature] !== enabled
    );
  }, [features, lastSavedFeatures]);

  const panel = (
    <Stack>
      <Title order={3}>Enable or disable features</Title>
      {Object.entries(features).map(([feature, enabled]) => (
        <Switch
          key={feature}
          label={feature}
          description={
            knownFeatureDescriptions[
              feature as keyof typeof knownFeatureDescriptions
            ] ?? "No description available"
          }
          checked={enabled}
          onChange={(event) => {
            setFeatures((prev) => ({
              ...prev,
              [feature]: event.target.checked,
            }));
          }}
          styles={{
            root: {
              color:
                !lastSavedFeatures[feature] && enabled
                  ? "var(--mantine-color-green-5)"
                  : lastSavedFeatures[feature] && !enabled
                  ? "var(--mantine-color-red-5)"
                  : undefined,
            },
          }}
        />
      ))}
      <Group justify="flex-end" mt="md">
        <Button
          onClick={revertChanges}
          disabled={!hasChanges}
          variant="outline"
        >
          Revert changes
        </Button>
        <Button onClick={handleSave} disabled={!hasChanges}>
          Save features
        </Button>
      </Group>
    </Stack>
  );

  return panel;
};
