import { Message } from "@ai-sdk/react";
import { Box, Group, Stack, Text } from "@mantine/core";
import { useMemo, useRef } from "react";

import ReactTimeAgo from "react-time-ago";
import { MessageEntity } from "../../../common/entities/message.js";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { UserEntity } from "../../../common/entities/user.js";
import { MarkdownRenderer } from "../../pages/penny/MarkdownRenderer.js";
import { messageWithDefaultSwapsReact } from "./messageWithDefaultSwapsReact.js";

import { messageWithDefaultSwaps } from "../../../common/entities/messageLibrary.js";
import classes from "./MessageDisplay.css.js";

const debug = false;

export interface ToolInvocation {
  toolName: string;
  toolCallId: string;
  state: "call" | "result" | "error" | "partial-call";
  args?: Record<string, unknown>;
  result?: any;
  error?: string;
}

export function MessageContent({
  message,
  streamFromStatic,
  addToolResult,
  messageRef,
}: {
  message: Message | MessageEntity;
  streamFromStatic?: boolean;
  addToolResult?: (internal: boolean, toolCallId: string, result: any) => void;
  messageRef?: React.RefObject<HTMLDivElement>;
}) {
  const { data } = message as MessageEntity & {
    data: { user: UserEntity; organization: OrganizationEntity; item: any };
  };

  // Helper to determine if message is MessageEntity
  const isMessageEntity = (
    msg: Message | MessageEntity
  ): msg is MessageEntity => {
    return "message" in msg;
  };

  // Existing Message type rendering
  const messageContentReact = useMemo(() => {
    return "content" in message
      ? message.content
      : messageWithDefaultSwapsReact(
          message.message!,
          data.user,
          data.organization,
          (data as { text?: string }).text,
          data.item
        );
  }, [message, data]);

  const messageContentText = useMemo(() => {
    return "content" in message
      ? message.content
      : messageWithDefaultSwaps(
          message.message!,
          data.user,
          data.organization,
          (data as { text?: string }).text,
          data.item
        );
  }, [data, message]);

  // Use refs to maintain state between renders
  const pendingToolCalls = useRef(new Map<string, ToolInvocation>());
  const toolInvocationsRef = useRef<ToolInvocation[]>([]);

  const parsedMessageContent = useMemo((): (JSX.Element | string)[] => {
    debug && console.log("Message content:", messageContentText);

    // Initialize toolInvocations on the message if not already present
    if (!(message as any).toolInvocations) {
      (message as any).toolInvocations = toolInvocationsRef.current;
    }

    debug &&
      console.log("MessageContent: Initial message state:", {
        hasToolInvocations: toolInvocationsRef.current.length > 0,
        toolInvocations: toolInvocationsRef.current.map((ti) => ({
          toolName: ti.toolName,
          state: ti.state,
          hasResult: !!ti.result,
          resultType: ti.result?.type,
        })),
      });

    function getLines(text: string): (JSX.Element | string)[] {
      const lines = text
        .split("\n")
        .filter(
          (line) =>
            !line.startsWith("d:") &&
            !line.startsWith("e:") &&
            !line.startsWith("f:")
        );
      const result = [];
      let accumulatingLines: string[] = [];
      let isAccumulating = false;

      for (const line of lines) {
        debug && console.log("Processing line:", JSON.stringify(line));

        if (line.startsWith("0:")) {
          try {
            const lineContent = JSON.parse(line.substring(2));
            if (isAccumulating) {
              accumulatingLines.push(lineContent);
            } else {
              isAccumulating = true;
              accumulatingLines = [lineContent];
            }
          } catch (e) {
            console.error("Error parsing line:", e);
          } finally {
            continue;
          }
        } else if (line.startsWith("3:")) {
          // If we were accumulating lines, add them before processing the error
          if (isAccumulating) {
            result.push(accumulatingLines.join(""));
            accumulatingLines = [];
            isAccumulating = false;
          }

          const errorRaw = line.substring(2, Infinity);
          console.log("Will render error message", JSON.stringify(errorRaw));
          try {
            const error = JSON.parse(errorRaw);
            result.push(
              <Text c="#f44141">
                {typeof error === "string" ? error : errorRaw}
              </Text>
            );
            continue;
          } catch (e) {
            console.error("Error parsing error message", e);
          }
          result.push(<Text c="#f44141">{errorRaw}</Text>);
          continue;
        } else if (line.startsWith("9:")) {
          // If we were accumulating lines, add them before processing the tool call
          if (isAccumulating) {
            result.push(accumulatingLines.join(""));
            accumulatingLines = [];
            isAccumulating = false;
          }

          const toolCall = JSON.parse(line.substring(2));
          if (toolCall.toolCallId === "data") {
            console.log("Skipping data tool call");
            continue;
          }
          console.log("Processing tool call:", {
            toolName: toolCall.toolName,
            toolCallId: toolCall.toolCallId,
            args: toolCall.args,
          });

          // Create a new tool invocation with 'call' state
          const newToolCall = {
            toolName: toolCall.toolName,
            toolCallId: toolCall.toolCallId,
            state: "call" as const,
            args: toolCall.args,
          };

          // Store in pending calls
          pendingToolCalls.current.set(toolCall.toolCallId, newToolCall);

          // Add to tool invocations if not already present
          if (
            !toolInvocationsRef.current.some(
              (ti) => ti.toolCallId === toolCall.toolCallId
            )
          ) {
            toolInvocationsRef.current.push(newToolCall);
          }
          continue;
        } else if (line.startsWith("a:")) {
          // If we were accumulating lines, add them before processing the tool result
          if (isAccumulating) {
            result.push(accumulatingLines.join(""));
            accumulatingLines = [];
            isAccumulating = false;
          }

          const toolCall = JSON.parse(line.substring(2));
          const pendingToolCall = pendingToolCalls.current.get(
            toolCall.toolCallId
          );
          if (!pendingToolCall) {
            console.log("No pending tool call found for:", toolCall.toolCallId);
            continue;
          }
          console.log("Processing tool result:", {
            toolName: toolCall.toolName,
            toolCallId: toolCall.toolCallId,
            result: toolCall.result,
          });

          // Add the tool result
          addToolResult?.(true, toolCall.toolCallId, toolCall.result);

          // Update the tool call in toolInvocations
          const existingToolCall = toolInvocationsRef.current.find(
            (x) => x.toolCallId === toolCall.toolCallId
          );

          if (existingToolCall) {
            existingToolCall.state = "result";
            existingToolCall.result = toolCall.result;
            console.log("Updated existing tool call:", {
              toolName: existingToolCall.toolName,
              state: existingToolCall.state,
              hasResult: !!existingToolCall.result,
            });
          } else {
            // If somehow the tool call doesn't exist, create it with result state
            const newToolCall = {
              toolName: pendingToolCall.toolName,
              toolCallId: pendingToolCall.toolCallId,
              state: "result" as const,
              args: pendingToolCall.args,
              result: toolCall.result,
            };
            toolInvocationsRef.current.push(newToolCall);
            console.log("Added new tool call with result:", {
              toolName: newToolCall.toolName,
              state: newToolCall.state,
              hasResult: !!newToolCall.result,
            });
          }
          continue;
        } else if (line.startsWith("f:")) {
          continue;
        } else {
          // If we were accumulating lines, add them before adding the regular line
          if (isAccumulating) {
            result.push(accumulatingLines.join(""));
            accumulatingLines = [];
            isAccumulating = false;
          }
          result.push(line);
        }
      }

      // Add any remaining accumulated lines at the end
      if (isAccumulating) {
        result.push(accumulatingLines.join(""));
      }

      return result;
    }

    // Process the message content if it's in the streaming format
    const processedContent =
      streamFromStatic && messageContentText[1] === ":"
        ? getLines(messageContentText)
        : [messageContentText];

    debug &&
      console.log("MessageContent: Final message state:", {
        hasToolInvocations: toolInvocationsRef.current.length > 0,
        toolInvocations: toolInvocationsRef.current.map((ti) => ({
          toolName: ti.toolName,
          state: ti.state,
          hasResult: !!ti.result,
          resultType: ti.result?.type,
        })),
      });

    return processedContent;
  }, [messageContentText, debug, message, addToolResult]);

  if (isMessageEntity(message)) {
    return (
      <Box className={classes.chatWrapper} ref={messageRef}>
        {(message.data as { role?: string })?.role === "assistant" ? (
          <>
            {parsedMessageContent.map((segment, index) =>
              typeof segment === "string" ? (
                <MarkdownRenderer content={segment} key={index} />
              ) : (
                segment
              )
            )}
          </>
        ) : (
          <Text size="sm" mb={5}>
            {messageContentReact}
          </Text>
        )}
        {message.timestamp && (
          <Group justify="space-between">
            <Text c="dimmed" size="xs">
              {new Date(message.timestamp).toLocaleString("en-US", {
                weekday: "long",
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              })}
            </Text>
            <Text
              c="dimmed"
              size="xs"
              title={new Date(message.timestamp).toLocaleString()}
            >
              <ReactTimeAgo date={new Date(message.timestamp)} locale="en-US" />
            </Text>
          </Group>
        )}
      </Box>
    );
  }

  return parsedMessageContent ? (
    <Box className={classes.chatWrapper} ref={messageRef}>
      <Stack>
        {parsedMessageContent.map((segment, index) =>
          typeof segment === "string" ? (
            <MarkdownRenderer content={segment} key={index} />
          ) : (
            segment
          )
        )}
      </Stack>
    </Box>
  ) : null;
}
