const DEFAULT_BACKGROUND_COLOR = "var(--mantine-color-body)";

// Color options for notes
export const COLOR_OPTIONS_LIGHT_MODE = [
  "#faafa8", // Coral
  "#f39f76", // Peach
  "#fff8b8", // Sand
  "#e2f6d3", // Mint
  "#b4ddd3", // Sage
  "#d4e4ed", // Fog
  "#aeccdc", // Storm
  "#d3bfdb", // Dusk
  "#f6e2dd", // Blossom
  "#e9e3d4", // Clay
  "#efeff1", // Chalk
];

export const COLOR_OPTIONS_DARK_MODE = [
  "#77172e", // Coral
  "#692b17", // Peach
  "#7c4a03", // Sand
  "#264d3b", // Mint
  "#0c625d", // Sage
  "#256377", // Fog
  "#284255", // Storm
  "#6c394f", // Dusk
  "#472e5b", // Blossom
  "#4b443a", // Clay
  "#232427", // Chalk
];

export const COLOR_OPTION_NAMES = [
  "Coral",
  "Peach",
  "Sand",
  "Mint",
  "Sage",
  "Fog",
  "Storm",
  "Dusk",
  "Blossom",
  "Clay",
  "Chalk",
];

export function darkModeColor(lightModeColor: string) {
  if (lightModeColor === "") {
    return DEFAULT_BACKGROUND_COLOR;
  }
  const index = COLOR_OPTIONS_LIGHT_MODE.indexOf(lightModeColor);
  if (index >= 0 && index < COLOR_OPTIONS_DARK_MODE.length) {
    return COLOR_OPTIONS_DARK_MODE[index];
  }
  // If not found in predefined colors, calculate a darker version
  const darken = (color: string) => {
    try {
      // Convert hex to RGB
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);

      // Darken by reducing RGB values
      const darkenFactor = 0.35;
      const darkR = Math.floor(r * darkenFactor);
      const darkG = Math.floor(g * darkenFactor);
      const darkB = Math.floor(b * darkenFactor);

      // Convert back to hex
      return `#${darkR.toString(16).padStart(2, "0")}${darkG
        .toString(16)
        .padStart(2, "0")}${darkB.toString(16).padStart(2, "0")}`;
    } catch {
      return DEFAULT_BACKGROUND_COLOR; // Default dark color if conversion fails
    }
  };

  return darken(lightModeColor);
}
