import { css } from "../../utils/css.js";

interface ClassNames {
  toolBar: string;
}

export default css<ClassNames>`
  .toolBar {
    background-color: var(--mantine-color-body);
    border-bottom: 1px solid
      light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }

  @media print {
    .toolBar {
      display: none;
    }
  }
`;
