import { Box, Group, Text, Title } from "@mantine/core";
import { UploadQueue } from "./UploadQueue.js";

interface UploadContentProps {
  currentPath: string;
  onUploadComplete?: () => void;
}

/**
 * Component displaying upload content within the aside panel
 */
export function UploadContent({
  currentPath,
  onUploadComplete,
}: UploadContentProps) {
  return (
    <Box p="md">
      <Title order={4} mb="md">
        Upload Files
      </Title>
      <Group mb="md">
        <Text size="sm">Uploading to: {currentPath || "root folder"}</Text>
      </Group>
      <UploadQueue
        currentPath={currentPath}
        onUploadComplete={onUploadComplete}
      />
    </Box>
  );
}
