import {
  ActionIcon,
  Indicator,
  Menu,
  rem,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import {
  IconBell,
  IconBellFilled,
  IconDashboard,
  IconDashboardFilled,
  IconDots,
  IconFolder,
  IconFolderFilled,
  IconHome,
  IconHomeFilled,
  IconNote,
} from "@tabler/icons-react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  ENTITLEMENT_MANAGE_FILES,
  ENTITLEMENT_VIEW_ACTIVITY,
  ENTITLEMENT_VIEW_DASHBOARD,
} from "../../../common/fields/entitlements.js";
import { LEDGERAI_SLUG } from "../../../common/fields/slug.js";
import {
  useActiveStates,
  useOrganizationRelationshipUserHasEntitlement,
  useOrganizationUserHasEntitlement,
} from "../../contexts/ActiveStatesContext.js";
import { useFeatureEnabled } from "../../hooks/useFeatureEnabled.js";
import { useResponsiveDesign } from "../../hooks/useResponsiveDesign.js";
import { ServiceProviderMenu } from "../Menus/ServiceProviderMenu.js";
import MoreOptions from "./MoreOptions.js";

import classes from "./Navbar.css.js";

export interface Sublinks {
  [key: string]: { label: string; href: string }[];
}

export function Navbar() {
  const {
    customer: activeCustomer,
    isServiceProvider,
    messageControl,
    serviceProvider,
  } = useActiveStates();

  const location = useLocation();
  const [active, setActive] = useState("");

  const { isAboveSmall } = useResponsiveDesign();
  const hasEntitlement1 = useOrganizationUserHasEntitlement();
  const hasEntitlement2 = useOrganizationRelationshipUserHasEntitlement();
  const hasEntitlement = (entitlement: number) =>
    hasEntitlement1(entitlement) || hasEntitlement2(entitlement);

  const notesFeatureEnabled = useFeatureEnabled("notes");
  const filesEntitlementPresent = hasEntitlement(ENTITLEMENT_MANAGE_FILES);

  const mainLinksData = useMemo(() => {
    return [
      {
        icon: IconHome,
        iconFilled: IconHomeFilled,
        label: "Home",
        href: "/",
        subtitle: "Get started and choose your path",
      },
      ...(notesFeatureEnabled
        ? [
            {
              icon: IconNote,
              iconFilled: IconNote,
              label: "Notes",
              href: "/notes",
              subtitle: "Quickly jot down notes and create to-do lists",
            },
          ]
        : []),
      ...(filesEntitlementPresent
        ? [
            {
              icon: IconFolder,
              iconFilled: IconFolderFilled,
              label: "Explore Files",
              href: "/files",
            },
          ]
        : []),
      ...(hasEntitlement(ENTITLEMENT_VIEW_DASHBOARD)
        ? [
            {
              icon: IconDashboard,
              iconFilled: IconDashboardFilled,
              label: "Dashboard",
              href: "/dashboard",
              indicator: messageControl.unreadCount > 0,
              indicatorLabel: messageControl.unreadCount?.toString?.(10) ?? "",
              subtitle: "Key information to see progress and trends",
            },
          ]
        : []),
      ...(false /*Activity is temporarily removed*/ &&
      hasEntitlement(ENTITLEMENT_VIEW_ACTIVITY)
        ? [
            {
              icon: IconBell,
              iconFilled: IconBellFilled,
              label: "Activity",
              href: "/activity",
              indicator: messageControl.unreadCount > 0,
              indicatorLabel: messageControl.unreadCount?.toString?.(10) ?? "",
            },
          ]
        : []),
      {
        icon: IconDots,
        iconFilled: IconDots,
        label: "More",
        subtitle: "Quick access to other various tools",
      },
    ];
  }, [
    messageControl.unreadCount,
    notesFeatureEnabled,
    filesEntitlementPresent,
  ]);

  useEffect(() => {
    const path = location.pathname;

    const mainLink = mainLinksData.find((link) => {
      if (link.href === "/") {
        return path === "/";
      } else if (link.href) {
        return path.startsWith(link.href);
      }
    });

    if (mainLink) {
      setActive(mainLink.label);
    } else {
      setActive("");
    }
  }, [location.pathname, mainLinksData]);

  const navigate = useNavigate();

  const isLedgerAI = !activeCustomer && serviceProvider?.slug === LEDGERAI_SLUG;

  function clickHandler(link: any) {
    if (link.href) {
      setActive(link.label);
      navigate(link.href);
    }
  }

  const mainLinks = mainLinksData.map((link) => {
    const IconComponent = link.label === active ? link.iconFilled : link.icon;

    const icon = () => {
      return link.indicator ? (
        <Indicator
          key={link.label}
          inline
          size={7}
          offset={3}
          position="top-end"
          color="#f44141"
        >
          <IconComponent
            style={isAboveSmall ? { width: rem(27), height: rem(27) } : {}}
          />
        </Indicator>
      ) : (
        <IconComponent
          key={link.label}
          style={isAboveSmall ? { width: rem(27), height: rem(27) } : {}}
        />
      );
    };

    if (link.label === "More") {
      return (
        <MoreOptions
          key={link.label}
          linkLabel={link.label}
          active={active}
          isLedgerAI={isLedgerAI}
          isServiceProvider={isServiceProvider}
          IconComponent={IconComponent}
          subtitle={link.subtitle ?? ""}
        />
      );
    }

    return (
      <Fragment key={link.label}>
        {isAboveSmall && (
          <Tooltip
            label={link.label}
            position="right"
            withArrow
            transitionProps={{ duration: 0 }}
          >
            <ActionIcon
              variant={link.label === active ? "filled" : "default"}
              className={`${classes.mainLink}`}
              size="xl"
              onClick={() => clickHandler(link)}
              data-active={link.label === active || undefined}
            >
              {icon()}
            </ActionIcon>
          </Tooltip>
        )}
        {!isAboveSmall && (
          <Menu.Item
            onClick={() => clickHandler(link)}
            px="sm"
            py="xs"
            style={{
              backgroundColor: "transparent",
            }}
            leftSection={
              <ThemeIcon
                variant={link.label === active ? "filled" : "default"}
                size="sm"
              >
                {icon()}
              </ThemeIcon>
            }
            data-active={link.label === active || undefined}
          >
            {link.label}
            <Text size="xs">{link.subtitle}</Text>
          </Menu.Item>
        )}
      </Fragment>
    );
  });

  if (typeof isAboveSmall === "undefined") {
    return null;
  }

  return (
    <nav
      className={[
        classes.navbar,
        ...(isAboveSmall ? [] : [classes.navbarMobile]),
      ].join(" ")}
    >
      <div className={classes.wrapper}>
        <div className={classes.aside}>
          <Stack
            justify="flex-start"
            gap={isAboveSmall ? 10 : 0}
            className={classes.mainLinks}
          >
            <Menu>{mainLinks}</Menu>
          </Stack>
          {isAboveSmall && (
            <Stack justify="flex-end" gap={15}>
              <ServiceProviderMenu />
            </Stack>
          )}
        </div>
      </div>
    </nav>
  );
}
