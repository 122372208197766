import { notifications } from "@mantine/notifications";
import { useState } from "react";

import { OrganizationEntity } from "../../common/entities/organization.js";
import { UserEntity } from "../../common/entities/user.js";
import { userClient } from "../../common/utils/entityClient.js";
import { UserModal } from "../components/Modals/UserModal.js";
import { clientError } from "../utils/clientError.js";

export function useCreateUserModal(
  providerId: number,
  customerId?: number,
  fetchUsers?: () => Promise<void>,
  organization?: OrganizationEntity
) {
  const [createModalOpened, setCreateModalOpened] = useState(false);

  const handleSendInvitation = async (newUser: Partial<UserEntity>) => {
    console.log("newUser", newUser);
    const response = await userClient(clientError).create({
      ...newUser,
      ...(providerId && { provider_id: providerId }),
      ...(customerId && { customer_id: customerId }),
    });
    if (response.success && "data" in response && "data" in response.data) {
      try {
        await fetchUsers?.();
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
      setCreateModalOpened(false);
      notifications.show({
        title: "Success",
        message: `User ${response.data.data.email} has been invited.`,
        color: "#54e382",
      });
    } else {
      notifications.show({
        title: "Error",
        message: `Failed to invite ${newUser.email}.`,
        color: "#f44141",
      });
      throw new Error("Failed to create user: " + JSON.stringify(response));
    }
  };

  return {
    createModalOpened,
    setCreateModalOpened,
    createModal: (
      <UserModal
        opened={createModalOpened}
        onSubmit={handleSendInvitation}
        editMode={false}
        close={() => setCreateModalOpened(false)}
        organization={organization}
      />
    ),
  };
}
