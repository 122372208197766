import { archivedAtField } from "../fields/archived_at";
import { createdAtField } from "../fields/created_at";
import { customerIdField } from "../fields/customer_id";
import { idField } from "../fields/id";
import { providerIdField } from "../fields/provider_id";
import { titleField } from "../fields/title";
import { updatedAtField } from "../fields/updated_at";
import { userIdFieldRequired } from "../fields/user_id";
import { EntityDocs } from "../types/docs";
import {
  entity,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";

// Conversation fields
export const fields = [
  archivedAtField,
  createdAtField,
  // if customer_id is set, then it's a customer-only conversation
  // if both customer_id and provider_id are set, then it's a customer and provider conversation
  // if provider_id is set, then it's a provider-only conversation
  // otherwise it's a user conversation
  customerIdField,
  providerIdField,
  idField,
  titleField,
  updatedAtField,
  userIdFieldRequired,
] satisfies FieldList;

// Conversation primary key fields
export const primaryKeyFields = [idField] satisfies PrimaryKeyFieldTuple;

// Docs
export const docs: EntityDocs = {
  collection: {
    summary: "List conversations.",
  },
  item: { summary: "Perform operations on a conversation." },
};

export const conversationEntity = entity({
  api: {
    collectionPath: "conversations",
    itemPath: "conversations/:id",
  },
  docs,
  name: "Conversation",
  fields,
  primaryKeyFields,
  tableName: "conversations",
});

export type ConversationEntity = EntityType<
  typeof conversationEntity.fields,
  typeof conversationEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleConversation: ConversationEntity = {
  customer_id: 123,
  id: 0,
  title: "My conversation",
  provider_id: 456,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  user_id: 543,
};
