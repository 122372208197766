import type { IconProps } from "@tabler/icons-react";
import { CSSProperties } from "react";

export default function MobileMenuIcon({
  Icon,
  style,
  ...props
}: {
  Icon: React.ElementType;
  style?: CSSProperties;
  props?: IconProps;
}) {
  return <Icon size={20} style={style} {...props} />;
}
