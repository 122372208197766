import { generateColors } from "@mantine/colors-generator";
import { createTheme, MantineThemeOverride } from "@mantine/core";

export const PrimaryColor = "org_primary" as const; // always customer if available, otherwise service provider
export const ServiceProviderPrimaryColor =
  "org_serviceProvider_primary" as const; // always service provider
export const CustomerPrimaryColor = "org_customer_primary" as const; // always customer
export const AccountPrimaryColor = "account_primary" as const; // customer if customer user, otherwise service provider

export const theme = (
  serviceProviderPrimaryColor: string,
  customerPrimaryColor: string,
  isServiceProviderUser: boolean
): MantineThemeOverride =>
  createTheme({
    /* Put your mantine theme override here */

    fontFamily: "Plus Jakarta Sans, sans-serif",
    fontFamilyMonospace: "PT Mono",
    primaryColor: PrimaryColor,
    autoContrast: true,
    shadows: {
      md: "0 calc(.0625rem * 1) calc(.1875rem * 1) #0000000e, #0000000e 0 calc(1.25rem * 1) calc(1.5625rem * 1) calc(-.3125rem * 1), #0000000b 0 calc(.625rem * 1) calc(.625rem * 1) calc(-.3125rem * 1)",
    },
    components: {
      Modal: {
        styles: (theme: MantineThemeOverride) => ({
          title: {
            fontWeight: 700,
            fontSize: theme.fontSizes?.lg,
            // color: theme.colors?.dark?.[0] ?? "#000000",
          },
        }),
      },

      Menu: {
        styles: (theme: MantineThemeOverride) => ({
          dropdown: {
            padding: theme.spacing?.sm + " 0",
            borderRadius: theme.radius?.lg,
          },
          item: {
            borderRadius: 0,
            padding: theme.spacing?.xs + " " + theme.spacing?.md,
            fontSize: theme?.fontSizes?.sm, // You can use theme's font sizes
            fontWeight: 700, // Medium weight
            "&:hover": {},
            "&:active": {},
          },
          label: {
            padding: theme.spacing?.xs + " " + theme.spacing?.md,
            textTransform: "uppercase",
            letterSpacing: "0.1em",
            fontSize: "0.6rem",
          },
        }),
      },
    },
    colors: {
      [PrimaryColor]: adjustGeneratedColors(
        generateColors(
          customerPrimaryColor === "#231F20" //fallback color means null
            ? serviceProviderPrimaryColor
            : customerPrimaryColor || serviceProviderPrimaryColor
        ),
        customerPrimaryColor === "#231F20" //fallback color means null
          ? serviceProviderPrimaryColor
          : customerPrimaryColor || serviceProviderPrimaryColor
      ),
      [ServiceProviderPrimaryColor]: adjustGeneratedColors(
        generateColors(serviceProviderPrimaryColor),
        serviceProviderPrimaryColor
      ),
      [CustomerPrimaryColor]: adjustGeneratedColors(
        generateColors(customerPrimaryColor),
        customerPrimaryColor
      ),
      [AccountPrimaryColor]: adjustGeneratedColors(
        generateColors(
          isServiceProviderUser
            ? serviceProviderPrimaryColor
            : customerPrimaryColor
        ),
        isServiceProviderUser
          ? serviceProviderPrimaryColor
          : customerPrimaryColor
      ),
    },
    white: "#FFFDFB",
    black: "#231F20",
  });

function adjustGeneratedColors(
  generatedColors: string[],
  inputColor: string
): [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
] {
  const adjustedColors = [...generatedColors] as [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ];
  adjustedColors[6] = inputColor; // Set the 7th item (index 6) to the input color
  return adjustedColors;
}
