import {
  ActionIcon,
  Button,
  Group,
  Paper,
  Progress,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";

import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { filesClient } from "../../utils/filesClient.js";

// File upload item type
interface UploadItem {
  id: string;
  file: File;
  progress: number;
  status: "queued" | "uploading" | "completed" | "error";
  error?: string;
}

// Format bytes to human-readable format
function formatBytes(bytes: number): string {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

interface UploadQueueProps {
  currentPath: string;
  onUploadComplete?: () => void;
}

export function UploadQueue({
  currentPath,
  onUploadComplete,
}: UploadQueueProps) {
  const { customer, serviceProvider, user } = useActiveStates();
  const [uploadQueue, setUploadQueue] = useState<UploadItem[]>([]);
  const [currentUpload, setCurrentUpload] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Process the next item in the queue
  useEffect(() => {
    const processQueue = async () => {
      // If there's already an upload in progress or no items queued, do nothing
      if (currentUpload || !uploadQueue.length) return;

      // Make sure we have a service provider ID before proceeding
      if (!serviceProvider?.id) {
        console.error("Upload failed: Service provider ID is missing");
        notifications.show({
          title: "Upload Error",
          message: "Service provider ID is required for uploads",
          color: "red",
        });
        return;
      }

      // Find the next queued item
      const nextItem = uploadQueue.find((item) => item.status === "queued");
      if (!nextItem) return;

      // Set as current upload
      setCurrentUpload(nextItem.id);

      // Update status to uploading
      setUploadQueue((queue) =>
        queue.map((item) =>
          item.id === nextItem.id ? { ...item, status: "uploading" } : item
        )
      );

      try {
        // Make sure we have a valid file
        if (!nextItem.file || !(nextItem.file instanceof File)) {
          throw new Error("Invalid file object");
        }

        // Simulate progress (replace with actual upload progress when available)
        const interval = setInterval(() => {
          setUploadQueue((queue) =>
            queue.map((item) =>
              item.id === nextItem.id && item.progress < 90
                ? { ...item, progress: item.progress + 10 }
                : item
            )
          );
        }, 300);

        // Create metadata for the upload
        const metadata = {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          uploadedBy: (user as any)?.name || "Unknown user",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          modifiedBy: (user as any)?.name || "Unknown user",
        };

        // Make sure IDs are properly formatted as strings if they exist
        const spId = serviceProvider.id
          ? serviceProvider.id.toString()
          : undefined;
        const custId = customer?.id ? customer.id.toString() : undefined;

        // Log the parameters for debugging
        console.log("Upload parameters:", {
          path: currentPath ? `${currentPath}/` : "",
          fileName: nextItem.file.name,
          fileSize: nextItem.file.size,
          serviceProviderId: spId,
          customerId: custId,
          metadataKeys: Object.keys(metadata),
        });

        // Use filesClient to upload with correct parameter order
        const result = await filesClient.uploadFile(
          nextItem.file, // file
          currentPath ? `${currentPath}/` : "", // path
          metadata, // metadata
          serviceProvider.id, // serviceProviderId (required, will be converted to string in the client)
          customer?.id // customerId (optional)
        );

        clearInterval(interval);

        if (!result.success) {
          throw new Error(result.error || "Upload failed");
        }

        // Upload completed successfully
        setUploadQueue((queue) =>
          queue.map((item) =>
            item.id === nextItem.id
              ? { ...item, status: "completed", progress: 100 }
              : item
          )
        );

        // Log successful upload details
        console.log("Upload completed successfully:", {
          fileName: nextItem.file.name,
          path: result.data?.path || "",
          size: result.data?.size || 0,
          fullResponse: result.data || {},
        });

        // Notify success
        notifications.show({
          title: "Upload complete",
          message: `${nextItem.file.name} has been uploaded successfully`,
          color: "green",
        });

        // Refresh the file list and handle Vercel Blob's eventual consistency
        if (onUploadComplete) {
          onUploadComplete();

          // Vercel Blob has eventual consistency - we try reloading one more time after a delay
          setTimeout(() => {
            onUploadComplete?.();
          }, 2000);
        }
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorMessage = (error as any)?.message || "Upload failed";

        // Update queue item with error
        setUploadQueue((queue) =>
          queue.map((item) =>
            item.id === nextItem.id
              ? { ...item, status: "error", error: errorMessage }
              : item
          )
        );

        // Notify error
        notifications.show({
          title: "Upload failed",
          message: errorMessage,
          color: "red",
        });
      } finally {
        // Clear current upload to allow next item to process
        setCurrentUpload(null);
      }
    };

    processQueue();
  }, [
    uploadQueue,
    currentUpload,
    currentPath,
    user,
    serviceProvider,
    customer,
    onUploadComplete,
  ]);

  // Add files to the upload queue
  const addToQueue = (files: FileList) => {
    const newItems: UploadItem[] = Array.from(files).map((file) => ({
      id: Math.random().toString(36).substr(2, 9),
      file,
      progress: 0,
      status: "queued",
    }));

    setUploadQueue((queue) => [...queue, ...newItems]);
  };

  // Trigger file input click
  const handleAddFiles = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle file selection
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      addToQueue(e.target.files);
      // Reset the input so selecting the same file again triggers the event
      e.target.value = "";
    }
  };

  // Remove item from queue
  const removeFromQueue = (id: string) => {
    setUploadQueue((queue) => queue.filter((item) => item.id !== id));
  };

  return (
    <Stack p="md" gap="md">
      <Group justify="space-between">
        <Title order={4}>File Uploads</Title>
        <Button size="xs" onClick={handleAddFiles}>
          Add Files
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple
        />
      </Group>

      {uploadQueue.length === 0 ? (
        <Text size="sm" color="dimmed" ta="center">
          No files in upload queue
        </Text>
      ) : (
        <Stack gap="xs">
          {uploadQueue.map((item) => (
            <Paper key={item.id} p="xs" withBorder>
              <Group justify="space-between" mb="xs">
                <Text size="sm" fw={500} lineClamp={1} style={{ flex: 1 }}>
                  {item.file.name}
                </Text>
                <Text size="xs" color="dimmed">
                  {formatBytes(item.file.size)}
                </Text>
                {item.status !== "uploading" && (
                  <ActionIcon
                    size="xs"
                    color="red"
                    onClick={() => removeFromQueue(item.id)}
                  >
                    <IconX size={14} />
                  </ActionIcon>
                )}
              </Group>

              <Group justify="space-between" gap="xs">
                <Progress
                  value={item.progress}
                  size="sm"
                  color={
                    item.status === "completed"
                      ? "green"
                      : item.status === "error"
                      ? "red"
                      : "blue"
                  }
                  style={{ flex: 1 }}
                />
                {item.status === "completed" && (
                  <IconCheck size={16} color="green" />
                )}
                {item.status === "error" && <IconX size={16} color="red" />}
              </Group>

              {item.status === "error" && (
                <Text size="xs" color="red" mt="xs">
                  {item.error}
                </Text>
              )}
            </Paper>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
