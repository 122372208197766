import {
  ActionIcon,
  Breadcrumbs,
  Button,
  Group,
  Input,
  Paper,
  SegmentedControl,
} from "@mantine/core";
import {
  IconFolder,
  IconLayoutGrid,
  IconLayoutList,
  IconSearch,
} from "@tabler/icons-react";
import { ReactNode } from "react";

import { IconSizes } from "./types.js";

interface ExplorerToolbarProps {
  breadcrumbs: ReactNode[];
  searchTerm: string;
  onSearchChange: (value: string) => void;
  onSearch: () => void;
  onNewFolder: () => void;
  viewMode: "grid" | "list";
  onViewModeChange: (value: "grid" | "list") => void;
  iconSize: IconSizes;
  onIconSizeChange: (value: IconSizes) => void;
  isLoading: boolean;
  isLoadingIconSize: boolean;
  isLoadingViewMode: boolean;
}

/**
 * Toolbar component for the file explorer with search, view controls, and breadcrumbs
 */
export function ExplorerToolbar({
  breadcrumbs,
  searchTerm,
  onSearchChange,
  onSearch,
  onNewFolder,
  viewMode,
  onViewModeChange,
  iconSize,
  onIconSizeChange,
  isLoading,
  isLoadingIconSize,
  isLoadingViewMode,
}: ExplorerToolbarProps) {
  return (
    <Group justify="space-between" px="md">
      <Paper shadow="xs" p={0}>
        <Breadcrumbs separator="/" p={0}>
          {breadcrumbs}
        </Breadcrumbs>
      </Paper>
      <Group>
        <div style={{ display: "flex" }}>
          <Input
            placeholder="Search files..."
            value={searchTerm}
            onChange={(e) => onSearchChange(e.currentTarget.value)}
            rightSection={
              <ActionIcon
                variant="subtle"
                onClick={onSearch}
                aria-label="Search"
              >
                <IconSearch size={16} />
              </ActionIcon>
            }
          />
          <Button
            size="xs"
            variant="light"
            leftSection={<IconFolder size={14} />}
            ml={10}
            onClick={onNewFolder}
          >
            New Folder
          </Button>
        </div>
      </Group>
      <Group>
        <SegmentedControl
          disabled={isLoading || isLoadingIconSize}
          size="xs"
          value={isLoadingIconSize ? undefined : iconSize}
          onChange={(value) => onIconSizeChange(value as IconSizes)}
          data={[
            { label: "Small", value: "small" },
            { label: "Large", value: "large" },
          ]}
        />
        <SegmentedControl
          disabled={isLoading || isLoadingViewMode}
          size="xs"
          value={isLoadingViewMode ? undefined : viewMode}
          onChange={(value) => onViewModeChange(value as "grid" | "list")}
          data={[
            {
              label: <IconLayoutGrid size={16} />,
              value: "grid",
            },
            {
              label: <IconLayoutList size={16} />,
              value: "list",
            },
          ]}
        />
      </Group>
    </Group>
  );
}
