import { Badge, Button, Group } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";

import { OrganizationIntegrationTokenEntity } from "../../../common/entities/organizationIntegrationToken.js";
import {
  INTEGRATION_LABELS,
  INTEGRATION_QUICKBOOKS,
} from "../../../common/fields/integration.js";
import { network } from "../../../common/utils/network.js";
import {
  useActiveStates,
  useUser,
} from "../../contexts/ActiveStatesContext.js";
import { useAuth } from "../../hooks/useAuth.js";
import { clientError } from "../../utils/clientError.js";

export function QuickbooksButton() {
  const user = useUser();
  const [integrationToken, setIntegrationToken] =
    useState<OrganizationIntegrationTokenEntity>();
  const [oAuthState, setOAuthState] = useState<string>();
  const { session } = useAuth();
  const { customer, serviceProvider } = useActiveStates();

  const organization = customer ?? serviceProvider;

  useEffect(() => {
    if (!session) {
      return;
    }
    async function checkQuickbooksConnection() {
      try {
        const response = await network.get<{
          token?: OrganizationIntegrationTokenEntity;
          state: string;
        }>(
          `/api/quickbooks/status`,
          {
            params: {
              provider_id: serviceProvider?.id,
              customer_id: customer?.id,
            },
            headers: {
              Authorization: `Bearer ${session?.accessToken}`,
            },
          },
          clientError
        );
        if ("data" in response) {
          setIntegrationToken(response.data.token);
          setOAuthState(response.data.state);
        }
      } catch (error) {
        console.error("Error checking Quickbooks connection:", error);
      }
    }
    checkQuickbooksConnection().catch(clientError);
  }, [organization, session, user]);

  const handleConnect = async () => {
    location.replace(`/api/quickbooks/auth-init?state=${oAuthState}`);
  };

  const handleReset = async () => {
    if (!integrationToken) {
      return;
    }
    setOAuthState(undefined);
    const response = await network.delete<{
      token?: OrganizationIntegrationTokenEntity;
      state: string;
    }>(
      `/api/quickbooks/status`,
      {
        params: {
          provider_id: serviceProvider?.id,
          customer_id: customer?.id,
          realm_id: integrationToken.realm_id,
        },
        headers: {
          Authorization: `Bearer ${session?.accessToken}`,
        },
      },
      clientError
    );
    if ("data" in response) {
      setIntegrationToken(undefined);
      setOAuthState(response.data.state);
    }
  };

  if (integrationToken) {
    return (
      <Group>
        <Badge
          variant="subtle"
          size="lg"
          radius="xs"
          leftSection={<IconCheck />}
        >
          Connected to {INTEGRATION_LABELS[INTEGRATION_QUICKBOOKS]}
        </Badge>
        <Button disabled={!oAuthState} onClick={handleReset} variant="default">
          Reset Connection
        </Button>
      </Group>
    );
  }

  return (
    <Button
      color="dark"
      disabled={!oAuthState}
      onClick={handleConnect}
      variant="primary"
    >
      Connect
    </Button>
  );
}
