import {
  Button,
  ColorInput,
  Grid,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconAt } from "@tabler/icons-react";
import { useMemo } from "react";

import {
  OrganizationEntity,
  organizationEntity,
  OrganizationUpdateType,
} from "../../../common/entities/organization.js";
import { descriptionField } from "../../../common/fields/description.js";
import { ENTITLEMENT_IS_SERVICE_PROVIDER } from "../../../common/fields/entitlements.js";
import { initialsField } from "../../../common/fields/initials.js";
import { nameField } from "../../../common/fields/name.js";
import { pictureField } from "../../../common/fields/picture.js";
import { pictureBackgroundColorField } from "../../../common/fields/picture_background_color.js";
import { primaryDomainField } from "../../../common/fields/primary_domain.js";
import { primaryLocationField } from "../../../common/fields/primary_location.js";
import { LEDGERAI_SLUG, slugField } from "../../../common/fields/slug.js";
import {
  DEFAULT_THEME_PRIMARY_COLOR,
  themePrimaryColorField,
} from "../../../common/fields/theme_primary_color.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { useForm } from "../../hooks/useForm.js";
import { ServiceProviderPrimaryColor } from "../../theme.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";

import TextEditor from "../Shared/TextEditor.js";

interface OrganizationModalProps {
  children?: React.ReactNode;
  editMode?: boolean; // true if editing, false if adding
  initialData?: Partial<OrganizationUpdateType>; // Prefill data for editing
  onClose: () => void;
  onSubmit: (data: OrganizationUpdateType) => Promise<boolean>;
  opened: boolean;
  serviceProvider: OrganizationEntity;
}

export function OrganizationModal({
  children,
  editMode = false,
  initialData,
  onClose,
  onSubmit,
  opened,
  serviceProvider,
}: OrganizationModalProps) {
  const { serviceProvider: currentServiceProvider, customer: currentCustomer } =
    useActiveStates();

  const initialFormData: OrganizationUpdateType = useMemo(
    () => ({
      entitlements: [] as unknown as number | undefined,
      extend_entitlements: [] as unknown as number | undefined,
      initials: "",
      name: "",
      picture: "",
      primary_domain: "",
      primary_location: "",
      slug: "",
      theme_primary_color: DEFAULT_THEME_PRIMARY_COLOR,
      ...initialData,
    }),
    [initialData]
  );

  const form = useForm(organizationEntity, {
    initialFormData,
    onClose,
    onSubmit,
    opened,
    type: editMode ? "update" : "create",
    errorComponent(message: string) {
      return <Text c="#f44141">{message}</Text>;
    },
  });

  const isLedgerAI = serviceProvider?.slug === LEDGERAI_SLUG;
  const customerIsServiceProvider = (
    currentCustomer?.entitlements as unknown as number[]
  )?.includes(ENTITLEMENT_IS_SERVICE_PROVIDER);

  return (
    <Modal centered onClose={onClose} opened={opened} size="xl">
      {children}
      {serviceProvider && (
        <Stack gap={0} pb="xl">
          <OrganizationAvatar
            variant="filled"
            color={ServiceProviderPrimaryColor}
            size={60}
            mx="auto"
            organization={serviceProvider}
            bg={ServiceProviderPrimaryColor}
          />
          <Text ta="center" fz="lg" fw={500} mt="md">
            {editMode
              ? `Edit ${isLedgerAI ? "Service Provider" : "Client"}`
              : `Add a New ${isLedgerAI ? "Service Provider" : "Client"}`}
          </Text>
        </Stack>
      )}
      <form onSubmit={form.submit}>
        <Grid gutter="md">
          <Grid.Col span={{ base: 12, sm: 6 }}>
            {form.fieldError(nameField)}
            <TextInput
              disabled={form.isSubmitting}
              label={`${
                isLedgerAI
                  ? "Service Provider"
                  : currentServiceProvider?.id === serviceProvider.id ||
                    (customerIsServiceProvider &&
                      editMode &&
                      initialData?.id === serviceProvider.id)
                  ? ""
                  : "Client"
              } Name`}
              placeholder="e.g., Jack's Kitchen"
              required={nameField.required}
              value={form.data.name}
              onChange={(e) =>
                form.handleInputChange("name", e.currentTarget.value)
              }
              mb="sm"
            />
            {form.fieldError(initialsField)}
            <TextInput
              disabled={form.isSubmitting}
              leftSection={<IconAt size={16} />}
              label={initialsField.label}
              placeholder="e.g., JK"
              required={initialsField.required}
              value={form.data.initials}
              onChange={(e) =>
                form.handleInputChange("initials", e.currentTarget.value)
              }
              mb="sm"
            />
            {form.fieldError(primaryDomainField)}
            <TextInput
              disabled={form.isSubmitting}
              label={primaryDomainField.label}
              placeholder="e.g., jackskitchen.com"
              required={primaryDomainField.required}
              value={form.data.primary_domain}
              onChange={(e) =>
                form.handleInputChange("primary_domain", e.currentTarget.value)
              }
              mb="sm"
            />
            {form.fieldError(slugField)}
            <TextInput
              disabled={form.isSubmitting}
              leftSection={<IconAt size={16} />}
              label={slugField.label}
              placeholder="e.g., jackskitchen"
              required={slugField.required}
              value={form.data.slug}
              onChange={(e) =>
                form.handleInputChange("slug", e.currentTarget.value)
              }
              mb="sm"
            />
            {form.fieldError(primaryLocationField)}
            <TextInput
              disabled={form.isSubmitting}
              label={primaryLocationField.label}
              placeholder="e.g., Salt Lake City, UT"
              required={primaryLocationField.required}
              value={form.data.primary_location}
              onChange={(e) =>
                form.handleInputChange(
                  "primary_location",
                  e.currentTarget.value
                )
              }
              mb="sm"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            {form.fieldError(themePrimaryColorField)}
            <ColorInput
              disabled={form.isSubmitting}
              label={themePrimaryColorField.label}
              placeholder="Pick a color"
              required={themePrimaryColorField.required}
              value={
                form.data.theme_primary_color ?? DEFAULT_THEME_PRIMARY_COLOR
              }
              onChange={(value) =>
                form.handleInputChange(
                  themePrimaryColorField.name,
                  value || "#000000"
                )
              }
              mb="sm"
            />

            {form.fieldError(pictureField)}
            <TextInput
              disabled={form.isSubmitting}
              label={pictureField.label}
              placeholder="e.g., https://example.org/image"
              required={pictureField.required}
              value={form.data.picture}
              onChange={(e) =>
                form.handleInputChange("picture", e.currentTarget.value)
              }
              mb="sm"
            />
            {form.data.picture && (
              <>
                {form.fieldError(pictureBackgroundColorField)}
                <ColorInput
                  disabled={form.isSubmitting}
                  label={pictureBackgroundColorField.label}
                  placeholder="Pick a color"
                  required={pictureBackgroundColorField.required}
                  value={form.data.picture_background_color ?? ""}
                  onChange={(value) =>
                    form.handleInputChange(
                      pictureBackgroundColorField.name,
                      value || ""
                    )
                  }
                  mb="sm"
                />
              </>
            )}
            <Stack>
              <Title order={5} ta="center">
                Avatar Preview
              </Title>
              <OrganizationAvatar
                organization={form.data}
                variant="filled"
                size={60}
                mx="auto"
              />
            </Stack>
          </Grid.Col>

          <Grid.Col span={12}>
            {form.fieldError(descriptionField)}
            <TextEditor
              label={descriptionField.label}
              placeholder="e.g., A description of the organization"
              content={form.data.description}
              editable={!form.isSubmitting}
              onUpdate={(value: string) => {
                form.handleInputChange("description", value);
              }}
            />
          </Grid.Col>
        </Grid>
        {form.overallError()}
        <Group mt="md">
          <Button
            bg={ServiceProviderPrimaryColor}
            disabled={form.isSubmitting}
            fullWidth
            type="submit"
          >
            {editMode
              ? `Save ${
                  isLedgerAI
                    ? "Service Provider"
                    : customerIsServiceProvider &&
                      initialData?.id === serviceProvider.id
                    ? ""
                    : "Client"
                }`
              : `Add ${isLedgerAI ? "Service Provider" : "Client"}`}
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
