import { Menu, UnstyledButton } from "@mantine/core";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganizationEntity } from "../../../../common/entities/organization.js";
import { EntityServiceResult } from "../../../../common/types/entity.js";
import { useActiveStates } from "../../../contexts/ActiveStatesContext.js";
import { useCreateUserModal } from "../../../hooks/useCreateUserModal.js";
import { useOrganizationModal } from "../../../hooks/useOrganizationModal.js";
import { useResponsiveDesign } from "../../../hooks/useResponsiveDesign.js";
import { PrimaryColor } from "../../../theme.js";
import { OrganizationAvatar } from "../../Avatars/OrganizationAvatar.js";
import { CustomersMenuContent } from "./CustomersMenuContent.js";

export default function CustomerMenuMain() {
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const { isAboveSmall } = useResponsiveDesign();
  const navigate = useNavigate();

  const {
    customer: activeCustomer,
    customers,
    isCustomerUser,
    serviceProvider,
    setCustomer,
  } = useActiveStates();
  const activeOrganization: OrganizationEntity = activeCustomer?.id
    ? activeCustomer
    : serviceProvider;

  const {
    createModal: createUserModal,
    setCreateModalOpened: setCreateUserModalOpened,
  } = useCreateUserModal(serviceProvider.id!, activeCustomer?.id);

  const {
    modal: createOrganizationModal,
    setModalOpened: setCreateOrganizationModalOpened,
  } = useOrganizationModal({
    editMode: false,
    serviceProvider,
    onCreateSuccess: async (
      result: EntityServiceResult<OrganizationEntity>
    ) => {
      if (result.success && result.data) {
        setCustomer(result.data);
        navigate("/");
      }
    },
  });

  if (!isCustomerUser && !Array.isArray(customers)) {
    throw new Error("Customers must be an array");
  }

  const ContentComponent = isAboveSmall ? Menu.Dropdown : Fragment;
  const contentProps = isAboveSmall ? { pt: 0 } : {};
  const wrapperProps = isAboveSmall
    ? { opened: menuOpened, onChange: setMenuOpened, shadow: "md" }
    : {};

  if (typeof isAboveSmall === "undefined") {
    return null;
  }

  return (
    <>
      <Menu {...wrapperProps}>
        {isAboveSmall && (
          <Menu.Target>
            <UnstyledButton onClick={() => setMenuOpened((o) => !o)}>
              <OrganizationAvatar
                organization={activeOrganization}
                size={45}
                color={PrimaryColor}
                bg={PrimaryColor}
                src={activeOrganization?.picture}
                hasShadow={(customers || []).length > 1}
              />
            </UnstyledButton>
          </Menu.Target>
        )}
        <ContentComponent {...contentProps}>
          <CustomersMenuContent
            customers={customers}
            setCustomer={setCustomer}
            activeCustomer={activeCustomer}
            serviceProvider={serviceProvider}
            activeOrganization={activeOrganization}
            isCustomerUser={isCustomerUser}
            setMenuOpened={setMenuOpened}
            setCreateOrganizationModalOpened={setCreateOrganizationModalOpened}
            setCreateUserModalOpened={setCreateUserModalOpened}
          />
        </ContentComponent>
      </Menu>

      {createUserModal}
      {!isCustomerUser && <>{createOrganizationModal}</>}
    </>
  );
}
