import { Alert, Button, Group, Stack, Title } from "@mantine/core";
import { IconAlertCircle, IconChevronRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";

import classes from "./StandardError.css.js";

export function StandardError({
  error,
  title = "Error",
}: {
  error: Error | string;
  title: string;
}) {
  return (
    <Group justify="center" h="100%" p="xl">
      <Stack w={400} maw="calc(100dvw - 40px)">
        <Stack gap="md" justify="center" align="center">
          <IconAlertCircle size={48} />
          <Title order={3} my="xs">
            {title}
          </Title>
          <Alert color="red" w="100%" className={classes.alert}>
            {typeof error === "string" ? error : error?.message ?? error}
          </Alert>
          <Button
            component={Link}
            to="/"
            variant="subtle"
            rightSection={<IconChevronRight size={16} />}
          >
            Go to home
          </Button>
        </Stack>
      </Stack>
    </Group>
  );
}
