import { ActionIcon, Box, Button, Menu, Stack, Text } from "@mantine/core";
import {
  IconCheck,
  IconDots,
  IconPencil,
  IconTrash,
} from "@tabler/icons-react";
import { useState } from "react";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationRelationshipUserEntity } from "../../../common/entities/organizationRelationshipUser.js";
import { organizationIdField } from "../../../common/fields/organization_id.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { OrgRelationship } from "../Tables/OrganizationsTable.js";

export function OrganizationMenu({
  relationship,
  onAddTeamMember,
  setEditingOrganization,
  onDelete,
}: {
  relationship: OrgRelationship;
  onAddTeamMember: (
    relationship: OrgRelationship
  ) => Promise<OrganizationRelationshipUserEntity>;
  setEditingOrganization: (organization: OrganizationEntity) => void;
  onDelete: (organization: OrgRelationship) => void;
}) {
  const { user, customers } = useActiveStates();
  const [isAddingTeamMember, setIsAddingTeamMember] = useState(false);
  const [isAddedTeamMember, setIsAddedTeamMember] = useState(false);

  return (
    <Menu position="bottom-end" withArrow>
      <Menu.Target>
        <ActionIcon variant="default" size="md">
          <IconDots size={15} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {(user as { [organizationIdField.name]: number })?.organization_id !==
          relationship.Organization.id &&
          !customers?.some(
            (customer) => customer.id === relationship.Organization.id
          ) && (
            <>
              <Box m="lg" mt="xs" ta="center">
                <Stack gap="sm">
                  <Text size="xs">
                    You're{" "}
                    {isAddedTeamMember
                      ? "now a member"
                      : "currently not a member"}
                    <br /> of this organization.
                  </Text>

                  <Button
                    disabled={isAddingTeamMember || isAddedTeamMember}
                    leftSection={isAddedTeamMember && <IconCheck size={20} />}
                    variant="default"
                    size="sm"
                    onClick={async () => {
                      setIsAddingTeamMember(true);
                      try {
                        await onAddTeamMember?.(relationship);
                        setIsAddedTeamMember(true);
                      } catch (error) {
                        console.error("Failed to add team member:", error);
                      } finally {
                        setIsAddingTeamMember(false);
                      }
                    }}
                  >
                    {isAddedTeamMember ? "Joined" : "Join"}{" "}
                    {relationship.Organization.name}
                  </Button>
                </Stack>
              </Box>
              <Menu.Divider />
            </>
          )}
        <Menu.Item
          leftSection={<IconPencil size={20} />}
          onClick={() => setEditingOrganization(relationship.Organization)}
        >
          Edit Organization
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item
          leftSection={<IconTrash size={20} />}
          color="#f44141"
          onClick={() => onDelete?.(relationship)}
        >
          Delete Organization
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
