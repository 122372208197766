import { Divider, ScrollArea, Stack, TextInput, Title } from "@mantine/core";
import { useState } from "react";
import { AdminLayout } from "../layouts/AdminLayout.js";
import { AdminOrgsList } from "./AdminOrgsList.js";

export function AdminOrgs() {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <AdminLayout>
      <Stack gap={0}>
        <Title order={2} p="sm">
          Organizations
        </Title>
        <Divider />
        <TextInput
          placeholder="Search by name, email or organization name"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.currentTarget.value)}
          m="sm"
        />
      </Stack>
      <Divider />
      <ScrollArea h={`calc(100vh - 200px)`}>
        <AdminOrgsList searchQuery={searchQuery} />
      </ScrollArea>
    </AdminLayout>
  );
}
