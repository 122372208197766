import { AccessControlGate } from "../../types/accessControl";

export interface AccessControlOrGate<T extends AccessControlGate<any, any>[]> {
  type: typeof OR_GATE;
  gates: T;
}

export const OR_GATE = "or" as const;

export function orGate<T extends AccessControlGate<any, any>[]>(
  ...gates: T
): AccessControlOrGate<T> {
  return {
    gates,
    type: OR_GATE,
  };
}
