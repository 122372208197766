import { css } from "../../utils/css.js";

interface ClassNames {
  printHidden: string;
}

export default css<ClassNames>`
  @media print {
    .printHidden {
      display: none;
    }
  }
`;
