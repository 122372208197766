import {
  ActionIcon,
  Card,
  Group,
  Loader,
  Stack,
  Textarea,
} from "@mantine/core";
import { IconArrowUp, IconPaperclip } from "@tabler/icons-react";
import { ChangeEvent, useEffect, useRef } from "react";

import classes from "../../components/Messages/MessageDisplay.css.js";

type ChatInputProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  input: string;
  sending: boolean;
  onSend: () => Promise<void> | void;
  setInput: (input: ChangeEvent<HTMLTextAreaElement>) => void;
  onSelectFiles: () => void;
};

export function ChatInput({
  children,
  disabled,
  input,
  sending,
  onSend,
  setInput,
  onSelectFiles,
}: ChatInputProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  // Set focus to the textarea when input is cleared
  useEffect(() => {
    if (input === "" && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [input]);

  const handleSend = async () => {
    if (!input.trim() || sending) return;

    console.log("ChatInput - Sending message:", input.trim());
    await onSend();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevents newline character
      handleSend();
    }
  };

  return (
    <Card withBorder radius="sm">
      <Stack>
        {children}
        <Textarea
          autosize
          className={classes.textarea}
          disabled={sending}
          minRows={3}
          maxRows={3}
          mb={0}
          onChange={setInput}
          onKeyDown={handleKeyDown}
          placeholder="Message Penny..."
          radius="sm"
          ref={textareaRef}
          size="md"
          value={input}
          variant="unstyled"
        />
        <Group justify="space-between">
          <ActionIcon
            size="md"
            radius="sm"
            variant="subtle"
            aria-label="Upload file"
            onClick={onSelectFiles}
          >
            <IconPaperclip />
          </ActionIcon>
          <ActionIcon
            size="md"
            radius="sm"
            variant="filled"
            aria-label="Send"
            onClick={handleSend}
            disabled={!input.trim() || sending || disabled}
          >
            {sending ? <Loader size="xs" type="dots" /> : <IconArrowUp />}
          </ActionIcon>
        </Group>
      </Stack>
    </Card>
  );
}
