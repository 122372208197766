type Primitive = string | number;

interface RequestOptions extends RequestInit {
  data?: object;
  params?: Record<string, Primitive | undefined>;
}

function urlWithParams(
  url: string,
  params?: Record<string, Primitive | undefined>
) {
  const fake = "http://_";
  const isLocalUrl = url.startsWith("/");
  const fullUrl = isLocalUrl ? `${fake}${url}` : url;
  const finalUrl = new URL(fullUrl);
  if (params) {
    Object.entries(params).forEach(([k, v]) => {
      if (typeof v !== "undefined") {
        finalUrl.searchParams.append(
          k,
          typeof v === "number"
            ? v.toString(10)
            : typeof v === "string"
            ? v
            : String(v)
        );
      }
    });
  }
  const finalUrlPath = finalUrl.toString();
  return isLocalUrl ? finalUrlPath.replace(fake, "") : finalUrlPath;
}

interface NetworkResponseSuccess<TResponseData> {
  data: TResponseData;
  status: number;
  success: true;
}

interface NetworkResponseError {
  error: string;
  text: string;
  status: number;
  success: false;
}

export type NetworkResponse<TResponseData> =
  | NetworkResponseError
  | NetworkResponseSuccess<TResponseData>;

async function resolve<TResponseData>(
  response: Response,
  onError: (error: string) => void
): Promise<NetworkResponse<TResponseData>> {
  try {
    if (response.status < 200 || response.status >= 500) {
      onError(response.statusText);
      return {
        error: response.statusText,
        text: await response.text(),
        status: response.status,
        success: false,
      };
    }
    if (response.status >= 300 && response.status < 500) {
      const [text, json] = await new Promise<[string, object]>(
        async (resolve) => {
          const text = await response.text();
          try {
            const json = JSON.parse(text);
            resolve([text, json]);
          } catch (e) {
            resolve([text, null]);
          }
        }
      );
      if (json) {
        if ("errors" in json && Array.isArray(json.errors)) {
          json.errors.map(onError);
        }
      }
      return {
        error: response.statusText,
        text,
        status: response.status,
        success: false,
      };
    }
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      return {
        data: await response.json(),
        status: response.status,
        success: true,
      };
    }
    return {
      error: "Unknown error",
      text: await response.text(),
      status: response.status,
      success: false,
    };
  } catch (e) {
    onError(e?.message ?? "Unknown error");
    return {
      error: e,
      text: await response.text(),
      status: response.status,
      success: false,
    };
  }
}

// Helper functions for caching
const cacheUtils = {
  // Helper to get cache key from URL and params
  getCacheKey(
    url: string,
    params?: Record<string, Primitive | undefined>
  ): string {
    const urlWithParamsStr = urlWithParams(url, params).toString();
    return urlWithParamsStr;
  },

  // Helper to clear cache by URL pattern
  clearCacheByPattern(pattern: string): void {
    for (const key of Array.from(requestCache.keys())) {
      if (key.includes(pattern)) {
        requestCache.delete(key);
      }
    }
  },
};

// Export cache utilities
export const { getCacheKey, clearCacheByPattern } = cacheUtils;

// Request cache to store responses and in-flight requests by URL
export const requestCache = new Map<
  string,
  {
    data?: any;
    timestamp?: number;
    promise?: Promise<any>;
  }
>();

export const networkOnce = {
  async get<TResponseData>(
    url: string,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, ...init } = options;
    if (!init.headers) {
      init.headers = {};
    }
    if (!("Accept" in init.headers)) {
      init.headers["Accept"] = "application/json";
    }
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), init),
      onError
    );
  },
  async delete<TResponseData>(
    url: string,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, data, ...init } = options;
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: data ? JSON.stringify(data) : undefined,
        method: "DELETE",
      }),
      onError
    );
  },
  async post<TResponseData>(
    url: string,
    body: object,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, ...init } = options;
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: JSON.stringify(body),
        method: "POST",
      }),
      onError
    );
  },
  async postFormData<TResponseData>(
    url: string,
    body: Record<string, Primitive | undefined>,
    options: RequestOptions | undefined,
    onError: (error: string) => void
  ) {
    const { params, ...init } = options ?? {};
    console.dir({
      url: urlWithParams(url, params),
      ...init,
      body: new URLSearchParams(body as Record<string, string>).toString(),
      method: "POST",
      headers: {
        ...(init.headers ?? {}),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: new URLSearchParams(body as Record<string, string>).toString(),
        method: "POST",
        headers: {
          ...(init.headers ?? {}),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
      onError
    );
  },
  async patch<TResponseData>(
    url: string,
    body: object,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, ...init } = options;
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: JSON.stringify(body),
        method: "PATCH",
      }),
      onError
    );
  },
  async put<TResponseData>(
    url: string,
    body: object,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, ...init } = options;
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: JSON.stringify(body),
        method: "PUT",
      }),
      onError
    );
  },
};

export const cachedNetworkOnce = {
  async get<TResponseData>(
    url: string,
    options: RequestOptions,
    onError: (error: string) => void,
    cacheDurationMs: number = 60000 // 1 minute default cache duration
  ) {
    const { params, ...init } = options;
    const cacheKey = getCacheKey(url, params);

    // Check cache first
    const cachedItem = requestCache.get(cacheKey);

    // If there's an in-flight request, return its promise
    if (cachedItem?.promise) {
      return cachedItem.promise as Promise<NetworkResponse<TResponseData>>;
    }

    // If there's a completed response that's still valid, return it
    if (
      cachedItem?.data &&
      cachedItem?.timestamp &&
      Date.now() - cachedItem.timestamp < cacheDurationMs
    ) {
      return cachedItem.data as NetworkResponse<TResponseData>;
    }

    // Not in cache or expired, make the request
    if (!init.headers) {
      init.headers = {};
    }
    if (!("Accept" in init.headers)) {
      init.headers["Accept"] = "application/json";
    }

    // Create the promise and store it in the cache immediately
    let responsePromise: Promise<NetworkResponse<TResponseData>>;
    responsePromise = (async () => {
      try {
        const response = await resolve<TResponseData>(
          await fetch(urlWithParams(url, params), init),
          onError
        );

        // When promise resolves, update the cache with the actual response
        if (response.success) {
          requestCache.set(cacheKey, {
            data: response,
            timestamp: Date.now(),
          });
        } else {
          // If request fails, remove the promise from cache so future requests can try again
          const current = requestCache.get(cacheKey);
          if (current?.promise === responsePromise) {
            requestCache.delete(cacheKey);
          }
        }
        return response;
      } catch (err) {
        // If promise rejects, also remove it from cache
        const current = requestCache.get(cacheKey);
        if (current?.promise === responsePromise) {
          requestCache.delete(cacheKey);
        }
        throw err;
      }
    })();

    // Store the promise in the cache
    requestCache.set(cacheKey, {
      promise: responsePromise,
    });

    return responsePromise;
  },
  // Other methods same as networkOnce
  // So, only GET is cached
  delete: networkOnce.delete,
  patch: networkOnce.patch,
  post: networkOnce.post,
  postFormData: networkOnce.postFormData,
  put: networkOnce.put,
};

// Cached network with retries
export const cachedNetwork: typeof cachedNetworkOnce = {
  get: retries(cachedNetworkOnce.get),
  delete: retries(cachedNetworkOnce.delete),
  patch: retries(cachedNetworkOnce.patch),
  post: retries(cachedNetworkOnce.post),
  postFormData: retries(cachedNetworkOnce.postFormData),
  put: retries(cachedNetworkOnce.put),
};

export const network: typeof networkOnce = {
  get: retries(networkOnce.get),
  delete: retries(networkOnce.delete),
  patch: retries(networkOnce.patch),
  post: retries(networkOnce.post),
  postFormData: retries(networkOnce.postFormData),
  put: retries(networkOnce.put),
};

export function retries<T extends unknown[]>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (...args: T) => Promise<NetworkResponse<any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (...args: T) => Promise<NetworkResponse<any>> {
  let retriesRemaining = 3;
  let retryDelaySeconds = 2;
  const retryDecayFactor = 4; // 2, 2*4 = 8, 8*4 = 32
  return async function (...args: T) {
    while (retriesRemaining >= 0) {
      const response = await fn(...(args as T));
      if (response.status >= 500) {
        if (retriesRemaining === 0) {
          console.error("Final error", args, response);
          return response;
        }
        retriesRemaining--;
        console.warn(
          `Waiting ${retryDelaySeconds} seconds (${retriesRemaining} retries remaining)...`,
          args
        );
        await new Promise((r) => setTimeout(r, retryDelaySeconds * 1000));
        retryDelaySeconds *= retryDecayFactor;
        continue;
      }
      return response;
    }
    throw new Error("unreachable");
  };
}
