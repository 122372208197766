/**
 * Safely converts a Date or undefined to ISO string
 * If the date is undefined, returns current date's ISO string
 */
export function toISOString(date?: Date | string): string {
  if (!date) {
    return new Date().toISOString();
  }
  if (typeof date === "string") {
    return date;
  }
  return date.toISOString();
}
