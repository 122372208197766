import { ReactNode, useState } from "react";

import { AsideContext, AsideContextType } from "./AsideContext.js";

interface AsideProviderProps {
  children: ReactNode;
}

/**
 * Provider component for the Aside functionality
 * This is the only implementation of the AsideContext provider
 */
export const AsideProvider = ({ children }: AsideProviderProps) => {
  const [opened, setOpened] = useState(false);
  const [isPennyChatOpen, setIsPennyChatOpen] = useState(false);
  const [currentContent, setCurrentContent] = useState<ReactNode | null>(null);
  const [currentFolderPath, setCurrentFolderPath] = useState<
    string | undefined
  >(undefined);

  // Force an update to ensure state changes are applied
  const [updateCounter, setUpdateCounter] = useState(0);
  const forceUpdate = () => setUpdateCounter((prev) => prev + 1);

  const openPennyChat = (folderPath?: string) => {
    console.log("openPennyChat called - state before:", {
      opened,
      isPennyChatOpen,
      hasContent: !!currentContent,
      folderPath,
    });
    setOpened(true);
    setIsPennyChatOpen(true);
    setCurrentContent(null);
    setCurrentFolderPath(folderPath);
    forceUpdate();
    console.log("openPennyChat complete - state after:", {
      opened: true,
      isPennyChatOpen: true,
      hasContent: false,
      folderPath,
    });
  };

  const closePennyChat = () => {
    console.log("closePennyChat called - state before:", {
      opened,
      isPennyChatOpen,
      hasContent: !!currentContent,
      folderPath: currentFolderPath,
    });
    setIsPennyChatOpen(false);
    setCurrentFolderPath(undefined);
    if (!currentContent) {
      setOpened(false);
    }
    forceUpdate();
    console.log("closePennyChat complete - state after:", {
      opened: !currentContent ? false : true,
      isPennyChatOpen: false,
      hasContent: !!currentContent,
      folderPath: undefined,
    });
  };

  const togglePennyChat = (folderPath?: string) => {
    console.log("togglePennyChat called - state before:", {
      opened,
      isPennyChatOpen,
      hasContent: !!currentContent,
      currentFolderPath,
      newFolderPath: folderPath,
    });

    // If we're already open and the folder path is the same, close it
    // Otherwise, open with the new folder path
    if (isPennyChatOpen && currentFolderPath === folderPath) {
      console.log("Same folder - closing aside");
      setIsPennyChatOpen(false);
      setOpened(false);
      setCurrentFolderPath(undefined);
    } else {
      console.log(
        "Different folder or not open - opening aside with new folder path"
      );
      setIsPennyChatOpen(true);
      setOpened(true);
      setCurrentContent(null);
      setCurrentFolderPath(folderPath);

      // Debug current state right after setting
      setTimeout(() => {
        console.log("State after immediate timeout:", {
          opened,
          isPennyChatOpen,
          currentFolderPath,
        });
      }, 0);
    }

    forceUpdate();
    console.log("togglePennyChat complete - state after:", {
      opened: !isPennyChatOpen || currentFolderPath !== folderPath,
      isPennyChatOpen: !isPennyChatOpen || currentFolderPath !== folderPath,
      hasContent: false,
      folderPath:
        !isPennyChatOpen || currentFolderPath !== folderPath
          ? folderPath
          : undefined,
    });
  };

  const openContent = (content: ReactNode, preserveFolderPath = true) => {
    console.log("openContent called with:", {
      content,
      preserveFolderPath,
      currentState: {
        opened,
        isPennyChatOpen,
        hasContent: !!currentContent,
        currentFolderPath,
      },
    });

    // Use a direct approach to ensure state is updated properly
    setOpened(true);
    setIsPennyChatOpen(false);
    setCurrentContent(content);

    // Only clear the folder path if not preserving it
    if (!preserveFolderPath) {
      setCurrentFolderPath(undefined);
    }

    forceUpdate();
    console.log(
      "openContent complete - folder path:",
      preserveFolderPath ? currentFolderPath : undefined
    );
  };

  const closeAside = () => {
    console.log("closeAside called - state before:", {
      opened,
      isPennyChatOpen,
      hasContent: !!currentContent,
      folderPath: currentFolderPath,
    });
    setOpened(false);
    setIsPennyChatOpen(false);
    setCurrentContent(null);
    setCurrentFolderPath(undefined);
    forceUpdate();
    console.log("closeAside complete - state after:", {
      opened: false,
      isPennyChatOpen: false,
      hasContent: false,
      folderPath: undefined,
    });
  };

  // New method to update folder path without changing panel state
  const updateFolderPath = (folderPath?: string) => {
    console.log(
      "updateFolderPath called - current path:",
      currentFolderPath,
      "new path:",
      folderPath
    );

    // Only update if the path is different and the panel is already open
    if (opened && currentFolderPath !== folderPath) {
      console.log("Updating folder path to:", folderPath);
      setCurrentFolderPath(folderPath);

      // If PennyChat is open, make sure it stays open with the new path
      if (isPennyChatOpen) {
        setIsPennyChatOpen(true);
      }

      forceUpdate();
      console.log("Folder path updated - new state:", {
        opened,
        isPennyChatOpen,
        currentFolderPath: folderPath,
      });
    }
  };

  // Create the context value object with all required properties
  const contextValue: AsideContextType = {
    currentContent,
    currentFolderPath,
    isPennyChatOpen,
    opened,
    closeAside,
    closePennyChat,
    openContent,
    openPennyChat,
    togglePennyChat,
    updateFolderPath,
  };

  return (
    <AsideContext.Provider value={contextValue}>
      {children}
    </AsideContext.Provider>
  );
};
