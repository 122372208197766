import { css } from "../../utils/css.js";

interface ClassNames {
  navbar: string;
  navbarMobile: string;
  wrapper: string;
  aside: string;
  mainLinks: string;
  logout: string;
  main: string;
  mainLink: string;
  title: string;
  logo: string;
  link: string;
}

export default css<ClassNames>`
  .navbar {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .navbarMobile {
    align-items: flex-start;
  }
  .navbarMobile .aside {
    flex: 1;
  }

  .wrapper {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
  }

  .aside {
    flex: 0 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Add this line */
    padding: var(--mantine-spacing-xs) 0;
    @media (max-width: 768px) {
      align-items: flex-start;
    }
  }

  .mainLinks {
    flex-grow: 1;
  }

  .logout {
    margin-bottom: var(--mantine-spacing-sm);
  }

  .main {
    flex: 1;
  }

  .mainLink {
    border-radius: var(--mantine-radius-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
  }

  .title {
    font-family: "Greycliff CF", var(--mantine-font-family);
    margin-bottom: var(--mantine-spacing-xl);
    background-color: var(--mantine-color-body);
    padding: var(--mantine-spacing-md);
    padding-top: 18px;
    height: 60px;
    border-bottom: 1px solid
      light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  }

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60px;
    padding-top: var(--mantine-spacing-md);
    border-bottom: 1px solid var(--app-shell-border-color);
    margin-bottom: var(--mantine-spacing-xs);
  }

  .link {
    display: block;
    text-decoration: none;
    border-top-right-radius: var(--mantine-radius-xl);
    border-bottom-right-radius: var(--mantine-radius-xl);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
    padding: 0 var(--mantine-spacing-md);
    font-size: var(--mantine-font-size-sm);
    margin-right: var(--mantine-spacing-md);
    font-weight: 500;
    height: 44px;
    line-height: 44px;
  }
`;
