import {
  Box,
  Button,
  Card,
  Group,
  Loader,
  Menu,
  Select,
  Text,
} from "@mantine/core";
import { useCallback } from "react";

import { useActiveStates } from "../../../contexts/ActiveStatesContext.js";
import { StoredAuthProfile } from "../../../contexts/AuthContext.js";
import { useAuth } from "../../../hooks/useAuth.js";
import { useBuildEnvironments } from "../../../hooks/useBuildEnvironments.js";
import { useFeatureEnabled } from "../../../hooks/useFeatureEnabled.js";
import { AccountPrimaryColor } from "../../../theme.js";
import { UserAvatar } from "../../Avatars/UserAvatar.js";
import AccountsList from "./AccountsList.js";

export default function AccountMenuContent({
  account,
  allAccounts,
  deleteAccount,
  openConfigureModal,
  handleEditProfileClick,
}: {
  account: StoredAuthProfile;
  allAccounts: StoredAuthProfile[];
  deleteAccount: (account: StoredAuthProfile) => void;
  openConfigureModal: () => void;
  handleEditProfileClick: () => void;
  opened: boolean;
}) {
  const {
    sessionId,
    session,
    logOut: _logOut,
    logOutAll: _logOutAll,
  } = useAuth(); // Get session which contains accessToken
  const { serviceProvider, isCustomerUser, customer, setUser } =
    useActiveStates();
  const {
    environments,
    loading: environmentsLoading,
    error: environmentsError,
    currentEnvironment,
  } = useBuildEnvironments();

  const configureEnabled = useFeatureEnabled("configure");

  const handleEnvironmentChange = useCallback(
    (selectedUrl: string | null) => {
      if (selectedUrl && session?.accessToken) {
        // Append the access token as a query parameter
        const url = new URL(selectedUrl);
        url.searchParams.set("accessToken", session.accessToken); // Use session.accessToken
        window.location.href = url.toString();
      } else if (selectedUrl) {
        // Fallback if no access token (might be public page or error)
        window.location.href = selectedUrl;
      } else {
        console.warn(
          "handleEnvironmentChange called with null or undefined URL"
        );
      }
    },
    [session?.accessToken] // Update dependency array
  );

  const logOut = useCallback(async () => {
    if (typeof sessionId === "number") {
      deleteAccount({ sessionId, profile: account?.profile });
    }
    setUser(undefined);
    await _logOut((url) => window.location.replace(url));
  }, [_logOut, sessionId, setUser]);

  const logOutAll = useCallback(async () => {
    setUser(undefined);
    try {
      // Remove all stored profiles from localStorage
      localStorage.removeItem("authProfiles");

      // Clear sessionStorage to remove session data
      sessionStorage.clear();

      // Call the logout function with a redirect
      await _logOutAll((url) => window.location.replace(url));
    } catch (error) {
      console.error("Error logging out of all accounts:", error);
    }
  }, [_logOutAll, setUser]);

  return (
    <>
      {account?.profile ? (
        <Card radius="lg">
          <Card.Section h={90} bg={AccountPrimaryColor}>
            <Text ta="center" mt="lg" c="white" size="sm" fw={600}>
              {isCustomerUser
                ? customer?.primary_domain
                : serviceProvider?.primary_domain}
            </Text>
          </Card.Section>
          <UserAvatar
            user={account?.profile ?? {}}
            color={AccountPrimaryColor}
            variant="filled"
            size={80}
            radius={80}
            mx="auto"
            mt={-30}
            style={{ border: "2px solid white" }}
          />
          <Text ta="center" fz="lg" fw={700} mt="sm">
            {account?.profile.given_name ||
              account?.profile.nickname ||
              account?.profile.email ||
              "Unknown"}
          </Text>
          <Text ta="center" fz="sm" fw={500} c="dimmed">
            {account?.profile.email || "No Email"}
          </Text>
          <Group justify="center">
            <Button
              color={AccountPrimaryColor}
              size="xs"
              mt="sm"
              variant="light"
              onClick={handleEditProfileClick}
            >
              Edit Profile
            </Button>

            <Button
              color={AccountPrimaryColor}
              onClick={logOut}
              size="xs"
              mt="sm"
              variant="light"
            >
              Log out
            </Button>
          </Group>
        </Card>
      ) : (
        <Group gap="xs" mr="sm">
          <Loader type="dots" />
        </Group>
      )}
      {account?.profile && allAccounts.length > 1 && (
        <>
          <Menu.Divider />
          <Menu.Label>Other accounts</Menu.Label>
          <AccountsList
            accounts={allAccounts.filter(
              (a) =>
                a.profile.id !==
                (typeof account.profile.id === "string"
                  ? parseInt(account.profile.id, 10)
                  : account.profile.id)
            )}
          />
        </>
      )}
      <Menu.Divider />
      <Menu.Item component="a" href="/api/auth/login">
        Add new account
      </Menu.Item>
      <Menu.Item onClick={logOutAll}>Log out of all accounts</Menu.Item>
      {configureEnabled && (
        <>
          <Menu.Divider />
          <Menu.Item onClick={openConfigureModal}>Configure...</Menu.Item>
          <Menu.Divider />
          <Menu.Label>Environment</Menu.Label>
          {environmentsLoading && (
            <Menu.Item disabled>Loading environments...</Menu.Item>
          )}
          {environmentsError && (
            <Menu.Item color="red" disabled>
              Error loading environments
            </Menu.Item>
          )}
          {!environmentsLoading &&
            !environmentsError &&
            environments.length > 0 && (
              <Box px="xs" py="xs">
                <Select
                  placeholder="Switch environment"
                  data={environments}
                  value={currentEnvironment}
                  onChange={handleEnvironmentChange}
                  disabled={environmentsLoading || !!environmentsError}
                  searchable
                  maxDropdownHeight={200}
                />
              </Box>
            )}
        </>
      )}
    </>
  );
}
