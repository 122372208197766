import { Loader, NavLink, Box, Text } from "@mantine/core";
import { NavLink as ReactRouterNavLink } from "react-router-dom";

import { usePennyConversationsList } from "./usePennyConversationsList.js";

export default function PennyConversations({
  opened,
  onClose,
  filter,
}: {
  opened: boolean;
  onClose: () => void;
  filter: string;
}) {
  const { conversations, isLoading } = usePennyConversationsList();

  const filteredConversations = conversations.filter((conversation) =>
    conversation.title?.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            alignContent: "flex-end",
            alignItems: "center",
            display: "flex",
            height: "fit-content",
            justifyContent: "center",
            overflow: "hidden",
            padding: "100px",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          {filteredConversations.length > 0 ? (
            filteredConversations.map((conversation) => (
              <NavLink
                component={ReactRouterNavLink}
                key={conversation.id}
                to={`/penny/conversations/${conversation.id}`}
                label={conversation.title}
                onClick={opened ? onClose : undefined}
              />
            ))
          ) : (
            <Box p="sm">
              <Text>No conversations found</Text>
            </Box>
          )}
        </>
      )}
    </>
  );
}
