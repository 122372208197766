import { ComboboxItemGroup, Group, Loader, Select } from "@mantine/core";
import {
  IconBrandAmazon,
  IconBrandGoogle,
  IconBrandOpenai,
  IconBrandXFilled,
  IconChevronDown,
  IconCode,
  IconRobotFace,
} from "@tabler/icons-react";
import { useEffect, useMemo } from "react";

import {
  AI_PROVIDER_AMAZON_BEDROCK,
  AI_PROVIDER_ANTHROPIC,
  AI_PROVIDER_GOOGLE,
  AI_PROVIDER_OPENAI,
  AI_PROVIDER_XAI,
  OPENAI_MODELS,
  SUPPORTED_AI_PROVIDERS,
} from "../../../common/configuration/aiProviders.js";
import { AIModelEntity } from "../../../common/entities/aiModel.js";
import { AIProviderEntity } from "../../../common/entities/aiProvider.js";
import {
  aiModelClient,
  aiProviderClient,
} from "../../../common/utils/entityClient.js";
import { useSessionState } from "../../hooks/useSessionState.js";
import { clientError } from "../../utils/clientError.js";

import classes from "./SelectModel.css.js";

function getAIProviderIcon(aiProviderSlug?: string) {
  return (
    (typeof aiProviderSlug === "string"
      ? {
          [AI_PROVIDER_AMAZON_BEDROCK]: <IconBrandAmazon size="16" />,
          [AI_PROVIDER_OPENAI]: <IconBrandOpenai size="16" />,
          [AI_PROVIDER_XAI]: <IconBrandXFilled size="16" />,
          [AI_PROVIDER_GOOGLE]: <IconBrandGoogle size="16" />,
          [AI_PROVIDER_ANTHROPIC]: <IconRobotFace size="16" />,
        }[aiProviderSlug]
      : undefined) ?? <IconCode size="16" />
  );
}

export default function SelectModel({
  disabled,
  model,
  setModel,
}: {
  disabled: boolean;
  model: AIModelEntity | null;
  setModel: (model: AIModelEntity | null) => void;
}) {
  const [aiModels, setAIModels] = useSessionState<AIModelEntity[]>(
    "ai-models",
    []
  );
  const [aiProviders, setAIProviders] = useSessionState<AIProviderEntity[]>(
    "ai-providers",
    []
  );

  useEffect(function () {
    async function load() {
      if (aiModels.length > 0 && aiProviders.length > 0) {
        if (!model) {
          console.log(
            `Setting default model to ${OPENAI_MODELS.DEFAULT}`,
            model,
            aiModels.find((m) => m.slug === OPENAI_MODELS.DEFAULT)
          );
          setModel(
            aiModels.find((m) => m.slug === OPENAI_MODELS.DEFAULT) || null
          );
        }
        return;
      }
      const [listModelsResponse, listProvidersResponse] = await Promise.all([
        aiModelClient(clientError).list(),
        aiProviderClient(clientError).list(),
      ]);
      if (listModelsResponse.success) {
        if (listModelsResponse.data.success) {
          setAIModels(listModelsResponse.data.data as AIModelEntity[]);
          if (!model) {
            console.log(
              `Setting default model to ${OPENAI_MODELS.DEFAULT}`,
              model,
              (listModelsResponse.data.data as AIModelEntity[]).find(
                (m: AIModelEntity) => m.slug === OPENAI_MODELS.DEFAULT
              )
            );
            setModel(
              (listModelsResponse.data.data as AIModelEntity[]).find(
                (m: AIModelEntity) => m.slug === OPENAI_MODELS.DEFAULT
              ) || null
            );
          }
        } else {
          return;
        }
      } else {
        return;
      }
      if (listProvidersResponse.success) {
        if (listProvidersResponse.data.success) {
          setAIProviders(listProvidersResponse.data.data as AIProviderEntity[]);
        }
      }
    }
    load().catch((e) => console.error(e));
  }, []);

  const groupedData = useMemo(
    () =>
      aiModels && aiProviders
        ? aiProviders
            .filter((provider) =>
              SUPPORTED_AI_PROVIDERS.includes(provider.slug!)
            )
            .map(
              (
                provider
              ): ComboboxItemGroup<{ value: string; label: string }> => ({
                group: provider.name!,
                items: aiModels
                  .filter((model) => model.ai_provider_id === provider.id)
                  .map((model) => ({
                    value: model.id!.toString(10),
                    label: model.name!,
                  })),
              })
            )
        : [],
    [aiModels, aiProviders]
  );

  if (aiProviders.length === 0 || aiModels.length === 0) {
    return (
      <Group>
        <Loader size="xs" type="dots" />
        <Select disabled />
      </Group>
    );
  }

  return (
    <Select
      disabled={disabled}
      leftSection={getAIProviderIcon(
        aiProviders.find((p) => p.id === model?.ai_provider_id)?.slug
      )}
      rightSection={<IconChevronDown size="16" />}
      placeholder="Select AI Model"
      data={groupedData}
      value={model?.id?.toString(10) || null}
      onChange={(value) => {
        const selectedModel = aiModels.find(
          (m) => m.id!.toString(10) === value
        );
        setModel(selectedModel || null);
      }}
      searchable
      classNames={{
        input: classes.selectInput,
      }}
    />
  );
}
