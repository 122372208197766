import { css } from "../utils/css.js";

interface ClassNames {
  content: string;
  wrapper: string;
}

export default css<ClassNames>`
  .content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    padding: 58px 0 0;
  }

  @media print {
    .content {
      padding-top: 0;
    }
  }
`;
