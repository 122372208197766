import { IconBoxModel } from "@tabler/icons-react";
import { Group, Table, Text } from "@mantine/core";
import { AIModelEntity } from "../../../common/entities/aiModel.js";
import { PrimaryColor } from "../../theme.js";
import { AIProviderEntity } from "../../../common/entities/aiProvider.js";

export function AdminAIProviderListItem(
  aiProvider: AIProviderEntity,
  models: AIModelEntity[]
) {
  return (
    <Table.Tr key={aiProvider.id}>
      <Table.Td p="xs">
        <Text size="lg">{aiProvider.name}</Text>
      </Table.Td>
      <Table.Td p="xs">
        {models
          .filter((m) => m.ai_provider_id === aiProvider.id)
          .map((m) => (
            <Group
              c={PrimaryColor}
              key={m.id}
              gap={0}
              style={{ whiteSpace: "nowrap", flexWrap: "nowrap" }}
            >
              <IconBoxModel />
              <Text
                size="sm"
                title={`${m.name} (${m.slug})`}
                style={{
                  maxWidth: "80%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {m.name}
              </Text>
            </Group>
          ))}
      </Table.Td>
    </Table.Tr>
  );
}
