import { useMemo, useState } from "react";
import {
  ActionIcon,
  Button,
  Card,
  Group,
  Modal,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useComputedColorScheme,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconPin, IconPinFilled } from "@tabler/icons-react";
import ReactTimeAgo from "react-time-ago";

import { getUserName, UserEntity } from "../../../common/entities/user.js";
import { Note } from "../../hooks/useNotes.js";
import { MarkdownRenderer } from "../../pages/penny/MarkdownRenderer.js";
import { ColorPopover } from "../Color/ColorPopover.js";
import { NoteMenu } from "./NoteMenu.js";

import {
  COLOR_OPTION_NAMES,
  COLOR_OPTIONS_DARK_MODE,
  COLOR_OPTIONS_LIGHT_MODE,
  darkModeColor,
} from "./noteCardUtils.js";

interface NoteCardProps {
  note: Note;
  user: UserEntity;
  onUpdate: (id: string, text: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onTogglePin: (id: string) => Promise<void>;
  onChangeColor: (id: string, color: string) => Promise<void>;
  onCopyText: (text: string) => void;
}

export function NoteCard({
  note,
  user,
  onUpdate,
  onDelete,
  onTogglePin,
  onChangeColor,
  onCopyText,
}: NoteCardProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editedText, setEditedText] = useState(note.text);
  const [colorPopoverOpened, setColorPopoverOpened] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!editedText.trim()) {
      return;
    }
    if (editedText.trim() === note.text.trim()) {
      handleCancelEdit();
      return;
    }
    setIsSaving(true);
    try {
      await onUpdate(note.id, editedText);
      setIsEditing(false);
      notifications.show({
        title: "Success",
        message: "Note updated successfully",
        color: "green",
      });
    } catch (err) {
      console.error("Failed to update note:", err);
      notifications.show({
        title: "Error",
        message: "Failed to update note",
        color: "red",
      });
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  const handleCancelEdit = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setEditedText(note.text);
    setIsEditing(false);
  };

  const isDarkMode = useComputedColorScheme() === "dark";

  const backgroundColor = useMemo(() => {
    return isDarkMode ? darkModeColor(note.color) : note.color;
  }, [isDarkMode, note.color]);

  const showActions = isHovering || isEditing || colorPopoverOpened;

  const card = (
    <Card
      className="noteContent"
      px="lg"
      pt="xs"
      pb="0"
      radius="sm"
      withBorder={!backgroundColor}
      style={{
        backgroundColor,
        opacity: isDeleting ? 0.5 : 1,
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => setIsEditing(true)}
    >
      <Stack justify="space-between" gap="0" h="100%">
        <Card.Section
          flex={1}
          inheritPadding
          mih={70}
          mah={isEditing ? "75dvh" : "unset"}
          style={{
            position: "relative",
            overflow: isEditing ? "auto" : "unset",
          }}
        >
          {(showActions || note.pinned) && (
            <Tooltip label={note.pinned ? "Unpin note" : "Pin note"}>
              <ActionIcon
                style={{
                  position: "absolute",
                  right: "5px",
                  top: 0,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onTogglePin(note.id);
                }}
                aria-label="Unpin note"
                title="Unpin note"
                variant="subtle"
                size="lg"
                color="var(--mantine-color-text)"
              >
                {note.pinned ? <IconPinFilled /> : <IconPin />}
              </ActionIcon>
            </Tooltip>
          )}
          {isEditing && (
            <Textarea
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              minRows={3}
              autosize
              variant="unstyled"
              data-autofocus
            />
          )}
          {!isEditing && <MarkdownRenderer content={note.text} />}
        </Card.Section>
        <Card.Section inheritPadding py="xs">
          <Text size="xs" style={{ opacity: "0.4" }}>
            <ReactTimeAgo date={new Date(note.updatedAt)} /> by{" "}
            {getUserName(user)}
          </Text>
        </Card.Section>
        <Card.Section inheritPadding py="xs" h={55}>
          <Group justify="space-between" h="100%" wrap="nowrap">
            <Group wrap="nowrap" flex={1}>
              {showActions && (
                <>
                  <ColorPopover
                    colorNames={COLOR_OPTION_NAMES}
                    colorPopoverOpened={colorPopoverOpened}
                    darkModeColors={COLOR_OPTIONS_DARK_MODE}
                    lightModeColors={COLOR_OPTIONS_LIGHT_MODE}
                    onChangeColor={(color) => onChangeColor(note.id, color)}
                    selectedColor={note.color}
                    setColorPopoverOpened={setColorPopoverOpened}
                  />
                  <NoteMenu
                    isEditing={isEditing}
                    onEdit={() => {
                      setEditedText(note.text);
                      setIsEditing(true);
                    }}
                    onCopy={() => onCopyText(editedText)}
                    onDelete={() => {
                      setIsDeleting(true);
                      onDelete(note.id);
                    }}
                  />
                </>
              )}
            </Group>
            {isEditing && (
              <Group justify="flex-end">
                <Button
                  variant="subtle"
                  disabled={isSaving}
                  onClick={handleCancelEdit}
                >
                  Cancel
                </Button>
                <Button
                  variant="subtle"
                  loading={isSaving}
                  disabled={!editedText.trim()}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Group>
            )}
          </Group>
        </Card.Section>
      </Stack>
    </Card>
  );

  return (
    <>
      {!isEditing ? (
        card
      ) : (
        <Modal
          opened={isEditing}
          onClose={handleCancelEdit}
          withCloseButton={false}
          styles={{ body: { padding: "0" } }}
          transitionProps={{ transition: "scale", duration: 200 }}
        >
          {card}
        </Modal>
      )}
    </>
  );
}
