function resizeGridItem(item: Element, rowHeight: number, rowGap: number) {
  if (!item) {
    return;
  }
  const noteContent = item.querySelector(".noteContent") as HTMLDivElement;
  if (!noteContent) {
    return;
  }
  const rowSpan = Math.ceil(
    (noteContent.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap)
  );
  (item as HTMLDivElement).style.gridRowEnd = "span " + rowSpan;
  noteContent.style.height = "100%";
}

export function resizeAllGridItems() {
  const grid = document.getElementById("pinnedNotesContainer");
  if (!grid) {
    return;
  }

  const rowHeight = parseInt(
    window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
  );
  const rowGap = parseInt(
    window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
  );

  const allItems = document.getElementsByClassName("noteItem");
  for (let x = 0; x < allItems.length; x++) {
    resizeGridItem(allItems[x], rowHeight, rowGap);
  }
}
