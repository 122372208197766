import { useContext } from "react";

import { AsideContext, AsideContextType } from "../contexts/AsideContext.js";

/**
 * Hook to access the aside panel functionality
 * Available methods:
 * - opened: boolean - Whether the aside panel is open
 * - openPennyChat: (folderPath?: string) => void - Opens the penny chat panel with optional folder path context
 * - closeAside: () => void - Closes the aside panel
 * - isPennyChatOpen: boolean - Whether the penny chat is open
 * - closePennyChat: () => void - Closes the penny chat
 * - togglePennyChat: (folderPath?: string) => void - Toggles the penny chat with optional folder path context
 * - openContent: (content: ReactNode) => void - Opens custom content in the aside panel
 * - currentContent: ReactNode | null - The current custom content in the aside panel
 * - currentFolderPath: string | undefined - The current folder path context for PennyChat
 */
export const useAside = (): AsideContextType => {
  const context = useContext(AsideContext);
  if (!context) {
    throw new Error("useAside must be used within an AsideProvider");
  }
  return context;
};
