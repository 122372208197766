import { EntityFilterType } from "../../services/entityService";
import { AccessControlOrGate } from "../accessControl/gates/orGate";
import { AccessControlIsOnlyMemberGate } from "../accessControl/gates/userIsOnlyOrganizationUserGate";
import { UserEntity } from "../entities/user";
import {
  Entity,
  EntityCreateType,
  EntityTypeFromFields,
  EntityUpdateType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "./entity";
import { Field, FieldMetadata } from "./field";

export { AccessControlOrGate, OR_GATE } from "../accessControl/gates/orGate";

export const FILTER_BY_JOIN_TABLE_GATE = "filterByJoinTable" as const;
export const IS_ONLY_MEMBER_GATE = "isOnlyMember" as const;
export const JOIN_RECORD_EXISTS_GATE = "joinRecordExists" as const;
export const READ_ONLY_TABLE_GATE = "readOnly" as const;
export const RECORD_EXISTS_GATE = "recordExists" as const;
export const RECORD_NOT_EXISTS_GATE = "recordNotExists" as const;
export const PREDETERMINED_GATE = "predetermined" as const;

// eslint-off @typescript-eslint/no-explicit-any
export interface EntityAccessControl<
  T extends FieldList,
  U extends PrimaryKeyFieldTuple
> {
  list(
    user: UserEntity,
    filters: EntityFilterType<T, U>
  ):
    | {
        errors: string[];
        gates: undefined;
      }
    | {
        errors: undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        gates: AccessControlGate<any, any>[];
      };
}

export type AccessControlRecordExistsGate<
  V extends FieldList,
  W extends PrimaryKeyFieldTuple
> = {
  condition: EntityFilterType<V, W>;
  entity: Entity<V, W>;
  reason?: string;
  type: typeof RECORD_EXISTS_GATE;
};

export type AccessControlRecordNotExistsGate<
  V extends FieldList,
  W extends PrimaryKeyFieldTuple
> = {
  condition: EntityFilterType<V, W>;
  entity: Entity<V, W>;
  reason?: string;
  type: typeof RECORD_NOT_EXISTS_GATE;
};

export type AccessControlJoinRecordExistsGate<
  V extends FieldList,
  W extends PrimaryKeyFieldTuple,
  V1 extends FieldList,
  W1 extends PrimaryKeyFieldTuple
> = {
  condition1: EntityFilterType<V, W>;
  condition2: EntityFilterType<V1, W1>;
  entity1: Entity<V, W>;
  entity2: Entity<V1, W1>;
  join: {
    from: Field<FieldMetadata>;
    to: Field<FieldMetadata>;
  };
  reason?: string;
  type: typeof JOIN_RECORD_EXISTS_GATE;
};

export type AccessControlFilterByJoinTableGate<
  V extends FieldList,
  W extends PrimaryKeyFieldTuple
> = {
  join: {
    from: Field<FieldMetadata>;
    to: Field<FieldMetadata>;
  };
  condition: EntityFilterType<V, W>;
  entity: Entity<V, W>;
  reason?: string;
  type: typeof FILTER_BY_JOIN_TABLE_GATE;
};

export type AccessControlReadOnlyGate = {
  reason?: string;
  type: typeof READ_ONLY_TABLE_GATE;
};

export type AccessControlGate<
  V extends FieldList,
  W extends PrimaryKeyFieldTuple
> =
  | AccessControlIsOnlyMemberGate
  | AccessControlRecordExistsGate<V, W>
  | AccessControlRecordNotExistsGate<V, W>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | AccessControlJoinRecordExistsGate<V, W, any, any>
  | AccessControlFilterByJoinTableGate<V, W>
  | AccessControlOrGate<AccessControlGate<V, W>[]>
  | AccessControlReadOnlyGate
  | AccessControlPredeterminedGate;

export type AccessControlPredeterminedGate = {
  entity: Entity<any, any>;
  reason?: string;
  type: typeof PREDETERMINED_GATE;
  value: boolean;
};

export type GateErrorResult = {
  errors: string[];
  gates: undefined;
};

export type GateSuccessResult = {
  errors: undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: EntityFilterType<any, any>;
  gates: AccessControlGate<any, any>[];
};

export type GateResult = GateErrorResult | GateSuccessResult;

export interface AccessControlOperations<
  T extends FieldList,
  U extends PrimaryKeyFieldTuple
> {
  create?(user: UserEntity, patch: EntityCreateType<T, U>): GateResult;
  delete?(
    user: UserEntity,
    filters: EntityFilterType<T, U>,
    toDelete: EntityTypeFromFields<T>
  ): GateResult;
  get?(
    user: UserEntity,
    filters: EntityFilterType<T, U>,
    toGet: EntityTypeFromFields<T>
  ): GateResult;
  list?(user: UserEntity, filters: EntityFilterType<T, U>): GateResult;
  put?(
    user: UserEntity,
    filters: EntityFilterType<T, U>,
    patch: EntityCreateType<T, U>
  ): GateResult;
  update?(
    user: UserEntity,
    filters: EntityFilterType<T, U>,
    patch: EntityUpdateType<T, U>,
    toUpdate: EntityTypeFromFields<T>
  ): GateResult;
}
