import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";

import { FileItem } from "./types.js";

interface DeleteConfirmationModalProps {
  opened: boolean;
  onClose: () => void;
  item: FileItem | null;
  onDelete: () => void;
  isDeleting: boolean;
}

/**
 * Modal for confirming file or folder deletion
 */
export function DeleteConfirmationModal({
  opened,
  onClose,
  item,
  onDelete,
  isDeleting,
}: DeleteConfirmationModalProps) {
  return (
    <Modal opened={opened} onClose={onClose} title="Confirm Delete" size="sm">
      <Stack>
        <Text>
          Are you sure you want to delete <strong>{item?.name}</strong>? This
          action cannot be undone.
        </Text>
        <Group justify="flex-end">
          <Button variant="subtle" onClick={onClose} disabled={isDeleting}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={onDelete}
            loading={isDeleting}
            leftSection={<IconTrash size={16} />}
          >
            Delete
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
