import { clientError } from "./clientError.js";

// Type definitions
export interface FileItem {
  name: string;
  type: "file" | "folder";
  size?: number;
  updatedAt?: Date;
  uploadedBy?: string;
  modifiedBy?: string;
  url?: string;
  path: string;
}

interface ListFilesResponse {
  items: FileItem[];
  storageUsed: number;
  hasEventualConsistency?: boolean;
  shouldRetry?: boolean;
}

interface FileUploadResponse {
  success: boolean;
  url: string;
  path: string;
  size: number;
}

type ApiResponse<T> =
  | { success: true; data: T; error?: undefined }
  | { success: false; error: string; data?: undefined };

// Client for interacting with file storage
export const filesClient = {
  // List files in a directory
  async listFiles(
    prefix: string,
    serviceProviderId?: number,
    customerId?: number,
    afterUpload?: boolean
  ) {
    try {
      const params = new URLSearchParams();

      // Add parameters
      if (prefix) params.append("prefix", prefix);
      if (serviceProviderId)
        params.append("serviceProviderId", serviceProviderId.toString());
      if (customerId) params.append("customerId", customerId.toString());
      if (afterUpload) params.append("afterUpload", "true");

      // Construct the direct URL to the list endpoint
      const url = `/api/files/list?${params.toString()}`;

      console.log("FILES CLIENT: Listing files with URL:", url);

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to list files: ${response.statusText}`);
      }

      const data = await response.json();
      return { success: true, data: data as ListFilesResponse };
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clientError(error as any);
      return { success: false, error: (error as Error).message };
    }
  },

  // Upload a file
  async uploadFile(
    file: File,
    path: string,
    metadata: Record<string, string>,
    serviceProviderId?: number,
    customerId?: number,
    onProgress?: (progress: number) => void
  ) {
    try {
      // Create URL with query parameters
      const url = new URL("/api/files/upload", window.location.origin);

      // Add query parameters for serviceProviderId and customerId
      if (serviceProviderId) {
        url.searchParams.append(
          "serviceProviderId",
          serviceProviderId.toString()
        );
      }
      if (customerId) {
        url.searchParams.append("customerId", customerId.toString());
      }

      console.log("Uploading file with:", {
        url: url.toString(),
        fileName: file.name,
        fileSize: file.size,
        filePath: path,
        serviceProviderId,
        customerId,
      });

      // Create form data for the file and metadata only
      const formData = new FormData();

      // The file must be added with the correct name "file"
      formData.append("file", file);
      formData.append("path", path);

      // Add metadata (such as uploaded by)
      Object.entries(metadata).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await fetch(url.toString(), {
        method: "POST",
        body: formData,
        // Do not set Content-Type header manually - browser will set it with boundary
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Upload failed:", errorText);
        throw new Error(`Upload failed: ${errorText}`);
      }

      const data = await response.json();
      return { success: true, data };
    } catch (error) {
      // Log the full error first
      console.error("File upload error:", error);

      // Send to client error handler
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clientError(error as any);

      // Return a structured error response
      return {
        success: false,
        error: error instanceof Error ? error.message : "Upload failed",
      };
    }
  },

  // Delete a file
  async deleteFile(url: string) {
    try {
      const response = await fetch("/api/files/delete", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url }),
      });

      if (!response.ok) {
        throw new Error(`Failed to delete file: ${response.statusText}`);
      }

      return { success: true };
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clientError(error as any);
      return { success: false, error: (error as Error).message };
    }
  },

  // Create a folder
  async createFolder(
    path: string,
    serviceProviderId?: number,
    customerId?: number
  ) {
    try {
      if (!serviceProviderId) {
        throw new Error("Service provider ID is required");
      }

      // Create URL with all parameters in the query string
      const url = new URL("/api/files/create-folder", window.location.origin);

      // Add all parameters to the query string
      url.searchParams.append(
        "serviceProviderId",
        serviceProviderId.toString()
      );
      if (customerId) {
        url.searchParams.append("customerId", customerId.toString());
      }
      url.searchParams.append("path", path);

      console.log("Creating folder with URL params only:", {
        path,
        serviceProviderId,
        customerId,
        url: url.toString(),
      });

      // Send POST request with no body (semantically correct for resource creation)
      const response = await fetch(url.toString(), {
        method: "POST",
        // No body or content-type needed
      });

      let errorText = "";
      if (!response.ok) {
        try {
          // Try to get the error text, but don't crash if it fails
          errorText = await response.text();
        } catch (textError) {
          errorText = response.statusText || "Unknown error";
        }

        console.error("Folder creation failed:", errorText);
        throw new Error(`Failed to create folder: ${errorText}`);
      }

      let data;
      try {
        data = await response.json();
      } catch (jsonError) {
        console.error("Error parsing JSON response:", jsonError);
        throw new Error("Invalid response from server");
      }

      return { success: true, data };
    } catch (error) {
      // Log the full error first
      console.error("Folder creation error:", error);

      // Send to client error handler
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clientError(error as any);

      // Return a structured error response
      return {
        success: false,
        error:
          error instanceof Error ? error.message : "Failed to create folder",
      };
    }
  },

  // Rename a file or folder
  async rename(
    oldPath: string,
    newPath: string,
    serviceProviderId?: number,
    customerId?: number
  ) {
    try {
      const response = await fetch("/api/files/rename", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          oldPath,
          newPath,
          serviceProviderId,
          customerId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to rename: ${response.statusText}`);
      }

      const data = await response.json();
      return { success: true, data };
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clientError(error as any);
      return { success: false, error: (error as Error).message };
    }
  },
};
