import { Button, Container, Group, Stack, Text } from "@mantine/core";
import { IconPlus, IconQuestionMark, IconUser } from "@tabler/icons-react";
import { Link } from "react-router-dom";

import { ENTITLEMENT_MANAGE_WORKFLOWS } from "../../../common/fields/entitlements.js";
import { LEDGERAI_SLUG } from "../../../common/fields/slug.js";
import {
  capitalizeFirstLetter,
  getEmailDomain,
} from "../../../common/utils/textUtils.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import {
  useActiveStates,
  useOrganizationUserHasEntitlement,
} from "../../contexts/ActiveStatesContext.js";
import { PrimaryColor } from "../../theme.js";
import classes from "./welcome.css.js";

export function Welcome() {
  const {
    user,
    customer,
    serviceProvider,
    isServiceProviderUser,
    isCustomerUser,
  } = useActiveStates();

  const hasEntitlement = useOrganizationUserHasEntitlement();

  const has_entitlement_manage_workflows = hasEntitlement(
    ENTITLEMENT_MANAGE_WORKFLOWS
  );

  const greetingName = user
    ? user.nickname || user.given_name || "Hello"
    : "Guest";

  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />
      <div className={classes.wrapper}>
        <Container>
          <Stack gap={10}>
            <h1 className={classes.title}>
              Welcome{" "}
              <Text component="span" c={PrimaryColor} inherit>
                {capitalizeFirstLetter(greetingName)}
              </Text>
              ,
            </h1>

            <Text className={classes.description} color="dimmed" size="md">
              {has_entitlement_manage_workflows
                ? `Let's begin by connecting the essential third-party services for ${
                    customer?.name || getEmailDomain(user?.email)
                  }. Meanwhile, Penny is here to answer any of your questions about finance and accounting.`
                : `Ask Penny a question about ${
                    customer?.name || getEmailDomain(user?.email)
                  }, or see who else is on your team.`}
            </Text>

            <Group>
              {has_entitlement_manage_workflows && (
                <Link to="/connect/overview">
                  <Button variant="filled" size="md" radius="xl">
                    Get connected
                  </Button>
                </Link>
              )}
              {serviceProvider && !customer && isServiceProviderUser && (
                <Link to="/clients/overview">
                  <Button radius="xl" size="md" leftSection={<IconPlus />}>
                    Add a{" "}
                    {serviceProvider.slug === LEDGERAI_SLUG
                      ? "service provider"
                      : "client"}
                  </Button>
                </Link>
              )}
              <Link to="/penny/conversations/create">
                <Button
                  size="md"
                  radius="xl"
                  variant="light"
                  leftSection={<IconQuestionMark />}
                >
                  Ask Penny a question
                </Button>
              </Link>
              <Link to="/people/overview">
                <Button
                  size="md"
                  radius="xl"
                  variant="subtle"
                  leftSection={<IconUser size={20} />}
                >
                  See who's on your team
                </Button>
              </Link>
            </Group>
            <Text c="dimmed" fz="sm" mt="xl">
              {isCustomerUser ? (
                <>This service is provided by</>
              ) : (
                <>You are navigating {customer?.name} as a team member of </>
              )}{" "}
              {serviceProvider.name}.{" "}
              {serviceProvider.slug === LEDGERAI_SLUG ? (
                <>(Platform Admin)</>
              ) : null}
            </Text>
          </Stack>
        </Container>
      </div>
    </Layout>
  );
}
