import { NotificationData } from "@mantine/notifications";
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

import { DataRouterContextObject } from "react-router/dist/lib/context.js";
import { OrganizationEntity } from "../../common/entities/organization.js";
import { OrganizationRelationshipEntity } from "../../common/entities/organizationRelationship.js";
import { OrganizationRelationshipUserEntity } from "../../common/entities/organizationRelationshipUser.js";
import { OrganizationUserEntity } from "../../common/entities/organizationUser.js";
import { UserEntity } from "../../common/entities/user.js";
import { MessageControl } from "../hooks/useMessages.js";

export interface ChatAttachment {
  url: string;
  name: string;
  size: number;
}

export const globalRouterRef: MutableRefObject<DataRouterContextObject | null> =
  { current: null };

export interface ActiveStatesContextValue {
  adminIsOpen: boolean;
  attachedFiles: ChatAttachment[];
  closeAdmin: () => void;
  customer?: OrganizationEntity;
  customers?: OrganizationEntity[];
  isCustomerUser: boolean;
  isServiceProvider: boolean;
  isServiceProviderUser: boolean;
  messageControl: MessageControl;
  organizationRelationship?: OrganizationRelationshipEntity;
  organizationRelationshipUser?: OrganizationRelationshipUserEntity;
  organizationUser: OrganizationUserEntity;
  showConfirmModal: (
    message: string,
    resolve: (value: boolean) => void
  ) => void;
  serviceProvider: OrganizationEntity;
  user?: UserEntity;
  setAttachedFiles: (
    files: ChatAttachment[] | ((prev: ChatAttachment[]) => ChatAttachment[])
  ) => void;
  setCustomer: (customer: OrganizationEntity | undefined) => void;
  setCustomers: (customers: OrganizationEntity[] | undefined) => void;
  setServiceProvider: (serviceProvider: OrganizationEntity) => void;
  setUser: (user: UserEntity | undefined) => void;
  shutdown: (
    notificationDefinition?: NotificationData,
    redirectUrl?: string
  ) => Promise<void>;
}

// Helper function to filter out duplicate URLs from attachments
function filterDuplicateAttachments(
  attachments: ChatAttachment[]
): ChatAttachment[] {
  const seen = new Set<string>();
  return attachments.filter((attachment) => {
    if (seen.has(attachment.url)) {
      console.log(
        `Filtering out duplicate attachment with URL: ${attachment.url}`
      );
      return false;
    }
    seen.add(attachment.url);
    return true;
  });
}

export const ActiveStatesContext = createContext<
  ActiveStatesContextValue | undefined
>(undefined);

export function useActiveStates() {
  const context = useContext(ActiveStatesContext);
  if (!context) {
    throw new Error(
      "useActiveStates must be used within an ActiveStatesProvider"
    );
  }
  return context;
}

export function useUser() {
  const context = useContext(ActiveStatesContext);
  if (!context) {
    throw new Error("useUser must be used within an ActiveStatesProvider");
  }
  return context.user;
}

export function useOrganizationUserHasEntitlement(): (
  entitlement: number
) => boolean {
  const context = useContext(ActiveStatesContext);
  if (!context) {
    throw new Error(
      "useOrganizationUserHasEntitlement must be used within an ActiveStatesProvider"
    );
  }

  if (!context.organizationUser) {
    return () => false;
  }

  return (entitlement: number) =>
    Array.isArray(context.organizationUser.entitlements)
      ? context.organizationUser.entitlements.includes(entitlement)
      : false;
}

export function useOrganizationRelationshipUserHasEntitlement(): (
  entitlement: number
) => boolean {
  const context = useContext(ActiveStatesContext);
  if (!context) {
    throw new Error(
      "useOrganizationRelationshipUserHasEntitlement must be used within an ActiveStatesProvider"
    );
  }

  if (!context.organizationRelationshipUser) {
    return () => false;
  }

  // Otherwise, check if the entitlement exists in the user's entitlements list.
  return (entitlement: number) =>
    Array.isArray(context.organizationRelationshipUser?.entitlements)
      ? context.organizationRelationshipUser.entitlements.includes(entitlement)
      : false;
}

export function ActiveStatesProvider({ children }: { children: ReactNode }) {
  const [attachedFiles, setAttachedFilesRaw] = useState<ChatAttachment[]>([]);
  const [adminIsOpen, setAdminIsOpen] = useState(false);
  const [customer, setCustomer] = useState<OrganizationEntity | undefined>();
  const [customers, setCustomers] = useState<
    OrganizationEntity[] | undefined
  >();
  const [isCustomerUser, setIsCustomerUser] = useState(false);
  const [isServiceProvider, setIsServiceProvider] = useState(false);
  const [isServiceProviderUser, setIsServiceProviderUser] = useState(false);
  const [messageControl] = useState<MessageControl>({
    activeMessage: undefined,
    activeMessageIndex: undefined,
    archiveMessage: async () => {},
    focusMessage: () => {},
    loadMessages: async () => {},
    loadingMessages: false,
    loadMessagesError: undefined,
    filter: { message: null },
    setFilter: () => {},
    markAsRead: async () => {},
    markingAsRead: false,
    messages: [],
    readTimestamp: 0,
    rescindActiveMessage: () => {},
    setReadTimestamp: () => {},
    unreadCount: 0,
  });
  const [organizationRelationship, setOrganizationRelationship] =
    useState<OrganizationRelationshipEntity>({
      archived_at: undefined,
      created_at: undefined,
      customer_id: 0,
      provider_id: 0,
    });
  const [organizationRelationshipUser, setOrganizationRelationshipUser] =
    useState<OrganizationRelationshipUserEntity>({
      archived_at: undefined,
      created_at: undefined,
      customer_id: 0,
      entitlements: undefined,
      provider_id: 0,
      user_id: 0,
    });
  const [organizationUser, setOrganizationUser] =
    useState<OrganizationUserEntity>({
      archived_at: undefined,
      created_at: undefined,
      entitlements: undefined,
      customer_id: undefined,
      provider_id: undefined,
      user_id: undefined,
      is_invitation: false,
      is_organization_owner: false,
      organization_id: undefined,
    });
  const [serviceProvider, setServiceProvider] = useState<OrganizationEntity>({
    archived_at: undefined,
    created_at: undefined,
    description: undefined,
    entitlements: undefined,
    extend_entitlements: undefined,
    id: undefined,
    name: "",
    picture: undefined,
    picture_background_color: undefined,
    slug: "",
    updated_at: undefined,
  });
  const [user, setUser] = useState<UserEntity | undefined>();

  // Wrap setAttachedFiles to filter duplicates
  const setAttachedFiles = useCallback(
    (
      filesOrUpdater:
        | ChatAttachment[]
        | ((prev: ChatAttachment[]) => ChatAttachment[])
    ) => {
      setAttachedFilesRaw((prev: ChatAttachment[]) => {
        // If it's a function updater, apply it to get the new files
        const newFiles =
          typeof filesOrUpdater === "function"
            ? filesOrUpdater(prev)
            : filesOrUpdater;

        // First deduplicate within the new files
        const deduplicatedNewFiles = filterDuplicateAttachments(newFiles);

        // Then combine with existing files and deduplicate again
        const combinedFiles = filterDuplicateAttachments([
          ...prev,
          ...deduplicatedNewFiles,
        ]);

        console.log("Setting attachedFiles:", {
          previousCount: prev.length,
          newFilesCount: newFiles.length,
          deduplicatedNewCount: deduplicatedNewFiles.length,
          finalCount: combinedFiles.length,
        });

        return combinedFiles;
      });
    },
    []
  );

  const closeAdmin = useCallback(() => setAdminIsOpen(false), []);
  const showConfirmModal = useCallback(
    (message: string, resolve: (value: boolean) => void) => {
      // Implementation here
    },
    []
  );
  const shutdown = useCallback(
    async (notificationDefinition?: NotificationData, redirectUrl?: string) => {
      // Implementation here
    },
    []
  );

  return (
    <ActiveStatesContext.Provider
      value={{
        adminIsOpen,
        attachedFiles,
        closeAdmin,
        customer,
        customers,
        isCustomerUser,
        isServiceProvider,
        isServiceProviderUser,
        messageControl,
        organizationRelationship,
        organizationRelationshipUser,
        organizationUser,
        serviceProvider,
        user,
        setAttachedFiles,
        setCustomer,
        setCustomers,
        setServiceProvider,
        setUser,
        showConfirmModal,
        shutdown,
      }}
    >
      {children}
    </ActiveStatesContext.Provider>
  );
}
