import { notifications } from "@mantine/notifications";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clientError = (error: any) => {
  // Extract error message from different error types
  const errorMessage =
    error instanceof Error
      ? error.message
      : typeof error === "string"
      ? error
      : "An unknown error occurred";

  console.error("Client Error:", error);

  notifications.show({
    autoClose: 30e3,
    color: "#f44141",
    message: errorMessage,
    position: "bottom-center",
    title: "Error",
  });
};
