import { notifications } from "@mantine/notifications";
import { NavigateFunction } from "react-router-dom";

import { FileItem } from "./types.js";
import { filesClient } from "../../utils/filesClient.js";
import { clearCacheByPattern } from "../../../common/utils/network.js";
import { clearKvCache } from "../../hooks/useKvStorage.js";

/**
 * Load files from the specified path
 */
export async function loadFiles(
  folderPath: string,
  afterUpload = false,
  serviceProviderId?: number,
  customerId?: number,
  setIsLoading?: (isLoading: boolean) => void,
  setError?: (error: string | null) => void,
  setItems?: (items: FileItem[]) => void,
  setStorage?: (storage: { used: number; total: number }) => void,
  latestRequestPathRef?: React.MutableRefObject<string>,
  navigate?: NavigateFunction
) {
  console.log("Loading files for path:", folderPath);

  if (setIsLoading) setIsLoading(true);
  if (setError) setError(null);
  // Clear items immediately to prevent flash of old content during navigation
  if (setItems) setItems([]);

  // Store the requested path in the ref to track the latest request
  const path = folderPath || "";
  if (latestRequestPathRef) latestRequestPathRef.current = path;

  console.log("Starting load for path:", path, "| afterUpload:", afterUpload);

  try {
    if (!serviceProviderId) {
      if (setError) setError("Service provider ID is required");
      return;
    }

    // Track if this request is after an upload
    console.log(
      "Loading files for path:",
      path,
      afterUpload ? "(after upload)" : ""
    );

    // Use filesClient to get the list of files with required parameters
    const result = await filesClient.listFiles(
      path,
      serviceProviderId,
      customerId,
      afterUpload // Pass whether this is after an upload to handle eventual consistency
    );

    // Check if this response is for the most recent request
    // If not, ignore it to prevent race conditions
    if (latestRequestPathRef && latestRequestPathRef.current !== path) {
      console.log(
        "Ignoring stale response for path:",
        path,
        "| Current path:",
        latestRequestPathRef.current
      );
      return; // Don't update state with old data
    }

    if (!result.success) {
      throw new Error(result.error || "Failed to load files");
    }

    // Handle empty results that might be due to eventual consistency
    if (afterUpload && result.data?.shouldRetry) {
      console.log(
        "Vercel Blob has eventual consistency - retrying list after delay"
      );
      // Wait a moment and retry once more
      setTimeout(() => {
        // Only retry if this is still the active path
        if (!latestRequestPathRef || latestRequestPathRef.current === path) {
          loadFiles(
            folderPath,
            false, // Retry without afterUpload flag to prevent loops
            serviceProviderId,
            customerId,
            setIsLoading,
            setError,
            setItems,
            setStorage,
            latestRequestPathRef,
            navigate
          );
        }
      }, 2000);
    }

    // Check one more time before updating state
    if (latestRequestPathRef && latestRequestPathRef.current !== path) {
      console.log("Preventing stale data update");
      return;
    }

    // Safely handle potentially undefined data
    if (result.data) {
      // Set the items in state
      if (setItems) setItems(result.data.items || []);
      if (setStorage) {
        setStorage({
          used: result.data.storageUsed || 0,
          total: 5 * 1024 * 1024 * 1024, // 5GB
        });
      }

      // Add a small delay before removing loading state to ensure content renders
      setTimeout(() => {
        // Only update loading state if this is still the active path
        if (!latestRequestPathRef || latestRequestPathRef.current === path) {
          if (setIsLoading) setIsLoading(false);
        }
      }, 150); // Short delay to ensure DOM updates
    } else {
      if (setItems) setItems([]);
      if (setError) setError("No data returned from server");
      if (setIsLoading) setIsLoading(false);
    }

    console.log("Files reloaded", afterUpload ? "after upload" : "");
  } catch (error) {
    // Only apply error state if this is still the current path
    if (latestRequestPathRef && latestRequestPathRef.current !== path) {
      return;
    }

    // Handle error and redirect if needed
    console.error("Error loading files:", error);
    if (setItems) setItems([]);
    if (setError) {
      setError(error instanceof Error ? error.message : "Failed to load files");
    }

    // If there was an error and we're not at root, redirect to root
    if (folderPath && navigate) {
      notifications.show({
        title: "Error",
        message: "Failed to load the specified folder. Returning to root.",
        color: "red",
      });
      navigate("/files");
    }

    // Error states should remove loading state immediately
    if (setIsLoading) setIsLoading(false);
  }
}

/**
 * Create a new folder
 */
export async function createFolder(
  currentPath: string,
  folderName: string,
  serviceProviderId: number,
  customerId?: number,
  setCreatingFolder?: (isCreating: boolean) => void
): Promise<boolean> {
  if (!folderName.trim()) {
    notifications.show({
      title: "Error",
      message: "Folder name cannot be empty",
      color: "red",
    });
    return false;
  }

  // Check for service provider ID first
  if (!serviceProviderId) {
    notifications.show({
      title: "Error",
      message: "Service provider ID is required to create folders",
      color: "red",
    });
    return false;
  }

  if (setCreatingFolder) setCreatingFolder(true);

  try {
    // Construct the path for the new folder - append to current path if we're in a folder
    const folderPath = currentPath
      ? `${currentPath}/${folderName}`
          .replace(/^\/+|\/+$/g, "") // Remove leading/trailing slashes
          .replace(/\/+/g, "/") // Replace multiple slashes with single slash
      : folderName
          .replace(/^\/+|\/+$/g, "") // Remove leading/trailing slashes
          .replace(/\/+/g, "/"); // Replace multiple slashes with single slash

    // Log important variables before making the request
    console.log("Creating folder with:", {
      folderPath,
      rawPath: currentPath ? `${currentPath}/${folderName}` : folderName,
      normalizedPath: folderPath,
      serviceProviderId,
      customerId,
    });

    // Use the filesClient to create the folder with the correct type
    const result = await filesClient.createFolder(
      folderPath,
      serviceProviderId,
      customerId
    );

    console.log("Create folder result:", result);

    if (!result.success) {
      console.error("Create folder error details:", {
        error: result.error,
        success: result.success,
        data: result.data,
      });
      throw new Error(result.error || "Failed to create folder");
    }

    // Show success notification
    notifications.show({
      title: "Success",
      message: `Folder "${folderName}" created successfully`,
      color: "green",
    });

    return true;
  } catch (error) {
    console.error("Error creating folder:", error);

    // Extract error message safely and ensure it's a string
    const errorMessage =
      error instanceof Error ? error.message : "Failed to create folder";

    notifications.show({
      title: "Error",
      message: errorMessage,
      color: "red",
    });

    return false;
  } finally {
    if (setCreatingFolder) setCreatingFolder(false);
  }
}

/**
 * Navigate to a folder, clearing caches first
 */
export function navigateToFolder(
  path: string,
  navigate: NavigateFunction,
  serviceProviderId?: number,
  customerId?: number
) {
  // Clear both caches before navigation
  clearKvCache("explorer", serviceProviderId, customerId);
  clearCacheByPattern("/api/kv");
  navigate(`/files/${path}`);
}

/**
 * Download a file
 */
export function downloadFile(item: FileItem) {
  if (item.type === "file" && item.url) {
    // Create a temporary anchor to trigger download
    const a = document.createElement("a");
    a.href = item.url;
    a.download = item.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    notifications.show({
      title: "Download started",
      message: `Downloading ${item.name}`,
      color: "blue",
    });
  }
}
