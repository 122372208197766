import { ActionIcon, Menu } from "@mantine/core";
import {
  IconDotsVertical,
  IconDownload,
  IconEdit,
  IconExternalLink,
  IconFolderOpen,
  IconTrash,
  IconMessagePlus,
} from "@tabler/icons-react";
import { useState } from "react";

// Import the FileItem type from the types file
import { FileItem } from "./types.js";

interface FileContextMenuProps {
  item: FileItem;
  onOpen: () => void;
  onDownload?: () => void;
  onRename: () => void;
  onDelete: () => void;
  onAddToChat?: () => void;
  position?: "bottom-end" | "bottom-start" | "top-end" | "top-start";
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  withinPortal?: boolean;
}

/**
 * FileContextMenu component - provides a consistent context menu for files and folders
 * in both list and grid views
 */
export function FileContextMenu({
  item,
  onOpen,
  onDownload,
  onRename,
  onDelete,
  onAddToChat,
  position = "bottom-end",
  size = "xs",
  withinPortal = true,
}: FileContextMenuProps) {
  const [menuOpened, setMenuOpened] = useState(false);

  // Prevent menu click from triggering parent click events
  const handleMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Menu
      opened={menuOpened}
      onChange={setMenuOpened}
      position={position}
      withinPortal={withinPortal}
    >
      <Menu.Target>
        <ActionIcon
          size={size}
          variant="subtle"
          onClick={handleMenuClick}
          aria-label="More options"
        >
          <IconDotsVertical size={size === "xs" ? 14 : 16} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {item.type === "folder" ? (
          <>
            <Menu.Item
              leftSection={<IconFolderOpen size={14} />}
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
            >
              Open
            </Menu.Item>
            <Menu.Item
              leftSection={<IconEdit size={14} />}
              onClick={(e) => {
                e.stopPropagation();
                onRename();
              }}
            >
              Rename
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              color="red"
              leftSection={<IconTrash size={14} />}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              Delete
            </Menu.Item>
          </>
        ) : (
          <>
            {item.url && (
              <>
                <Menu.Item
                  leftSection={<IconDownload size={14} />}
                  component="a"
                  href={`/api/download?url=${encodeURIComponent(
                    item.url
                  )}&filename=${encodeURIComponent(item.name)}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    // Still call onDownload for any tracking/analytics if provided
                    if (onDownload) onDownload();
                  }}
                >
                  Download
                </Menu.Item>
                {onAddToChat && (
                  <Menu.Item
                    leftSection={<IconMessagePlus size={14} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      onAddToChat();
                    }}
                  >
                    Add to chat
                  </Menu.Item>
                )}
              </>
            )}
            <Menu.Item
              leftSection={<IconEdit size={14} />}
              onClick={(e) => {
                e.stopPropagation();
                onRename();
              }}
            >
              Rename
            </Menu.Item>
            <Menu.Item
              leftSection={<IconExternalLink size={14} />}
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
            >
              Open in new tab
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              color="red"
              leftSection={<IconTrash size={14} />}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              Delete
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}
