export interface KVStore {
  clear: () => Promise<void>;
  getItem: (key: string) => Promise<string | null>;
  key: (index: number) => Promise<string | null>;
  removeItem: (key: string) => Promise<void>;
  setItem: (key: string, value: string) => Promise<void>;
  list: (from?: number, to?: number) => Promise<string[]>;
  length: Promise<number>;
}

export interface KVEntityStore {
  clear: () => Promise<EntityServiceResult<undefined>>;
  getItem: (key: string) => Promise<EntityServiceResult<KVEntity | null>>;
  key: (index: number) => Promise<EntityServiceResult<KVEntity | null>>;
  list: (
    from?: number,
    to?: number
  ) => Promise<EntityServiceResult<(KVEntity & { User: UserEntity })[]>>;
  removeItem: (key: string) => Promise<EntityServiceResult<KVEntity>>;
  setItem: (
    key: string,
    value: string
  ) => Promise<EntityServiceResult<KVEntity>>;
  length: Promise<number>;
}

import { archivedAtField } from "../fields/archived_at";
import { createdAtField } from "../fields/created_at";
import { customerIdField } from "../fields/customer_id";
import { keyField } from "../fields/key";
import { namespaceField } from "../fields/namespace";
import { providerIdField } from "../fields/provider_id";
import { updatedAtField } from "../fields/updated_at";
import { userIdFieldRequired } from "../fields/user_id";
import { valueField } from "../fields/value";
import { EntityDocs } from "../types/docs";
import {
  entity,
  EntityServiceResult,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";
import { UserEntity } from "./user";

//   fields
export const fields = [
  archivedAtField,
  createdAtField,
  // if customer_id is set, then it's a customer-only value
  // if both customer_id and provider_id are set, then it's a customer and provider value
  // if provider_id is set, then it's a provider-only value
  // otherwise it's a user value
  customerIdField,
  keyField,
  namespaceField,
  providerIdField,
  updatedAtField,
  userIdFieldRequired,
  valueField,
] satisfies FieldList;

// Key value primary key fields
export const primaryKeyFields = [
  providerIdField,
  customerIdField,
  namespaceField,
  keyField,
] satisfies PrimaryKeyFieldTuple;

// Docs
export const docs: EntityDocs = {
  collection: {
    summary: "List conversations.",
  },
  item: { summary: "Perform operations on a conversation." },
};

export const kvEntity = entity({
  api: {
    collectionPath: "kv",
    itemPath: `kv/${primaryKeyFields.map((f) => `:${f.name}`).join("/")}`,
  },
  docs,
  name: "KV",
  fields,
  primaryKeyFields,
  tableName: "kv",
});

export type KVEntity = EntityType<
  typeof kvEntity.fields,
  typeof kvEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleKV: KVEntity = {
  customer_id: 123,
  namespace: "test",
  key: "test",
  value: "test",
  provider_id: 456,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  user_id: 543,
};

export const KV_ALL = "*";
