import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Group,
  Menu,
  MenuItem,
  MenuLabel,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";

import {
  useActiveStates,
  useOrganizationUserHasEntitlement,
} from "../../../contexts/ActiveStatesContext.js";
import { OrganizationEntity } from "../../../../common/entities/organization.js";
import {
  ENTITLEMENT_MANAGE_ORGANIZATION_MEMBERS,
  ENTITLEMENT_MANAGE_ORGANIZATION_SETTINGS,
  ENTITLEMENT_VIEW_WORKFLOWS,
} from "../../../../common/fields/entitlements.js";
import { LEDGERAI_SLUG } from "../../../../common/fields/slug.js";
import { useCreateUserModal } from "../../../hooks/useCreateUserModal.js";

import { OrganizationAvatar } from "../../Avatars/OrganizationAvatar.js";

export default function CustomerMenuSecond() {
  const [menu2Opened, setMenu2Opened] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    customer: activeCustomer,
    isServiceProvider,
    serviceProvider,
    isCustomerUser,
    setCustomer,
  } = useActiveStates();
  // Use active customer if available, otherwise use service provider
  const activeOrganization: OrganizationEntity = activeCustomer?.id
    ? activeCustomer
    : serviceProvider;
  const isLedgerAI = !activeCustomer && serviceProvider?.slug === LEDGERAI_SLUG;

  const {
    createModal: createUserModal,
    setCreateModalOpened: setCreateUserModalOpened,
  } = useCreateUserModal(serviceProvider.id!, activeCustomer?.id);

  const hasEntitlement = useOrganizationUserHasEntitlement();

  const has_entitlement_view_workflows = hasEntitlement(
    ENTITLEMENT_VIEW_WORKFLOWS
  );

  const has_entitlement_manage_members = hasEntitlement(
    ENTITLEMENT_MANAGE_ORGANIZATION_MEMBERS
  );

  const has_entitlement_manage_organization_settings = hasEntitlement(
    ENTITLEMENT_MANAGE_ORGANIZATION_SETTINGS
  );

  return (
    <>
      <Menu
        withArrow
        shadow="md"
        opened={menu2Opened}
        onChange={setMenu2Opened}
      >
        <Menu.Target>
          <UnstyledButton onClick={() => setMenu2Opened((o) => !o)}>
            <Group gap={0}>
              <Title mr={10} maw={{ base: 70, xs: 70, sm: 150, lg: 200 }}>
                <Text truncate fw={700} fs="lg">
                  {activeCustomer?.name || activeOrganization?.name}
                </Text>
              </Title>
              <IconChevronDown size={16} />
            </Group>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          <MenuItem
            key={activeOrganization.id}
            onClick={() => setCustomer(activeOrganization)}
            leftSection={
              <OrganizationAvatar
                organization={activeOrganization}
                variant="filled"
                size={45}
                color={activeOrganization.theme_primary_color ?? "#228BE6"}
              />
            }
          >
            <Stack mr="xl" gap={0}>
              <Text fw={700}> {activeOrganization.name}</Text>
              <Text c="dimmed" size="sm" fw={500}>
                @{activeOrganization.slug}
              </Text>
            </Stack>
          </MenuItem>
          {has_entitlement_manage_members ? (
            <>
              <Menu.Divider />
              <Menu.Item
                onClick={() => {
                  setCreateUserModalOpened(true);
                }}
              >
                Invite people to {activeOrganization.name}
              </Menu.Item>
            </>
          ) : null}
          {has_entitlement_manage_organization_settings && (
            <>
              <Menu.Divider />
              <MenuLabel>Settings</MenuLabel>
              <Menu.Item
                onClick={(e) => {
                  e.stopPropagation(); // Prevents the menu from toggling
                  navigate("/settings/overview");
                }}
              >
                Customize Workspace
              </Menu.Item>
            </>
          )}

          <Menu.Divider />
          <MenuLabel>Admin</MenuLabel>
          {has_entitlement_view_workflows ? (
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation(); // Prevents the menu from toggling
                navigate("/connect/overview");
              }}
            >
              Apps &amp; Integrations
            </Menu.Item>
          ) : null}
          <Menu.Item
            onClick={(e) => {
              e.stopPropagation(); // Prevents the menu from toggling
              navigate("/people/overview");
            }}
          >
            People
          </Menu.Item>
          {!isCustomerUser && isServiceProvider ? (
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation(); // Prevents the menu from toggling
                navigate("/clients/overview");
              }}
            >
              {isLedgerAI ? "Service Providers" : "Clients"}
            </Menu.Item>
          ) : null}
          {/* <Menu.Item>Billing</Menu.Item> */}
        </Menu.Dropdown>
      </Menu>
      {createUserModal}
    </>
  );
}
