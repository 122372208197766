import { ActionIcon, Group, Loader } from "@mantine/core";
import { IconHome, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

import { useAdmin } from "../../contexts/AdminContext.js";
import { AdminMainMenu } from "../menus/AdminMainMenu.js";

import classes from "../menus/AdminToolBar.css.js";

export function AdminToolBar() {
  const { closeAdmin, organization } = useAdmin();
  const navigate = useNavigate();

  if (!organization) {
    return (
      <Group
        className={classes.toolBar}
        justify="space-between"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 300001,
        }}
      >
        <Loader size="xs" />
      </Group>
    );
  }

  return (
    <Group
      className={classes.toolBar}
      p="xs"
      justify="space-between"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 300001,
      }}
    >
      <Group gap="sm">
        <ActionIcon variant="default" size="lg" onClick={() => navigate("/")}>
          <IconHome size={20} />
        </ActionIcon>
        <AdminMainMenu />
      </Group>
      <Group gap="sm">
        <ActionIcon onClick={closeAdmin} size="lg" variant="subtle">
          <IconX size={20} />
        </ActionIcon>
      </Group>
    </Group>
  );
}
