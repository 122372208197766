import {
  Box,
  Button,
  Center,
  Divider,
  Group,
  Loader,
  Stack,
  Title,
} from "@mantine/core";
import { useCallback, useEffect, useState } from "react";

import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import {
  organizationEntity,
  OrganizationEntity,
} from "../../../common/entities/organization.js";
import { customerIdField } from "../../../common/fields/customer_id.js";
import {
  ENTITLEMENT_IS_SERVICE_PROVIDER,
  ENTITLEMENT_MANAGE_CLIENTS,
} from "../../../common/fields/entitlements.js";
import { idField } from "../../../common/fields/id.js";
import { providerIdField } from "../../../common/fields/provider_id.js";
import { LEDGERAI_SLUG } from "../../../common/fields/slug.js";
import { EntityServiceResult } from "../../../common/types/entity.js";
import {
  organizationRelationshipClient,
  organizationRelationshipUserClient,
} from "../../../common/utils/entityClient.js";
import {
  useActiveStates,
  useOrganizationUserHasEntitlement,
} from "../../contexts/ActiveStatesContext.js";
import { useOrganizationModal } from "../../hooks/useOrganizationModal.js";
import { clientError } from "../../utils/clientError.js";
import {
  OrganizationsTable,
  OrgRelationship,
} from "../Tables/OrganizationsTable.js";
import { OrganizationRelationshipUserEntity } from "../../../common/entities/organizationRelationshipUser.js";

export function OrganizationManagement() {
  const hasEntitlement = useOrganizationUserHasEntitlement();
  const has_entitlement_manage_clients = hasEntitlement(
    ENTITLEMENT_MANAGE_CLIENTS
  );
  const [editingOrganization, setEditingOrganization] = useState<
    OrganizationEntity | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeletingCustomerId, setIsDeletingCustomerId] = useState<
    number | undefined
  >();
  const {
    user,
    setCustomers,
    setCustomer,
    serviceProvider,
    customer: activeCustomer,
    isServiceProvider,
  } = useActiveStates();
  const [organizationRelationships, setOrganizationRelationships] = useState<
    OrgRelationship[]
  >([]);

  const isLedgerAI = !activeCustomer && serviceProvider?.slug === LEDGERAI_SLUG;

  const customerIsServiceProvider = (
    activeCustomer?.entitlements as unknown as number[]
  )?.includes(ENTITLEMENT_IS_SERVICE_PROVIDER);

  const fetchOrganizationRelationships = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await organizationRelationshipClient(clientError).list(
        {
          [providerIdField.name]: customerIsServiceProvider
            ? activeCustomer?.id
            : serviceProvider?.id,
        },
        {
          join: organizationEntity.name,
          from: idField.name,
          to: customerIdField.name,
        }
      );
      if (response.success && "data" in response && "data" in response.data) {
        setOrganizationRelationships(response.data.data as OrgRelationship[]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [serviceProvider?.id]);

  useEffect(() => {
    fetchOrganizationRelationships();
  }, [fetchOrganizationRelationships]);

  const navigate = useNavigate();

  const handleCreate = async (
    result: EntityServiceResult<OrganizationEntity>
  ) => {
    if (!has_entitlement_manage_clients) {
      notifications.show({
        title: "Error",
        message: "You do not have permission to create clients.",
        color: "#f44141",
      });
      return;
    }
    if (result.success && result.data) {
      await fetchOrganizationRelationships();

      const newCustomer = result.data;
      setCustomers((prev: OrganizationEntity[] | undefined) => [
        ...(prev ?? []),
        newCustomer,
      ]);
      setCustomer(newCustomer);
      setOrganizationModalOpened(false);
      navigate("/people/overview");
    }
  };

  const {
    modalOpened: organizationModalOpened,
    modal: organizationModal,
    setModalOpened: setOrganizationModalOpened,
  } = useOrganizationModal({
    editMode: Boolean(editingOrganization),
    organization: editingOrganization,
    serviceProvider: customerIsServiceProvider
      ? activeCustomer!
      : serviceProvider,
    onClose() {
      if (editingOrganization) {
        setEditingOrganization(undefined);
      }
    },
    onCreateSuccess: handleCreate,
  });

  useEffect(() => {
    if (editingOrganization && !organizationModalOpened) {
      setOrganizationModalOpened(true);
    }
  }, [
    organizationModalOpened,
    editingOrganization,
    setOrganizationModalOpened,
  ]);

  const handleDelete = async (relationship: OrgRelationship) => {
    if (!has_entitlement_manage_clients) {
      notifications.show({
        title: "Error",
        message: "You do not have permission to delete clients.",
        color: "#f44141",
      });
      return;
    }
    if (!relationship.provider_id || !relationship.customer_id) {
      console.error(
        "Provider ID or Customer ID is missing when trying to delete organization relationship"
      );
      return;
    }
    setIsDeletingCustomerId(relationship.customer_id);
    const response = await organizationRelationshipClient(clientError)
      .item(relationship.provider_id!, relationship.customer_id!)
      .delete();
    if (response.success) {
      await fetchOrganizationRelationships();
      setIsDeletingCustomerId(undefined);
      notifications.show({
        title: "Success",
        message: `Organization relationship with ${relationship.Organization.name} has been removed.`,
        color: "#54e382",
      });
    } else {
      notifications.show({
        title: "Error",
        message: `Failed to remove organization relationship with ${relationship.Organization.name}.`,
        color: "#f44141",
      });
      setIsDeletingCustomerId(undefined);
    }
  };

  const handleAddUserToTeam = async (
    relationship: OrgRelationship
  ): Promise<OrganizationRelationshipUserEntity> => {
    console.log("Add user to team", relationship.Organization);

    const response = await organizationRelationshipUserClient(clientError)
      .item(serviceProvider.id!, relationship.Organization.id!, user.id!)
      .upsert({
        archived_at: null as unknown as undefined,
        customer_id: relationship.Organization.id,
        entitlements: [] as unknown as number, // array types aren't correct
        provider_id: serviceProvider.id,
        user_id: user.id,
      });
    if (
      response.success &&
      "data" in response &&
      "data" in response.data &&
      response.data.success
    ) {
      return response.data.data;
    }
    throw new Error("Failed to add user to team");
  };

  const title = isLedgerAI ? "Service Providers" : "Clients";

  return (
    <Box w="100%">
      <Stack gap={0}>
        <Box py="md" px="lg">
          <Group justify="space-between" align="center">
            <Title order={4}>{title}</Title>
            {isServiceProvider && has_entitlement_manage_clients ? (
              <Button
                onClick={() => {
                  setEditingOrganization(undefined);
                  setOrganizationModalOpened(true);
                }}
              >
                Add {isLedgerAI ? "Service Provider" : "Client"}
              </Button>
            ) : null}
          </Group>
        </Box>
        <Divider />

        <OrganizationsTable
          hasEntitlementManageClients={has_entitlement_manage_clients}
          isDeletingCustomerId={isDeletingCustomerId}
          onDelete={handleDelete}
          organizations={organizationRelationships}
          setEditingOrganization={setEditingOrganization}
          onAddTeamMember={handleAddUserToTeam}
        />

        {organizationRelationships.length === 0 && !isLoading && (
          <Box w="100%" m="xl" p="xl">
            <Center>
              <Title order={4}>
                No {isLedgerAI ? "service providers" : "clients"} found.
              </Title>
            </Center>
          </Box>
        )}

        {isLoading && organizationRelationships.length === 0 && (
          <Box
            style={{
              alignContent: "flex-end",
              alignItems: "center",
              display: "flex",
              height: "fit-content",
              justifyContent: "center",
              overflowY: "auto",
              padding: "100px",
            }}
          >
            <Loader type="oval" />
          </Box>
        )}
        {organizationModal}
      </Stack>
    </Box>
  );
}
