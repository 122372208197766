import {
  ActionIcon,
  Group,
  Loader,
  Menu,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import { Fragment, useContext, useState } from "react";

import { UserEntity } from "../../../common/entities/user.js";
import { AuthContext } from "../../contexts/AuthContext.js";
import { useConfigureModal } from "../../hooks/useConfigureModal.js";
import { useResponsiveDesign } from "../../hooks/useResponsiveDesign.js";
import { AccountPrimaryColor } from "../../theme.js";
import { UserAvatar } from "../Avatars/UserAvatar.js";
import { EditProfileModal } from "../Modals/EditProfileModal.js";
import AccountMenuContent from "./AccountMenu/AccountMenuContent.js";

export function AccountMenu() {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);

  const { account, allAccounts, updateAccount, deleteAccount } =
    useContext(AuthContext);

  const [configurePanel, openConfigureModal] = useConfigureModal();

  const handleEditProfileClick = () => {
    setMenuOpened(false);
    setIsEditModalOpen(true);
  };

  const { isAboveSmall } = useResponsiveDesign();

  if (!account || typeof isAboveSmall === "undefined") {
    return null;
  }

  const containerProps = isAboveSmall
    ? { opened: menuOpened, onChange: setMenuOpened, shadow: "md" }
    : {};

  const ContentComponent = isAboveSmall ? Menu.Dropdown : Fragment;
  const contentProps = isAboveSmall ? { pt: 0 } : {};

  return (
    <>
      <Menu {...containerProps}>
        {isAboveSmall && (
          <Menu.Target>
            {account?.profile ? (
              <Group gap={0}>
                <UnstyledButton>
                  <UserAvatar
                    user={account?.profile ?? { email: "-" }}
                    radius="xl"
                    size={35}
                    color={AccountPrimaryColor}
                    variant="filled"
                    src={account?.profile?.picture}
                  />
                </UnstyledButton>
                <UnstyledButton>
                  <Stack gap={0} mt="6" ml="xs" mr="md" visibleFrom="sm">
                    <Text size="sm" fw={700} mt={-8}>
                      {account?.profile.given_name ||
                        account?.profile.nickname ||
                        account?.profile.email ||
                        "Unknown"}
                    </Text>
                    <Text mt={-2} size="xs" visibleFrom="sm">
                      {account?.profile.email || "No Email"}
                    </Text>
                  </Stack>
                </UnstyledButton>
                <ActionIcon variant="default" size="lg" visibleFrom="sm">
                  <IconDots size={20} />
                </ActionIcon>
              </Group>
            ) : (
              <Group gap="xs" mr="sm">
                <Loader type="dots" />
              </Group>
            )}
          </Menu.Target>
        )}

        <ContentComponent {...contentProps}>
          <AccountMenuContent
            account={account}
            allAccounts={allAccounts}
            deleteAccount={deleteAccount}
            opened={menuOpened}
            openConfigureModal={openConfigureModal}
            handleEditProfileClick={handleEditProfileClick}
          />
        </ContentComponent>
      </Menu>
      {configurePanel}
      <EditProfileModal
        opened={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        activeProfile={account}
        onSave={(user: UserEntity) => {
          updateAccount({
            ...(account as { sessionId: number }),
            profile: {
              ...user,
            },
          });
        }}
      />
    </>
  );
}
