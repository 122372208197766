import { Table, Text } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";

import {
  OrganizationEntity,
  organizationEntity,
} from "../../../common/entities/organization.js";
import { OrganizationUserEntity } from "../../../common/entities/organizationUser.js";
import {
  fields,
  getUserName,
  primaryKeyFields,
  userEntity,
  UserEntity,
} from "../../../common/entities/user.js";
import { idField } from "../../../common/fields/id.js";
import { organizationIdField } from "../../../common/fields/organization_id.js";
import { userIdFieldRequired } from "../../../common/fields/user_id.js";
import { organizationUserClient } from "../../../common/utils/entityClient.js";

import { clientError } from "../../utils/clientError.js";

import { StandardError } from "../../components/Standard/StandardError.js";
import { StandardLoader } from "../../components/Standard/StandardLoader.js";
import { AdminUserListItem } from "./AdminUsersListItem.js";

type CompoundOrgUser = OrganizationUserEntity & {
  User: UserEntity;
  Organization: OrganizationEntity;
};

export function AdminUsersList({ searchQuery }: { searchQuery: string }) {
  const [users, setUsers] = useState<CompoundOrgUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const manageEntitlements = useMemo(() => {
    return (compoundOrgUser: CompoundOrgUser) => {
      return {
        add: async (entitlement: number) => {
          const updatedUser: CompoundOrgUser = {
            ...compoundOrgUser,
            entitlements: [
              ...((compoundOrgUser.entitlements as unknown as number[])?.filter(
                (e) => e !== entitlement
              ) ?? []),
              entitlement,
            ] as unknown as CompoundOrgUser["entitlements"],
          };
          if (typeof updatedUser.user_id !== "number") {
            throw new Error("User ID is required");
          }
          if (typeof updatedUser.organization_id !== "number") {
            throw new Error("Organization ID is required");
          }
          const orgUserResponse = await organizationUserClient(clientError)
            .item(updatedUser.organization_id, updatedUser.user_id)
            .update({ entitlements: updatedUser.entitlements });
          if (orgUserResponse.success) {
            setUsers((latestUsers) =>
              latestUsers.map((u) =>
                compoundOrgUser.user_id === u.user_id &&
                compoundOrgUser.organization_id === u.organization_id
                  ? updatedUser
                  : u
              )
            );
          } else {
            throw new Error(orgUserResponse.error);
          }
        },
        remove: async (entitlement: number) => {
          const updatedUser: CompoundOrgUser = {
            ...compoundOrgUser,
            entitlements: ((
              compoundOrgUser.entitlements as unknown as number[]
            )?.filter((e) => e !== entitlement) ??
              []) as unknown as CompoundOrgUser["entitlements"],
          };
          if (typeof updatedUser.user_id !== "number") {
            throw new Error("User ID is required");
          }
          if (typeof updatedUser.organization_id !== "number") {
            throw new Error("Organization ID is required");
          }
          const orgUserResponse = await organizationUserClient(clientError)
            .item(updatedUser.organization_id, updatedUser.user_id)
            .update({ entitlements: updatedUser.entitlements });
          if (orgUserResponse.success) {
            setUsers((latestUsers) =>
              latestUsers.map((u) =>
                compoundOrgUser.user_id === u.user_id &&
                compoundOrgUser.organization_id === u.organization_id
                  ? updatedUser
                  : u
              )
            );
          } else {
            throw new Error(orgUserResponse.error);
          }
        },
      };
    };
  }, [users, setUsers]);
  useEffect(function () {
    async function load() {
      try {
        setIsLoading(true);
        setError(null);
        const listUsersResponse = await organizationUserClient(
          clientError
        ).list<typeof fields, typeof primaryKeyFields>(
          {},
          {
            join: userEntity.name,
            from: idField.name,
            to: userIdFieldRequired.name,
            join2: organizationEntity.name,
            from2: idField.name,
            to2: organizationIdField.name,
          }
        );
        if (listUsersResponse.success) {
          if (listUsersResponse.data.success) {
            setUsers(
              (listUsersResponse.data.data as CompoundOrgUser[]).sort((a, b) =>
                a.organization_id === b.organization_id
                  ? getUserName(a.User).localeCompare(getUserName(b.User))
                  : typeof a.Organization?.name === "string" &&
                    typeof b.Organization?.name === "string"
                  ? a.Organization.name.localeCompare(b.Organization.name)
                  : (a.organization_id ?? 0) - (b.organization_id ?? 0)
              )
            );
          } else {
            setError(listUsersResponse.data.errors.join(", "));
          }
        } else {
          setError(listUsersResponse.error);
        }
      } catch (error: any) {
        console.error(error);
        setError(error.message ?? error ?? "Unknown error");
      } finally {
        setIsLoading(false);
      }
    }
    load();
  }, []);

  // Filter users based on search query
  const filteredUsers = users.filter((user) => {
    const userName = getUserName(user.User).toLowerCase();
    const userEmail = user.User.email?.toLowerCase() || "";
    const orgName = user.Organization.name?.toLowerCase() || "";
    const query = searchQuery.toLowerCase();
    return (
      userName.includes(query) ||
      userEmail.includes(query) ||
      orgName.includes(query)
    );
  });

  if (isLoading) {
    return <StandardLoader title="Loading users" />;
  }

  if (error) {
    return <StandardError error={error} title="Error loading users" />;
  }

  return (
    <Table highlightOnHover stickyHeader>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <Text size="sm" fw={600}>
              User
            </Text>
          </Table.Th>
          <Table.Th>
            <Text size="sm" fw={600} w={240}>
              Organization
            </Text>
          </Table.Th>
          <Table.Th style={{ minWidth: "360px" }}>
            <Text size="sm" fw={600}>
              Entitlements
            </Text>
          </Table.Th>
          <Table.Th ta="right"></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {filteredUsers.map((p) => {
          const manageOrgUserEntitlements = manageEntitlements(p);
          return p.User ? (
            AdminUserListItem(
              p,
              p.User,
              p.Organization,
              manageOrgUserEntitlements.add,
              manageOrgUserEntitlements.remove
            )
          ) : (
            <p>{JSON.stringify(p)}</p>
          );
        })}
      </Table.Tbody>
    </Table>
  );
}
