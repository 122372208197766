import { Button, Group, Modal, Stack, TextInput } from "@mantine/core";
import { IconFolderPlus } from "@tabler/icons-react";

interface NewFolderModalProps {
  opened: boolean;
  onClose: () => void;
  folderName: string;
  onFolderNameChange: (name: string) => void;
  onCreate: () => void;
  isCreating: boolean;
}

/**
 * Modal for creating a new folder
 */
export function NewFolderModal({
  opened,
  onClose,
  folderName,
  onFolderNameChange,
  onCreate,
  isCreating,
}: NewFolderModalProps) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Create New Folder"
      size="sm"
    >
      <Stack>
        <TextInput
          label="Folder Name"
          placeholder="Enter folder name"
          value={folderName}
          onChange={(e) => onFolderNameChange(e.currentTarget.value)}
          data-autofocus
        />
        <Group justify="flex-end">
          <Button variant="subtle" onClick={onClose} disabled={isCreating}>
            Cancel
          </Button>
          <Button
            onClick={onCreate}
            loading={isCreating}
            leftSection={<IconFolderPlus size={16} />}
          >
            Create Folder
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
