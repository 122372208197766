import { StringField } from "../types/field";

export const realmIdField: StringField<{
  final: true;
  name: "realm_id";
  required: true;
}> = {
  final: true,
  label: "Realm ID",
  name: "realm_id",
  required: true,
  type: "string",
};
