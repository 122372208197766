import {
  AccessControlFilterByJoinTableGate,
  AccessControlGate,
  AccessControlJoinRecordExistsGate,
  AccessControlRecordExistsGate,
  AccessControlRecordNotExistsGate,
  FILTER_BY_JOIN_TABLE_GATE,
  GateResult,
  IS_ONLY_MEMBER_GATE,
  JOIN_RECORD_EXISTS_GATE,
  OR_GATE,
  PREDETERMINED_GATE,
  READ_ONLY_TABLE_GATE,
  RECORD_EXISTS_GATE,
  RECORD_NOT_EXISTS_GATE,
} from "../types/accessControl.js";
import {
  AccessControlMultipleResult,
  AccessControlMultipleResultBodies,
  AccessControlMultipleResultFilters,
} from "../types/apiDocumentation.js";
import { Entity, FieldList, PrimaryKeyFieldTuple } from "../types/entity.js";

export function summarizeGate(
  forEntity: Entity<any, any, string>,
  gate: {
    result:
      | AccessControlGate<FieldList, PrimaryKeyFieldTuple>
      | AccessControlMultipleResult
      | GateResult
      | string;
    requiredFilters: string[];
  }
): string {
  return `${
    typeof gate.result === "object" && "reason" in gate.result
      ? `**${gate.result.reason}**\n`
      : ""
  }${
    gate.requiredFilters.length > 0
      ? `Filters:\n${gate.requiredFilters
          .map((x) => `\n - **${x}**`)
          .join("")}\n\n`
      : ""
  }${summarizeGateInternal(forEntity, gate)
    .split("\n")
    .map((x) => `${x}`)
    .join("\n")}`;
}

function summarizeGateInternal(
  forEntity: Entity<any, any, string>,
  gate: {
    result:
      | AccessControlGate<FieldList, PrimaryKeyFieldTuple>
      | AccessControlMultipleResult
      | GateResult
      | string;
    requiredFilters: string[];
  }
): string {
  if (typeof gate.result === "string") {
    return gate.result;
  }
  if (!gate.result) {
    return "⚠️ No access control";
  }
  if ("type" in gate.result) {
    switch (gate.result.type) {
      case "filterByJoinTable":
        return `🔐 Filters ${
          forEntity.name
        } records to only include those that have matching **${
          (
            gate.result as AccessControlFilterByJoinTableGate<
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).entity.name
        }** records with matching ${[
          [
            (
              gate.result as AccessControlFilterByJoinTableGate<
                FieldList,
                PrimaryKeyFieldTuple
              >
            ).join.from.name,
          ],
        ]
          .concat(
            Object.keys(
              (
                gate.result as AccessControlFilterByJoinTableGate<
                  FieldList,
                  PrimaryKeyFieldTuple
                >
              ).condition
            )
          )
          .map((key) => `**${key}**`)
          .join(" and ")}`;
      case OR_GATE:
        return `At least one of the following conditions must be met:
${gate.result.gates
  .map((g) =>
    summarizeGate(forEntity, { result: g, requiredFilters: [] })
      .split("\n")
      .map((x) => `  ${x}`)
      .join("\n")
  )
  .map((x) => `   - ${x}`)
  .join("\n")}`;
      case IS_ONLY_MEMBER_GATE:
        return `🔐 User must be the only member of the organization`;
      case READ_ONLY_TABLE_GATE:
        return "Read-only access is allowed";
      case RECORD_EXISTS_GATE:
        return `🔐 ${Aan(
          (
            gate.result as AccessControlRecordExistsGate<
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).entity.name
        )} **${
          (
            gate.result as AccessControlRecordExistsGate<
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).entity.name
        }** must exist with matching ${Object.entries(
          (
            gate.result as AccessControlRecordExistsGate<
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).condition
        )
          .map(([key]) => `**${key}**`)
          .join(" and ")}`;
      case JOIN_RECORD_EXISTS_GATE:
        return `🔐 ${Aan(
          (
            gate.result as AccessControlJoinRecordExistsGate<
              FieldList,
              PrimaryKeyFieldTuple,
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).entity1.name
        )} **${
          (
            gate.result as AccessControlJoinRecordExistsGate<
              FieldList,
              PrimaryKeyFieldTuple,
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).entity1.name
        }** must exist with matching ${Object.entries(
          (
            gate.result as AccessControlJoinRecordExistsGate<
              FieldList,
              PrimaryKeyFieldTuple,
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).condition1
        )
          .map(([key]) => `**${key}**`)
          .join(" and ")} and a **${
          (
            gate.result as AccessControlJoinRecordExistsGate<
              FieldList,
              PrimaryKeyFieldTuple,
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).entity2.name
        }** must exist with matching ${Object.entries(
          (
            gate.result as AccessControlJoinRecordExistsGate<
              FieldList,
              PrimaryKeyFieldTuple,
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).condition2
        )
          .map(([key]) => `**${key}**`)
          .join(" and ")}`;
      case RECORD_NOT_EXISTS_GATE:
        console.log(
          (
            gate.result as AccessControlRecordNotExistsGate<
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).entity.name
        );
        return `🔐 ${Aan(
          (
            gate.result as AccessControlRecordNotExistsGate<
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).entity.name
        )}  **${
          (
            gate.result as AccessControlRecordNotExistsGate<
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).entity.name
        }** must not exist with the given ${Object.keys(
          (
            gate.result as AccessControlRecordNotExistsGate<
              FieldList,
              PrimaryKeyFieldTuple
            >
          ).condition
        )
          .map((x) => `**${x}**`)
          .join(" and ")}`;
      case PREDETERMINED_GATE:
        return `🔐 ${gate.result.reason}`;
      case IS_ONLY_MEMBER_GATE:
        return "🔐 User must be the only member of the organization";
      default:
        return `Unknown gate type: ${
          (gate.result as unknown as { type: string }).type
        }`;
    }
  }

  if ("errors" in gate.result) {
    if (gate.result.errors) {
      return `🚨 Gate errors: ${gate.result.errors.join(", ")}`;
    }
  }

  if ("gates" in gate.result) {
    if (Array.isArray(gate.result.gates) && gate.result.gates.length === 0) {
      return "🟢 All users";
    }
    const { gates } = gate.result as unknown as {
      gates: AccessControlGate<FieldList, PrimaryKeyFieldTuple>[];
    };

    const accessGates = gates.filter(
      (g) => g.type !== FILTER_BY_JOIN_TABLE_GATE
    );

    const filterGates = gates.filter(
      (g) => g.type === FILTER_BY_JOIN_TABLE_GATE
    );

    const gateDescription =
      "> " +
      accessGates
        .map((g) =>
          summarizeGate(forEntity, { result: g, requiredFilters: [] })
            .split("\n")
            .join("\n> ")
        )
        .join("\n\n**OR**\n\n> ");

    return (
      (gateDescription.length > 2 ? gateDescription : "") +
      (filterGates.length > 0
        ? "\n\n**ALWAYS**\n\n> " +
          filterGates
            .map((g) =>
              summarizeGate(forEntity, { result: g, requiredFilters: [] })
                .split("\n")
                .join("\n> ")
            )
            .join("\n\n**AND**\n\n> ")
        : "")
    );
  }

  if ("multiple" in gate.result) {
    if ("filters" in gate.result) {
      return `Multiple access controls based on provided filters:

${gate.result.filters
  .map(
    (f, i) =>
      `#### Filter combination ${i + 1}: **${Object.keys(f).join(
        ", "
      )}**\n${summarizeGate(forEntity, {
        result: (gate.result as AccessControlMultipleResultFilters).results[i],
        requiredFilters: [],
      })}`
  )
  .join("\n\n")}`;
    }
    if ("bodies" in gate.result) {
      return `Multiple bodies with results:
${gate.result.bodies
  .map(
    (b, i) =>
      `#### Body containing ${Object.keys(b)
        .map((k) => `**${k}**`)
        .join(", ")}:\n\n${summarizeGate(forEntity, {
        result: (gate.result as AccessControlMultipleResultBodies).results[i],
        requiredFilters: [],
      })}`
  )
  .join("\n\n")}`;
    }
  }

  return `Unknown gate type: ${JSON.stringify(gate, null, 2)}`;
}

function Aan(word: string) {
  return "aeiou".includes(word[0].toLowerCase()) ? "An" : "A";
}
