import { Text } from "@mantine/core";
import { OrganizationManagement } from "../../components/Admin/OrganizationManagement.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";

export function ClientsOverview() {
  const { isServiceProvider } = useActiveStates();

  return (
    <Layout>
      <PageTitle title="Organization Profile" />
      {isServiceProvider ? (
        <OrganizationManagement />
      ) : (
        <Text m="xl">This page is not available.</Text>
      )}
    </Layout>
  );
}
