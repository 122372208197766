import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ActionIcon, Group, TextInput } from "@mantine/core";
import { IconEdit, IconX } from "@tabler/icons-react";
import MobileMenuIcon from "./MobileMenuIcon.js";

export default function SearchBox({
  updateSearch,
}: {
  updateSearch: (search: string) => void;
}) {
  const [search, setSearch] = useState("");

  useEffect(() => {
    updateSearch(search);
  }, [search]);

  return (
    <Group justify="space-between" p="sm">
      <TextInput
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        flex={1}
        rightSection={
          search ? (
            <ActionIcon
              variant="default"
              size="sm"
              style={{ border: 0 }}
              onClick={() => setSearch("")}
            >
              <MobileMenuIcon Icon={IconX} />
            </ActionIcon>
          ) : null
        }
      />
      <Link
        to="/penny/conversations/create"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ActionIcon variant="default" size="lg" style={{ border: 0 }}>
          <MobileMenuIcon Icon={IconEdit} />
        </ActionIcon>
      </Link>
    </Group>
  );
}
