import { Badge, Group, Menu, Stack, Text } from "@mantine/core";
import { StoredAuthProfile } from "../../../contexts/AuthContext.js";
import { UserAvatar } from "../../Avatars/UserAvatar.js";
import { AccountPrimaryColor } from "../../../theme.js";
import { useActiveStates } from "../../../contexts/ActiveStatesContext.js";

export default function AccountsList({
  accounts,
}: {
  accounts: StoredAuthProfile[];
}) {
  const { shutdown } = useActiveStates();
  /**
   * Handles switching between accounts by setting the appropriate session ID
   * and updating the ActiveStatesContext.
   *
   * @param account - The account to switch to.
   */
  async function handleSwitch(account: StoredAuthProfile) {
    if (account.sessionId === undefined) {
      throw new Error("account has no session");
    }
    // Real account: set sessionId in sessionStorage
    sessionStorage.setItem("sessionId", account.sessionId.toString(10));
    shutdown(
      {
        color: "#54e382",
        message: "Switching session, please wait...",
        position: "bottom-center",
        title: "Switching",
      },
      "/"
    );
  }
  return (
    <>
      {accounts.map((account) => {
        return (
          <Menu.Item
            key={account.sessionId}
            onClick={() => handleSwitch(account)}
            leftSection={
              <UserAvatar
                user={account.profile}
                color={AccountPrimaryColor}
                variant="filled"
                size={45}
                alt={
                  account.profile.given_name ||
                  account.profile.nickname ||
                  account.profile.email ||
                  "Unknown"
                }
                radius="xl"
              />
            }
            rightSection={<Badge color={AccountPrimaryColor}>{2}</Badge>}
          >
            <Stack mr="xl" gap={0}>
              <Group>
                <Text fw={700}>
                  {" "}
                  {account.profile.given_name ||
                    account.profile.nickname ||
                    account.profile.email ||
                    "Unknown"}
                </Text>

                <Badge
                  variant="light"
                  size="xs"
                  radius="sm"
                  color={AccountPrimaryColor}
                >
                  Admin
                </Badge>
              </Group>

              <Text size="sm" fw={500}>
                {account.profile.email}
              </Text>
            </Stack>
          </Menu.Item>
        );
      })}
    </>
  );
}
