import { ActionIcon, Tooltip } from "@mantine/core";
import { IconCopy, IconEdit, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useConfirmWithModal } from "../../hooks/useConfirmWithModal.js";

interface NoteMenuProps {
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
  isEditing: boolean;
}

export function NoteMenu({
  isEditing,
  onEdit,
  onCopy,
  onDelete,
}: NoteMenuProps) {
  const confirmWithModal = useConfirmWithModal();
  const [menuOpened, setMenuOpened] = useState(false);

  const handleDelete = async () => {
    const confirmed = await confirmWithModal(
      "Are you sure you want to delete this note?"
    );
    if (confirmed) {
      onDelete();
    }
  };

  return (
    <>
      {!isEditing && (
        <Tooltip label="Edit note">
          <ActionIcon
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            size="sm"
            variant="subtle"
            color="var(--mantine-color-text)"
          >
            <IconEdit />
          </ActionIcon>
        </Tooltip>
      )}

      <Tooltip label="Copy text">
        <ActionIcon
          onClick={(e) => {
            e.stopPropagation();
            onCopy();
          }}
          size="sm"
          variant="subtle"
          color="var(--mantine-color-text)"
        >
          <IconCopy />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="Delete note">
        <ActionIcon
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
          size="sm"
          variant="subtle"
          color="var(--mantine-color-text)"
        >
          <IconTrash />
        </ActionIcon>
      </Tooltip>
    </>
  );
}
