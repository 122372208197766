import { Alert, Text } from "@mantine/core";

import { summarizeGate } from "../../../common/accessControl/documentation.js";
import {
  AccessControlGate,
  GateResult,
} from "../../../common/types/accessControl.js";
import { AccessControlMultipleResult } from "../../../common/types/apiDocumentation.js";
import {
  Entity,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../../../common/types/entity.js";
import { MarkdownRenderer } from "../../pages/penny/MarkdownRenderer.js";

export function RouteAccessControlSummary({
  accessControl,
  forEntity,
}: {
  accessControl?: {
    result:
      | AccessControlGate<FieldList, PrimaryKeyFieldTuple>
      | AccessControlMultipleResult
      | GateResult
      | string;
    requiredFilters: string[];
  };
  forEntity: Entity<any, any, string>;
}) {
  if (!accessControl) {
    return (
      <Alert color="orange">
        <Text>🟢 All users</Text>
      </Alert>
    );
  }

  if (typeof accessControl === "string") {
    return (
      <Alert color="purple">
        <MarkdownRenderer content={accessControl} />
      </Alert>
    );
  }

  return (
    <Alert color="green">
      <MarkdownRenderer content={summarizeGate(forEntity, accessControl)} />
    </Alert>
  );
}
