import { ActionIcon, AppShell, Group, rem } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { Link, useLocation } from "react-router-dom";

import { useActiveStates } from "../contexts/ActiveStatesContext.js";
import { useResponsiveDesign } from "../hooks/useResponsiveDesign.js";

import { AsideToggleButton } from "./Buttons/AsideToggleButton.js";
import { AccountMenu } from "./Menus/AccountMenu.js";
import { ColorSwitcherMenu } from "./Menus/ColorSwitcherMenu.js";
import { CustomerMenu } from "./Menus/CustomerMenu.js";
import MainMenuMobile from "./Menus/MobileMenu/MainMenuMobile.js";

export function AppHeader() {
  const { organizationUser } = useActiveStates();
  const { isAboveSmall } = useResponsiveDesign();
  const location = useLocation();

  // Extract current folder path when in files section
  const getCurrentFolderPath = () => {
    const pathMatch = location.pathname.match(/^\/files(?:\/(.*))?$/);
    return pathMatch ? pathMatch[1] || "" : undefined;
  };

  const currentFolderPath = getCurrentFolderPath();

  if (typeof isAboveSmall === "undefined") {
    return null;
  }

  return (
    <AppShell.Header>
      <Group justify="space-between" py="xs" px="md">
        {!isAboveSmall && (
          <>
            <MainMenuMobile />
            <Link
              to="/penny/conversations/create"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ActionIcon
                variant="default"
                size="lg"
                style={{
                  border: 0,
                }}
              >
                <IconEdit style={{ width: rem(27), height: rem(27) }} />
              </ActionIcon>
            </Link>
          </>
        )}
        {isAboveSmall && (
          <>
            <CustomerMenu />
            <Group gap="xs">
              <AccountMenu />
              <ColorSwitcherMenu />
              {organizationUser.is_invitation ? null : (
                <AsideToggleButton folderPath={currentFolderPath} />
              )}
              <Link
                to="/penny/conversations/create"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ActionIcon variant="default" size="lg">
                  <IconEdit style={{ width: rem(27), height: rem(27) }} />
                </ActionIcon>
              </Link>
            </Group>
          </>
        )}
      </Group>
    </AppShell.Header>
  );
}
