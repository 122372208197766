import {
  ActionIcon,
  AppShell,
  Box,
  Button,
  Divider,
  Group,
  Indicator,
  Loader,
  Select,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import {
  IconChecks,
  IconFilterCheck,
  IconFilterSearch,
  IconRefresh,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

import { MessageEntity } from "../../../common/entities/message.js";
import { UserEntity } from "../../../common/entities/user.js";
import { MESSAGE_LIBRARY } from "../../../common/fields/message.js";
import { ComponentError } from "../../components/Errors/ComponentError.js";
import { MessageDisplay } from "../../components/Messages/MessageDisplay.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { MessageControl } from "../../hooks/useMessages.js";

import classes from "./Notifications.css.js";

function MarkAsReadButton({
  message,
  messageControl,
  user,
}: {
  message: MessageEntity;
  messageControl: MessageControl;
  user: UserEntity;
}) {
  const messageIsRead =
    messageControl.readTimestamp >= new Date(message.timestamp ?? 0).getTime();
  return messageIsRead ? (
    <Button
      size="xs"
      disabled={!messageIsRead || messageControl.markingAsRead}
      title="Mark as Unread"
      variant="outline"
      onClick={(e) => {
        e.stopPropagation();
        if (typeof user?.id === "number" && message) {
          messageControl.markAsRead(message, user.id, true);
        }
      }}
    >
      Mark as unread
    </Button>
  ) : (
    <Button
      size="xs"
      disabled={messageIsRead || messageControl.markingAsRead}
      title="Mark as Read"
      variant="primary"
      onClick={(e) => {
        e.stopPropagation();
        if (typeof user?.id === "number" && message) {
          messageControl.markAsRead(message, user.id);
        }
      }}
    >
      Mark as read
    </Button>
  );
}

export function NotificationsOverview({
  isAllActivity = false,
}: {
  isAllActivity?: boolean;
  recentActivityOnly?: boolean;
}) {
  const navigate = useNavigate();
  const { messageControl, user, customer, serviceProvider } = useActiveStates();

  const notificationsButtons = (
    <Group justify="space-between">
      <Button
        size="sm"
        variant="outline"
        title={
          messageControl.activeMessageIndex === 0
            ? "No newer message"
            : "Newer message"
        }
        leftSection={<IconChecks />}
        disabled={messageControl.loadingMessages}
        onClick={() => {
          if (user?.id) {
            messageControl.loadMessages(
              user.id,
              serviceProvider?.id,
              customer?.id
            );
          }
        }}
      >
        Mark all as read
      </Button>
      <Button
        size="sm"
        variant="primary"
        title={
          messageControl.activeMessageIndex ===
          messageControl.messages.length - 1
            ? "No older message"
            : "Older message"
        }
        onClick={() => navigate("/activity")}
      >
        View all activity
      </Button>
    </Group>
  );

  const notifications = (
    <>
      {messageControl.messages.map((message) => {
        const isRead =
          messageControl.readTimestamp >=
          new Date(message.timestamp ?? 0).getTime();
        return (
          <div key={message.id}>
            <Divider />
            <Group
              align="flex-start"
              justify="space-between"
              key={message.id}
              onClick={() => messageControl.focusMessage(message)}
            >
              <Box w="calc(100% - 40px)">
                <MessageDisplay
                  focusable={false}
                  message={message}
                  read={isAllActivity || isRead}
                  streamFromStatic
                />
                {!isAllActivity && (
                  <MarkAsReadButton
                    message={message}
                    messageControl={messageControl}
                    user={user!}
                  />
                )}
              </Box>
              {isRead || isAllActivity ? null : <Indicator mr={10} mt={10} />}
            </Group>
          </div>
        );
      })}
    </>
  );

  return (
    <>
      <Title order={5} p="md">
        Activity
      </Title>
      <Tabs defaultValue="all" mb="md">
        <Tabs.List
          style={{
            position: "sticky",
            top: "70px",
            backgroundColor: "var(--mantine-color-dark-7)",
          }}
        >
          <Tabs.Tab value="all">All</Tabs.Tab>
          <Tabs.Tab value="vip">VIP</Tabs.Tab>
          <Tabs.Tab value="mentions">Mentions</Tabs.Tab>
          <Tabs.Tab value="threads">Threads</Tabs.Tab>
          <Tabs.Tab value="reactions">Reactions</Tabs.Tab>
          <Tabs.Tab value="invitations">Invitations</Tabs.Tab>
          <Tabs.Tab value="apps">Apps</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="all">
          <Box
            p="md"
            style={{
              borderBottom: "1px solid var(--mantine-color-gray-8)",
              backgroundColor: messageControl.filter?.message
                ? "var(--mantine-color-dark-5)"
                : "transparent",
            }}
          >
            <Group gap="xs">
              {typeof messageControl.filter?.message === "number" ? (
                <IconFilterCheck size={16} />
              ) : (
                <IconFilterSearch size={16} />
              )}
              {typeof messageControl.filter?.message === "number" ? (
                <Text>Filtering by message</Text>
              ) : (
                <Text>Select a message to filter by</Text>
              )}
              <Select
                multiple
                data={Object.entries(MESSAGE_LIBRARY).map(([key, value]) => ({
                  value: key,
                  label: value,
                }))}
                onChange={(message) => {
                  console.log("message", message);
                  messageControl.setFilter({
                    ...messageControl.filter,
                    message:
                      typeof message === "number"
                        ? message
                        : typeof message === "string"
                        ? parseInt(message, 10)
                        : null,
                  });
                }}
                searchable
                clearable
                nothingFoundMessage="No messages found"
                maxDropdownHeight={400}
              />
            </Group>
          </Box>
          <Box p="md">
            {Array.isArray(messageControl.loadMessagesError) ? (
              <ComponentError
                title="Can't load messages"
                messages={messageControl.loadMessagesError}
              />
            ) : messageControl.loadingMessages ? (
              <AppShell.Section
                grow
                ta="left"
                style={{
                  alignContent: "flex-end",
                  alignItems: "center",
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  overflowY: "auto",
                }}
              >
                <Box p="xl">
                  <Loader />
                </Box>
              </AppShell.Section>
            ) : (
              <AppShell.Section
                grow
                ta="left"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "flex-end",
                  overflowY: "auto",
                }}
              >
                {notifications}
              </AppShell.Section>
            )}
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="vip" p="md">
          {/* Content for VIP tab */}
          VIP tab content
        </Tabs.Panel>

        <Tabs.Panel value="mentions" p="md">
          {/* Content for Mentions tab */}
          Mentions tab content
        </Tabs.Panel>

        <Tabs.Panel value="threads" p="md">
          {/* Content for Threads tab */}
          Threads tab content
        </Tabs.Panel>

        <Tabs.Panel value="reactions" p="md">
          {/* Content for Reactions tab */}
          Reactions tab content
        </Tabs.Panel>

        <Tabs.Panel value="invitations" p="md">
          {/* Content for Invitations tab */}
          Invitations tab content
        </Tabs.Panel>

        <Tabs.Panel value="apps" p="md">
          {/* Content for Apps tab */}
          Apps tab content
        </Tabs.Panel>
      </Tabs>
      <Box p="md">
        <ActionIcon
          variant="outline"
          disabled={messageControl.loadingMessages}
          onClick={() => {
            if (user?.id) {
              messageControl.loadMessages(
                user.id,
                serviceProvider?.id,
                customer?.id
              );
            }
          }}
        >
          <IconRefresh size={24} />
        </ActionIcon>
      </Box>

      {!isAllActivity && (
        <AppShell.Section
          p="md"
          pt="lg"
          className={classes.sectionAsideFooterBorder}
        >
          {notificationsButtons}
        </AppShell.Section>
      )}
    </>
  );
}
