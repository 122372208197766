import { css } from "../../utils/css.js";

interface ClassNames {
  wrapper: string;
  title: string;
  description: string;
  error: string;
}

export default css<ClassNames>`
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 70px); /* Full viewport height */
  }

  .title {
    font-family: Greycliff CF, var(--mantine-font-family);
    text-align: left;
    font-size: 62px;
    font-weight: 900;
    line-height: 1.1;
    margin: 0;
    padding: 0;

    @media (max-width: var(--mantine-breakpoint-sm)) {
      font-size: 42px;
      line-height: 1.2;
    }
  }

  .description {
    text-align: left;
    margin: var(--mantine-spacing-md) 0 var(--mantine-spacing-xl) 0;
    font-size: 24px;

    @media (max-width: var(--mantine-breakpoint-sm)) {
      font-size: 18px;
    }
  }

  .error {
    text-align: left;
    margin: var(--mantine-spacing-md) 0 var(--mantine-spacing-xl) 0;
    font-size: 18px;

    @media (max-width: var(--mantine-breakpoint-sm)) {
      font-size: 16px;
    }

    opacity: 0.65;
  }
`;
