import { Alert, Box, Stack, Text, TextInput, Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconAlertCircle, IconSearch } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";

import { KVEntityStore } from "../../../common/entities/kv.js";
import { NoteCard } from "../../components/Notes/NoteCard.js";
import NewNoteCard from "../../components/Notes/NewNoteCard.js";

import { StandardLoader } from "../../components/Standard/StandardLoader.js";
import { useNotes } from "../../hooks/useNotes.js";
import { resizeAllGridItems } from "./notesUtils.js";

import classes from "./Notes.css.js";

export function Notes({ kv }: { kv: KVEntityStore }) {
  const {
    error,
    loading,
    notes,
    saving,
    addNote,
    deleteNote,
    togglePin,
    updateNote,
    updateNoteColor,
  } = useNotes(kv);

  const [searchTerm, setSearchTerm] = useState("");

  // For note copying functionality
  const copyTextRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (notes) {
      resizeAllGridItems();
    }
  }, [notes]);

  const handleCopyText = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notifications.show({
          title: "Note text copied to clipboard",
          color: "green",
        });
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
        notifications.show({
          title: "Failed to copy text",
          message: err.message,
          color: "red",
        });
      });
  };

  // Filter notes based on search term
  const filteredNotes =
    notes?.filter((note) =>
      note.text.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  const sortedNotes = [...filteredNotes].sort((a, b) => {
    // Sort by most recently updated
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });

  const pinnedNotes = sortedNotes.filter((note) => note.pinned);
  const unpinnedNotes = sortedNotes.filter((note) => !note.pinned);

  return (
    <Stack gap="md">
      {/* Add new note form */}
      <NewNoteCard addNote={addNote} loading={loading} saving={saving} />

      {/* Search notes */}
      <TextInput
        leftSection={<IconSearch size={16} />}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search notes..."
      />

      {/* Loading and error states */}
      {loading && <StandardLoader title="Loading notes" />}

      {error && (
        <Alert icon={<IconAlertCircle size={16} />} title="Error" color="red">
          Failed to load notes: {error.message || String(error)}
        </Alert>
      )}

      {/* Notes masonry layout */}
      {!loading && !error && (
        <>
          {sortedNotes.length === 0 ? (
            <Text ta="center" c="dimmed">
              {searchTerm
                ? "No notes found matching your search."
                : "No notes yet. Add one above!"}
            </Text>
          ) : (
            <Stack>
              <Title order={5} fw={400} tt="uppercase" c="dimmed">
                Pinned
              </Title>

              <Box className={classes.notesContainer} id="pinnedNotesContainer">
                {pinnedNotes.map((note) => (
                  <Box key={note.id} className="noteItem">
                    <NoteCard
                      note={note}
                      user={note.User}
                      onUpdate={updateNote}
                      onDelete={deleteNote}
                      onTogglePin={togglePin}
                      onChangeColor={updateNoteColor}
                      onCopyText={handleCopyText}
                    />
                  </Box>
                ))}
              </Box>
              <Title order={5} fw={400} tt="uppercase" c="dimmed">
                Others
              </Title>
              <Box
                className={classes.notesContainer}
                id="unpinnedNotesContainer"
              >
                {unpinnedNotes.map((note) => (
                  <Box key={note.id} className="noteItem">
                    <NoteCard
                      note={note}
                      user={note.User}
                      onUpdate={updateNote}
                      onDelete={deleteNote}
                      onTogglePin={togglePin}
                      onChangeColor={updateNoteColor}
                      onCopyText={handleCopyText}
                    />
                  </Box>
                ))}
              </Box>
            </Stack>
          )}
        </>
      )}

      {/* Hidden textarea for copying (fallback method) */}
      <textarea
        ref={copyTextRef}
        style={{ position: "absolute", left: "-9999px" }}
        aria-hidden="true"
      />
    </Stack>
  );
}
