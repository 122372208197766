import { useCallback, useEffect, useState } from "react";

import { UserEntity } from "../../common/entities/user.js";
import { virtualCustomerIdField } from "../../common/fields/virtual_customer_id.js";
import { virtualProviderIdField } from "../../common/fields/virtual_provider_id.js";
import { userClient } from "../../common/utils/entityClient.js";
import { useActiveStates } from "../contexts/ActiveStatesContext.js";
import { useAside } from "../hooks/useAside.js";
import {
  PennyConversation,
  PennyConversationState,
} from "../pages/penny/PennyConversation.js";
import { clientError } from "../utils/clientError.js";
import { addUsersToCache } from "./Excelente/userProfileUtils.js";

const debug = false;

export function DefaultAsideContent() {
  const [state, setState] = useState<PennyConversationState>({
    conversation: {
      id: "new" as unknown as number,
      threads: [],
      created_at: new Date(),
      updated_at: new Date(),
      title: "New Conversation",
    },
  });
  const { user, serviceProvider, customer } = useActiveStates();
  const { currentContent, currentFolderPath } = useAside();
  const organization = customer || serviceProvider;

  // Add debug logging to see when this component renders and what content it receives
  debug &&
    console.log(
      "DefaultAsideContent rendering with currentContent:",
      currentContent ? "Content exists" : "No content",
      { isPennyChat: currentContent === null, currentFolderPath }
    );

  const [users, setUsers] = useState<UserEntity[]>([]);

  const fetchUsers = useCallback(async () => {
    const response = await userClient(clientError).list({
      [virtualProviderIdField.name]: serviceProvider.id!,
      [virtualCustomerIdField.name]: customer?.id,
    });
    if (response.success && "data" in response && "data" in response.data) {
      setUsers(response.data.data as UserEntity[]);
      addUsersToCache(response.data.data as UserEntity[]);
    }
  }, [organization?.id]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // If custom content is available, render it
  if (currentContent) {
    debug && console.log("DefaultAsideContent rendering custom content");
    debug && console.log("currentContent", currentContent);
    return <>{currentContent}</>;
  }

  // Otherwise render PennyChat (already wrapped with AsideContentWrapper)
  debug &&
    console.log(
      "DefaultAsideContent rendering PennyChat with folder path:",
      currentFolderPath
    );
  return (
    <PennyConversation
      state={state}
      setState={setState}
      user={user!}
      users={users}
      organization={organization}
      customer={customer}
      serviceProvider={serviceProvider}
    />
  );
}
