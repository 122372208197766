import { useState } from "react";

import { Paper, Textarea, Stack, Group, Button, Overlay } from "@mantine/core";
import { ColorPopover } from "../../components/Color/ColorPopover.js";
import {
  COLOR_OPTION_NAMES,
  COLOR_OPTIONS_DARK_MODE,
  COLOR_OPTIONS_LIGHT_MODE,
} from "../../components/Notes/noteCardUtils.js";
import { Note } from "../../hooks/useNotes.js";

export default function NewNoteCard({
  addNote,
  loading,
  saving,
}: {
  addNote: (text: string, color?: string) => Promise<Note>;
  loading: boolean;
  saving: boolean;
}) {
  const [newNoteText, setNewNoteText] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [colorPopoverOpened, setColorPopoverOpened] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const handleAddNote = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newNoteText.trim()) return;

    try {
      await addNote(newNoteText, selectedColor);
      setNewNoteText("");
    } catch (err) {
      console.error("Failed to add note:", err);
    } finally {
      setShowActions(false);
      setSelectedColor("");
    }
  };

  return (
    <>
      {/* lose focus on new note card when clicking outside of it */}
      {showActions && (
        <Overlay
          color="#000"
          fixed
          opacity={0}
          onClick={() => {
            if (!loading && !saving) {
              setShowActions(false);
              setSelectedColor("");
              setNewNoteText("");
            }
          }}
          zIndex={50}
        ></Overlay>
      )}
      <Paper
        withBorder
        p="xs"
        radius="md"
        bg={selectedColor}
        style={{ zIndex: 51 }}
      >
        <form onSubmit={handleAddNote}>
          <Stack>
            <Textarea
              value={newNoteText}
              onChange={(e) => setNewNoteText(e.target.value)}
              placeholder="Add a new note..."
              minRows={1}
              autosize
              maxRows={10}
              variant="unstyled"
              onFocus={() => setShowActions(true)}
            />
            {showActions && (
              <Group justify="space-between">
                <ColorPopover
                  colorNames={COLOR_OPTION_NAMES}
                  colorPopoverOpened={colorPopoverOpened}
                  darkModeColors={COLOR_OPTIONS_DARK_MODE}
                  lightModeColors={COLOR_OPTIONS_LIGHT_MODE}
                  onChangeColor={setSelectedColor}
                  selectedColor={selectedColor}
                  setColorPopoverOpened={setColorPopoverOpened}
                />
                <Button
                  type="submit"
                  loading={saving}
                  disabled={!newNoteText.trim() || loading || saving}
                >
                  Add Note
                </Button>
              </Group>
            )}
          </Stack>
        </form>
      </Paper>
    </>
  );
}
