import { useState } from "react";
import {
  Alert,
  Drawer,
  Group,
  Menu,
  Text,
  useComputedColorScheme,
} from "@mantine/core";
import {
  IconMail,
  IconUserCircle,
  IconSun,
  IconMoon,
  IconNotebook,
  IconLock,
  IconChevronRight,
  IconExternalLink,
} from "@tabler/icons-react";

import { AccountMenu } from "../AccountMenu.js";

import { OrganizationEntity } from "../../../../common/entities/organization.js";
import { LEDGERAI_SLUG } from "../../../../common/fields/slug.js";
import { StoredAuthProfile } from "../../../contexts/AuthContext.js";
import { ColorSwitcherMenu } from "../ColorSwitcherMenu.js";
import CustomerMenuMain from "../CustomerMenu/CustomerMenuMain.js";
import MobileMenuIcon from "./MobileMenuIcon.js";

import classes from "./SettingsMenu.css.js";

export default function SettingsMenu({
  account,
  activeOrganization,
  serviceProvider,
  isCustomerUser,
  opened,
  onClose,
}: {
  account: StoredAuthProfile | undefined;
  activeOrganization: OrganizationEntity;
  serviceProvider: OrganizationEntity;
  isCustomerUser: boolean;
  opened: boolean;
  onClose: () => void;
}) {
  const [customerMenuOpened, setCustomerMenuOpened] = useState(false);
  const [accountsMenuOpened, setAccountsMenuOpened] = useState(false);
  const [colorSchemeMenuOpened, setColorSchemeMenuOpened] = useState(false);
  const isDark = useComputedColorScheme() === "dark";

  return (
    <>
      <Drawer
        opened={opened}
        onClose={onClose}
        position="bottom"
        title="Settings"
        classNames={{
          content: classes.drawerContent,
          body: classes.drawerBody,
          close: classes.drawerCloseButton,
        }}
      >
        <Alert variant="default" mx="md" mb="md">
          {isCustomerUser ? (
            <>This service is provided by</>
          ) : (
            <>You are navigating {activeOrganization?.name} as a member of </>
          )}{" "}
          {serviceProvider.name}.{" "}
          {serviceProvider.slug === LEDGERAI_SLUG ? (
            <>(Platform Admin)</>
          ) : null}
        </Alert>
        <Menu classNames={{ item: classes.menuItem }}>
          <Menu.Label>Account</Menu.Label>
          <Menu.Item
            leftSection={<IconMail />}
            rightSection={
              <Group gap="xs">
                <Text size="sm">{account?.profile.email}</Text>
                <MobileMenuIcon Icon={IconChevronRight} />
              </Group>
            }
            onClick={() => setAccountsMenuOpened(true)}
          >
            Email
          </Menu.Item>
          <Menu.Item
            leftSection={<IconUserCircle />}
            rightSection={
              <Group gap="xs">
                <Text size="sm">{activeOrganization?.name}</Text>
                <MobileMenuIcon Icon={IconChevronRight} />
              </Group>
            }
            onClick={() => setCustomerMenuOpened(true)}
          >
            Workspace
          </Menu.Item>
          <Menu.Label>App</Menu.Label>
          <Menu.Item
            leftSection={isDark ? <IconMoon /> : <IconSun />}
            rightSection={
              <Group gap="xs">
                <Text size="sm"></Text>
                <MobileMenuIcon Icon={IconChevronRight} />
              </Group>
            }
            onClick={() => setColorSchemeMenuOpened(true)}
          >
            Color Scheme
          </Menu.Item>
          <Menu.Label>About</Menu.Label>
          <Menu.Item
            leftSection={<IconNotebook />}
            rightSection={<MobileMenuIcon Icon={IconExternalLink} />}
          >
            Terms of Use
          </Menu.Item>
          <Menu.Item
            leftSection={<IconLock />}
            rightSection={<MobileMenuIcon Icon={IconExternalLink} />}
          >
            Privacy Policy
          </Menu.Item>
        </Menu>
      </Drawer>

      <Drawer
        opened={accountsMenuOpened}
        onClose={() => setAccountsMenuOpened(false)}
        position="bottom"
        classNames={{
          content: `${classes.drawerContent} ${
            isDark ? classes.drawerContentDark : ""
          }`,
          body: classes.drawerBody,
          header: classes.drawerHeader,
          close: classes.drawerCloseButton,
        }}
      >
        <AccountMenu />
      </Drawer>

      <Drawer
        opened={customerMenuOpened}
        onClose={() => setCustomerMenuOpened(false)}
        position="bottom"
        classNames={{
          content: `${classes.drawerContent} ${
            isDark ? classes.drawerContentDark : ""
          }`,
          body: classes.drawerBody,
          header: classes.drawerHeader,
          close: classes.drawerCloseButton,
        }}
      >
        <CustomerMenuMain />
      </Drawer>

      <Drawer
        opened={colorSchemeMenuOpened}
        onClose={() => setColorSchemeMenuOpened(false)}
        position="bottom"
        size="250px"
        title="Color Scheme"
        classNames={{
          content: `${classes.drawerContentFixedSize} ${
            isDark ? classes.drawerContentDark : ""
          }`,
          body: classes.drawerBody,
          close: classes.drawerCloseButton,
        }}
      >
        <ColorSwitcherMenu />
      </Drawer>
    </>
  );
}
