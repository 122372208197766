import { Box, Stack, Text, Title } from "@mantine/core";
import { AdminLayout } from "./../layouts/AdminLayout.js";

export function AdminNotFound() {
  return (
    <AdminLayout>
      <Box p="xl">
        <Stack justify="center" align="center">
          <Title order={2}>Not found</Title>
          <Text>Page not found</Text>
        </Stack>
      </Box>
    </AdminLayout>
  );
}
