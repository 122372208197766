import { UserEntity } from "../../../common/entities/user.js";
import { userClient } from "../../../common/utils/entityClient.js";
import { clientError } from "../../utils/clientError.js";

export const USER_PROFILE_CACHE = new Map<number, UserEntity>(); // cache user profiles to avoid multiple requests
const USER_PROFILE_FETCH_CACHE = new Map<number, Promise<UserEntity>>();
const USER_PROFILE_RESOLVE_CACHE = new Map<
  number,
  (user: UserEntity) => void
>();

export function addUsersToCache(users: UserEntity[]) {
  users.forEach((user) => {
    const userId = user.id!;
    if (!USER_PROFILE_CACHE.has(userId)) {
      USER_PROFILE_CACHE.set(userId, user);
    }
    if (USER_PROFILE_RESOLVE_CACHE.has(userId)) {
      USER_PROFILE_RESOLVE_CACHE.get(userId)!(user);
    }
  });
}

export function fetchUserCached(
  id: number,
  userData: UserEntity | undefined,
  setUserData: (user: UserEntity) => void
) {
  const fetchUser = async () => {
    const cachedUser = USER_PROFILE_CACHE.get(id);
    if (userData) {
      if (!cachedUser) {
        USER_PROFILE_CACHE.set(id, userData);
      }
      return;
    }
    if (cachedUser) {
      setUserData(cachedUser);
      return;
    }
    if (USER_PROFILE_FETCH_CACHE.has(id)) {
      setUserData(await USER_PROFILE_FETCH_CACHE.get(id)!);
      return;
    }
    USER_PROFILE_FETCH_CACHE.set(
      id,
      new Promise(async (resolve, reject) => {
        USER_PROFILE_RESOLVE_CACHE.set(id, resolve);
        const userResponse = await userClient(clientError).item(id).get();
        if (
          userResponse.success &&
          "data" in userResponse.data &&
          userResponse.data.success
        ) {
          USER_PROFILE_CACHE.set(id, userResponse.data.data);
          setUserData(userResponse.data.data);
          resolve(userResponse.data.data);
        } else {
          reject(userResponse.success ? "No user data" : userResponse.error);
        }
        USER_PROFILE_RESOLVE_CACHE.delete(id);
      })
    );
  };
  fetchUser();
}
