import { ReactNode } from "react";
import { MantineProvider } from "@mantine/core";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { theme } from "../../theme.js";

const debug = false;

interface AsideContentWrapperProps {
  children: ReactNode;
}

/**
 * Wrapper component that ensures aside content has access to the MantineProvider context
 * This prevents the "MantineProvider was not found in component tree" error
 * when rendering Mantine components in the aside panel
 */
export function AsideContentWrapper({ children }: AsideContentWrapperProps) {
  const { serviceProvider, customer, isServiceProviderUser } =
    useActiveStates();

  debug &&
    console.log("AsideContentWrapper rendering with theme:", {
      primaryColor: serviceProvider?.theme_primary_color || "#231F20",
      customerColor: customer?.theme_primary_color || "#231F20",
      isServiceProviderUser,
    });

  // Use the same theme configuration as the main app
  return (
    <MantineProvider
      theme={theme(
        serviceProvider?.theme_primary_color || "#231F20",
        customer?.theme_primary_color || "#231F20",
        isServiceProviderUser
      )}
    >
      {children}
    </MantineProvider>
  );
}
