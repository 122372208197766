import { css } from "../../utils/css.js";

export interface MessageDisplayClasses {
  botBubble: string;
  chatWrapper: string;
  chatWrapperInner: string;
  conversationLink: string;
  focused: string;
  infoText: string;
  messageDisplay: string;
  messageDisplayTimestamp: string;
  textarea: string;
  toolResult: string;
  unread: string;
  userBubble: string;
}

export default css<MessageDisplayClasses>`
  .messageDisplay {
    flex-grow: 1;
    margin-bottom: 20px;
  }

  .messageDisplay.unread {
    background-color: var(--mantine-color-blue-0);
    border: 1px solid var(--mantine-color-blue-2);
  }

  .messageDisplay a {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  }

  .messageDisplayTimestamp {
    color: var(--mantine-color-gray-5);
    font-size: var(--mantine-font-size-sm);
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  .messageDisplay.focused {
    background-color: var(--mantine-color-gray-1);
  }

  .messageDisplay.focused.unread {
    background-color: var(--mantine-color-blue-1);
  }

  .userBubble {
    align-self: flex-end;
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-6)
    );
    padding: 10px 20px;
    border-radius: var(--mantine-radius-md);
    max-width: 70%;
    flex-grow: unset;
  }

  .userBubble .chatWrapper {
    min-width: unset;
  }

  .botBubble {
    align-self: flex-start;
    max-width: 100%;
  }

  .chatWrapper {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    width: 100%;
    overflow-x: auto;
  }

  .chatWrapper div.!mantine-Avatar-root {
    margin-top: 5px;
  }

  .chatWrapper div.!mantine-Tabs-panel > div > *:last-child {
    margin-bottom: 0;
  }

  .chatWrapper > *:first-child,
  .chatWrapper > *:first-child > *:first-child {
    margin-top: 0 !important;
  }

  .chatWrapper > *:last-child {
    margin-bottom: 0;
  }

  .chatWrapperInner {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 100%;
  }

  .infoText {
    text-align: center;
  }

  .textarea {
    width: 100%;
    margin-bottom: var(--mantine-spacing-xs);
  }

  .toolResult {
    background-color: var(--mantine-color-gray-0);
    padding: var(--mantine-spacing-sm);
    border-radius: var(--mantine-radius-sm);
    font-size: var(--mantine-font-size-sm);
    font-family: var(--mantine-font-family-monospace);
    white-space: pre-wrap;
    overflow-x: auto;
    margin: var(--mantine-spacing-xs) 0;
    border: 1px solid var(--mantine-color-gray-2);
  }

  .conversationLink {
    text-decoration: none;
    color: #a0a0a0;
    margin: 0 var(--mantine-spacing-md) var(--mantine-spacing-md);
  }

  .conversationLink:hover {
    color: #d0d0d0;
  }
`;
