import { isLightColor, useMantineTheme } from "@mantine/core";
import { useEffect } from "react";

import { OrganizationEntity } from "../../common/entities/organization.js";
import { DEFAULT_THEME_PRIMARY_COLOR } from "../../common/fields/theme_primary_color.js";

export const useFavicon = (organization: OrganizationEntity) => {
  const theme = useMantineTheme();

  useEffect(() => {
    if (!organization) {
      return;
    }

    const bgColor =
      organization.picture_background_color ||
      organization.theme_primary_color ||
      DEFAULT_THEME_PRIMARY_COLOR;

    if (organization.picture) {
      // Create SVG with background color and image
      createImageFavicon(
        organization.picture,
        bgColor,
        organization.name ?? ""
      );
    } else {
      // Generate SVG with organization's initials
      const initials =
        typeof organization.initials === "string" &&
        organization.initials.length > 0
          ? organization.initials!.substring(0, 2)
          : getInitialsFromName(organization.name ?? "");
      const textColor = isLightColor(bgColor, theme.luminanceThreshold)
        ? "black"
        : "white";
      const svgData = generateSvgFavicon(
        initials.toUpperCase(),
        bgColor,
        textColor
      );
      updateFavicon(svgData);
    }
  }, [organization]);

  // Helper to create favicon with image and background
  const createImageFavicon = (
    imageUrl: string,
    bgColor: string,
    fallbackName: string
  ) => {
    // Create a temporary image to load the organization's picture
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      // Create canvas to generate the combined favicon
      const canvas = document.createElement("canvas");
      const iconSize = 64;
      canvas.width = iconSize;
      canvas.height = iconSize;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        // Draw background square with rounded corners
        ctx.fillStyle = bgColor;
        ctx.beginPath();
        ctx.roundRect(0, 0, iconSize, iconSize, 8);
        ctx.fill();

        // Draw the image centered, maintaining aspect ratio
        const size = Math.max(img.width, img.height);
        const x = (img.width - size) / 2;
        const y = (img.height - size) / 2;

        // Draw the image with a slight padding
        const padding = 4;
        ctx.drawImage(
          img,
          x,
          y,
          size,
          size,
          padding,
          padding,
          iconSize - padding * 2,
          iconSize - padding * 2
        );

        // Update favicon with the combined image
        updateFavicon(canvas.toDataURL("image/png"));
      }
    };

    // Fallback to initials if image fails to load
    img.onerror = () => {
      const initials =
        typeof organization.initials === "string" &&
        organization.initials.length > 0
          ? organization.initials!.substring(0, 2)
          : getInitialsFromName(fallbackName);
      const textColor = isLightColor(bgColor, theme.luminanceThreshold)
        ? "black"
        : "white";
      const svgData = generateSvgFavicon(
        initials.toUpperCase(),
        bgColor,
        textColor
      );
      updateFavicon(svgData);
    };

    img.src = imageUrl;
  };

  // Helper function to update favicon
  const updateFavicon = (faviconUrl: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let link = document.querySelector('link[rel="icon"]') as any;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = faviconUrl;
  };

  // Helper to generate SVG favicon with initials
  const generateSvgFavicon = (
    initials: string,
    bgColor: string,
    textColor: string
  ): string => {
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
        <rect width="32" height="32" fill="${bgColor}" rx="8" ry="8" />
        <text 
          x="16" 
          y="16" 
          font-family="Arial, sans-serif" 
          font-size="17" 
          fill="${textColor}" 
          text-anchor="middle" 
          dominant-baseline="central"
          font-weight="bold"
        >${initials}</text>
      </svg>
    `;
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };

  // Helper to get initials from name
  const getInitialsFromName = (name: string): string => {
    return name
      .split(" ")
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase()
      .substring(0, 2);
  };
};
