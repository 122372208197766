import { Group, Stack, Title } from "@mantine/core";
import { IconFile } from "@tabler/icons-react";

export function StandardEmpty({ title = "No items found" }: { title: string }) {
  return (
    <Group justify="center" h="100%" p="xl" mt="xl">
      <Stack w={400} maw="calc(100dvw - 40px)">
        <Stack gap="md" justify="center" align="center">
          <IconFile size={48} />
          <Title order={3} my="xs">
            {title}
          </Title>
        </Stack>
      </Stack>
    </Group>
  );
}
