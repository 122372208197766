import { useCallback, useEffect, useState } from "react";
import { useActiveStates } from "../../../contexts/ActiveStatesContext.js";
import { ConversationEntity } from "../../../../common/entities/conversation.js";
import { conversationClient } from "../../../../common/utils/entityClient.js";
import { clientError } from "../../../utils/clientError.js";

export function usePennyConversationsList() {
  const [conversations, setConversations] = useState<ConversationEntity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { customer, serviceProvider } = useActiveStates();

  const load = useCallback(async () => {
    // todo: add pagination
    try {
      const conversationsResponse = await conversationClient(clientError).list({
        provider_id: serviceProvider?.id,
        customer_id: customer?.id,
      });
      if (conversationsResponse.success) {
        setConversations(
          conversationsResponse.data.success
            ? (
                (await Promise.all(
                  conversationsResponse.data.data
                )) as ConversationEntity[]
              ).filter((x: ConversationEntity) => x)
            : []
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [customer?.id, serviceProvider?.id]);

  useEffect(() => {
    load().catch((e) => console.error(e));
  }, [load]);

  return {
    conversations,
    isLoading,
  };
}
