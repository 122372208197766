import { accessTokenExpiresAtField } from "../fields/access_token_expires_at";
import { accessTokenExpiresInField } from "../fields/access_token_expires_in";
import { createdAtField } from "../fields/created_at";
import { encryptedAccessTokenField } from "../fields/encrypted_access_token";
import { encryptedRefreshTokenField } from "../fields/encrypted_refresh_token";
import {
  INTEGRATION_QUICKBOOKS,
  integrationField,
} from "../fields/integration";
import { organizationIdField } from "../fields/organization_id";
import { realmIdField } from "../fields/realm_id";
import { refreshTokenExpiresAtField } from "../fields/refresh_token_expires_at";
import { refreshTokenExpiresInField } from "../fields/refresh_token_expires_in";
import { tokenTypeField } from "../fields/token_type";
import { updatedAtField } from "../fields/updated_at";
import { userIdFieldRequired } from "../fields/user_id";
import { EntityDocs } from "../types/docs";
import {
  entity,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";

// Organization Integration Token fields
export const fields = [
  organizationIdField,
  integrationField,
  encryptedAccessTokenField,
  encryptedRefreshTokenField,
  tokenTypeField,
  accessTokenExpiresInField,
  accessTokenExpiresAtField,
  realmIdField,
  refreshTokenExpiresInField,
  refreshTokenExpiresAtField,
  userIdFieldRequired,
  createdAtField,
  updatedAtField,
] satisfies FieldList;

// Organization Integration Token primary key fields
export const primaryKeyFields = [
  organizationIdField,
  integrationField,
  realmIdField,
] satisfies PrimaryKeyFieldTuple;

// Docs
export const docs: EntityDocs = {
  collection: {
    summary:
      "List organization integration tokens, which connect an organization to a third-party provider, like Quickbooks.",
  },
  item: { summary: "Perform operations on an organization integration token." },
};

export const organizationIntegrationTokenEntity = entity({
  api: {
    collectionPath: "organization-integration-tokens",
    itemPath:
      "organization-integration-tokens/:organization_id/:integration/:realm_id",
  },
  docs,
  name: "OrganizationIntegrationToken",
  fields,
  primaryKeyFields,
  tableName: "organization_integration_tokens",
});

export type OrganizationIntegrationTokenEntity = EntityType<
  typeof organizationIntegrationTokenEntity.fields,
  typeof organizationIntegrationTokenEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleOrganizationIntegrationToken: OrganizationIntegrationTokenEntity =
  {
    access_token_expires_at: new Date().toISOString(),
    access_token_expires_in: 3600,
    created_at: new Date().toISOString(),
    encrypted_access_token: "abc123",
    encrypted_refresh_token: "def456",
    integration: INTEGRATION_QUICKBOOKS,
    organization_id: 123,
    realm_id: "abc",
    refresh_token_expires_at: new Date().toISOString(),
    refresh_token_expires_in: 86400,
    token_type: "bearer",
    updated_at: new Date().toISOString(),
    user_id: 543,
  };
