import { useContext, useState } from "react";
import {
  ActionIcon,
  Box,
  Drawer,
  Group,
  Stack,
  UnstyledButton,
  Text,
  Menu,
  useComputedColorScheme,
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";

import { OrganizationEntity } from "../../../../common/entities/organization.js";
import { useActiveStates } from "../../../contexts/ActiveStatesContext.js";
import { AuthContext } from "../../../contexts/AuthContext.js";
import { PrimaryColor } from "../../../theme.js";
import { Navbar } from "../../Navbar/Navbar.js";
import { OrganizationAvatar } from "../../Avatars/OrganizationAvatar.js";
import SettingsMenu from "./SettingsMenu.js";
import SearchBox from "./SearchBox.js";
import PennyConversations from "./PennyConversations.js";
import MobileMenuIcon from "./MobileMenuIcon.js";

import classes from "./MainMenuMobile.css.js";

export default function MainMenuMobile() {
  const { account } = useContext(AuthContext);
  const isDark = useComputedColorScheme() === "dark";

  const [search, setSearch] = useState("");
  const updateSearch = (search: string) => {
    setSearch(search);
  };

  const {
    customer: activeCustomer,
    customers,
    serviceProvider,
    isCustomerUser,
  } = useActiveStates();

  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [settingsMenuOpened, setSettingsMenuOpened] = useState(false);
  // Use active customer if available, otherwise use service provider
  const activeOrganization: OrganizationEntity = activeCustomer?.id
    ? activeCustomer
    : serviceProvider;

  let userName = `${account?.profile.given_name} ${account?.profile.family_name}`;
  if (!userName.trim()) {
    userName = account?.profile.nickname || "Unknown";
  }

  return (
    <>
      <UnstyledButton onClick={() => setMenuOpened((o) => !o)}>
        <OrganizationAvatar
          organization={activeOrganization}
          size={45}
          color={PrimaryColor}
          bg={PrimaryColor}
          src={activeOrganization?.picture}
          hasShadow={(customers?.length || 0) > 1}
        />
      </UnstyledButton>
      <Drawer
        opened={menuOpened}
        onClose={() => setMenuOpened(false)}
        withCloseButton={false}
        size={"85%"}
        classNames={{
          body: classes.drawerBody,
          content: isDark ? classes.drawerContentDark : "",
        }}
      >
        <SearchBox updateSearch={updateSearch} />
        <Box
          px="0"
          style={{
            height: `calc(100dvh - 130px)`,
            overflowY: "auto",
          }}
        >
          <Navbar />
          <Menu>
            <Menu.Label px="sm">Chats</Menu.Label>
          </Menu>
          <PennyConversations
            opened={menuOpened}
            onClose={() => setMenuOpened(false)}
            filter={search}
          />
        </Box>
        <Group
          justify="space-between"
          onClick={() => setSettingsMenuOpened(true)}
          p="sm"
          style={{ position: "fixed", bottom: 0, width: "100%" }}
        >
          <Group gap="sm">
            <OrganizationAvatar
              organization={activeOrganization}
              size={45}
              color={PrimaryColor}
              bg={PrimaryColor}
              src={activeOrganization?.picture}
            />
            <Stack gap={0}>
              <Text fw={700} size="sm">
                {userName}
              </Text>
              <Text size="xs" c="dimmed">
                {account?.profile.email}
              </Text>
            </Stack>
          </Group>
          <ActionIcon variant="default" size="lg" style={{ border: 0 }}>
            <MobileMenuIcon Icon={IconChevronRight} />
          </ActionIcon>
        </Group>
      </Drawer>
      <SettingsMenu
        account={account}
        activeOrganization={activeOrganization}
        serviceProvider={serviceProvider}
        isCustomerUser={isCustomerUser}
        opened={settingsMenuOpened}
        onClose={() => setSettingsMenuOpened(false)}
      />
    </>
  );
}
