import { useAuth } from "../../app/hooks/useAuth";
import {
  EntityFilterType,
  EntityListOptionsType,
} from "../../services/entityService";
import { aiModelEntity } from "../entities/aiModel";
import { aiProviderEntity } from "../entities/aiProvider";
import { conversationEntity } from "../entities/conversation";
import { kvEntity } from "../entities/kv";
import { messageEntity } from "../entities/message";
import { messagesLastReadEntity } from "../entities/messagesLastRead";
import { metricEntity } from "../entities/metric";
import { organizationEntity } from "../entities/organization";
import { organizationIntegrationTokenEntity } from "../entities/organizationIntegrationToken";
import { organizationRelationshipEntity } from "../entities/organizationRelationship";
import { organizationRelationshipUserEntity } from "../entities/organizationRelationshipUser";
import { organizationUserEntity } from "../entities/organizationUser";
import { threadEntity } from "../entities/thread";
import { userEntity } from "../entities/user";
import {
  Entity,
  EntityCreateType,
  EntityPrimaryKeyType,
  EntityServiceResult,
  EntityType,
  EntityUpdateType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";
import { cachedNetwork, network } from "./network";

const debug = false;

function entityClient<T extends FieldList, U extends PrimaryKeyFieldTuple>(
  entity: Entity<T, U>,
  onError: (error: string) => void
) {
  const auth = useAuth();
  const accessToken = auth.session?.accessToken;

  function replaceIds(path: string, ids: EntityPrimaryKeyType<U>) {
    let finalPath = path;
    for (const index in entity.primaryKeyFields) {
      const id = ids[index];
      finalPath = finalPath.replace(
        `:${entity.primaryKeyFields[index].name}`,
        typeof id === "number" ? (id as number).toString(10) : String(id)
      );
    }
    return finalPath;
  }

  return {
    async create(data: EntityCreateType<T, U>) {
      return network.post<EntityServiceResult<EntityType<T, U>>>(
        "/api/" + entity.api.collectionPath,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
        onError
      );
    },
    async itemAtIndex(index: number, filters: EntityFilterType<T, U> = {}) {
      return cachedNetwork.get<EntityServiceResult<EntityType<T, U>>>(
        "/api/" + entity.api.collectionPath + "/.at-index",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            index,
            filters: Object.keys(filters).length
              ? JSON.stringify(filters)
              : undefined,
          },
        },
        onError
      );
    },
    async length(filters: EntityFilterType<T, U> = {}) {
      return cachedNetwork.get<EntityServiceResult<number>>(
        "/api/" + entity.api.collectionPath,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            filters: Object.keys(filters).length
              ? JSON.stringify(filters)
              : undefined,
            options: JSON.stringify({ return: "length" }),
          },
        },
        onError
      );
    },
    async list<
      V extends FieldList = FieldList,
      W extends PrimaryKeyFieldTuple = PrimaryKeyFieldTuple,
      V2 extends FieldList = FieldList,
      W2 extends PrimaryKeyFieldTuple = PrimaryKeyFieldTuple
    >(
      filters: EntityFilterType<T, U> = {},
      options: EntityListOptionsType<T, U, V, W, V2, W2> = {},
      range: { from?: number; to?: number } = {}
    ) {
      return cachedNetwork.get<EntityServiceResult<EntityType<T, U>[]>>(
        "/api/" + entity.api.collectionPath,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            filters: Object.keys(filters).length
              ? JSON.stringify(filters)
              : undefined,
            options: Object.keys(options).length
              ? JSON.stringify(options)
              : undefined,
            range: Object.keys(range).length
              ? JSON.stringify(range)
              : undefined,
          },
        },
        onError
      );
    },
    item(...ids: EntityPrimaryKeyType<U>) {
      const itemPath = replaceIds("/api/" + entity.api.itemPath, ids)
        .split("/")
        .map((x) => encodeURIComponent(x))
        .join("/");
      return {
        async delete() {
          return network.delete<EntityServiceResult<EntityType<T, U>>>(
            itemPath,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
            onError
          );
        },
        async get(filters?: EntityFilterType<T, U>) {
          debug && console.log("get", filters);
          return cachedNetwork.get<EntityServiceResult<EntityType<T, U>>>(
            itemPath,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              params: {
                filters:
                  typeof filters === "object" &&
                  filters &&
                  Object.keys(filters).length
                    ? JSON.stringify(filters)
                    : undefined,
              },
            },
            onError
          );
        },
        async update(data: Partial<EntityUpdateType<T, U>>) {
          return network.patch<EntityServiceResult<EntityType<T, U>>>(
            itemPath,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            },
            onError
          );
        },
        async upsert(data: Partial<EntityUpdateType<T, U>>) {
          return network.put<EntityServiceResult<EntityType<T, U>>>(
            itemPath,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            },
            onError
          );
        },
      };
    },
  };
}

// Be created, 'O' entity clients, and serve ye well the purpose of connecting front end
// user interface to the api for each entity and prosper.

export const aiModelClient = (onError: (error: string) => void) =>
  entityClient(aiModelEntity, onError);

export const aiProviderClient = (onError: (error: string) => void) =>
  entityClient(aiProviderEntity, onError);

export const conversationClient = (onError: (error: string) => void) =>
  entityClient(conversationEntity, onError);

export const kvClient = (onError: (error: string) => void) =>
  entityClient(kvEntity, onError);

export const messageClient = (onError: (error: string) => void) =>
  entityClient(messageEntity, onError);

export const metricClient = (onError: (error: string) => void) =>
  entityClient(metricEntity, onError);

export const messagesLastReadClient = (onError: (error: string) => void) =>
  entityClient(messagesLastReadEntity, onError);

export const organizationClient = (onError: (error: string) => void) =>
  entityClient(organizationEntity, onError);

export const organizationIntegrationTokenClient = (
  onError: (error: string) => void
) => entityClient(organizationIntegrationTokenEntity, onError);

export const organizationRelationshipClient = (
  onError: (error: string) => void
) => entityClient(organizationRelationshipEntity, onError);

export const organizationRelationshipUserClient = (
  onError: (error: string) => void
) => entityClient(organizationRelationshipUserEntity, onError);

export const organizationUserClient = (onError: (error: string) => void) =>
  entityClient(organizationUserEntity, onError);

export const threadClient = (onError: (error: string) => void) =>
  entityClient(threadEntity, onError);

export const userClient = (onError: (error: string) => void) =>
  entityClient(userEntity, onError);

/** Example usage * /
async function example() {
  const createResponse = await organizationClient.create({
    name: "New Organization",
    entitlements: [],
    initials: "NO",
    slug: "new-organization",
    description: "This is a new organization",
  });

  if ("data" in createResponse && createResponse.data) {
    console.log(createResponse.data);
  }

  const organization = await organizationClient.item(1).get();

  if ("data" in organization && organization.data) {
    console.log(organization.data);
  }

  const updatedOrganization = await organizationClient.item(1).update({
    name: "Updated Organization",
  });

  if ("data" in updatedOrganization && updatedOrganization.data) {
    console.log(updatedOrganization.data);
  }

  const deletedOrganization = await organizationClient.item(1).delete();

  if (deletedOrganization.success) {
    console.log("Organization deleted");
  }

  const organizations = await organizationClient.list();

  if ("data" in organizations && organizations.data) {
    console.log(organizations.data);
  }
}
/**/
