import { Box, Stack, Text } from "@mantine/core";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import {
  ENTITLEMENT_IS_SERVICE_PROVIDER,
  OrganizationEntitlementsCustomer,
  OrganizationEntitlementsServiceProvider,
} from "../../../common/fields/entitlements.js";

import {
  OrganizationEntitlement,
  UpdateEntitlements,
} from "./OrganizationEntitlement.js";

export function OrganizationEntitlements({
  organization,
  onUpdateEntitlements,
}: {
  organization: OrganizationEntity;
  onUpdateEntitlements: UpdateEntitlements;
}) {
  const isServiceProvider = (
    organization.entitlements as unknown as number[]
  )?.includes(ENTITLEMENT_IS_SERVICE_PROVIDER);

  const entitlements = isServiceProvider
    ? OrganizationEntitlementsServiceProvider
    : OrganizationEntitlementsCustomer;

  return (
    <Box px="xl">
      <Stack gap="xs">
        <Text fw={500} size="lg">
          Organization Entitlements
        </Text>
        <Text size="sm" c="dimmed">
          Product features that your organization has access to
        </Text>
      </Stack>

      {entitlements.map((entitlementId) => {
        return (
          <OrganizationEntitlement
            key={entitlementId}
            organization={organization}
            isServiceProvider={isServiceProvider}
            entitlementId={entitlementId}
            onUpdateEntitlements={onUpdateEntitlements}
          />
        );
      })}
    </Box>
  );
}
