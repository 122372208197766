import { Button, Group, Modal, Stack, TextInput } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";

import { FileItem } from "./types.js";

interface RenameModalProps {
  opened: boolean;
  onClose: () => void;
  item: FileItem | null;
  newName: string;
  onNameChange: (name: string) => void;
  onRename: () => void;
  isRenaming: boolean;
}

/**
 * Modal for renaming a file or folder
 */
export function RenameModal({
  opened,
  onClose,
  item,
  newName,
  onNameChange,
  onRename,
  isRenaming,
}: RenameModalProps) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={`Rename ${item?.type || "item"}`}
      size="sm"
    >
      <Stack>
        <TextInput
          label="New name"
          placeholder="Enter new name"
          value={newName}
          onChange={(e) => onNameChange(e.currentTarget.value)}
          data-autofocus
        />
        <Group justify="flex-end">
          <Button variant="subtle" onClick={onClose} disabled={isRenaming}>
            Cancel
          </Button>
          <Button
            onClick={onRename}
            loading={isRenaming}
            leftSection={<IconEdit size={16} />}
          >
            Rename
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
