import { css } from "../../utils/css.js";

interface ClassNames {
  isSuperAdmin: "isSuperAdmin";
  loadingEntitlements: "loadingEntitlements";
  manageEntitlements: "manageEntitlements";
  superAdmin: "superAdmin";
  table: "table";
}

export default css<ClassNames>`
  .manageEntitlements {
    background-color: var(--mantine-color-org_primary-9);
    border-radius: 4px;
    border: 1px solid var(--mantine-color-org_primary-6);
    top: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: space-between;
    overflow: hidden;
    padding: 4px;
    right: 100%;
    white-space: nowrap;
  }

  .manageEntitlements > select {
    width: 80px;
  }

  .loadingEntitlements {
    align-items: center;
    background-color: #80808080;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .isSuperAdmin select {
    background-color: var(--mantine-color-yellow-9) !important;
  }

  .superAdmin {
    background-color: var(--mantine-color-org_primary-9);
    border-radius: 4px;
    border: 1px solid var(--mantine-color-org_primary-6);
    padding: 16px;
    position: relative;
  }

  .superAdmin::before {
    color: var(--mantine-color-yellow-9);
    font-weight: bold;
    content: "SUPER ADMIN";
    position: absolute;
    right: 5px;
    bottom: 0px;
    border-radius: 4px;
  }
`;
