import type { DrawerProps } from "@mantine/core";
import { ActionIcon, Drawer, Menu, rem, Text, ThemeIcon } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  IconBuilding,
  IconLink,
  IconPlayerPlay,
  IconUserSquare,
} from "@tabler/icons-react";

import { ENTITLEMENT_VIEW_WORKFLOWS } from "../../../common/fields/entitlements.js";
import { useOrganizationUserHasEntitlement } from "../../contexts/ActiveStatesContext.js";
import { useResponsiveDesign } from "../../hooks/useResponsiveDesign.js";

import classes from "../Menus/MobileMenu/SettingsMenu.css.js";
import classesLinks from "./Navbar.css.js";

export default function MoreOptions({
  linkLabel,
  active,
  isLedgerAI,
  isServiceProvider,
  IconComponent,
  subtitle,
}: {
  linkLabel: string;
  active: string;
  isLedgerAI: boolean;
  isServiceProvider: boolean;
  IconComponent: React.ElementType;
  subtitle: string;
}) {
  const [moreMenuOpened, setMoreMenuOpened] = useState(false);
  const { isAboveSmall } = useResponsiveDesign();
  const navigate = useNavigate();

  const hasEntitlement = useOrganizationUserHasEntitlement();
  const has_entitlement_view_workflows = hasEntitlement(
    ENTITLEMENT_VIEW_WORKFLOWS
  );

  const ContentComponent = isAboveSmall ? Menu.Dropdown : Drawer;
  const contentProps = isAboveSmall
    ? {}
    : {
        opened: moreMenuOpened,
        onClose: () => setMoreMenuOpened(false),
        position: "bottom" as DrawerProps["position"],
        size: "50%",
        title: "More",
        classNames: {
          content: classes.drawerContent,
          body: classes.drawerBody,
        },
      };

  if (typeof isAboveSmall === "undefined") {
    return null;
  }

  return (
    <Menu key={linkLabel} withArrow shadow="md" position="right">
      {isAboveSmall && (
        <Menu.Target>
          <ActionIcon
            onClick={() => setMoreMenuOpened(!moreMenuOpened)}
            variant={linkLabel === active ? "light" : "default"}
            className={`${classesLinks.mainLink}`}
            size="xl"
            data-active={linkLabel === active || undefined}
          >
            <IconComponent style={{ width: rem(27), height: rem(27) }} />
          </ActionIcon>
        </Menu.Target>
      )}
      {!isAboveSmall && (
        <Menu.Item
          onClick={() => setMoreMenuOpened(!moreMenuOpened)}
          px="sm"
          py="xs"
          style={{
            backgroundColor: "transparent",
          }}
          className={`${classesLinks.mainLink}`}
          leftSection={
            <ThemeIcon
              variant={linkLabel === active ? "light" : "default"}
              className={classesLinks.mainLink}
              size="sm"
            >
              <IconComponent />
            </ThemeIcon>
          }
        >
          {linkLabel}
          <Text size="xs">{subtitle}</Text>
        </Menu.Item>
      )}
      <ContentComponent
        onClose={contentProps.onClose ?? (() => {})}
        opened={contentProps.opened ?? false}
        {...contentProps}
      >
        {false /* Automations are temporarily removed */ &&
        has_entitlement_view_workflows ? (
          <Menu.Item
            onClick={() => navigate("/recipes/overview")}
            leftSection={
              <IconPlayerPlay
                style={isAboveSmall ? { width: rem(27), height: rem(27) } : {}}
              />
            }
          >
            Automations
            <Text size="xs">Create and find workflows</Text>
          </Menu.Item>
        ) : null}
        {has_entitlement_view_workflows ? (
          <Menu.Item
            onClick={() => navigate("/connect/overview")}
            leftSection={
              <IconLink
                style={isAboveSmall ? { width: rem(27), height: rem(27) } : {}}
              />
            }
          >
            Apps & Integrations
            <Text size="xs">
              Access knowledge and data from external sources
            </Text>
          </Menu.Item>
        ) : null}
        {isServiceProvider ? (
          <Menu.Item
            onClick={() => navigate("/clients/overview")}
            leftSection={
              <IconBuilding
                style={isAboveSmall ? { width: rem(27), height: rem(27) } : {}}
              />
            }
          >
            {isLedgerAI ? "Service Providers" : "Clients"}
            <Text size="xs">
              {isLedgerAI ? "All service providers" : "Your clients"} and their
              settings
            </Text>
          </Menu.Item>
        ) : null}
        <Menu.Item
          onClick={() => navigate("/people/overview")}
          leftSection={
            <IconUserSquare
              style={isAboveSmall ? { width: rem(27), height: rem(27) } : {}}
            />
          }
        >
          People
          <Text size="xs">Your team and their settings</Text>
        </Menu.Item>
      </ContentComponent>
    </Menu>
  );
}
