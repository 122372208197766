import { Box, Loader, Stack, Text } from "@mantine/core";

export function StandardLoader({ title }: { title: string }) {
  return (
    <Box p="xs" ta="center" mt="xl">
      <Stack gap={0} justify="center" align="center">
        <Loader type="dots" color="var(--mantine-color-org_primary-3)" />
        <Text>{title}</Text>
      </Stack>
    </Box>
  );
}
