import { FilePart, ImagePart, TextPart } from "ai";
import { ConversationEntity } from "../entities/conversation.js";
import { MessageEntity } from "../entities/message.js";
import { messageWithDefaultSwaps } from "../entities/messageLibrary.js";
import { OrganizationEntity } from "../entities/organization.js";
import { ThreadEntity } from "../entities/thread.js";
import { UserEntity } from "../entities/user.js";
import { Entity } from "../types/entity.js";
import { messageClient, threadClient } from "../utils/entityClient.js";

export type Conversation = {
  created_at: Date;
  id: number | "new";
  threads_loading?: boolean;
  threads: Thread[];
  title: string;
  updated_at: Date;
};

export type Thread = {
  conversation_id: number;
  created_at: Date;
  id: number;
  messages_loading?: boolean;
  messages?: Message[];
  parent_message_id?: number;
  parent_thread_id?: number;
  updated_at: Date;
};

export type MessageRole = "user" | "data" | "assistant" | "system";

export interface MessageAttachment {
  url: string;
  name: string;
  size: number;
  mimeType?: string;
}

export interface MessageData {
  role?: MessageRole;
  text?: string;
  attachments?: MessageAttachment[];
}

export type Message = {
  content: string | (FilePart | TextPart | ImagePart)[];
  conversation_id?: number;
  id: string;
  role: MessageRole;
  thread_id?: number;
  timestamp?: Date;
  user_id?: number;
  data?: {
    text?: string;
    attachments?: MessageAttachment[];
  };
};

export async function toConversation(
  clientError: (e: string) => void,
  conversation: ConversationEntity,
  customer?: OrganizationEntity,
  serviceProvider?: OrganizationEntity
): Promise<Conversation> {
  const threadsResponse = await threadClient(clientError).list({
    conversation_id: conversation.id!,
    customer_id: customer?.id,
    provider_id: serviceProvider?.id,
  });
  const threads =
    threadsResponse.success && threadsResponse.data.success
      ? await Promise.all(
          threadsResponse.data.data.map((thread) =>
            toThreadWithMessages(clientError, thread)
          )
        )
      : [];
  return {
    id: conversation.id!,
    title: conversation.title!,
    created_at: new Date(conversation.created_at!),
    updated_at: new Date(conversation.updated_at!),
    threads,
  };
}

export async function toThread(thread: ThreadEntity): Promise<Thread> {
  return {
    id: thread.id!,
    conversation_id: thread.conversation_id!,
    parent_message_id: thread.parent_message_id,
    parent_thread_id: thread.parent_thread_id,
    created_at: new Date(thread.created_at!),
    updated_at: new Date(thread.updated_at!),
  };
}

export async function toThreadWithMessages(
  clientError: (e: string) => void,
  thread: ThreadEntity
): Promise<Thread> {
  const messages = await messageClient(clientError).list({
    thread_id: thread.id!,
  });
  return {
    id: thread.id!,
    conversation_id: thread.conversation_id!,
    parent_message_id: thread.parent_message_id,
    parent_thread_id: thread.parent_thread_id,
    created_at: new Date(thread.created_at!),
    updated_at: new Date(thread.updated_at!),
    messages:
      messages.success && messages.data.success
        ? messages.data.data.map(toMessage)
        : [],
  };
}

function toMessage(message: MessageEntity): Message {
  const { data } = message as MessageEntity & {
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      item?: Entity<any, any>;
      organization: OrganizationEntity;
      role?: MessageRole;
      text?: string;
      user: UserEntity;
      attachments?: MessageAttachment[];
    };
  };
  return {
    conversation_id: message.conversation_id!,
    thread_id: message.thread_id!,
    id: message.id!.toString(10),
    content: messageWithDefaultSwaps(
      message.message!,
      data.user,
      data.organization,
      data.text,
      data.item
    ),
    role: (data as { role: MessageRole }).role,
    timestamp:
      (message.timestamp ? new Date(message.timestamp as string) : undefined) ??
      new Date(),
    user_id: message.user_id,
    data: {
      attachments: data.attachments,
    },
  };
}
