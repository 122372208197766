import { useCallback } from "react";

import { useActiveStates } from "../contexts/ActiveStatesContext.js";

export function useConfirmWithModal(): (message: string) => Promise<boolean> {
  const { showConfirmModal } = useActiveStates();

  return useCallback(
    (message: string) =>
      new Promise<boolean>((resolve) => {
        showConfirmModal(message, resolve);
      }),
    [showConfirmModal]
  );
}
