import { Divider, ScrollArea, Stack, TextInput, Title } from "@mantine/core";
import { useState } from "react";
import { AdminLayout } from "../layouts/AdminLayout.js";
import { AdminUsersList } from "./AdminUsersList.js";

export function AdminUsers() {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <AdminLayout>
      <Stack p="sm">
        <Title order={2} p="xs">
          Users
        </Title>
        <TextInput
          placeholder="Search by name, email or organization name"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.currentTarget.value)}
          mb="xs"
        />
      </Stack>
      <Divider />
      <ScrollArea h={`calc(100vh - 200px)`}>
        <AdminUsersList searchQuery={searchQuery} />
      </ScrollArea>
    </AdminLayout>
  );
}
