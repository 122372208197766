import { Tabs } from "@mantine/core";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { ENTITLEMENT_MANAGE_ORGANIZATION_MEMBERS } from "../../../common/fields/entitlements.js";
import { OrganizationRelationshipUserManagement } from "../../components/Admin/OrganizationRelationshipUserManagement.js";
import { UserManagement } from "../../components/Admin/UserManagement.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import {
  useActiveStates,
  useOrganizationUserHasEntitlement,
} from "../../contexts/ActiveStatesContext.js";

export function PeopleOverview() {
  const { customer, serviceProvider, isCustomerUser } = useActiveStates();

  // Use customer if available, otherwise use service provider
  const activeOrganization: OrganizationEntity | undefined = customer?.id
    ? customer
    : serviceProvider;

  const isCustomerProviderRelationshipView =
    customer && serviceProvider && customer.id !== serviceProvider.id;

  const hasEntitlement = useOrganizationUserHasEntitlement();
  const has_entitlement_manage_organization_members = hasEntitlement(
    ENTITLEMENT_MANAGE_ORGANIZATION_MEMBERS
  );

  return (
    <Layout>
      <PageTitle title="Organization Profile" />
      <Tabs defaultValue="users">
        <Tabs.List>
          <Tabs.Tab value="users">
            {isCustomerUser
              ? "Your Team"
              : `The ${customer?.name || serviceProvider.name} Team`}
          </Tabs.Tab>
          {isCustomerProviderRelationshipView && (
            <Tabs.Tab value="accountants">
              {isCustomerUser
                ? `Your ${serviceProvider?.name} Team`
                : `The ${serviceProvider?.name} Service Team`}
            </Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panel value="users">
          <UserManagement
            serviceProvider={serviceProvider}
            customer={customer}
            hasManageEntitlement={has_entitlement_manage_organization_members}
            organizationId={activeOrganization.id!}
          />
        </Tabs.Panel>
        {isCustomerProviderRelationshipView && (
          <Tabs.Panel value="accountants">
            <OrganizationRelationshipUserManagement
              serviceProvider={serviceProvider}
              customer={customer}
              isCustomerUser={isCustomerUser}
              hasManageEntitlement={has_entitlement_manage_organization_members}
            />
          </Tabs.Panel>
        )}
      </Tabs>
    </Layout>
  );
}
