import { StringField } from "../types/field";

export const DEFAULT_THEME_PRIMARY_COLOR = "#228BE6";

export const themePrimaryColorField: StringField<{
  name: "theme_primary_color";
}> = {
  label: "Primary Color",
  name: "theme_primary_color",
  type: "string",
};
