import {
  Anchor,
  Box,
  Button,
  Group,
  List,
  Table,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { Fragment, useEffect, useState } from "react";

import type {
  APIDocumentation,
  HttpMethod,
} from "../../../common/types/apiDocumentation.js";

import { entitiesByName } from "../../../common/entities/entitiesByName.js";
import { lastUpdated } from "../../../common/last-updated.js";
import { network } from "../../../common/utils/network.js";
import { StandardError } from "../../components/Standard/StandardError.js";
import { StandardLoader } from "../../components/Standard/StandardLoader.js";
import { MarkdownRenderer } from "../../pages/penny/MarkdownRenderer.js";
import { clientError } from "../../utils/clientError.js";
import { AdminLayout } from "../layouts/AdminLayout.js";
import { RouteAccessControlSummary } from "./RouteAccessControlSummary.js";

import classes from "./APIDocumentation.css.js";

export function APIAccessControl() {
  const [doc, setDoc] = useState<APIDocumentation | null>(null);
  const [reload, setReload] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | string | null>(null);

  useEffect(() => {
    async function load() {
      try {
        setIsLoading(true);
        setError(null);
        const docResponse = await network.get<
          | {
              success: true;
              data: APIDocumentation;
            }
          | { success: false; error: string }
        >(`/api/docs/api`, {}, clientError);
        if (docResponse.success) {
          if (docResponse.data && docResponse.data.success) {
            setDoc(docResponse.data.data);
          } else {
            setError(docResponse.data.error ?? "No data returned");
          }
        } else {
          setError(docResponse.error ?? "No data returned");
        }
      } catch (e: any) {
        console.error(e);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }
    load().catch((e) => console.error(e));
  }, [reload]);

  return (
    <AdminLayout>
      {isLoading ? (
        <StandardLoader title="Loading documentation" />
      ) : (
        <>
          {error && (
            <StandardError error={error} title="Error loading documentation" />
          )}
          <Group className={classes.printHidden}>
            <Tooltip label="Reload documentation" position="left" withArrow>
              <Button
                disabled={isLoading}
                size="sm"
                variant="outline"
                style={{
                  bottom: "11px",
                  flexShrink: "0",
                  paddingInline: "4px",
                  position: "fixed",
                  right: "24px",
                  zIndex: 30001,
                }}
                onClick={() => setReload((x) => x + 1)}
              >
                <IconRefresh />
              </Button>
            </Tooltip>
          </Group>
          {doc ? <APIAccessControlRenderer doc={doc} /> : null}
        </>
      )}
    </AdminLayout>
  );
}

function smoothScrollToId(id: string) {
  return (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      (element.firstElementChild ?? element).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };
}

function APIAccessControlRenderer({ doc }: { doc: APIDocumentation }) {
  const accessControlledRouteMethods = Object.values(doc.routes).reduce(
    (acc, routes) => {
      return (
        acc +
        routes.reduce(
          (acc, route) =>
            acc +
            route.methods.filter(
              (k) => route.accessControl[k as HttpMethod]?.result !== undefined
            ).length,
          0
        )
      );
    },
    0
  );

  const totalRouteMethods = Object.values(doc.routes).reduce((acc, routes) => {
    return acc + routes.reduce((acc, route) => acc + route.methods.length, 0);
  }, 0);

  const fractionOfRouteMethodsWithAccessControl =
    accessControlledRouteMethods / totalRouteMethods;

  return (
    <>
      <Box id="toc">
        <Title order={1} px="md" mt="lg">
          LedgerAI API Documentation
        </Title>
        <Text px="md" mt="lg">
          This documentation was last updated on{" "}
          {new Date(lastUpdated * 1000).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
          .
        </Text>
        <Text px="md" mt="lg">
          Access control is applied to {accessControlledRouteMethods} (
          {Math.round(fractionOfRouteMethodsWithAccessControl * 100)}%) of the{" "}
          {totalRouteMethods} routes.
        </Text>
        <Title order={4} px="md" mt="lg">
          Table of Contents
        </Title>
        <List py="xs" px="lg">
          <List.Item>
            <Anchor
              href="#access-control-summary"
              onClick={smoothScrollToId("access-control-summary")}
            >
              Access Control Summary
            </Anchor>
          </List.Item>
          <List py="xs" px="lg">
            {Object.keys(doc.routes).map((group) => (
              <List.Item key={group}>
                <Anchor
                  href={`#entity--${group.replace(/\s+/g, "")}`}
                  onClick={smoothScrollToId(
                    `entity--${group.replace(/\s+/g, "")}`
                  )}
                >
                  {group}
                </Anchor>
              </List.Item>
            ))}
          </List>
        </List>
      </Box>
      <Box id="access-control-summary">
        <Title order={2} px="md" mt="xl" mb="lg">
          Access Control Summary
        </Title>
        <Table highlightOnHover>
          {Object.entries(doc.routes).map(([group, routes]) => (
            <Fragment key={group}>
              <Table.Thead>
                <Table.Tr
                  id={group}
                  style={{ borderTop: `1px solid var(--mantine-color-gray-2)` }}
                >
                  <Table.Td colSpan={4}>
                    <Text c="dimmed" size="sm" tt="uppercase" mb={0} pt="xl">
                      ENTITY
                    </Text>

                    <Title
                      order={3}
                      pb="md"
                      id={`entity--${group.replace(/\s+/g, "")}`}
                    >
                      🗃️ {group}
                    </Title>
                  </Table.Td>
                  <Table.Td colSpan={1} />
                </Table.Tr>
                <Table.Tr>
                  <Table.Th>
                    <Text size="sm" fw={600}>
                      Method
                    </Text>
                  </Table.Th>
                  <Table.Th style={{ width: "240px" }}>
                    <Text size="sm" fw={600}>
                      URL
                    </Text>
                  </Table.Th>
                  <Table.Th>
                    <Text size="sm" fw={600}>
                      Title
                    </Text>
                  </Table.Th>
                  <Table.Th style={{ minWidth: "360px" }}>
                    <Text size="sm" fw={600}>
                      Access Control
                    </Text>
                  </Table.Th>
                  <Table.Th>
                    <Text size="sm" fw={600}></Text>
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {routes.map((route) =>
                  route.methods.map((method) => (
                    <Table.Tr key={`${route.path}-${method}`}>
                      <Table.Td>
                        <Text>{method}</Text>
                      </Table.Td>
                      <Table.Td style={{ width: "240px" }}>
                        <Text>
                          <code
                            style={{
                              fontSize: "0.9em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              wordBreak: "break-all",
                            }}
                          >
                            ./{route.path}
                          </code>
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{route.title}</Text>
                        {typeof route.description[method] === "string" && (
                          <Text size="sm" c="dimmed">
                            <MarkdownRenderer
                              content={route.description[method]}
                            />
                          </Text>
                        )}
                      </Table.Td>
                      <Table.Td>
                        <RouteAccessControlSummary
                          accessControl={route.accessControl[method]}
                          forEntity={
                            entitiesByName[group as keyof typeof entitiesByName]
                          }
                        />
                      </Table.Td>
                      <Table.Td>
                        <Text> </Text>
                      </Table.Td>
                    </Table.Tr>
                  ))
                )}
              </Table.Tbody>
            </Fragment>
          ))}
        </Table>
      </Box>
    </>
  );
}
