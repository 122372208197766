import { FormEvent, useState } from "react";
import { Badge, Group, Loader, Stack, Table, Text } from "@mantine/core";

import { OrganizationUserEntity } from "../../../common/entities/organizationUser.js";
import { getUserName, UserEntity } from "../../../common/entities/user.js";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import {
  OrganizationUserEntitlementLabels,
  OrganizationUserEntitlements,
} from "../../../common/fields/entitlements.js";
import { isSuperAdmin } from "../../../services/accessControl/superAdmin.js";

import { UserAvatar } from "../../components/Avatars/UserAvatar.js";
import { OrganizationAvatar } from "../../components/Avatars/OrganizationAvatar.js";

import classes from "./AdminUsersListItem.css.js";

export function AdminUserListItem(
  orgUser: OrganizationUserEntity,
  user: UserEntity,
  organization: OrganizationEntity,
  addEntitlement: (entitlement: number) => Promise<void>,
  removeEntitlement: (entitlement: number) => Promise<void>
) {
  return (
    <Table.Tr
      key={`${orgUser.organization_id}-${user.id}`}
      className={isSuperAdmin(user) ? classes.isSuperAdmin : undefined}
    >
      <Table.Td>
        <Group
          gap="xs"
          style={{ "--group-wrap": "nowrap" }}
          className={isSuperAdmin(user) ? classes.superAdmin : undefined}
        >
          <UserAvatar size={45} user={user} />
          <Stack gap={0}>
            <Text fw={700}>{getUserName(user)}</Text>
            <Text size="sn" c="dimmed">
              {user.email}
            </Text>
          </Stack>
        </Group>
      </Table.Td>
      <Table.Td>
        <Group gap="xs" style={{ "--group-wrap": "nowrap" }}>
          <OrganizationAvatar size={45} organization={organization} />
          <Stack gap={0}>
            <Text fw={700}> {organization.name}</Text>
            <Text c="dimmed" size="sm" fw={500}>
              @{organization.slug}
            </Text>
          </Stack>
        </Group>
      </Table.Td>
      <UserEntitlementManagement
        orgUser={orgUser}
        addEntitlement={addEntitlement}
        removeEntitlement={removeEntitlement}
      />
    </Table.Tr>
  );
}

function UserEntitlementManagement({
  orgUser,
  addEntitlement,
  removeEntitlement,
}: {
  orgUser: OrganizationUserEntity;
  addEntitlement: (entitlement: number) => Promise<void>;
  removeEntitlement: (entitlement: number) => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const existingEntitlements = Array.isArray(
    orgUser.entitlements! as unknown as number[] | undefined
  )
    ? (orgUser.entitlements! as unknown as number[]).sort()
    : [];

  const handleAddEntitlement = async (event: FormEvent<HTMLSelectElement>) => {
    const entitlement = Number(event.currentTarget.value);
    setLoading(true);
    setError(null);
    try {
      await addEntitlement(entitlement);
    } catch (error: any) {
      setError(error.message ?? error ?? "Unknown error");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveEntitlement = async (
    event: FormEvent<HTMLSelectElement>
  ) => {
    const entitlement = Number(event.currentTarget.value);
    setLoading(true);
    setError(null);
    try {
      await removeEntitlement(entitlement);
    } catch (error: any) {
      setError(error.message ?? error ?? "Unknown error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Table.Td>
        {loading ? (
          <div className={classes.loadingEntitlements}>
            <Loader type="dots" color="var(--mantine-color-org_primary-3)" />
          </div>
        ) : null}

        {existingEntitlements.length > 0 ? (
          existingEntitlements.map((id) => (
            <Badge key={id} variant="light" m={5}>
              {OrganizationUserEntitlementLabels[
                id as keyof typeof OrganizationUserEntitlementLabels
              ] ?? id.toString(10)}
            </Badge>
          ))
        ) : (
          <Text size="sm" c="dimmed">
            None
          </Text>
        )}
      </Table.Td>
      <Table.Td ta="right">
        <Group
          className={classes.manageEntitlements}
          style={{ "--group-wrap": "nowrap", gap: "4px" }}
        >
          {error && (
            <Text size="xs" c="#f44141">
              {error}
            </Text>
          )}
          <select
            disabled={loading || existingEntitlements.length === 0}
            value=""
            onInput={handleRemoveEntitlement}
          >
            <option disabled value="">
              Remove
            </option>
            {OrganizationUserEntitlements.filter((id) =>
              existingEntitlements.includes(id)
            ).map((id) => (
              <option key={id} value={id.toString(10)}>
                {OrganizationUserEntitlementLabels[
                  id as keyof typeof OrganizationUserEntitlementLabels
                ] ?? id.toString(10)}
              </option>
            ))}
          </select>
          <select
            disabled={
              loading ||
              existingEntitlements.length ===
                OrganizationUserEntitlements.length
            }
            value=""
            onInput={handleAddEntitlement}
          >
            <option disabled value="">
              Add
            </option>
            {OrganizationUserEntitlements.filter(
              (id) => !existingEntitlements.includes(id)
            ).map((id) => (
              <option key={id} value={id.toString(10)}>
                {OrganizationUserEntitlementLabels[
                  id as keyof typeof OrganizationUserEntitlementLabels
                ] ?? id.toString(10)}
              </option>
            ))}
          </select>
        </Group>
      </Table.Td>
    </>
  );
}
