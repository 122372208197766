import { useEffect, useState } from "react";
import { Table, Text } from "@mantine/core";

import { AIModelEntity } from "../../../common/entities/aiModel.js";
import { AIProviderEntity } from "../../../common/entities/aiProvider.js";
import {
  aiModelClient,
  aiProviderClient,
} from "../../../common/utils/entityClient.js";
import { StandardError } from "../../components/Standard/StandardError.js";
import { StandardLoader } from "../../components/Standard/StandardLoader.js";
import { clientError } from "../../utils/clientError.js";
import { AdminAIProviderListItem } from "./AdminAIProviderListItem.js";

export function AdminAIProvidersList() {
  const [aiModels, setAIModels] = useState<AIModelEntity[]>([]);
  const [aiProviders, setAIProviders] = useState<AIProviderEntity[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(function () {
    async function load() {
      try {
        const listModelsResponse = await aiModelClient(clientError).list();
        if (listModelsResponse.success) {
          if (listModelsResponse.data.success) {
            setAIModels(listModelsResponse.data.data as AIModelEntity[]);
          }
        }
        const listProvidersResponse = await aiProviderClient(
          clientError
        ).list();
        if (listProvidersResponse.success) {
          if (listProvidersResponse.data.success) {
            setAIProviders(
              listProvidersResponse.data.data as AIProviderEntity[]
            );
          }
        }
      } catch (e: any) {
        console.error(e);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }
    load().catch((e) => console.error(e));
  }, []);

  if (isLoading) {
    return <StandardLoader title="Loading AI Providers and Models" />;
  }

  if (error) {
    return (
      <StandardError
        error={error}
        title="Error loading AI Providers and Models"
      />
    );
  }

  return (
    <Table>
      <Table.Tbody>
        <Table.Tr>
          <Table.Th>
            <Text p="xs" fw={600} w={240}>
              Provider Name
            </Text>
          </Table.Th>
          <Table.Th>
            <Text p="xs" fw={600}>
              Available Models
            </Text>
          </Table.Th>
        </Table.Tr>
        {aiProviders.map((p) => AdminAIProviderListItem(p, aiModels))}
      </Table.Tbody>
    </Table>
  );
}
