import { Modal } from "@mantine/core";
import { useState } from "react";
import { knownFeatures } from "../config/features.js";
import { useFeaturePanel } from "./useFeaturePanel.js";

export const useConfigureModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const panel = useFeaturePanel(knownFeatures);

  const configureModal = (
    <Modal
      opened={isOpen}
      onClose={() => setIsOpen(false)}
      title="Configure"
      size="sm"
    >
      {panel}
    </Modal>
  );

  const openConfigureModal = () => {
    setIsOpen(true);
  };

  return [configureModal, openConfigureModal] as const;
};
