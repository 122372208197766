import { Box, Group, Paper, Text } from "@mantine/core";
import { IconFileDescription, IconFolder } from "@tabler/icons-react";

import { FileItem } from "./types.js";
import { FileContextMenu } from "./FileContextMenu.js";
import { formatBytes } from "../../utils/formatters.js";

interface FileListProps {
  items: FileItem[];
  onItemClick: (item: FileItem) => void;
  onOpen: (item: FileItem) => void;
  onDownload: (item: FileItem) => void;
  onRename: (item: FileItem) => void;
  onDelete: (item: FileItem) => void;
  onAddToChat?: (item: FileItem) => void;
}

/**
 * FileList component - displays files and folders in a list view
 */
export function FileList({
  items,
  onItemClick,
  onOpen,
  onDownload,
  onRename,
  onDelete,
  onAddToChat,
}: FileListProps) {
  return (
    <Paper>
      {items.map((item, index) => (
        <Group
          key={`${item.path}-${item.name}-${index}`}
          p="xs"
          style={{
            cursor: "pointer",
            borderBottom: "1px solid #eee",
          }}
          onClick={() => onItemClick(item)}
        >
          {item.type === "folder" ? (
            <IconFolder size={24} color="#FFD43B" />
          ) : (
            <IconFileDescription size={24} color="#2196F3" />
          )}
          <Box style={{ flex: 1 }}>
            <Text>{item.name}</Text>
          </Box>
          {item.type === "file" && item.size && (
            <Text size="sm" color="dimmed">
              {formatBytes(item.size)}
            </Text>
          )}
          {item.updatedAt && (
            <Text size="sm" color="dimmed">
              {item.updatedAt instanceof Date
                ? item.updatedAt.toLocaleDateString()
                : "Unknown date"}
            </Text>
          )}

          <FileContextMenu
            item={item}
            onOpen={() => onOpen(item)}
            onDownload={() => onDownload(item)}
            onRename={() => onRename(item)}
            onDelete={() => onDelete(item)}
            onAddToChat={onAddToChat ? () => onAddToChat(item) : undefined}
            size="sm"
            position="bottom-end"
          />
        </Group>
      ))}
    </Paper>
  );
}
