import {
  Group,
  Table,
  Text,
  Badge,
  Menu,
  ActionIcon,
  Loader,
  Switch,
} from "@mantine/core";
import { IconCheck, IconDots, IconSend, IconX } from "@tabler/icons-react";
import { getUserName } from "../../../common/entities/user.js";
import { UserAvatar } from "../Avatars/UserAvatar.js";
import {
  ENTITLEMENT_ACCESS_QUICKBOOKS,
  OrganizationUserEntitlementLabels,
} from "../../../common/fields/entitlements.js";
import {
  OrganizationUserWithUser,
  useUpdateUserEntitlements,
  UsersTableProps,
} from "./useUpdateUserEntitlements.js";

type UserRowProps = Omit<UsersTableProps, "users"> & {
  orgUser: OrganizationUserWithUser;
  isLastActiveUser: (orgUser: OrganizationUserWithUser) => boolean;
};

export default function UserRow({
  orgUser,
  hasManageEntitlement,
  onDelete,
  onSendInvitation,
  sendingInvitationUserIds,
  deletingUserIds,
  isLastActiveUser,
  updateUsersTable,
}: UserRowProps) {
  const existingEntitlements = Array.isArray(
    orgUser.entitlements! as unknown as number[] | undefined
  )
    ? (orgUser.entitlements! as unknown as number[]).sort()
    : [];

  const hasQBAccess = existingEntitlements.includes(
    ENTITLEMENT_ACCESS_QUICKBOOKS
  );

  const { loadingEntitlement, manageEntitlements } = useUpdateUserEntitlements({
    onSuccess: (updatedUser: OrganizationUserWithUser) => {
      updateUsersTable(updatedUser);
    },
  });

  const updateQBAccess = async () => {
    hasQBAccess
      ? onRemoveEntitlement(ENTITLEMENT_ACCESS_QUICKBOOKS)
      : onAddEntitlement(ENTITLEMENT_ACCESS_QUICKBOOKS);
  };

  const onAddEntitlement = async (entitlement: number) => {
    await manageEntitlements(orgUser).add(entitlement);
  };

  const onRemoveEntitlement = async (entitlement: number) => {
    await manageEntitlements(orgUser).remove(entitlement);
  };

  return (
    <Table.Tr key={orgUser.User.id}>
      <Table.Td pl="lg">
        <Group gap="sm">
          <UserAvatar user={orgUser.User} size={45} radius={80} />
          <div>
            <Text fw={700}> {getUserName(orgUser.User)}</Text>
            <Text fz="sm" c="dimmed">
              {orgUser.is_invitation ? "Invited on " : "Member since "}

              {new Date(orgUser.created_at ?? Date.now()).toLocaleDateString(
                undefined,
                {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                }
              )}
            </Text>
          </div>
        </Group>
      </Table.Td>
      <Table.Td>
        <Badge
          variant="light"
          color={
            orgUser.is_invitation
              ? "var(--mantine-color-gray-4)"
              : orgUser.archived_at
              ? "#f44141"
              : "#54e382"
          }
        >
          {orgUser.is_invitation
            ? "Invited"
            : orgUser.archived_at
            ? "Archived"
            : "Active"}
        </Badge>
      </Table.Td>
      <Table.Td>{orgUser.User.email}</Table.Td>
      <Table.Td maw={200}>
        {orgUser.entitlements && orgUser.entitlements ? (
          (orgUser.entitlements as unknown as number[]).map(
            (entitlement: number) => (
              <Badge
                key={entitlement}
                variant="light"
                mr="xs"
                mb="xs"
                radius="xs"
              >
                {OrganizationUserEntitlementLabels[
                  entitlement as keyof typeof OrganizationUserEntitlementLabels
                ] ?? `Unknown (${entitlement})`}
              </Badge>
            )
          )
        ) : (
          <Text size="sm" c="dimmed">
            No entitlements
          </Text>
        )}
      </Table.Td>
      {hasManageEntitlement ? (
        <Table.Td p="lg" ta="right">
          {deletingUserIds && deletingUserIds.has(orgUser.User.id!) ? (
            <Group gap="xs">
              <Loader type="dots" color="var(--mantine-color-org_primary-3)" />
              <Text>Removing</Text>
            </Group>
          ) : sendingInvitationUserIds &&
            sendingInvitationUserIds.has(orgUser.User.id!) ? (
            <Group gap="xs">
              <Loader type="dots" color="var(--mantine-color-org_primary-3)" />
              <Text>Sending</Text>
            </Group>
          ) : (
            <Menu position="bottom-end" withArrow>
              <Menu.Target>
                <ActionIcon variant="default" size="md">
                  <IconDots size={15} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                {orgUser.is_invitation ? (
                  <Menu.Item
                    leftSection={<IconSend size={14} />}
                    color="#54e382"
                    onClick={() => onSendInvitation?.(orgUser.User)}
                  >
                    Send invitation again
                  </Menu.Item>
                ) : null}

                <Menu.Item
                  closeMenuOnClick={false}
                  rightSection={
                    loadingEntitlement === ENTITLEMENT_ACCESS_QUICKBOOKS ? (
                      <Loader type="dots" size={14} />
                    ) : (
                      <Switch
                        checked={hasQBAccess}
                        size="sm"
                        color="var(--mantine-color-teal-6)"
                        onChange={() => {
                          updateQBAccess();
                        }}
                        thumbIcon={
                          hasQBAccess ? (
                            <IconCheck
                              size={12}
                              color="var(--mantine-color-teal-6)"
                              stroke={3}
                            />
                          ) : (
                            <IconX
                              size={12}
                              color="var(--mantine-color-red-5)"
                              stroke={3}
                            />
                          )
                        }
                      />
                    )
                  }
                >
                  Allow QuickBooks Access
                </Menu.Item>

                {isLastActiveUser(orgUser) ? (
                  <Menu.Item
                    disabled={true}
                    leftSection={<IconX size={14} />}
                    color="#f44141"
                  >
                    Remove
                    <Text>Last active user cannot be removed.</Text>
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    leftSection={<IconX size={14} />}
                    color="#f44141"
                    onClick={() => onDelete?.(orgUser)}
                  >
                    Remove
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
          )}
        </Table.Td>
      ) : null}
    </Table.Tr>
  );
}
