import { aiModelEntity } from "./aiModel";
import { aiProviderEntity } from "./aiProvider";
import { conversationEntity } from "./conversation";
import { messageEntity } from "./message";
import { messagesLastReadEntity } from "./messagesLastRead";
import { organizationEntity } from "./organization";
import { organizationIntegrationTokenEntity } from "./organizationIntegrationToken";
import { organizationRelationshipEntity } from "./organizationRelationship";
import { organizationRelationshipUserEntity } from "./organizationRelationshipUser";
import { organizationUserEntity } from "./organizationUser";
import { threadEntity } from "./thread";
import { userEntity } from "./user";

export const entitiesByName = {
  [aiModelEntity.name]: aiModelEntity,
  [aiProviderEntity.name]: aiProviderEntity,
  [conversationEntity.name]: conversationEntity,
  [messageEntity.name]: messageEntity,
  [messagesLastReadEntity.name]: messagesLastReadEntity,
  [organizationEntity.name]: organizationEntity,
  [organizationIntegrationTokenEntity.name]: organizationIntegrationTokenEntity,
  [organizationRelationshipEntity.name]: organizationRelationshipEntity,
  [organizationRelationshipUserEntity.name]: organizationRelationshipUserEntity,
  [organizationUserEntity.name]: organizationUserEntity,
  [threadEntity.name]: threadEntity,
  [userEntity.name]: userEntity,
};
