import { ErrorBoundary } from "../../ErrorBoundary.js";
import { AdminToolBar } from "../modules/AdminToolBar.js";

interface LayoutProps {
  children: React.ReactNode;
}

export const AdminLayout = ({ children }: LayoutProps) => (
  <>
    <AdminToolBar />
    <ErrorBoundary>{children}</ErrorBoundary>
  </>
);
