import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Group,
  Menu,
  Stack,
  Text,
  useMantineColorScheme,
} from "@mantine/core";
import { IconCircleCheckFilled, IconPlus } from "@tabler/icons-react";

import { OrganizationEntity } from "../../../../common/entities/organization.js";
import {
  ActiveStatesContextValue,
  useOrganizationUserHasEntitlement,
} from "../../../contexts/ActiveStatesContext.js";
import {
  ENTITLEMENT_MANAGE_ORGANIZATION_MEMBERS,
  ENTITLEMENT_MANAGE_ORGANIZATION_SETTINGS,
} from "../../../../common/fields/entitlements.js";
import { LEDGERAI_SLUG } from "../../../../common/fields/slug.js";
import { useResponsiveDesign } from "../../../hooks/useResponsiveDesign.js";

import { OrganizationAvatar } from "../../Avatars/OrganizationAvatar.js";
import CustomersList from "./CustomersList.js";

export function CustomersMenuContent({
  customers,
  setCustomer,
  activeCustomer,
  serviceProvider,
  activeOrganization,
  isCustomerUser,
  setMenuOpened,
  setCreateOrganizationModalOpened,
  setCreateUserModalOpened,
}: {
  customers?: OrganizationEntity[];
  setCustomer: ActiveStatesContextValue["setCustomer"];
  activeCustomer: OrganizationEntity | undefined;
  serviceProvider: OrganizationEntity;
  activeOrganization: OrganizationEntity;
  isCustomerUser: boolean;
  setMenuOpened: (opened: boolean) => void;
  setCreateOrganizationModalOpened: (opened: boolean) => void;
  setCreateUserModalOpened: (opened: boolean) => void;
}) {
  const navigate = useNavigate();
  const { isAboveSmall } = useResponsiveDesign();

  const isLedgerAIProvider = serviceProvider?.slug === LEDGERAI_SLUG;
  // Simplified text helper - only handles the variable parts
  const menuText = isLedgerAIProvider
    ? {
        label: "LedgerAI Service Providers",
        button: "a service provider",
        empty: "No service providers yet",
      }
    : {
        label: `${serviceProvider?.name} Clients`,
        button: "a client",
        empty: "No clients yet",
      };

  const hasEntitlement = useOrganizationUserHasEntitlement();
  const has_entitlement_manage_organization_settings = hasEntitlement(
    ENTITLEMENT_MANAGE_ORGANIZATION_SETTINGS
  );
  const has_entitlement_manage_members = hasEntitlement(
    ENTITLEMENT_MANAGE_ORGANIZATION_MEMBERS
  );

  const theme = useMantineColorScheme();
  const backgroundColor =
    theme.colorScheme === "dark" ? "org_primary.9" : "org_primary.0";

  return (
    <Stack style={{ height: "100%" }} gap={0}>
      <Card radius="lg">
        <Card.Section h={90} bg={backgroundColor}>
          <Text ta="center" mt="lg" size="sm" fw={600}>
            {activeOrganization?.primary_domain}
          </Text>
        </Card.Section>
        <OrganizationAvatar
          organization={activeOrganization}
          variant="filled"
          size={80}
          mx="auto"
          mt={-30}
          color={activeOrganization?.theme_primary_color ?? "#228BE6"}
          style={{ border: "2px solid white" }}
        />
        <Stack gap={0} ta="center" fz="lg" fw={700} mt="sm">
          <Text fw={700}> {activeOrganization?.name}</Text>
          <Text c="dimmed" size="sm" fw={500}>
            @{activeOrganization?.slug}
          </Text>
        </Stack>
        <Group justify="center" mt="sm">
          {has_entitlement_manage_organization_settings && (
            <Button
              color={activeOrganization?.theme_primary_color ?? "#228BE6"}
              size="xs"
              variant="light"
              onClick={(e) => {
                e.stopPropagation(); // Prevents the menu from toggling
                navigate("/settings/overview");
              }}
            >
              Customize
            </Button>
          )}
          {has_entitlement_manage_members && (
            <Button
              color={activeOrganization?.theme_primary_color ?? "#228BE6"}
              size="xs"
              variant="light"
              onClick={() => {
                setMenuOpened(false);
                setCreateUserModalOpened(true);
              }}
            >
              Invite People
            </Button>
          )}
        </Group>
      </Card>

      {!isCustomerUser && customers && (
        <>
          <Menu.Divider />
          <CustomersList
            customers={customers}
            activeCustomer={activeCustomer}
            menuText={menuText}
            setCustomer={setCustomer}
          />
          {activeOrganization?.id !== serviceProvider?.id && (
            <>
              <Menu.Divider />
              <Menu.Item
                key={serviceProvider?.id}
                onClick={() => setCustomer(undefined)}
                leftSection={
                  <OrganizationAvatar
                    organization={serviceProvider}
                    variant="filled"
                    size={45}
                    color={serviceProvider?.theme_primary_color ?? "#228BE6"}
                  />
                }
                rightSection={
                  activeOrganization?.id === serviceProvider?.id ? (
                    <IconCircleCheckFilled color="#00ba7c" size={19} />
                  ) : null
                }
              >
                <Stack mr="xl" gap={0}>
                  <Text fw={700}> {serviceProvider?.name}</Text>
                  <Text c="dimmed" size="sm" fw={500}>
                    @{serviceProvider?.slug}
                  </Text>
                </Stack>
              </Menu.Item>
            </>
          )}
          <Menu.Divider />
          <Box mx="lg" my={isAboveSmall ? "lg" : "xs"}>
            <Button
              size="sm"
              variant="default"
              fullWidth
              onClick={() => {
                setMenuOpened(false);
                setCreateOrganizationModalOpened(true);
              }}
              leftSection={<IconPlus size={16} />}
            >
              Add {menuText.button}
            </Button>
          </Box>
        </>
      )}
    </Stack>
  );
}
