import { Menu, ScrollArea, Stack, Text } from "@mantine/core";
import { IconCircleCheckFilled } from "@tabler/icons-react";

import { OrganizationAvatar } from "../../Avatars/OrganizationAvatar.js";
import { OrganizationEntity } from "../../../../common/entities/organization.js";
import { ActiveStatesContextValue } from "../../../contexts/ActiveStatesContext.js";
import { useResponsiveDesign } from "../../../hooks/useResponsiveDesign.js";

export default function CustomersList({
  customers,
  activeCustomer,
  menuText,
  setCustomer,
}: {
  customers: OrganizationEntity[];
  activeCustomer: OrganizationEntity | undefined;
  menuText: { label: string; button: string; empty: string };
  setCustomer: ActiveStatesContextValue["setCustomer"];
}) {
  const { isAboveSmall } = useResponsiveDesign();

  return (
    <div style={{ flex: 1 }}>
      <Menu.Label>{menuText.label}</Menu.Label>
      <ScrollArea.Autosize
        type="scroll"
        style={{
          maxHeight: isAboveSmall
            ? "calc(100dvh - 470px)"
            : "calc(100dvh - 410px)",
        }}
      >
        {customers.length > 0 ? (
          customers.map((org) => (
            <Menu.Item
              key={org.id}
              onClick={() => setCustomer(org)}
              leftSection={
                <OrganizationAvatar
                  organization={org}
                  variant="filled"
                  size={45}
                  color={org.theme_primary_color ?? "#228BE6"}
                />
              }
              rightSection={
                org.id === activeCustomer?.id ? (
                  <IconCircleCheckFilled color="#00ba7c" size={19} />
                ) : null
              }
            >
              <Stack mr="xl" gap={0}>
                <Text fw={700}> {org.name}</Text>
                <Text color="dimmed" size="sm" fw={500}>
                  @{org.slug}
                </Text>
              </Stack>
            </Menu.Item>
          ))
        ) : (
          <Menu.Item disabled>{menuText.empty}</Menu.Item>
        )}
      </ScrollArea.Autosize>
    </div>
  );
}
