import { ActionIcon, Tooltip } from "@mantine/core";
import {
  IconLayoutSidebarRight,
  IconLayoutSidebarRightFilled,
} from "@tabler/icons-react";
import { useAside } from "../../hooks/useAside.js";

const debug = false;

interface AsideToggleButtonProps {
  folderPath?: string;
}

export function AsideToggleButton({ folderPath = "" }: AsideToggleButtonProps) {
  const {
    opened,
    isPennyChatOpen,
    openPennyChat,
    closeAside,
    currentContent,
    currentFolderPath,
    updateFolderPath,
  } = useAside();

  // Add debug logging
  debug &&
    console.log("AsideToggleButton rendered with props:", {
      opened,
      isPennyChatOpen,
      hasContent: !!currentContent,
      folderPath,
      currentFolderPath,
    });

  const toggleAside = () => {
    debug &&
      console.log("AsideToggleButton - toggle clicked, current state:", {
        opened,
        isPennyChatOpen,
        folderPath,
        currentFolderPath,
      });

    // If opened and showing the same folder, close it
    if (opened && isPennyChatOpen && currentFolderPath === folderPath) {
      console.log("AsideToggleButton - calling closeAside()");
      closeAside();
    }
    // If already open with PennyChat but different folder, update the folder path
    else if (opened && isPennyChatOpen && currentFolderPath !== folderPath) {
      console.log("AsideToggleButton - updating folder path:", folderPath);
      updateFolderPath(folderPath);
    }
    // If not open or not showing PennyChat, open PennyChat with the folder
    else {
      console.log(
        "AsideToggleButton - calling openPennyChat() with path:",
        folderPath
      );
      openPennyChat(folderPath);
    }
  };

  // Determine if this button is for the currently active folder
  const isActive =
    opened && isPennyChatOpen && currentFolderPath === folderPath;

  return (
    <Tooltip
      label={isActive ? "Close chat" : "Open chat for this folder"}
      position="left"
    >
      <ActionIcon
        onClick={toggleAside}
        variant="default"
        size="lg"
        color={isActive ? "blue" : "gray"}
      >
        {isActive ? (
          <IconLayoutSidebarRightFilled />
        ) : (
          <IconLayoutSidebarRight />
        )}
      </ActionIcon>
    </Tooltip>
  );
}
