import { TimestampField } from "../types/field";

export const refreshTokenExpiresAtField: TimestampField<{
  name: "refresh_token_expires_at";
  required: true;
}> = {
  label: "Refresh Token Expires At",
  name: "refresh_token_expires_at",
  required: true,
  type: "timestamp",
};
