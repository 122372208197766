import { ActionIcon, Card, Tooltip } from "@mantine/core";
import { IconCode } from "@tabler/icons-react";
import { useCallback } from "react";
import {
  createMemoryRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";

import { OrganizationEntity } from "../common/entities/organization.js";
import { OrganizationUserEntity } from "../common/entities/organizationUser.js";
import { UserEntity } from "../common/entities/user.js";
import { LEDGERAI_SLUG } from "../common/fields/slug.js";
import { AdminAI } from "./admin/modules/AdminAI.js";
import { AdminBuilds } from "./admin/modules/AdminBuilds.js";
import { AdminDocument } from "./admin/modules/AdminDocument.js";
import { AdminNotFound } from "./admin/modules/AdminNotFound.js";
import { AdminUsers } from "./admin/modules/AdminUsers.js";
import { AdminWelcome } from "./admin/modules/AdminWelcome.js";
import { APIAccessControl } from "./admin/modules/APIAccessControl.js";
import { APIDocumentationComponent } from "./admin/modules/APIDocumentation.js";
import { withPathPersistence } from "./admin/modules/withPathPersistence.js";
import { AdminContext } from "./contexts/AdminContext.js";
import { ErrorBoundary } from "./ErrorBoundary.js";

import classes from "./admin/AdminApp.css.js";
import { AdminOrgs } from "./admin/modules/AdminOrgs.js";

const adminAppRoutes = (
  <Routes>
    <Route path="/" Component={withPathPersistence(AdminWelcome)} />
    <Route path="/ai" Component={withPathPersistence(AdminAI)} />
    <Route
      path="/docs/api/access-control"
      Component={withPathPersistence(APIAccessControl)}
    />
    <Route
      path="/docs/api"
      Component={withPathPersistence(APIDocumentationComponent)}
    />
    <Route
      path="/docs/:document_id"
      Component={withPathPersistence<{ document_id: string }>(AdminDocument)}
    />
    <Route path="/users" Component={withPathPersistence(AdminUsers)} />
    <Route path="/organizations" Component={withPathPersistence(AdminOrgs)} />
    <Route path="/builds" Component={withPathPersistence(AdminBuilds)} />
    <Route path="*" Component={withPathPersistence(AdminNotFound)} />
  </Routes>
);

function AdminAppRoot() {
  return <ErrorBoundary>{adminAppRoutes}</ErrorBoundary>;
}

const adminAppRouter = createMemoryRouter(
  [{ path: "*", Component: AdminAppRoot }],
  {
    future: {},
  }
);

export function AdminApp({
  isExpanded,
  organization,
  organizationUser,
  setIsExpanded,
  user,
}: {
  isExpanded: boolean;
  organization: OrganizationEntity;
  organizationUser: OrganizationUserEntity;
  setIsExpanded: (isExpanded: boolean) => void;
  user: UserEntity;
}) {
  const closeAdmin = useCallback(() => {
    setIsExpanded(false);
  }, [setIsExpanded]);

  if (organization.slug !== LEDGERAI_SLUG) {
    return null; // Only LedgerAI organization members can access admin features
  }

  if (!isExpanded) {
    return (
      <Tooltip label="LedgerAI Admin">
        <ActionIcon
          variant="default"
          size="xl"
          style={{
            position: "absolute",
            bottom: "10px",
            left: "15px",
            zIndex: 1000,
          }}
          onClick={() => {
            setIsExpanded(true);
          }}
        >
          <IconCode />
        </ActionIcon>
      </Tooltip>
    );
  }

  return (
    <AdminContext.Provider
      value={{
        adminIsOpen: isExpanded,
        closeAdmin,
        organization,
        organizationUser,
        user,
      }}
    >
      <Card className={classes.content}>
        <RouterProvider router={adminAppRouter} />
      </Card>
    </AdminContext.Provider>
  );
}
