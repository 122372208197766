import { useAuth } from "../../app/hooks/useAuth";
import { OrganizationEntity } from "../entities/organization";
import { OrganizationRelationshipEntity } from "../entities/organizationRelationship";
import { OrganizationRelationshipUserEntity } from "../entities/organizationRelationshipUser";
import { OrganizationUserEntity } from "../entities/organizationUser";
import { UserEntity } from "../entities/user";
import { network } from "./network";

export interface InitErrorResponse {
  success: false;
  reason: string;
  errors: string[];
}

export type InitResponse =
  | {
      success: true;
      customer: OrganizationEntity | undefined;
      customers: OrganizationEntity[] | undefined;
      isServiceProviderUser: boolean;
      organizationRelationship: OrganizationRelationshipEntity | undefined;
      organizationRelationshipUser:
        | OrganizationRelationshipUserEntity
        | undefined;
      organizationUser: OrganizationUserEntity;
      serviceProvider: OrganizationEntity;
      user: UserEntity;
    }
  | InitErrorResponse;

export const initClient = (onError: (error: string) => void) => ({
  get({ customerIdHint }: { customerIdHint?: number } = {}) {
    const accessToken = useAuth().session?.accessToken;
    return network.get<InitResponse>(
      "/api/init",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          customerIdHint,
        },
      },
      onError
    );
  },
});
