import { IntegerField } from "../types/field";

export const virtualClientOfOrganizationIdField: IntegerField<{
  name: "client_of_organization_id";
  virtual: true;
}> = {
  label: "Client of Organization ID",
  name: "client_of_organization_id",
  type: "integer",
  virtual: true,
};
