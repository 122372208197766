import { IntegerField } from "../types/field";

export const virtualProviderIdField: IntegerField<{
  name: "provider_id";
  virtual: true;
}> = {
  label: "Provider ID",
  name: "provider_id",
  virtual: true,
  type: "integer",
};
