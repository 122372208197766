import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Group,
  Paper,
  RingProgress,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useComputedColorScheme,
} from "@mantine/core";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { IconFileUpload, IconX } from "@tabler/icons-react";
import { useCallback, useEffect, useRef, useState } from "react";

import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { formatBytes } from "../../utils/formatters.js";
import { handleUpload } from "../FileExplorer/handleUpload.js";
import { UploadItem } from "../FileExplorer/types.js";
import UploadedFile from "./UploadedFile.js";

const debug = false;

interface FileUploadAreaProps {
  currentPath: string;
  onUploadComplete?: (
    files: { url: string; name: string; size: number }[]
  ) => void;
  children?: (
    fileInput: React.ReactNode,
    selectFiles: () => void,
    isUploading: boolean
  ) => React.ReactNode;
}

/**
 * File upload area component for use within PennyChat
 * Provides a simplified interface for uploading files in the context of a conversation
 */
export function FileUploadArea({
  currentPath,
  onUploadComplete,
  children,
}: FileUploadAreaProps) {
  const { customer, serviceProvider, user, attachedFiles, setAttachedFiles } =
    useActiveStates();
  const [uploadQueue, setUploadQueue] = useState<UploadItem[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openRef = useRef<any>(null);
  // Track processed file URLs to prevent duplicates
  const processedUrls = useRef<Set<string>>(new Set());
  // Track if we're currently processing a completion callback
  const isProcessingCompletion = useRef<boolean>(false);

  const computedColorScheme = useComputedColorScheme();
  const isDark = computedColorScheme === "dark";

  // Log visibility information
  debug &&
    console.log("FileUploadArea rendered:", {
      currentPath,
      uploadQueueSize: uploadQueue.length,
      hasServiceProvider: !!serviceProvider?.id,
      attachments: attachedFiles,
    });

  useEffect(() => {
    // Log that component mounted with folder path
    debug &&
      console.log(
        "FileUploadArea mounted with path:",
        currentPath || "empty/undefined"
      );
  }, [currentPath]);

  const handleDrop = (files: FileWithPath[]) => {
    debug && console.log("Files dropped:", files.length);

    // Check if any of the dropped files are already in attachedFiles
    const newFiles = files.filter((file) => {
      return !attachedFiles.some((attachment) => attachment.name === file.name);
    });

    // Only proceed with upload if there are new files
    if (newFiles.length > 0) {
      handleUpload(newFiles, [], {
        currentPath,
        serviceProviderId: serviceProvider?.id,
        customerId: customer?.id,
        uploadQueue,
        setUploadQueue,
        onUploadComplete: () => {
          // Prevent duplicate processing
          if (isProcessingCompletion.current) {
            debug && console.log("Already processing completion, skipping");
            return;
          }

          isProcessingCompletion.current = true;

          try {
            // Get completed files from the current queue state
            setUploadQueue((currentQueue) => {
              // Get all completed files that haven't been processed
              const completedFiles = currentQueue
                .filter((item) => item.status === "completed" && item.url)
                .map((item) => ({
                  url: item.url!,
                  name: item.file.name,
                  size: item.file.size,
                }));

              debug &&
                console.log("Processing completed files:", {
                  completedFiles,
                  currentQueue,
                  processedUrls: Array.from(processedUrls.current),
                });

              if (completedFiles.length > 0) {
                // Add to attachedFiles, ensuring no duplicates
                setAttachedFiles((prev) => {
                  // Filter out any files that are already in attachedFiles
                  const newFiles = completedFiles.filter(
                    (file) =>
                      !processedUrls.current.has(file.url) &&
                      !prev.some((existing) => existing.url === file.url)
                  );

                  debug &&
                    console.log("Adding to attachedFiles", {
                      prev,
                      completedFiles,
                      newFiles,
                    });

                  // Mark all files as processed
                  newFiles.forEach((file) =>
                    processedUrls.current.add(file.url)
                  );

                  // Only add if we have new files
                  return newFiles.length > 0 ? [...prev, ...newFiles] : prev;
                });

                // Notify parent of completed files
                onUploadComplete?.(completedFiles);
              }

              // Return queue without completed files
              return currentQueue.filter((item) => item.status !== "completed");
            });
          } finally {
            // Reset processing flag after a short delay to ensure state updates have completed
            setTimeout(() => {
              isProcessingCompletion.current = false;
            }, 100);
          }
        },
        // Pass user directly without type checks
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        user: user as any,
      });
    } else {
      debug && console.log("All files are already attached, skipping upload");
    }
  };

  // Function to programmatically open the file picker
  // const openFilePicker = () => {
  //   if (openRef.current) {
  //     openRef.current.openRef?.current?.();
  //   }
  // };

  // Remove item from queue
  const removeFromQueue = (id: string) => {
    setUploadQueue((queue) => {
      const newQueue = queue.filter((item) => item.id !== id);
      return newQueue;
    });
  };

  const removeAttachment = (url: string) => {
    setAttachedFiles((prev) => prev.filter((file) => file.url !== url));
  };

  // Filter out duplicate attachments by URL, keeping only the first occurrence
  const uniqueAttachments = attachedFiles.filter(
    (attachment, index) =>
      attachedFiles.findIndex((a) => a.url === attachment.url) === index
  );

  const fileList =
    uniqueAttachments.length === 0 && uploadQueue.length === 0 ? null : (
      <Group gap="xs" wrap="nowrap" style={{ overflow: "auto" }}>
        {uniqueAttachments.map((attachment) => {
          return (
            <UploadedFile
              key={attachment.url}
              file={attachment}
              widthFitContent={true}
              actions={
                <Button
                  style={{ flexShrink: 0 }}
                  size="xs"
                  variant="subtle"
                  color="red"
                  p={0}
                  onClick={() => removeAttachment(attachment.url)}
                >
                  <IconX size={14} />
                </Button>
              }
            />
          );
        })}
        {uploadQueue.map((item) => (
          <Paper key={item.id} p="xs" withBorder>
            <Group mb="xs" wrap="nowrap">
              <ThemeIcon>
                <RingProgress
                  size={25}
                  thickness={2.5}
                  color={
                    item.status === "completed"
                      ? "green"
                      : item.status === "error"
                      ? "red"
                      : "blue"
                  }
                  style={{ flex: 1 }}
                  sections={[
                    { value: item.progress, color: "white" },
                    { value: 100 - item.progress, color: "gray" },
                  ]}
                />
              </ThemeIcon>
              <Stack gap={0}>
                <Text size="sm" style={{ flexGrow: 1 }}>
                  {item.file.name}
                </Text>
                {item.file.size > 0 && (
                  <Text size="xs" c="dimmed" style={{ whiteSpace: "nowrap" }}>
                    {formatBytes(item.file.size)}
                  </Text>
                )}
              </Stack>
              {item.status !== "uploading" && (
                <Button
                  style={{ flexShrink: 0 }}
                  size="xs"
                  variant="subtle"
                  color="red"
                  p={0}
                  onClick={() => removeFromQueue(item.id)}
                >
                  <IconX size={14} />
                </Button>
              )}
            </Group>

            {item.error && (
              <Text size="xs" c="red" mt="xs">
                Error: {item.error}
              </Text>
            )}
          </Paper>
        ))}
      </Group>
    );

  const selectFiles = useCallback(() => {
    openRef.current?.();
  }, [openRef]);

  return (
    <Dropzone
      activateOnClick={false}
      maxFiles={10}
      maxSize={100 * 1024 * 1024} // 100MB
      onDrop={handleDrop}
      openRef={openRef}
    >
      <Dropzone.Accept>
        <Box
          p="md"
          style={{
            pointerEvents: "none",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: isDark
              ? "rgba(36,36,36,0.85)"
              : "rgba(255,253,251,0.92)",
            zIndex: 100,
          }}
        >
          <Stack justify="center" ta="center" align="center" gap="xl">
            <ActionIcon variant="subtle" size="xl">
              <IconFileUpload size={52} />
            </ActionIcon>

            <Stack gap="md">
              <Title order={3}>Add anything</Title>
              <Text size="md">
                Drop any file here to add it to the conversation.
              </Text>
              <Text size="sm">
                Attachments are uploaded to{" "}
                <Badge color="blue" variant="light">
                  {currentPath || "root folder"}
                </Badge>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Dropzone.Accept>
      <Dropzone.Reject></Dropzone.Reject>

      {children?.(fileList, selectFiles, uploadQueue.length > 0)}
    </Dropzone>
  );
}

// Export the FileUploadArea component to provide access to its ref and methods
export type FileUploadAreaRef = {
  openFilePicker: () => void;
};
