import { useChat } from "@ai-sdk/react";
import { Avatar, Box, Group, Tooltip } from "@mantine/core";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { Conversation } from "../../../common/core/messages.js";
import { AIModelEntity } from "../../../common/entities/aiModel.js";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { getUserName, UserEntity } from "../../../common/entities/user.js";
import {
  ENTITLEMENT_ACCESS_QUICKBOOKS,
  ENTITLEMENT_PENNY_SELECT_MODEL,
} from "../../../common/fields/entitlements.js";
import { MessageDisplay } from "../../components/Messages/MessageDisplay.js";
import { FileUploadArea } from "../../components/PennyChat/FileUploadArea.js";
import {
  ChatAttachment,
  useActiveStates,
  useOrganizationRelationshipUserHasEntitlement,
  useOrganizationUserHasEntitlement,
} from "../../contexts/ActiveStatesContext.js";
import { useAside } from "../../hooks/useAside.js";
import { useAuth } from "../../hooks/useAuth.js";
import { useResponsiveDesign } from "../../hooks/useResponsiveDesign.js";
import { useSessionState } from "../../hooks/useSessionState.js";
import { clientError } from "../../utils/clientError.js";
import { ChatInput } from "./ChatInput.js";
import SelectModel from "./SelectModel.js";

const debug = false;

export interface PennyConversationState {
  conversation?: Conversation;
}

export function PennyConversation({
  state,
  setState,
  user,
  users,
  organization,
  customer,
  serviceProvider,
}: {
  state: PennyConversationState;
  setState: (
    callback: (oldState: PennyConversationState) => PennyConversationState
  ) => void;
  user: UserEntity;
  users: UserEntity[];
  organization: OrganizationEntity;
  customer?: OrganizationEntity;
  serviceProvider: OrganizationEntity;
}) {
  const hasEntitlement = useOrganizationUserHasEntitlement();
  const hasEntitlementOrgRelUser =
    useOrganizationRelationshipUserHasEntitlement();
  const hasEntitlementAccessQuickbooks =
    hasEntitlement(ENTITLEMENT_ACCESS_QUICKBOOKS) ||
    hasEntitlementOrgRelUser(ENTITLEMENT_ACCESS_QUICKBOOKS);

  const { currentFolderPath } = useAside();
  const { attachedFiles, setAttachedFiles } = useActiveStates();
  debug &&
    console.log(
      "PennyConversation - currentFolderPath:",
      currentFolderPath,
      typeof currentFolderPath
    );

  // Add function to get time-based greeting
  const getTimeBasedGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 17) return "Good afternoon";
    return "Good evening";
  };

  const [model, setModel] = useSessionState<AIModelEntity | null>(
    "ai-model",
    null
  );

  const initialThreadMessages = useMemo(() => {
    return state.conversation?.threads[0]?.messages ?? [];
  }, [state.conversation?.id]);

  const initialMessages = useMemo(
    () =>
      state.conversation
        ? Array.from(
            [
              ...(initialThreadMessages.length > 0
                ? initialThreadMessages
                : [
                    {
                      id: "welcome-message",
                      role: "assistant" as const,
                      content: `${getTimeBasedGreeting()} ${
                        user?.given_name || "there"
                      }! 👋 I'm Penny, your AI assistant${
                        organization ? ` for ${organization.name}` : ""
                      }. I can help you with various tasks, answer questions, and provide assistance. Feel free to ask me anything!`,
                    },
                  ]),
              ...initialThreadMessages,
            ]
              .reduce((messages, message) => {
                // deduplicate messages by id; @todo investigate why this is necessary
                messages.set(message.id, message);
                return messages;
              }, new Map())
              .values()
          )
        : [],
    [state.conversation?.id, initialThreadMessages]
  );

  const auth = useAuth();
  const accessToken = auth.session?.accessToken;

  const {
    messages = [],
    input = "",
    handleInputChange = () => {},
    handleSubmit: originalHandleSubmit = () => {},
    isLoading = false,
    addToolResult = () => {},
  } = useChat({
    api: "/api/chat",
    body: {
      conversationId: state.conversation?.id,
      threadId: state.conversation?.threads[0]?.id,
      customer,
      model,
      serviceProvider,
      attachments: attachedFiles,
      hasEntitlementAccessQuickbooks,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    onError: (error) => {
      console.error("error", error);
      if (error?.message.startsWith("Failed to parse stream string.")) {
        return;
      }
      clientError(error?.message ?? "Unknown error");
    },
    initialMessages,
  });

  // Wrap handleSubmit to clear attachments after sending
  const handleSubmit = useCallback(() => {
    originalHandleSubmit();
    setAttachedFiles([]);
  }, [originalHandleSubmit, setAttachedFiles]);

  debug && console.log({ initialMessages, messages });

  // Reference to the chat display container
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Function to scroll to bottom
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const scrollElement = chatContainerRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  };

  // Scroll when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Add a small delay to ensure content is rendered
  useEffect(() => {
    const timeoutId = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timeoutId);
  }, [messages]);

  const has_entitlement_penny_select_model = hasEntitlement(
    ENTITLEMENT_PENNY_SELECT_MODEL
  );

  useEffect(() => {
    // Update the conversation state with the new conversation ID on the first message if the conversation is new
    if (state.conversation?.id !== "new") {
      return;
    }
    if (messages.length === 0) {
      return;
    }
    const lastMessage = messages[messages.length - 1];
    if (lastMessage.role !== "assistant") {
      return;
    }
    if (!("toolInvocations" in lastMessage)) {
      return;
    }
    if (
      !lastMessage.toolInvocations ||
      lastMessage.toolInvocations?.length < 1
    ) {
      return;
    }
    if (
      lastMessage.toolInvocations &&
      lastMessage.toolInvocations[lastMessage.toolInvocations.length - 1]
        .toolCallId !== "data"
    ) {
      return;
    }
    const { conversation: newConversation }: { conversation: Conversation } =
      (lastMessage.toolInvocations &&
        lastMessage.toolInvocations[lastMessage.toolInvocations.length - 1]
          ?.args) ??
      {};
    if (!newConversation) {
      return;
    }
    setTimeout(
      () =>
        setState((x) => ({
          ...x,
          conversation: {
            ...x.conversation,
            id: newConversation.id as number,
            title: newConversation.title,
            threads:
              newConversation.threads && newConversation.threads.length > 0
                ? newConversation.threads
                : x.conversation?.threads ?? [],
            created_at: newConversation.created_at,
            updated_at: newConversation.updated_at,
          },
        })),
      50
    );
  }, [messages, state.conversation?.id, setState, state]);

  const { isAboveLarge, isAboveMedium } = useResponsiveDesign();

  let chatMaxWidth = "100%";
  if (isAboveLarge) {
    chatMaxWidth = "780px";
  } else if (isAboveMedium) {
    chatMaxWidth = "610px";
  }

  // Function to handle when file uploads are complete
  const handleUploadComplete = (files: ChatAttachment[]) => {
    // Inform the user that files were uploaded
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addToolResult({
      toolCallId: "upload_complete",
      result: {
        message: `Files were uploaded to "${
          currentFolderPath || "root folder"
        }". You can reference these files in your conversation or continue chatting about them.`,
      },
    });

    // Wait 200ms then add the files to attachedFiles
    setTimeout(() => {
      if (files.length > 0) {
        setAttachedFiles((prev) => [...prev, ...files]);
      }
    }, 200);
  };

  if (
    typeof isAboveLarge === "undefined" ||
    typeof isAboveMedium === "undefined"
  ) {
    return null;
  }

  return (
    <>
      <Group
        p="md"
        wrap="nowrap"
        style={{
          backgroundColor: "var(--mantine-color-body)",
          borderBottom: "1px solid var(--app-shell-border-color)",
        }}
        justify="space-between"
      >
        {has_entitlement_penny_select_model && (
          <SelectModel
            disabled={!has_entitlement_penny_select_model}
            model={model}
            setModel={setModel}
          />
        )}
        <Tooltip.Group openDelay={300} closeDelay={100}>
          <Avatar.Group spacing="sm">
            {users.slice(0, 4).map?.((user) => (
              <Tooltip key={user.id} label={getUserName(user)} withArrow>
                <Avatar
                  src={user.picture}
                  alt={getUserName(user)}
                  size={35}
                  radius={80}
                />
              </Tooltip>
            ))}

            {users.length > 4 && (
              <Tooltip
                withArrow
                label={
                  <>
                    {users.slice(4).map((user) => (
                      <div key={user.id}>{getUserName(user)}</div>
                    ))}
                  </>
                }
              >
                <Avatar size={35} radius={80}>
                  +{users.length - 4}
                </Avatar>
              </Tooltip>
            )}
          </Avatar.Group>
        </Tooltip.Group>
      </Group>
      <FileUploadArea
        currentPath={currentFolderPath ?? ""}
        onUploadComplete={handleUploadComplete}
      >
        {(fileList, selectFiles, isUploading) => (
          <Box
            style={{
              display: "flex",
              flexGrow: "1",
              flexDirection: "column",
              maxHeight: `calc(100vh - ${
                fileList ? "100px" : "80px"
              } - var(--app-shell-header-height))`,
              minHeight: `calc(100vh - ${
                fileList ? "100px" : "80px"
              } - var(--app-shell-header-height))`,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box
              ref={chatContainerRef}
              style={{
                overflowY: "auto",
                width: "100%",
              }}
            >
              <Box
                p="xl"
                style={{
                  alignContent: "flex-end",
                  maxWidth: chatMaxWidth,
                  margin: "0 auto",
                }}
              >
                {(messages ?? []).map((message, index) => (
                  <MessageDisplay
                    addToolResult={addToolResult}
                    focusable={false}
                    key={message.id}
                    message={
                      index in initialMessages
                        ? initialMessages[index]
                        : message
                    }
                    user={user} // note that this is only used if the message has no user information
                    streamFromStatic
                  />
                ))}
              </Box>
            </Box>
            <Box
              p="md"
              style={{
                position: "relative",
                maxWidth: chatMaxWidth,
                width: "100%",
              }}
            >
              <ChatInput
                input={input}
                setInput={handleInputChange}
                onSend={handleSubmit}
                sending={isLoading}
                disabled={isUploading}
                onSelectFiles={selectFiles}
              >
                {fileList}
              </ChatInput>
            </Box>
          </Box>
        )}
      </FileUploadArea>
    </>
  );
}
