import { css } from "../../utils/css.js";

interface ClassNames {
  entitlement: string;
}

export default css<ClassNames>`
  .entitlement:not(:last-child) {
    border-bottom: 1px solid var(--mantine-color-gray-2);
  }
`;
