import {
  Box,
  Button,
  Group,
  Loader,
  Modal,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { FormEvent, useState } from "react";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { UserEntity } from "../../../common/entities/user.js";

interface UserModalProps {
  opened: boolean;
  close: () => void;
  onSubmit: (values: Partial<UserEntity>) => Promise<void>;
  editMode?: boolean;
  initialData?: UserEntity;
  organization?: OrganizationEntity;
}

export function UserModal({
  opened,
  close,
  onSubmit,
  editMode = false,
  initialData,
  organization,
}: UserModalProps) {
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState(initialData?.email || "");
  const [firstName, setFirstName] = useState(initialData?.given_name || "");
  const [lastName, setLastName] = useState(initialData?.family_name || "");
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!firstName || firstName.length < 2) {
      newErrors.firstName = "First name must be at least 2 characters";
    }

    if (!lastName || lastName.length < 2) {
      newErrors.lastName = "Last name must be at least 2 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: FormEvent) => {
    setSubmitting(true);
    e.preventDefault();

    try {
      const isValid = validateForm();

      console.log("isValid", isValid);

      if (!isValid) {
        return;
      }

      const userData: Partial<UserEntity> = {
        email,
        given_name: firstName,
        family_name: lastName,
      };

      await onSubmit(userData);
      handleReset();
      close();
    } catch (error: any) {
      console.error("Error submitting user", error);
      notifications.show({
        title: "Error",
        message: `Error submitting invitation: ${
          error?.message ?? error ?? "unknown"
        }`,
        color: "#f44141",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleReset = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setErrors({});
  };

  return (
    <Modal
      closeOnClickOutside={false}
      closeOnEscape={false}
      opened={opened}
      onClose={() => {
        close();
        handleReset();
      }}
      title={
        editMode
          ? `Edit ${organization?.name} User`
          : `Invite ${organization?.name} User`
      }
      size="md"
    >
      <form onSubmit={handleSubmit}>
        <Stack>
          <Text>
            Invite someone to join the {organization?.name} team on LedgerAI.
            They will receive an email with a link to get started.
          </Text>
          <TextInput
            disabled={submitting}
            label="Email"
            placeholder="user@example.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errors.email}
          />
          <Group grow align="top">
            <TextInput
              disabled={submitting}
              label="First Name"
              placeholder="John"
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              error={errors.firstName}
            />
            <TextInput
              disabled={submitting}
              label="Last Name"
              placeholder="Doe"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              error={errors.lastName}
            />
          </Group>
          {submitting ? (
            <Box p="xs" ta="center">
              <Stack gap={0} justify="center" align="center">
                <Loader
                  type="dots"
                  color="var(--mantine-color-org_primary-3)"
                />
                <Text>Sending email invitation</Text>
              </Stack>
            </Box>
          ) : (
            <Group justify="flex-end">
              <Button disabled={submitting} variant="light" onClick={close}>
                Cancel
              </Button>
              <Button disabled={submitting} type="submit">
                {editMode ? "Save" : "Invite"}
              </Button>
            </Group>
          )}
        </Stack>
      </form>
    </Modal>
  );
}
