import { Avatar, AvatarProps, useMantineColorScheme } from "@mantine/core";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import { DEFAULT_THEME_PRIMARY_COLOR } from "../../../common/fields/theme_primary_color.js";
import { getInitials } from "../../../common/utils/textUtils.js";
import { PrimaryColor } from "../../theme.js";

import createStyles from "./OrganizationAvatar.css.js";

export function OrganizationAvatar({
  organization,
  hasShadow = false,
  ...props
}: { organization: OrganizationEntity; hasShadow?: boolean } & AvatarProps) {
  const { bg, color, ...rest } = props;
  const styles = createStyles(
    organization.theme_primary_color ?? DEFAULT_THEME_PRIMARY_COLOR
  );
  const theme = useMantineColorScheme();
  const isDark = theme.colorScheme === "dark";

  return (
    <div className={hasShadow ? styles.avatarWrapper : ""}>
      <div className="organizationAvatar">
        <Avatar
          alt={`${organization.name} Profile Picture`}
          bg={organization.picture_background_color || bg || PrimaryColor}
          color={organization.theme_primary_color || color || PrimaryColor}
          fw={900}
          name={organization.initials || getInitials(organization.name ?? "-")}
          radius="sm"
          src={organization.picture}
          variant="filled"
          autoContrast
          {...rest}
          styles={{
            root: {
              ...(!organization.picture
                ? {}
                : isDark
                ? { border: `1px solid var(--mantine-color-dark-4)` }
                : { border: `1px solid var(--mantine-color-gray-4)` }),

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            image: {
              width: "100%",
              height: "auto",
              objectFit: "contain",
              padding: "5px",
            },
          }}
        />
      </div>
    </div>
  );
}
