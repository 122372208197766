import {
  ActionIcon,
  Badge,
  Group,
  Menu,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";

import { IconDots } from "@tabler/icons-react";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationIntegrationTokenEntity } from "../../../common/entities/organizationIntegrationToken.js";
import { OrganizationEntitlementLabels } from "../../../common/fields/entitlements.js";
import {
  INTEGRATION_GURU,
  INTEGRATION_LABELS,
} from "../../../common/fields/integration.js";
import {
  organizationClient,
  organizationIntegrationTokenClient,
} from "../../../common/utils/entityClient.js";
import { OrganizationAvatar } from "../../components/Avatars/OrganizationAvatar.js";
import { StandardError } from "../../components/Standard/StandardError.js";
import { StandardLoader } from "../../components/Standard/StandardLoader.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { clientError } from "../../utils/clientError.js";

export function AdminOrgsList({ searchQuery }: { searchQuery: string }) {
  const [orgIntegrationTokens, setOrgIntegrationTokens] = useState<
    OrganizationIntegrationTokenEntity[]
  >([]);
  const [organizations, setOrganizations] = useState<OrganizationEntity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function load() {
      try {
        setIsLoading(true);
        setError(null);
        const [listOrgsResponse, listOrgIntegrationTokensResponse] =
          await Promise.all([
            organizationClient(clientError).list(),
            organizationIntegrationTokenClient(clientError).list(),
          ]);
        if (listOrgIntegrationTokensResponse.success) {
          if (listOrgIntegrationTokensResponse.data.success) {
            setOrgIntegrationTokens(
              listOrgIntegrationTokensResponse.data
                .data as OrganizationIntegrationTokenEntity[]
            );
          } else {
            setError(listOrgIntegrationTokensResponse.data.errors.join(", "));
          }
        } else {
          setError(listOrgIntegrationTokensResponse.error);
        }
        if (listOrgsResponse.success) {
          if (listOrgsResponse.data.success) {
            setOrganizations(
              (listOrgsResponse.data.data as OrganizationEntity[]).sort(
                (a, b) => a.name!.localeCompare(b.name!)
              )
            );
          } else {
            setError(listOrgsResponse.data.errors.join(", "));
          }
        } else {
          setError(listOrgsResponse.error);
        }
      } catch (error: any) {
        console.error(error);
        setError(error.message ?? error ?? "Unknown error");
      } finally {
        setIsLoading(false);
      }
    }
    load();
  }, []);

  const { user } = useActiveStates();

  // Filter organizations based on search query
  const filteredOrganizations = organizations.filter((org) => {
    const orgName = org.name?.toLowerCase() || "";
    const query = searchQuery.toLowerCase();
    return orgName.includes(query);
  });

  async function setGuruToken(organization: OrganizationEntity) {
    const realmId = prompt("Enter the Guru realm ID", "general");
    if (!realmId) {
      alert("No realm ID provided");
      return;
    }
    const token = prompt("Enter the Guru token");
    if (!token) {
      alert("No token provided");
      return;
    }

    const response = await organizationIntegrationTokenClient(clientError)
      .item(organization.id!, INTEGRATION_GURU, realmId)
      .upsert({
        encrypted_access_token: token,
        integration: INTEGRATION_GURU,
        organization_id: organization.id,
        realm_id: realmId,
        token_type: "collection",
        user_id: user.id,
      });
    if (response.success) {
      if (response.data.success) {
        alert("Token set");
        setOrgIntegrationTokens([
          ...orgIntegrationTokens.filter(
            (token) =>
              token.organization_id !== organization.id ||
              token.integration !== INTEGRATION_GURU ||
              token.realm_id !== realmId
          ),
          response.data.data,
        ]);
      } else {
        alert(response.data.errors.join(", "));
      }
    } else {
      alert(response.error ?? "Unknown error");
    }
  }

  if (isLoading) {
    return <StandardLoader title="Loading organizations" />;
  }

  if (error) {
    return <StandardError error={error} title="Error loading organizations" />;
  }

  return (
    <Table highlightOnHover stickyHeader>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <Text size="sm" fw={600} w={240}>
              Organization
            </Text>
          </Table.Th>
          <Table.Th>
            <Text size="sm" fw={600}>
              Entitlements
            </Text>
          </Table.Th>
          <Table.Th>Integrations</Table.Th>
          <Table.Th ta="right"></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {filteredOrganizations.map((organization) => (
          <Table.Tr key={organization.id}>
            <Table.Td>
              <Group gap="xs" style={{ "--group-wrap": "nowrap" }}>
                <OrganizationAvatar size={45} organization={organization} />
                <Stack gap={0}>
                  <Text fw={700}> {organization.name}</Text>
                  <Text c="dimmed" size="sm" fw={500}>
                    @{organization.slug}
                  </Text>
                </Stack>
              </Group>
            </Table.Td>
            <Table.Td>
              {organization.entitlements &&
              (organization.entitlements as unknown as number[]).length > 0 ? (
                (organization.entitlements as unknown as number[]).map(
                  (entitlement: number) => (
                    <Badge key={entitlement} variant="light" m={5}>
                      {OrganizationEntitlementLabels[
                        entitlement as keyof typeof OrganizationEntitlementLabels
                      ] ?? `Unknown (${entitlement})`}
                    </Badge>
                  )
                )
              ) : (
                <Text size="sm" c="dimmed">
                  No entitlements
                </Text>
              )}
            </Table.Td>

            <Table.Td>
              {orgIntegrationTokens
                .filter((token) => token.organization_id === organization.id)
                .map((token) => (
                  <Badge
                    key={`${token.organization_id}-${token.integration}`}
                    variant="light"
                    m={5}
                  >
                    {
                      INTEGRATION_LABELS[
                        token.integration as keyof typeof INTEGRATION_LABELS
                      ]
                    }
                    {(token.realm_id?.length ?? 0) > 0 &&
                      ` (${token.realm_id})`}
                  </Badge>
                ))}
            </Table.Td>
            <Table.Td p="md" ta="right">
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <ActionIcon variant="default" size="lg">
                    <IconDots size={20} />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => {
                      setGuruToken(organization).catch((e) => console.error(e));
                    }}
                  >
                    Set Guru Token
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
}
