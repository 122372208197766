import { useCallback, useEffect, useMemo } from "react";
import { AuthContext, StoredAuthProfile } from "./AuthContext.js";

import { UserEntity } from "../../common/entities/user.js";
import { userClient } from "../../common/utils/entityClient.js";
import { useAuth } from "../hooks/useAuth.js";
import { useLocalState } from "../hooks/useLocalState.js";
import { clientError } from "../utils/clientError.js";

const debug = false; // Enable debug logging

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { sessionId, session } = useAuth();
  // Derive a flag to check if the essential session data is ready
  const isSessionDataReady =
    !!session?.data?.user?.id && typeof sessionId === "number";

  debug &&
    console.log("AuthProvider Initial Render:", {
      sessionId,
      session: !!session,
    }); // Log 1
  const [storedAccounts, setStoredAccounts] = useLocalState<
    StoredAuthProfile[]
  >("authProfiles", []);
  const userClientInstance = userClient(clientError);

  // Calculate the current account based on the storedAccounts and current sessionId
  const account = useMemo(() => {
    debug &&
      console.log("AuthProvider Calculating account:", {
        sessionId,
        storedAccounts, // Use direct state
      });
    // Find the account in the stored list that matches the current active sessionId
    return storedAccounts.find((a) => a.sessionId === sessionId);
  }, [storedAccounts, sessionId]); // Depend on direct state and sessionId

  const updateAccount = useCallback(
    (newAccountData: StoredAuthProfile) => {
      if (!newAccountData.profile) {
        throw new Error(
          "Account profile is required when calling updateAccount"
        );
      }
      debug &&
        console.log("AuthProvider updateAccount called with:", newAccountData);

      // Update the storedAccounts state
      setStoredAccounts((prev: StoredAuthProfile[]) => {
        const existingAccountIndex = prev.findIndex(
          (a) => a.profile?.id === newAccountData.profile.id
        );
        let updatedAccounts;
        if (existingAccountIndex > -1) {
          updatedAccounts = prev.map((acc, index) =>
            index === existingAccountIndex
              ? {
                  ...acc,
                  profile: newAccountData.profile,
                  sessionId: newAccountData.sessionId,
                }
              : acc
          );
        } else {
          updatedAccounts = [
            ...prev.filter((a) => a.sessionId !== newAccountData.sessionId),
            newAccountData,
          ];
        }
        debug &&
          console.log(
            "AuthProvider updateAccount - new state:",
            updatedAccounts
          );
        return updatedAccounts.sort((a, b) => a.sessionId - b.sessionId); // Sorting seems okay
      });
    },
    [setStoredAccounts]
  );

  const deleteAccount = useCallback(
    (accountToDelete: StoredAuthProfile) => {
      // Update the storedAccounts state
      setStoredAccounts((prev: StoredAuthProfile[]) =>
        prev.filter((a) => a.sessionId !== accountToDelete.sessionId)
      );
    },
    [setStoredAccounts]
  );

  useEffect(() => {
    const profileExists = storedAccounts.some(
      // Check based on userId from session if data is ready
      (acc) => isSessionDataReady && acc.profile?.id === session.data.user.id
    );
    debug &&
      console.log("AuthProvider useEffect Triggered:", {
        sessionId,
        sessionExists: !!session,
        isSessionDataReady, // Log the flag
        profileExists,
        hasUserId: !!session?.data?.user?.id, // Keep original check for logging comparison
      });

    // Use the isSessionDataReady flag in the main condition
    if (isSessionDataReady && userClientInstance && !profileExists) {
      // We can now safely access session.data.user.id and sessionId
      const userId = session.data.user.id;
      const currentSessionId = sessionId;

      debug &&
        console.log(`AuthProvider: Fetching profile for user ${userId}...`);
      userClientInstance
        .item(userId)
        .get()
        .then((result) => {
          debug &&
            console.log(
              `AuthProvider: Fetch result for user ${userId}:`,
              result
            );
          if (result.success && result.data) {
            const fetchedProfile = result.data as UserEntity;
            debug &&
              console.log(
                `AuthProvider: Calling updateAccount with profile for session ${currentSessionId}`
              );
            updateAccount({
              profile: fetchedProfile,
              sessionId: currentSessionId,
            });
          } else {
            const errorMsg = !result.success
              ? result.error || `Failed to fetch profile for user ${userId}`
              : `Unknown error fetching profile for user ${userId}`;
            console.error(
              `AuthProvider: Failed to fetch profile for user ${userId}`,
              errorMsg,
              result
            );
            clientError(new Error(errorMsg));
          }
        })
        .catch((err) => {
          console.error(
            `AuthProvider: Error fetching profile for user ${userId}`,
            err
          );
          clientError(err);
        });
    }
    // Depend on the derived flag and other dependencies
  }, [
    isSessionDataReady,
    session,
    storedAccounts,
    updateAccount,
    userClientInstance,
    sessionId,
  ]); // Add isSessionDataReady

  debug && console.log("AuthProvider Final Render - Account value:", account);

  return (
    <AuthContext.Provider
      value={{
        account, // The calculated current account
        allAccounts: storedAccounts, // Provide the direct state list
        deleteAccount,
        updateAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
