import { Box, Loader, NavLink, Text, Title } from "@mantine/core";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { NavLink as ReactRouterNavLink } from "react-router-dom";

import { ConversationEntity } from "../../../common/entities/conversation.js";
import { conversationClient } from "../../../common/utils/entityClient.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { clientError } from "../../utils/clientError.js";
import { toISOString } from "../../utils/dateUtils.js";
import { PennyConversationState } from "./PennyConversation.js";

const headerStyles: CSSProperties = {
  color: "var(--mantine-color-dimmed)",
  fontSize: "10px",
  fontWeight: "bold",
  letterSpacing: "0.05em",
  textTransform: "uppercase",
};

function groupConversations(conversations: ConversationEntity[]) {
  const today = new Date();
  const oneDayAgo = new Date(today);
  oneDayAgo.setDate(today.getDate() - 1);
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const oneYearAgo = new Date(today);
  oneYearAgo.setFullYear(today.getFullYear() - 1);

  const todayConversations: ConversationEntity[] = [];
  const last7DaysConversations: ConversationEntity[] = [];
  const last30DaysConversations: ConversationEntity[] = [];
  const lastYearConversations: ConversationEntity[] = [];

  conversations.forEach((conversation) => {
    const updatedAt = new Date(conversation.updated_at!);
    if (updatedAt >= oneDayAgo) {
      todayConversations.push(conversation);
    } else if (updatedAt >= sevenDaysAgo) {
      last7DaysConversations.push(conversation);
    } else if (updatedAt >= thirtyDaysAgo) {
      last30DaysConversations.push(conversation);
    } else if (updatedAt >= oneYearAgo) {
      lastYearConversations.push(conversation);
    }
  });

  return {
    todayConversations,
    last7DaysConversations,
    last30DaysConversations,
    lastYearConversations,
  };
}

export function PennyConversationList({
  conversationState,
}: {
  conversationState: PennyConversationState;
}) {
  const [conversations, setConversations] = useState<ConversationEntity[]>([]);
  const { customer, serviceProvider } = useActiveStates();
  const [isLoading, setIsLoading] = useState(true);
  const [hasAddedNewConversation, setHasAddedNewConversation] = useState(false);

  const load = useCallback(async () => {
    // todo: add pagination
    try {
      const conversationsResponse = await conversationClient(clientError).list({
        provider_id: serviceProvider?.id,
        customer_id: customer?.id,
      });
      if (conversationsResponse.success) {
        setConversations(
          conversationsResponse.data.success
            ? (
                (
                  await Promise.all(conversationsResponse.data.data)
                ).reverse() as ConversationEntity[]
              ).filter((x: ConversationEntity) => x)
            : []
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [customer?.id, serviceProvider?.id]);

  useEffect(() => {
    load().catch((e: any) => console.error(e));
  }, [load]);

  useEffect(() => {
    // if we have a conversation id that isn't in the list, add it
    if (
      conversationState?.conversation &&
      typeof conversationState.conversation.id === "number" &&
      !conversations.some((c) => c.id === conversationState.conversation?.id)
    ) {
      // Add the new conversation to the list
      const newConversation = {
        id: conversationState.conversation.id,
        title: conversationState.conversation.title,
        created_at: toISOString(conversationState.conversation.created_at),
        updated_at: toISOString(conversationState.conversation.updated_at),
        provider_id: serviceProvider?.id,
        customer_id: customer?.id,
      };
      setConversations((prev) => {
        // Remove any existing version of this conversation
        const filtered = prev.filter((c) => c.id !== newConversation.id);
        // Add the new conversation at the top
        return [newConversation, ...filtered];
      });
    }
  }, [
    conversationState?.conversation?.id,
    conversations,
    serviceProvider?.id,
    customer?.id,
  ]);

  const {
    todayConversations,
    last7DaysConversations,
    last30DaysConversations,
    lastYearConversations,
  } = groupConversations(conversations);

  // Add new conversation to the list if we're in a new conversation
  if (
    (conversationState?.conversation?.id as unknown as string) === "new" ||
    (conversationState?.conversation?.id === undefined &&
      window.location.pathname.startsWith("/penny/conversations/new"))
  ) {
    todayConversations.unshift({
      title: conversationState?.conversation?.title ?? "New Conversation",
      id: "new" as unknown as number,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      provider_id: serviceProvider?.id,
      customer_id: customer?.id,
    });
  }

  // Sort conversations by updated_at, but keep new conversations at the top
  const sortConversations = (convs: ConversationEntity[]) => {
    return convs.sort((a, b) => {
      // Keep new conversations at the top
      if (a.id === ("new" as unknown as number)) return -1;
      if (b.id === ("new" as unknown as number)) return 1;

      const dateA = new Date(a.updated_at || new Date().toISOString());
      const dateB = new Date(b.updated_at || new Date().toISOString());
      return dateB.getTime() - dateA.getTime();
    });
  };

  // Sort each group of conversations
  sortConversations(todayConversations);
  sortConversations(last7DaysConversations);
  sortConversations(last30DaysConversations);
  sortConversations(lastYearConversations);

  // If we're in a new conversation, ensure it's selected
  const selectedConversationId =
    conversationState?.conversation?.id ??
    (window.location.pathname.startsWith("/penny/conversations/new")
      ? "new"
      : undefined);

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            alignContent: "flex-end",
            alignItems: "center",
            display: "flex",
            height: "fit-content",
            justifyContent: "center",
            overflow: "hidden",
            padding: "100px",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          {todayConversations.length > 0 && (
            <Title order={6} p="sm" style={headerStyles}>
              Today
            </Title>
          )}
          {todayConversations.map((conversation) => (
            <NavLink
              component={ReactRouterNavLink}
              key={conversation.id}
              to={`/penny/conversations/${conversation.id}`}
              label={conversation.title}
              active={conversation.id === selectedConversationId}
            />
          ))}

          {last7DaysConversations.length > 0 && (
            <Title order={6} p="sm" style={headerStyles}>
              Previous 7 days
            </Title>
          )}
          {last7DaysConversations.map((conversation) => (
            <NavLink
              component={ReactRouterNavLink}
              key={conversation.id}
              to={`/penny/conversations/${conversation.id}`}
              label={conversation.title}
              active={conversation.id === selectedConversationId}
            />
          ))}

          {last30DaysConversations.length > 0 && (
            <Title order={6} p="sm" style={headerStyles}>
              Previous 30 days
            </Title>
          )}
          {last30DaysConversations.map((conversation) => (
            <NavLink
              component={ReactRouterNavLink}
              key={conversation.id}
              to={`/penny/conversations/${conversation.id}`}
              label={conversation.title}
              active={conversation.id === selectedConversationId}
            />
          ))}

          {lastYearConversations.length > 0 && (
            <Title order={6} p="sm" style={headerStyles}>
              Previous year
            </Title>
          )}
          {lastYearConversations.map((conversation) => (
            <NavLink
              component={ReactRouterNavLink}
              key={conversation.id}
              to={`/penny/conversations/${conversation.id}`}
              label={conversation.title}
              active={conversation.id === selectedConversationId}
            />
          ))}

          {conversations?.length === 0 ? (
            <Text p="md">No conversations</Text>
          ) : null}
        </>
      )}
    </>
  );
}
