import { IntegerField } from "../types/field";

export const virtualCustomerIdField: IntegerField<{
  name: "customer_id";
  virtual: true;
}> = {
  label: "Customer ID",
  name: "customer_id",
  virtual: true,
  type: "integer",
};
