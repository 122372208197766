import { useCallback, useState } from "react";
import { Box, Chip, Grid, Group, Stack, Text } from "@mantine/core";
import {
  IconArrowsSplit,
  IconBox,
  IconSunElectricity,
} from "@tabler/icons-react";
import { LockedEntitlements } from "../../../common/configuration/entitlementMap.js";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationEntitlementLabels } from "../../../common/fields/entitlements.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { LEDGERAI_SLUG } from "../../../common/fields/slug.js";
import classes from "./OrganizationEntitlement.css.js";

export type UpdateEntitlements = (operations: {
  add_entitlement?: number;
  remove_entitlement?: number;
  add_extend_entitlement?: number;
  remove_extend_entitlement?: number;
}) => Promise<void>;

export function OrganizationEntitlement({
  organization,
  isServiceProvider,
  entitlementId,
  onUpdateEntitlements,
}: {
  organization: OrganizationEntity;
  isServiceProvider: boolean;
  entitlementId: number;
  onUpdateEntitlements: UpdateEntitlements;
}) {
  const { serviceProvider } = useActiveStates();
  const serviceProviderIsLedgerAI = serviceProvider?.slug === LEDGERAI_SLUG;

  const locked = LockedEntitlements.includes(entitlementId);
  const active = (organization.entitlements as unknown as number[])?.includes(
    entitlementId
  );
  const extended = (
    organization.extend_entitlements as unknown as number[]
  )?.includes(entitlementId);

  const [loading, setLoading] = useState(false);

  const updateEntitlements = useCallback(
    ({
      add_entitlement,
      add_extend_entitlement,
      remove_entitlement,
      remove_extend_entitlement,
    }: {
      add_entitlement?: number;
      add_extend_entitlement?: number;
      remove_entitlement?: number;
      remove_extend_entitlement?: number;
    }) => {
      setLoading(true);
      async function set() {
        await onUpdateEntitlements({
          add_entitlement,
          add_extend_entitlement,
          remove_entitlement,
          remove_extend_entitlement,
        });
      }
      set()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          alert("That didn't work: " + e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [onUpdateEntitlements, setLoading]
  );

  return (
    <Grid py="md" className={classes.entitlement}>
      <Grid.Col span={{ base: 12, sm: 6 }}>
        <Stack gap="xs">
          <Box>
            <Group gap="xs" c="dimmed">
              <IconBox size={16} />
              <Text size="sm" fw={500}>
                Name
              </Text>
            </Group>
            <Text style={{ lineHeight: "28px" }}>
              {
                OrganizationEntitlementLabels[
                  entitlementId as keyof typeof OrganizationEntitlementLabels
                ]
              }
            </Text>

            {locked ? (
              <Text size="sm" c="var(--mantine-color-pink-6)">
                This entitlement cannot be modified
              </Text>
            ) : active ? (
              extended ? (
                <Text size="sm" c="var(--mantine-color-blue-7)">
                  This entitlement can be extended to clients
                </Text>
              ) : isServiceProvider ? (
                <Text size="sm">
                  This entitlement can not be extended to clients
                </Text>
              ) : (
                <Text size="sm" c="var(--mantine-color-gray-4)">
                  This entitlement is active
                </Text>
              )
            ) : (
              <Text size="sm" c="var(--mantine-color-gray-4)">
                This entitlement is not active
              </Text>
            )}
          </Box>
        </Stack>
      </Grid.Col>
      <Grid.Col span={{ base: 12, sm: 6 }}>
        <Grid>
          <Grid.Col span={6}>
            <Stack gap="xs">
              <Group gap="xs" c="dimmed">
                <IconSunElectricity size={16} />
                <Text size="sm" fw={500}>
                  Active
                </Text>
              </Group>
              <Chip
                disabled={locked || loading}
                checked={active}
                onClick={() => {
                  updateEntitlements(
                    active
                      ? {
                          remove_entitlement: entitlementId,
                          remove_extend_entitlement: entitlementId,
                        }
                      : { add_entitlement: entitlementId }
                  );
                }}
              >
                {active ? "Active" : "Activate"}
              </Chip>
            </Stack>
          </Grid.Col>
          {isServiceProvider && serviceProviderIsLedgerAI ? (
            <Grid.Col span={6}>
              <Stack gap="xs">
                <Group gap="xs" c="dimmed">
                  <IconArrowsSplit size={16} />
                  <Text size="sm" fw={500}>
                    Client Access
                  </Text>
                </Group>
                <Chip
                  disabled={!active || locked || loading}
                  checked={extended}
                  onClick={() => {
                    updateEntitlements(
                      extended
                        ? { remove_extend_entitlement: entitlementId }
                        : { add_extend_entitlement: entitlementId }
                    );
                  }}
                >
                  {extended ? "Extended" : "Extend"}
                </Chip>
              </Stack>
            </Grid.Col>
          ) : null}
        </Grid>
      </Grid.Col>
    </Grid>
  );
}
