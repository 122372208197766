import { Box, Button, Group, Tooltip } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { network } from "../../../common/utils/network.js";
import { StandardError } from "../../components/Standard/StandardError.js";
import { StandardLoader } from "../../components/Standard/StandardLoader.js";
import { MarkdownRenderer } from "../../pages/penny/MarkdownRenderer.js";
import { clientError } from "../../utils/clientError.js";
import { AdminLayout } from "../layouts/AdminLayout.js";

export function AdminDocument() {
  const { document_id } = useParams();
  const [doc, setDoc] = useState<{ markdown: string } | null>(null);
  const [reload, setReload] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function load() {
      try {
        setIsLoading(true);
        setError(null);
        const docResponse = await network.get<{ markdown: string }>(
          `/api/docs/${document_id}`,
          {},
          clientError
        );
        if (docResponse.success) {
          if (docResponse.data) {
            setDoc(docResponse.data);
          } else {
            setError(new Error("No data returned"));
          }
        } else {
          setError(new Error(docResponse.error));
        }
      } catch (e: any) {
        console.error(e);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }
    load().catch((e) => console.error(e));
  }, [reload]);

  return (
    <AdminLayout>
      {isLoading ? (
        <StandardLoader title="Loading document" />
      ) : (
        <>
          {error && (
            <StandardError error={error} title="Error loading document" />
          )}
          <Group>
            <Tooltip
              label="Reload document"
              position="left"
              withArrow
              zIndex={301}
            >
              <Button
                disabled={isLoading}
                size="sm"
                variant="outline"
                style={{
                  bottom: "11px",
                  flexShrink: "0",
                  paddingInline: "4px",
                  position: "fixed",
                  right: "24px",
                }}
                onClick={() => setReload((x) => x + 1)}
              >
                <IconRefresh />
              </Button>
            </Tooltip>
          </Group>
          {doc ? (
            <Box p="md">
              <MarkdownRenderer content={doc.markdown} />
            </Box>
          ) : null}
        </>
      )}
    </AdminLayout>
  );
}
