import { AdminHomePage } from "../HomePage.js";
import { AdminLayout } from "../layouts/AdminLayout.js";

export function AdminWelcome() {
  return (
    <AdminLayout>
      <AdminHomePage />
    </AdminLayout>
  );
}
