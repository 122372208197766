import {
  ActionIcon,
  Badge,
  Code,
  Group,
  Loader,
  Menu,
  Popover,
  ScrollArea,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCopy,
  IconDots,
  IconExternalLink,
  IconTrash,
} from "@tabler/icons-react";
import { useClipboard } from "@mantine/hooks";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { LEDGERAI_SLUG } from "../../../common/fields/slug.js";
import { organizationClient } from "../../../common/utils/entityClient.js";
import { StandardEmpty } from "../../components/Standard/StandardEmpty.js";
import { StandardError } from "../../components/Standard/StandardError.js";
import { StandardLoader } from "../../components/Standard/StandardLoader.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { useAuth } from "../../hooks/useAuth.js";
import { useKvStorage } from "../../hooks/useKvStorage.js";
import { clientError } from "../../utils/clientError.js";
import { AdminLayout } from "../layouts/AdminLayout.js";

const debug = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface BuildData {
  os: {
    platform: string;
    arch: string;
    version: string;
    release: string;
  };
  type: string;
  env: Record<string, any>;
  config: {
    rootPackageJson: any;
    appPackageJson: any;
    rootTsConfig: any;
    appTsConfig: any;
  };
  status?: "started" | "completed" | "error";
  startTime?: string;
  endTime?: string;
  errorMessage?: string;
}

function parseBuildId(buildId: string) {
  const parts = buildId.split("-");
  if (parts.length < 5) {
    return {
      isoDateTime: null,
      environment: "unknown",
      isVercel: false,
    };
  }

  const isoDateTime = `${parts[0]}-${parts[1]}-${parts[2]}T${parts[3]}:${parts[4]}:${parts[5]}`;

  const envPart = parts[parts.length - 1];
  const isVercel = envPart.endsWith("--vercel");
  const environment = envPart.replace("--vercel", "");

  return {
    isoDateTime,
    environment,
    isVercel,
  };
}

export function AdminBuilds() {
  const [builds, setBuilds] = useState<{ id: string; data: BuildData }[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deletingBuildId, setDeletingBuildId] = useState<string | null>(null);
  const { serviceProvider } = useActiveStates();
  const { session } = useAuth();
  const accessToken = session?.accessToken;
  const clipboard = useClipboard({ timeout: 1000 });

  const { full: kvStore } = useKvStorage("ledgerai-builds", {
    user: { id: 1 },
    provider: { id: serviceProvider?.id ?? 1 },
    customer: { id: -1 },
  });

  const loadOrganization = useCallback(async () => {
    try {
      const response = await organizationClient(clientError).list({
        slug: LEDGERAI_SLUG,
      });
      if (
        !response.success ||
        !response.data?.success ||
        !response.data?.data?.length
      ) {
        throw new Error("Could not find LedgerAI organization");
      }
      const org = response.data.data[0] as OrganizationEntity;
      if (typeof org.id !== "number") {
        throw new Error("Invalid organization ID");
      }
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to load organization"
      );
      console.error("Error loading organization:", err);
    }
  }, []);

  const loadBuilds = useCallback(async () => {
    if (!serviceProvider?.id) return;

    try {
      setLoading(true);
      setError(null);

      debug &&
        console.log("AdminBuilds: Calling kvStore.list() using full client");
      const listResult = await kvStore.list();
      debug && console.log("AdminBuilds: kvStore.list() returned:", listResult);

      if (!listResult.success || !listResult.data) {
        const errorMsg = !listResult.success
          ? "Failed to fetch build list from storage (request unsuccessful)"
          : "Failed to fetch build list: No data returned";
        console.error(
          "AdminBuilds: kvStore.list() failed or returned no data:",
          listResult
        );
        setError(errorMsg);
        setLoading(false);
        return;
      }

      const kvItems = listResult.data;

      if (
        !Array.isArray(kvItems) ||
        !kvItems.every(
          (item) =>
            item &&
            typeof item === "object" &&
            typeof item.key === "string" &&
            typeof item.value === "string"
        )
      ) {
        console.error(
          "AdminBuilds: kvStore.list() data is not an array of { key, value } objects!",
          kvItems
        );
        setError(
          "Failed to load builds: Invalid data format received from storage."
        );
        setLoading(false);
        return;
      }

      const processedBuilds = kvItems
        .map((item) => {
          if (!item) return null;
          try {
            if (item.value == null) {
              console.warn(
                `Skipping item with null value for key: ${item.key}`
              );
              return null;
            }
            const buildData = JSON.parse(item.value);
            if (!buildData || typeof buildData.os !== "object") {
              console.warn(`Skipping invalid build data for key: ${item.key}`);
              return null;
            }
            return { id: item.key, data: buildData as BuildData };
          } catch (parseError) {
            console.error(
              `AdminBuilds: Failed to parse build data for key: ${item.key}`,
              parseError
            );
            return null;
          }
        })
        .filter(
          (build): build is { id: string; data: BuildData } => build !== null
        );

      debug &&
        console.log(
          "AdminBuilds: Processed builds before setting state:",
          processedBuilds
        );
      setBuilds(processedBuilds);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to load builds");
      console.error("Error loading builds:", err);
    } finally {
      setLoading(false);
    }
  }, [kvStore, serviceProvider?.id]);

  const handleDeleteBuild = useCallback(
    async (buildId: string) => {
      if (!buildId) return;
      setDeletingBuildId(buildId);
      setError(null);
      try {
        debug && console.log(`AdminBuilds: Removing build ${buildId}`);
        await kvStore.removeItem(buildId);

        debug &&
          console.log(
            `AdminBuilds: Successfully removed/confirmed removal of build ${buildId}`
          );
        setBuilds((prevBuilds) => prevBuilds.filter((b) => b.id !== buildId));
      } catch (err) {
        const errorMsg =
          err instanceof Error ? err.message : "Failed to remove build";
        setError(errorMsg);
        console.error(`Error removing build ${buildId}:`, err);
      } finally {
        setDeletingBuildId(null);
      }
    },
    [kvStore]
  );

  useEffect(() => {
    loadOrganization();
  }, [loadOrganization]);

  useEffect(() => {
    if (serviceProvider?.id) {
      loadBuilds();
    }
  }, [loadBuilds, serviceProvider?.id]);

  return (
    <AdminLayout>
      <Stack p="sm">
        <Title order={2} p="xs">
          Builds
        </Title>
      </Stack>
      <ScrollArea h={`calc(100vh - 200px)`}>
        {error ? (
          <StandardError error={error} title="Error loading builds" />
        ) : loading ? (
          <StandardLoader title="Loading builds..." />
        ) : (
          <>
            <Table highlightOnHover withTableBorder withColumnBorders>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Date & Time</Table.Th>
                  <Table.Th>Environment</Table.Th>
                  <Table.Th>Status</Table.Th>
                  <Table.Th>URL</Table.Th>
                  <Table.Th>Build ID</Table.Th>
                  <Table.Th>Platform</Table.Th>
                  <Table.Th>Duration</Table.Th>
                  <Table.Th>Actions</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {builds.map((build) => {
                  const { isoDateTime, environment, isVercel } = parseBuildId(
                    build.id
                  );
                  const vercelBranchUrl = build.data.env?.VERCEL_BRANCH_URL;
                  const status = build.data.status || "unknown";
                  const startTime = build.data.startTime
                    ? moment(build.data.startTime)
                    : null;
                  const endTime = build.data.endTime
                    ? moment(build.data.endTime)
                    : null;

                  // Calculate duration in seconds with 3 decimal places
                  const duration =
                    startTime && endTime
                      ? (endTime.diff(startTime) / 1000).toFixed(3) + " s"
                      : null;

                  // --- Status and Timeout Logic ---
                  let displayStatus = status;
                  let statusColor = "gray";
                  const errorMessage = build.data.errorMessage;

                  const isTimeout =
                    status === "started" &&
                    startTime &&
                    moment().diff(startTime, "minutes") > 15;

                  if (status === "error") {
                    displayStatus = "error";
                    statusColor = "red";
                  } else if (isTimeout) {
                    displayStatus = "timeout";
                    statusColor = "orange";
                  } else if (status === "completed") {
                    displayStatus = "completed";
                    statusColor = "teal";
                  } else if (status === "started") {
                    displayStatus = "started";
                    statusColor = "blue";
                  }
                  // --- End Status and Timeout Logic ---

                  const formattedDateTime = isoDateTime
                    ? moment(isoDateTime).format("MMM D, YYYY h:mm:ss A")
                    : "Invalid Date";
                  const fullIsoDateTime = isoDateTime
                    ? moment(isoDateTime).toISOString()
                    : "N/A";

                  const sessionBaseUrl =
                    vercelBranchUrl || window.location.origin;
                  const sessionUrl = accessToken
                    ? `${sessionBaseUrl}#accessToken=${accessToken}`
                    : sessionBaseUrl;

                  return (
                    <Table.Tr key={build.id}>
                      <Table.Td>
                        <Tooltip label={`Timestamp: ${fullIsoDateTime}`}>
                          <Text size="sm">{formattedDateTime}</Text>
                        </Tooltip>
                      </Table.Td>
                      <Table.Td>
                        <Group gap="xs">
                          <Text size="sm" tt="capitalize">
                            {environment}
                          </Text>
                          {isVercel && <Badge size="sm">Vercel</Badge>}
                        </Group>
                      </Table.Td>
                      <Table.Td>
                        {errorMessage ? (
                          <Popover
                            width={300}
                            position="bottom"
                            withArrow
                            shadow="md"
                            clickOutsideEvents={["mouseup", "touchend"]}
                          >
                            <Popover.Target>
                              <Badge
                                variant="light"
                                color={statusColor}
                                style={{ cursor: "pointer" }}
                              >
                                {displayStatus}
                              </Badge>
                            </Popover.Target>
                            <Popover.Dropdown>
                              <Text size="sm">{errorMessage}</Text>
                            </Popover.Dropdown>
                          </Popover>
                        ) : (
                          <Badge variant="light" color={statusColor}>
                            {displayStatus}
                          </Badge>
                        )}
                      </Table.Td>
                      <Table.Td>
                        {vercelBranchUrl ? (
                          <Text
                            component="a"
                            href={vercelBranchUrl}
                            target="_blank"
                            size="sm"
                          >
                            {
                              vercelBranchUrl
                                .replace("https://", "")
                                .split("/")[0]
                            }
                          </Text>
                        ) : (
                          <Text size="sm" c="dimmed">
                            N/A
                          </Text>
                        )}
                      </Table.Td>
                      <Table.Td>
                        <Tooltip label={build.id}>
                          <Code>{build.id.split("-").slice(-2).join("-")}</Code>
                        </Tooltip>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm">
                          {build.data.os.platform} ({build.data.os.arch})
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" c={duration ? undefined : "dimmed"}>
                          {duration ?? "N/A"}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        {deletingBuildId === build.id ? (
                          <Loader size="xs" />
                        ) : (
                          <Menu position="bottom-end" withArrow shadow="md">
                            <Menu.Target>
                              <ActionIcon variant="subtle">
                                <IconDots size={16} />
                              </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                              {vercelBranchUrl && accessToken && (
                                <Menu.Item
                                  component="a"
                                  href={`${vercelBranchUrl}#accessToken=${accessToken}`}
                                  target="_blank"
                                  leftSection={<IconExternalLink size={14} />}
                                >
                                  Sign in in new tab
                                </Menu.Item>
                              )}
                              {accessToken && (
                                <Menu.Item
                                  leftSection={<IconCopy size={14} />}
                                  onClick={() => clipboard.copy(sessionUrl)}
                                  color={clipboard.copied ? "teal" : undefined}
                                >
                                  {clipboard.copied
                                    ? "Copied!"
                                    : "Copy Session URL"}
                                </Menu.Item>
                              )}
                              <Menu.Item
                                leftSection={<IconTrash size={14} />}
                                color="red"
                                onClick={() => handleDeleteBuild(build.id)}
                              >
                                Delete build
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        )}
                      </Table.Td>
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </Table>
            {builds.length === 0 && <StandardEmpty title="No builds found" />}
          </>
        )}
      </ScrollArea>
    </AdminLayout>
  );
}
