import { IntegerField } from "../types/field";

export const entitlementsField: IntegerField<{
  array: true;
  name: "entitlements";
  required: true;
}> = {
  array: true,
  label: "Entitlements",
  name: "entitlements",
  required: true,
  type: "integer",
};

// entitlements available to organizations

export const ENTITLEMENT_IS_SERVICE_PROVIDER = 0 as const;
export const ENTITLEMENT_SAMPLE = 2 as const;

export const OrganizationEntitlementsServiceProvider: number[] = [
  ENTITLEMENT_IS_SERVICE_PROVIDER,
];

export const OrganizationEntitlementsCustomer: number[] = [];

export const OrganizationEntitlementLabels = {
  [ENTITLEMENT_IS_SERVICE_PROVIDER]: "Service Provider",
  [ENTITLEMENT_SAMPLE]: "Sample Entitlement",
};

// entitlements available to both organization users and organization relationship users

export const ENTITLEMENT_VIEW_FILES = 11 as const;
export const ENTITLEMENT_MANAGE_FILES = 12 as const;
export const ENTITLEMENT_ACCESS_QUICKBOOKS = 13 as const;

// entitlements available to organization relationship users

export const ENTITLEMENT_IS_CUSTOMER_OWNER = 1 as const;
export const ENTITLEMENT_SAMPLE2 = 2 as const;

export const OrganizationRelationshipUserEntitlements: number[] = [
  ENTITLEMENT_IS_CUSTOMER_OWNER,
  ENTITLEMENT_SAMPLE,
  ENTITLEMENT_VIEW_FILES,
  ENTITLEMENT_MANAGE_FILES,
  ENTITLEMENT_ACCESS_QUICKBOOKS,
];

export const OrganizationRelationshipUserEntitlementLabels = {
  [ENTITLEMENT_IS_CUSTOMER_OWNER]: "Customer Owner",
  [ENTITLEMENT_SAMPLE2]: "Sample Entitlement",
  [ENTITLEMENT_VIEW_FILES]: "View Files",
  [ENTITLEMENT_MANAGE_FILES]: "Manage Files",
  [ENTITLEMENT_ACCESS_QUICKBOOKS]: "Access QuickBooks",
};

// entitlements available to organization users

export const ENTITLEMENT_MANAGE_CLIENTS = 3 as const;
export const ENTITLEMENT_MANAGE_ORGANIZATION_SETTINGS = 4 as const;
export const ENTITLEMENT_MANAGE_ORGANIZATION_MEMBERS = 5 as const;
export const ENTITLEMENT_VIEW_WORKFLOWS = 6 as const;
export const ENTITLEMENT_MANAGE_WORKFLOWS = 7 as const;
export const ENTITLEMENT_PENNY_SELECT_MODEL = 8 as const;
export const ENTITLEMENT_VIEW_ACTIVITY = 9 as const;
export const ENTITLEMENT_VIEW_DASHBOARD = 10 as const;
// entitlements available to organization users

export const OrganizationUserEntitlements: number[] = [
  ENTITLEMENT_MANAGE_CLIENTS,
  ENTITLEMENT_MANAGE_ORGANIZATION_SETTINGS,
  ENTITLEMENT_MANAGE_ORGANIZATION_MEMBERS,
  ENTITLEMENT_VIEW_WORKFLOWS,
  ENTITLEMENT_MANAGE_WORKFLOWS,
  ENTITLEMENT_PENNY_SELECT_MODEL,
  ENTITLEMENT_VIEW_ACTIVITY,
  ENTITLEMENT_VIEW_DASHBOARD,
  ENTITLEMENT_VIEW_FILES,
  ENTITLEMENT_MANAGE_FILES,
  ENTITLEMENT_ACCESS_QUICKBOOKS,
];

export const OrganizationUserEntitlementLabels = {
  [ENTITLEMENT_MANAGE_CLIENTS]: "Manage Clients",
  [ENTITLEMENT_MANAGE_ORGANIZATION_SETTINGS]: "Manage Organization Settings",
  [ENTITLEMENT_MANAGE_ORGANIZATION_MEMBERS]: "Manage Organization Members",
  [ENTITLEMENT_VIEW_WORKFLOWS]: "View Workflows",
  [ENTITLEMENT_MANAGE_WORKFLOWS]: "Manage Workflows",
  [ENTITLEMENT_PENNY_SELECT_MODEL]: "Penny Select Model",
  [ENTITLEMENT_VIEW_ACTIVITY]: "View Activity",
  [ENTITLEMENT_VIEW_DASHBOARD]: "View Dashboard",
  [ENTITLEMENT_VIEW_FILES]: "View Files",
  [ENTITLEMENT_MANAGE_FILES]: "Manage Files",
  [ENTITLEMENT_ACCESS_QUICKBOOKS]: "Access QuickBooks",
};
