import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";

import { App } from "./App.js";
import { theme } from "./theme.js";
import { AuthProvider } from "./contexts/AuthContextProvider.js";

TimeAgo.addDefaultLocale(en);

const default_theme = theme("#231F20", "#231F20", false);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <MantineProvider theme={default_theme} defaultColorScheme="auto">
      <Notifications position="bottom-center" zIndex={1000} />
      <AuthProvider>
        <App />
      </AuthProvider>
    </MantineProvider>
  </StrictMode>
);
