import { ActionIcon, Group, Text, Title, Tooltip } from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";

import { formatBytes } from "../../utils/formatters.js";
import { AsideToggleButton } from "../Buttons/AsideToggleButton.js";
import { useAside } from "../../hooks/useAside.js";

interface FileExplorerHeaderProps {
  storageUsed: number;
  storageTotal: number;
  usedStoragePercentage: number;
  onUpload: () => void;
  currentPath: string;
}

/**
 * Header component for the file explorer showing title, storage usage, and upload button
 */
export function FileExplorerHeader({
  storageUsed,
  storageTotal,
  usedStoragePercentage,
  onUpload,
  currentPath,
}: FileExplorerHeaderProps) {
  const { openPennyChat, isPennyChatOpen, currentFolderPath } = useAside();

  // Log context for diagnostic purposes
  console.log("FileExplorerHeader - aside context:", {
    isPennyChatOpen,
    currentPath,
    currentFolderPath,
    pathsMatch: currentPath === currentFolderPath,
  });

  // Enhanced upload handler that ensures the PennyChat opens with the current path
  const handleUpload = () => {
    console.log("Upload button clicked - currentPath:", currentPath);

    // Simply call the parent onUpload handler which has been updated to handle both scenarios
    onUpload();
  };

  return (
    <Group justify="space-between" p="md">
      <Title order={3}>File Explorer</Title>
      <Group>
        <Text size="sm">
          Storage: {formatBytes(storageUsed)} / {formatBytes(storageTotal)} (
          {usedStoragePercentage}%)
        </Text>
        <Tooltip label="Chat and upload files in this folder">
          <AsideToggleButton folderPath={currentPath} />
        </Tooltip>
        <Tooltip label="Upload files directly">
          <ActionIcon color="blue" variant="filled" onClick={handleUpload}>
            <IconUpload size={16} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Group>
  );
}
