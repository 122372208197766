import { useMemo, useState } from "react";

import { organizationUserClient } from "../../../common/utils/entityClient.js";

import { clientError } from "../../utils/clientError.js";
import { OrganizationUserEntity } from "../../../common/entities/organizationUser.js";
import { UserEntity } from "../../../common/entities/user.js";

export type OrganizationUserWithUser = OrganizationUserEntity & {
  User: UserEntity;
};

export interface UsersTableProps {
  hasManageEntitlement: boolean;
  onDelete?: (orgUser: OrganizationUserWithUser) => Promise<void>;
  onSendInvitation?: (user: UserEntity) => Promise<void>;
  users: OrganizationUserWithUser[];
  deletingUserIds?: Set<number>;
  sendingInvitationUserIds?: Set<number>;
  updateUsersTable: (orgUser: OrganizationUserWithUser) => void;
}

export function useUpdateUserEntitlements({
  onSuccess,
}: {
  onSuccess: (updatedUser: OrganizationUserWithUser) => void;
}) {
  const [loadingEntitlement, setLoadingEntitlement] = useState<number | null>(
    null
  );
  const manageEntitlements = useMemo(() => {
    return (orgUser: OrganizationUserWithUser) => {
      return {
        add: async (entitlement: number) => {
          setLoadingEntitlement(entitlement);
          const updatedUser: OrganizationUserWithUser = {
            ...orgUser,
            entitlements: [
              ...((orgUser.entitlements as unknown as number[])?.filter(
                (e) => e !== entitlement
              ) ?? []),
              entitlement,
            ] as unknown as OrganizationUserWithUser["entitlements"],
          };
          if (typeof updatedUser.user_id !== "number") {
            throw new Error("User ID is required");
          }
          if (typeof updatedUser.organization_id !== "number") {
            throw new Error("Organization ID is required");
          }
          const orgUserResponse = await organizationUserClient(clientError)
            .item(updatedUser.organization_id, updatedUser.user_id)
            .update({ entitlements: updatedUser.entitlements });
          if (orgUserResponse.success) {
            onSuccess?.(updatedUser);
          } else {
            throw new Error(orgUserResponse.error);
          }
          setLoadingEntitlement(null);
        },
        remove: async (entitlement: number) => {
          setLoadingEntitlement(entitlement);
          const updatedUser: OrganizationUserWithUser = {
            ...orgUser,
            entitlements: ((
              orgUser.entitlements as unknown as number[]
            )?.filter((e) => e !== entitlement) ??
              []) as unknown as OrganizationUserWithUser["entitlements"],
          };
          if (typeof updatedUser.user_id !== "number") {
            throw new Error("User ID is required");
          }
          if (typeof updatedUser.organization_id !== "number") {
            throw new Error("Organization ID is required");
          }
          const orgUserResponse = await organizationUserClient(clientError)
            .item(updatedUser.organization_id, updatedUser.user_id)
            .update({ entitlements: updatedUser.entitlements });
          if (orgUserResponse.success) {
            onSuccess?.(updatedUser);
          } else {
            throw new Error(orgUserResponse.error);
          }
          setLoadingEntitlement(null);
        },
      };
    };
  }, []);

  return { loadingEntitlement, manageEntitlements };
}
