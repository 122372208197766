import {
  ActionIcon,
  ColorSwatch,
  Group,
  Popover,
  Tooltip,
  useComputedColorScheme,
} from "@mantine/core";
import { IconCheck, IconDropletOff, IconPalette } from "@tabler/icons-react";
import { useCallback, useState } from "react";

const DEFAULT_BACKGROUND_COLOR = "var(--mantine-color-body)";

export function ColorPopover({
  selectedColor,
  onChangeColor,
  setColorPopoverOpened,
  colorPopoverOpened,
  lightModeColors,
  darkModeColors,
  colorNames,
}: {
  selectedColor: string;
  onChangeColor: (color: string) => void | Promise<void>;
  setColorPopoverOpened: (opened: boolean) => void;
  colorPopoverOpened: boolean;
  lightModeColors: string[];
  darkModeColors: string[];
  colorNames: string[];
}) {
  const [isSaving, setIsSaving] = useState(false);

  const isDarkMode = useComputedColorScheme() === "dark";

  const knownDarkColor = useCallback(
    (lightModeColor: string) => {
      const index = lightModeColors.indexOf(lightModeColor);
      return darkModeColors[index];
    },
    [lightModeColors, darkModeColors]
  );

  const colors = isDarkMode ? darkModeColors : lightModeColors;

  return (
    <Popover
      onChange={setColorPopoverOpened}
      opened={colorPopoverOpened}
      position="bottom"
      shadow="md"
      withArrow
    >
      <Popover.Target>
        <Tooltip label="Background options">
          <ActionIcon
            variant="subtle"
            color="var(--mantine-color-text)"
            size="sm"
            radius="sm"
            onClick={(e) => {
              e.stopPropagation();
              setColorPopoverOpened(!colorPopoverOpened);
            }}
          >
            <IconPalette />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>
        <Group>
          <ColorSwatch
            title="Default"
            color={DEFAULT_BACKGROUND_COLOR}
            size={24}
            onClick={async (e) => {
              e.stopPropagation();
              setIsSaving(true);
              await onChangeColor("");
              setIsSaving(false);
            }}
            style={{
              cursor: "pointer",
              boxShadow:
                selectedColor === ""
                  ? `0 0 0 2px ${isDarkMode ? "#ffffffd0" : "#000000d0"}`
                  : "none",
            }}
          >
            <IconDropletOff size={16} />
            {selectedColor === "" && (
              <IconCheck
                size={12}
                style={{
                  backgroundColor: isDarkMode ? "#fff" : "#000",
                  borderRadius: "100%",
                  color: isDarkMode ? "#000" : "#fff",
                  overflow: "hidden",
                  position: "absolute",
                  right: "-4px",
                  top: "-4px",
                }}
              />
            )}
          </ColorSwatch>
          {colors.map((color, colorIndex) => (
            <ColorSwatch
              title={colorNames[colorIndex]}
              key={color}
              color={color}
              size={24}
              onClick={async (e) => {
                e.stopPropagation();
                setIsSaving(true);
                const lightModeColor = lightModeColors[colorIndex];
                await onChangeColor(lightModeColor);
                setIsSaving(false);
              }}
              style={{
                cursor: "pointer",
                boxShadow:
                  selectedColor === lightModeColors[colorIndex]
                    ? `0 0 0 2px ${isDarkMode ? "#ffffffd0" : "#000000d0"}`
                    : "none",
              }}
            >
              {selectedColor === lightModeColors[colorIndex] && (
                <IconCheck
                  size={12}
                  style={{
                    backgroundColor: isDarkMode ? "#fff" : "#000",
                    borderRadius: "100%",
                    color: isDarkMode ? "#000" : "#fff",
                    overflow: "hidden",
                    position: "absolute",
                    right: "-4px",
                    top: "-4px",
                  }}
                />
              )}
            </ColorSwatch>
          ))}
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
}
