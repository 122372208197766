import { Group, Paper, ThemeIcon, Text, Stack } from "@mantine/core";
import {
  IconFile,
  IconFileTypeXls,
  IconFileTypePdf,
  IconFileTypeCsv,
} from "@tabler/icons-react";

import { formatBytes } from "../../utils/formatters.js";
import { ChatAttachment } from "../../contexts/ActiveStatesContext.js";

import classes from "./UploadedFile.css.js";

function getIcon(fileType: string | undefined) {
  switch (fileType) {
    case "xls":
      return IconFileTypeXls;
    case "pdf":
      return IconFileTypePdf;
    case "csv":
      return IconFileTypeCsv;
    default:
      return IconFile;
  }
}

export default function UploadedFile({
  file,
  actions,
  widthFitContent = false,
}: {
  file: ChatAttachment;
  actions?: React.ReactNode;
  widthFitContent?: boolean;
}) {
  const fileType = file.name.split(".").pop();
  const Icon = getIcon(fileType);

  return (
    <Paper
      key={file.url}
      p="xs"
      withBorder
      className={widthFitContent ? classes.fileInline : classes.file}
    >
      <Group gap="xs" wrap="nowrap" justify="space-between">
        <Group gap="xs" wrap="nowrap">
          <ThemeIcon>
            <Icon size={16} style={{ flexShrink: 0 }} />
          </ThemeIcon>
          <Stack gap={0}>
            <Text size="sm" className={classes.fileName}>
              {file.name}
            </Text>
            {file.size > 0 && (
              <Text size="xs" c="dimmed" className={classes.fileSize}>
                {formatBytes(file.size)}
              </Text>
            )}
          </Stack>
        </Group>
        {actions}
      </Group>
    </Paper>
  );
}
