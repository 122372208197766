import { css } from "./utils/css.js";

export default css`
  html,
  body {
    overflow-x: hidden;
  }
  div.!mantine-ColorInput-section {
    pointer-events: none !important;
  }
  table {
    width: 100%;
  }
  .noteItem a,
  .noteItem a:visited {
    color: #1155cc;
  }
  th,
  td {
    text-align: left;
    vertical-align: top;
  }
  div.!mantine-Alert-message > div > *:first-child {
    margin-top: 0;
  }
  div.!mantine-Alert-message > div > *:last-child {
    margin-bottom: 0;
  }
  div.!mantine-Alert-message blockquote > *:first-child {
    margin-top: 0;
  }
  div.!mantine-Alert-message blockquote > *:last-child {
    margin-bottom: 0;
  }
  div.!mantine-Alert-message blockquote {
    background-color: #f0f0f080;
    border: 1px solid #40404080;
    border-radius: 0.25rem;
    border: none;
    box-shadow: inset 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 1rem;
  }
  div.!mantine-Card-root > div > *:first-child {
    margin-top: 0;
  }
  div.!mantine-Card-root > div > *:last-child {
    margin-bottom: 0;
  }
`;
