import { aboutField } from "../fields/about";
import { archivedAtField } from "../fields/archived_at";
import { auth0UserIdField } from "../fields/auth0_user_id";
import {
  CONTACT_PREFERENCE_EMAIL,
  CONTACT_PREFERENCE_TEXT,
  contactPreferenceField,
} from "../fields/contact_preference";
import { contactTimesField } from "../fields/contact_times";
import { createdAtField } from "../fields/created_at";
import { emailField } from "../fields/email";
import { emailVerifiedField } from "../fields/email_verified";
import { familyNameField } from "../fields/family_name";
import { givenNameField } from "../fields/given_name";
import { idField } from "../fields/id";
import { nicknameField } from "../fields/nickname";
import { pictureField } from "../fields/picture";
import { socialAuthProviderField } from "../fields/social_auth_provider";
import { teamIdField } from "../fields/team_id";
import { timezoneField } from "../fields/timezone";
import { updatedAtField } from "../fields/updated_at";
import { virtualCustomerIdField } from "../fields/virtual_customer_id";
import { virtualProviderIdField } from "../fields/virtual_provider_id";
import { EntityDocs } from "../types/docs";
import {
  entity,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";

export const fields = [
  aboutField,
  archivedAtField,
  auth0UserIdField,
  contactPreferenceField,
  contactTimesField,
  createdAtField,
  emailField,
  emailVerifiedField,
  familyNameField,
  givenNameField,
  idField,
  nicknameField,
  pictureField,
  socialAuthProviderField,
  teamIdField,
  timezoneField,
  updatedAtField,
  virtualCustomerIdField,
  virtualProviderIdField,
] satisfies FieldList;

export const primaryKeyFields = [idField] satisfies PrimaryKeyFieldTuple;

// Docs
export const docs: EntityDocs = {
  collection: {
    summary: "List users.",
  },
  item: { summary: "Perform operations on a user." },
};

export const userEntity = entity({
  api: {
    collectionPath: "users",
    itemPath: "users/:id",
    specialId: "me",
  },
  docs,
  name: "User",
  defaultJoinFields: [
    idField,
    givenNameField,
    familyNameField,
    nicknameField,
  ] as FieldList,
  fields,
  primaryKeyFields,
  tableName: "users",
} as const);

export type UserEntity = EntityType<typeof fields, typeof primaryKeyFields>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleUser: UserEntity = {
  about: "I love painting",
  contact_preference: [CONTACT_PREFERENCE_EMAIL, CONTACT_PREFERENCE_TEXT],
  contact_times: [[960, 1080]], // i.e. 4pm - 6pm
  created_at: new Date().toISOString(),
  email_verified: false,
  email: "hello@world.com",
  family_name: "User",
  given_name: "Example",
  id: 1234,
  nickname: "ExampleUser",
  updated_at: new Date().toISOString(),
};

export function getUserName(user: UserEntity) {
  const nickname =
    typeof user.nickname === "string" && user.nickname.length > 0
      ? ` (${user.nickname})`
      : "";
  if (
    typeof user.given_name === "string" &&
    user.given_name.length > 0 &&
    typeof user.family_name === "string" &&
    user.family_name.length > 0
  ) {
    return `${user.given_name} ${user.family_name}${nickname}`;
  }
  if (typeof user.given_name === "string" && user.given_name.length > 0) {
    return `${user.given_name}${nickname}`;
  }
  if (typeof user.family_name === "string" && user.family_name.length > 0) {
    return `${user.family_name}${nickname}`;
  }
  if (typeof user.email === "string" && user.email.length > 0) {
    return `${user.email}${nickname}`;
  }
  return typeof user.nickname === "string" && user.nickname.length > 0
    ? user.nickname
    : "No Name";
}
