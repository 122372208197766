import { UserEntity } from "../../common/entities/user";

const SUPER_ADMIN_EMAIL_ADDRESSES = [
  "ashley@ledger.ai",
  "nate@ledger.ai",
  "ryan@ledger.ai",
];

export function isSuperAdmin(user: UserEntity) {
  // Email must be verified
  if (!user.email_verified) {
    return false;
  }
  // Email must have a super admin name
  if (SUPER_ADMIN_EMAIL_ADDRESSES.includes(user.email)) {
    return true;
  }
  return false;
}
