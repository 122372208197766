import { notifications } from "@mantine/notifications";
import { useCallback, useState } from "react";

import {
  OrganizationEntity,
  OrganizationUpdateType,
} from "../../common/entities/organization.js";
import { slugField } from "../../common/fields/slug.js";
import { virtualClientOfOrganizationIdField } from "../../common/fields/virtual_client_of_organization_id.js";
import { EntityServiceResult } from "../../common/types/entity.js";
import { organizationClient } from "../../common/utils/entityClient.js";
import { OrganizationModal } from "../components/Modals/OrganizationModal.js";
import { clientError } from "../utils/clientError.js";

export function useOrganizationModal({
  serviceProvider,
  onUpdateSuccess,
  organization,
  onClose,
  onCreateSuccess,
  editMode = false,
}: {
  serviceProvider: OrganizationEntity;
  onUpdateSuccess?: (
    result: EntityServiceResult<OrganizationEntity>
  ) => Promise<void>;
  organization?: OrganizationEntity;
  onClose?: () => void;
  onCreateSuccess?: (
    result: EntityServiceResult<OrganizationEntity>
  ) => Promise<void>;
  editMode?: boolean;
}) {
  const [modalOpened, setModalOpened] = useState(false);

  const handleSubmit = useCallback(
    async function (patch: OrganizationUpdateType): Promise<boolean> {
      if (editMode && typeof patch.id !== "number") {
        throw new Error("Organization ID is required in edit mode");
      }
      if (!editMode && typeof patch.id === "number") {
        throw new Error("Organization ID is not allowed in create mode");
      }
      if (editMode) {
        const response = await organizationClient(clientError)
          .item(patch.id ?? null)
          .update({
            ...patch,
            [virtualClientOfOrganizationIdField.name]: serviceProvider.id,
          });
        if (response.success && response.data) {
          if (
            Object.keys(response.data).length === 1 &&
            "slug" in response.data
          ) {
            notifications.show({
              title: `Failed to update organization.`,
              message: `This handle “${response.data.slug}” already exists for another client.`,
              color: "#f44141",
            });
            return false;
          }
          try {
            await onUpdateSuccess?.(response.data);
          } catch (error) {
            console.error("Failed to fetch users:", error);
          }
          notifications.show({
            title: "Success",
            message: `Organization updated.`,
            color: "#54e382",
          });
          return true;
        } else {
          notifications.show({
            title: "Error",
            message: `Failed to update organization.`,
            color: "#f44141",
          });
          return false;
        }
      } else {
        const response = await organizationClient(clientError).create({
          ...patch,
          [virtualClientOfOrganizationIdField.name]: serviceProvider.id,
        });
        if (response.success && response.data) {
          if (response.data.success && response.data.data) {
            if (
              Object.keys(response.data.data).length === 1 &&
              "slug" in response.data.data
            ) {
              const e: Error & { field: "slug" } = new Error(
                `This handle “${response.data.data.slug}” already exists for another client.`
              ) as Error & { field: "slug" };
              e.field = slugField.name;
              throw e;
            }
            try {
              await onCreateSuccess?.(response.data);
            } catch (error) {
              console.error("Failed to run onCreateSuccess:", error);
            }
            setModalOpened(false);
            notifications.show({
              title: "Success",
              message: `Organization created.`,
              color: "#54e382",
            });
            return true;
          } else {
            notifications.show({
              title: "Error",
              message: `Failed to create organization: ${
                response.data.success
                  ? "missing data"
                  : response.data.errors.join(", ")
              }`,
              color: "#f44141",
            });
            return false;
          }
        } else {
          notifications.show({
            title: "Error",
            message: `Failed to create organization.`,
            color: "#f44141",
          });
          return false;
        }
      }
    },
    [organization?.id, serviceProvider.id, onCreateSuccess, onUpdateSuccess]
  );

  return {
    modalOpened,
    setModalOpened,
    modal: (
      <OrganizationModal
        editMode={editMode}
        initialData={organization}
        serviceProvider={serviceProvider}
        onSubmit={handleSubmit}
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          onClose?.();
        }}
      />
    ),
  };
}
