import {
  Button,
  Card,
  Grid,
  Group,
  Modal,
  MultiSelect,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useCallback, useEffect, useState } from "react";

import { UserEntity, userEntity } from "../../../common/entities/user.js";
import { aboutField } from "../../../common/fields/about.js";
import { contactPreferenceField } from "../../../common/fields/contact_preference.js";
import { contactTimesField } from "../../../common/fields/contact_times.js";
import { emailField } from "../../../common/fields/email.js";
import { familyNameField } from "../../../common/fields/family_name.js";
import { givenNameField } from "../../../common/fields/given_name.js";
import { nicknameField } from "../../../common/fields/nickname.js";
import { pictureField } from "../../../common/fields/picture.js";
import { SocialAuthProviderLabel } from "../../../common/fields/social_auth_provider.js";
import { timezoneField } from "../../../common/fields/timezone.js";
import { userClient } from "../../../common/utils/entityClient.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { StoredAuthProfile } from "../../contexts/AuthContext.js";
import { useForm } from "../../hooks/useForm.js";
import { AccountPrimaryColor } from "../../theme.js";
import { clientError } from "../../utils/clientError.js";
import { UserAvatar } from "../Avatars/UserAvatar.js";
import TextEditor from "../Shared/TextEditor.js";

interface EditProfileModalProps {
  opened: boolean;
  onClose: () => void;
  activeProfile?: StoredAuthProfile | null;
  onSave: (user: UserEntity) => void;
}

const TIMEZONE_OPTIONS = [
  { value: "America/New_York", label: "America/New York (UTC-5)" },
  { value: "America/Chicago", label: "America/Chicago (UTC-6)" },
  { value: "America/Denver", label: "America/Denver (UTC-7)" },
  { value: "America/Los_Angeles", label: "America/Los Angeles (UTC-8)" },
  { value: "America/Phoenix", label: "America/Phoenix (UTC-7)" },
  { value: "Europe/London", label: "Europe/London (UTC+0)" },
  { value: "Europe/Paris", label: "Europe/Paris (UTC+1)" },
  { value: "Europe/Berlin", label: "Europe/Berlin (UTC+1)" },
  { value: "Asia/Tokyo", label: "Asia/Tokyo (UTC+9)" },
  { value: "Asia/Shanghai", label: "Asia/Shanghai (UTC+8)" },
  { value: "Asia/Dubai", label: "Asia/Dubai (UTC+4)" },
  { value: "Australia/Sydney", label: "Australia/Sydney (UTC+11)" },
  { value: "Pacific/Auckland", label: "Pacific/Auckland (UTC+13)" },
] as const;

export function EditProfileModal({
  opened,
  onClose,
  activeProfile,
  onSave,
}: EditProfileModalProps) {
  const [updatedProfile, setUpdatedProfile] =
    useState<StoredAuthProfile | null>(null);

  useEffect(() => {
    if (activeProfile?.profile) {
      setUpdatedProfile({
        ...activeProfile,
        profile: {
          ...activeProfile.profile,
          contact_preference: (activeProfile.profile.contact_preference ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            []) as any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          contact_times: (activeProfile.profile.contact_times || []) as any,
        },
      });
    }
  }, [activeProfile?.profile]);

  // Handle input changes
  const handleInputChange = (
    field: keyof StoredAuthProfile["profile"],
    value: string | string[]
  ) => {
    setUpdatedProfile((prev: StoredAuthProfile | null) => ({
      ...prev!,
      profile: {
        ...prev!.profile,
        [field]: value,
      },
    }));
  };
  const { setUser } = useActiveStates();

  // Handle form submission
  const onSubmit = useCallback(
    async (data: Partial<UserEntity>): Promise<boolean> => {
      if (!activeProfile?.profile.id) {
        return false;
      }
      const response = await userClient(clientError)
        .item(activeProfile.profile.id)
        .update(data);
      if (response.success) {
        if (response.data.success) {
          onSave(response.data.data);
          await notifications.show({
            title: "Profile Updated",
            message: "Your profile has been updated successfully",
            color: "#54e382",
          });
          return true; // close the modal
        } else {
          notifications.show({
            title: "Profile Update Failed",
            message: response.data.errors.join(", "),
            color: "#f44141",
          });
          return false;
        }
      } else {
        notifications.show({
          title: "Profile Update Failed",
          message: response.error,
          color: "#f44141",
        });
        return false;
      }
    },
    [onClose, activeProfile?.profile?.id, setUser]
  );

  const hasSocialAuthProvider =
    typeof updatedProfile?.profile?.social_auth_provider === "number";

  const form = useForm(userEntity, {
    initialFormData: activeProfile?.profile ?? ({} as UserEntity),
    onClose,
    onSubmit,
    opened,
    type: "update",
    errorComponent(message: string) {
      return <Text c="#f44141">{message}</Text>;
    },
  });

  // Don't render the form until we have a profile
  if (!updatedProfile) {
    return null;
  }

  return (
    <Modal opened={opened} onClose={onClose} centered size="xl">
      <Card pt={0} bg="transparent">
        <UserAvatar
          color={AccountPrimaryColor}
          variant="filled"
          user={updatedProfile?.profile ?? activeProfile?.profile}
          size={80}
          radius={100}
          mx="auto"
        />
        <Text ta="center" fz="lg" fw={500} mt="md">
          Edit Profile
        </Text>
      </Card>
      <form onSubmit={form.submit}>
        <fieldset
          disabled={form.isSubmitting}
          style={{ padding: "0", border: "none" }}
        >
          <Grid gutter="md">
            {/* Auth Provider Managed Fields */}
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Text fw={500} size="sm" mb="xs">
                {typeof updatedProfile?.profile?.social_auth_provider ===
                "number"
                  ? `Managed by ${
                      SocialAuthProviderLabel[
                        updatedProfile.profile.social_auth_provider as 0
                      ]
                    }`
                  : "Auth Provider Details"}
              </Text>
              <TextInput
                label={givenNameField.label}
                placeholder="First name"
                required={givenNameField.required}
                value={form.data.given_name ?? ""}
                onChange={(e) =>
                  form.handleInputChange("given_name", e.currentTarget.value)
                }
                mb="sm"
                disabled={hasSocialAuthProvider}
              />
              <TextInput
                label={familyNameField.label}
                placeholder="Last name"
                required={familyNameField.required}
                value={form.data.family_name ?? ""}
                onChange={(e) =>
                  form.handleInputChange("family_name", e.currentTarget.value)
                }
                mb="sm"
                disabled={hasSocialAuthProvider}
              />
              <TextInput
                label={emailField.label}
                placeholder="Email address"
                required={emailField.required}
                value={form.data.email ?? ""}
                onChange={(e) =>
                  form.handleInputChange("email", e.currentTarget.value)
                }
                mb="sm"
                disabled={hasSocialAuthProvider}
              />
              <TextInput
                label={pictureField.label}
                placeholder="https://example.org/image.jpg"
                required={pictureField.required}
                value={form.data.picture ?? ""}
                onChange={(e) =>
                  form.handleInputChange("picture", e.currentTarget.value)
                }
                mb="sm"
                disabled={hasSocialAuthProvider}
              />
              <Stack>
                <Title order={5} ta="center">
                  Avatar Preview
                </Title>
                <UserAvatar
                  user={form.data}
                  variant="filled"
                  size={60}
                  radius="xl"
                  mx="auto"
                />
              </Stack>
            </Grid.Col>

            {/* User Customizable Fields */}
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Text fw={500} size="sm" mb="xs">
                Preferences
              </Text>
              <TextInput
                label={nicknameField.label}
                placeholder="Nickname"
                value={form.data.nickname}
                required={nicknameField.required}
                onChange={(e) =>
                  form.handleInputChange(
                    nicknameField.name,
                    e.currentTarget.value
                  )
                }
                mb="sm"
              />
              <MultiSelect
                label={contactPreferenceField.label}
                data={[
                  { value: "0", label: "Email" },
                  { value: "1", label: "Phone" },
                  { value: "2", label: "Text" },
                ]}
                required={contactPreferenceField.required}
                placeholder="Choose preferences"
                value={
                  (
                    updatedProfile.profile
                      .contact_preference as unknown as number[]
                  )?.map(String) || []
                }
                onChange={(value) =>
                  form.handleInputChange(
                    contactPreferenceField.name,
                    value.map((x) => parseInt(x, 10)) as unknown as number
                  )
                }
                mb="sm"
              />
              <Select
                label={timezoneField.label}
                placeholder="Select timezone"
                data={TIMEZONE_OPTIONS}
                required={timezoneField.required}
                value={form.data.timezone}
                onChange={(value) => handleInputChange("timezone", value || "")}
                searchable
                mb="sm"
              />
              <TextInput
                label={contactTimesField.label}
                placeholder="Preferred contact times"
                value={form.data.contact_times?.join(", ")}
                required={contactTimesField.required}
                onChange={(e) =>
                  form.handleInputChange(
                    contactTimesField.name,
                    e.currentTarget.value.split(", ") as unknown as [
                      number,
                      number
                    ][] as unknown as number
                  )
                }
                mb="sm"
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={12}>
              <TextEditor
                label={aboutField.label}
                placeholder="Describe your skills, fun facts, or anything else about yourself..."
                content={form.data.about}
                onUpdate={(value: string) => {
                  form.handleInputChange("about", value);
                }}
              />
            </Grid.Col>
          </Grid>
          <Group mt="md">
            <Button type="submit" fullWidth>
              Save Changes
            </Button>
          </Group>
        </fieldset>
      </form>
    </Modal>
  );
}
