import { Buffer as BufferPolyfill } from "buffer";

if (!("Buffer" in globalThis)) {
  globalThis.Buffer = BufferPolyfill;
}

import {
  Button,
  Center,
  Group,
  Loader,
  MantineProvider,
  Modal,
  Stack,
  Transition,
  useComputedColorScheme,
} from "@mantine/core";
import { NotificationData, notifications } from "@mantine/notifications";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";

import { OrganizationEntity } from "../common/entities/organization.js";
import { OrganizationRelationshipEntity } from "../common/entities/organizationRelationship.js";
import { OrganizationRelationshipUserEntity } from "../common/entities/organizationRelationshipUser.js";
import { OrganizationUserEntity } from "../common/entities/organizationUser.js";
import { UserEntity } from "../common/entities/user.js";
import { ENTITLEMENT_IS_SERVICE_PROVIDER } from "../common/fields/entitlements.js";
import { pictureField } from "../common/fields/picture.js";
import { pictureBackgroundColorField } from "../common/fields/picture_background_color.js";
import { LEDGERAI_SLUG } from "../common/fields/slug.js";
import { initClient } from "../common/utils/initClient.js";
import { AdminApp } from "./AdminApp.js";
import { OrganizationAvatar } from "./components/Avatars/OrganizationAvatar.js";
import {
  ActiveStatesContext,
  ChatAttachment,
} from "./contexts/ActiveStatesContext.js";
import { AsideProvider } from "./contexts/AsideContextProvider.js";
import { AuthContext } from "./contexts/AuthContext.js";
import { getAuth, useAuth } from "./hooks/useAuth.js";
import { useMessages } from "./hooks/useMessages.js";
import { useResponsiveDesign } from "./hooks/useResponsiveDesign.js";
import { useSessionState } from "./hooks/useSessionState.js";
import { ActivityOverview } from "./pages/activity/index.js";
import { ClientsOverview } from "./pages/clients/overview.js";
import { ConnectOverview } from "./pages/connect/overview.js";
import { DashboardOverview } from "./pages/dashboard/index.js";
import { FileExplorer } from "./pages/files/index.js";
import { GeneralError } from "./pages/GeneralError.js";
import { NotesOverview } from "./pages/notes/overview.js";
import { NotFound } from "./pages/NotFound.js";
import { MarkdownRenderer } from "./pages/penny/MarkdownRenderer.js";
import { PennyConversationRoute } from "./pages/penny/PennyConversationRoute.js";
import { PeopleOverview } from "./pages/people/overview.js";
import { RecipesOverview } from "./pages/recipes/overview.js";
import { SettingsOverview } from "./pages/settings/overview.js";
import { SystemError } from "./pages/SystemError.js";
import { Welcome } from "./pages/welcome/index.js";
import { PrimaryColor, theme } from "./theme.js";
import { clientError } from "./utils/clientError.js";

import "./App.css.js";

const outerRouter = createBrowserRouter([{ path: "*", Component: AppRoot }], {
  future: {
    // add future react-router-dom version 8 compatibility options here
  },
});

const appRoutes = (
  <Routes>
    <Route path="/" Component={Welcome} />
    <Route path="/activity" Component={ActivityOverview} />
    <Route path="/clients/overview" Component={ClientsOverview} />
    <Route path="/connect/overview" Component={ConnectOverview} />
    <Route path="/dashboard" Component={DashboardOverview} />
    <Route path="/files/*" Component={FileExplorer} />
    <Route path="/notes" Component={NotesOverview} />
    <Route path="/penny/conversations/:id" Component={PennyConversationRoute} />
    <Route path="/people/overview" Component={PeopleOverview} />
    <Route path="/recipes/overview" Component={RecipesOverview} />
    <Route path="/settings/overview" Component={SettingsOverview} />
    {/**Not found. Note: This must be the last route */}
    <Route path="*" Component={NotFound} />
  </Routes>
);

const initialIsMounted = localStorage.getItem("startupNotification") !== null;

export function App() {
  const { readURLHash, logOut } = useAuth();

  useEffect(() => {
    const startupNotification = localStorage.getItem("startupNotification");
    localStorage.removeItem("startupNotification");
    if (startupNotification) {
      notifications.show({
        ...JSON.parse(startupNotification),
        autoClose: 2500,
      });
    }
  }, []);

  useEffect(() => {
    readURLHash((url) => window.location.replace(url));
  }, [readURLHash]);

  const [customerIdHint, setCustomerIdHint] = useSessionState<
    number | undefined
  >("customerIdHint", undefined);

  const [attachedFiles, setAttachedFiles] = useState<ChatAttachment[]>([]);
  const [initError, setInitError] = useState<string | undefined>();
  const [user, setUser] = useState<UserEntity | undefined>();
  const [isServiceProviderUser, setIsServiceProviderUser] = useState(false);
  const [serviceProvider, setServiceProvider] = useState<OrganizationEntity>();
  const [customer, _setCustomer] = useState<OrganizationEntity | undefined>();
  const [customers, setCustomers] = useState<
    OrganizationEntity[] | undefined
  >();
  const [organizationRelationship, setOrganizationRelationship] = useState<
    OrganizationRelationshipEntity | undefined
  >();
  const [organizationRelationshipUser, setOrganizationRelationshipUser] =
    useState<OrganizationRelationshipUserEntity | undefined>();
  const [organizationUser, setOrganizationUser] =
    useState<OrganizationUserEntity>();
  const [primaryError, setPrimaryError] = useState<string | undefined>();

  const isFirstSetCustomerRef = useRef(true);

  const shutdown = useCallback(
    async (notificationDefinition?: NotificationData, redirectUrl?: string) => {
      setUser(undefined);
      if (notificationDefinition) {
        delete notificationDefinition.autoClose;
        localStorage.setItem(
          "startupNotification",
          JSON.stringify(notificationDefinition)
        );
        notificationDefinition.autoClose = 500000;
        notifications.show(notificationDefinition);
      }
      localStorage.setItem(
        "startupNotification",
        JSON.stringify(notificationDefinition)
      );
      await new Promise((resolve) => setTimeout(resolve, 250));
      const root = document.getElementById("root");
      if (root) {
        root.style.opacity = "0";
      }
      await new Promise((resolve) => setTimeout(resolve, 250));
      if (redirectUrl) {
        location.replace(redirectUrl);
      } else {
        location.reload();
      }
    },
    []
  );

  const setCustomer = useCallback(
    async (newCustomer: OrganizationEntity | undefined) => {
      // Don't allow setting the customer to the service provider
      if (
        typeof serviceProvider?.id !== "undefined" &&
        newCustomer?.id === serviceProvider.id
      ) {
        return;
      }
      if (Array.isArray(customers) && typeof newCustomer !== "undefined") {
        setCustomers(
          customers.map((c) => (c.id === newCustomer?.id ? newCustomer : c))
        );
      }

      if (newCustomer) {
        setCustomerIdHint(newCustomer.id);
      } else {
        setCustomerIdHint(undefined);
      }

      if (isFirstSetCustomerRef.current) {
        isFirstSetCustomerRef.current = false;
      } else {
        if (customer?.id !== newCustomer?.id) {
          return shutdown(
            {
              color: "#54e382",
              message: "Please wait, switching organization",
              position: "bottom-center",
              title: "Restarting application",
            },
            "/"
          );
        }
      }

      _setCustomer(newCustomer);
    },
    [customer, customers, setCustomerIdHint]
  );

  const messageControl = useMessages(user, serviceProvider?.id, customer?.id);

  const { loadMessages } = messageControl;

  const loadOnceRef = useRef(false);

  const { updateAccount } = useContext(AuthContext);

  const { sessionId } = getAuth();

  useEffect(
    function () {
      async function load() {
        if (typeof sessionId !== "number") {
          return;
        }
        const initResponse = await initClient(clientError).get({
          customerIdHint,
        });
        if (initResponse.success) {
          if (initResponse.data.success) {
            const {
              customer,
              customers,
              isServiceProviderUser,
              organizationRelationship,
              organizationRelationshipUser,
              organizationUser,
              serviceProvider,
              user,
            } = initResponse.data;

            setCustomer(customer);
            setCustomers(customers);
            setIsServiceProviderUser(isServiceProviderUser);
            setOrganizationRelationship(organizationRelationship);
            setOrganizationRelationshipUser(organizationRelationshipUser);
            setOrganizationUser(organizationUser);
            setServiceProvider(serviceProvider);
            setUser(user);
            updateAccount({
              profile: user,
              sessionId,
            });
          } else {
            setPrimaryError(initResponse.data.reason);
          }
        } else {
          notifications.show({
            autoClose: 500000,
            color: "#54e382",
            message: `Init response failed: ${initResponse.error}`,
            position: "bottom-center",
            title:
              initResponse.status === 401 ? "Session Expired" : "Unknown Error",
          });
          console.error(initResponse.error);
          if (initResponse.status === 401) {
            setUser(undefined);
            logOut((url) => window.location.replace(url));
          } else {
            setInitError(initResponse.error);
          }
        }
      }
      if (!loadOnceRef.current) {
        loadOnceRef.current = true;
        load().catch((error) => {
          setInitError(error.message);
        });
      }
    },
    [customerIdHint, loadOnceRef, logOut, sessionId, setCustomer, updateAccount]
  );

  useEffect(() => {
    if (user) {
      loadMessages(user.id!, serviceProvider?.id, customer?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, customer, serviceProvider]);

  const computedColorScheme = useComputedColorScheme("light");

  const [isMounted, setIsMounted] = useState(initialIsMounted);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  // admin app state
  const [isExpanded, setIsExpanded] = useSessionState(".admin/expanded", false);

  const { isAboveSmall } = useResponsiveDesign();

  // todo make this a hook
  useEffect(() => {
    const setMountedTimeout = setTimeout(() => {
      setIsMounted(true);
    }, 1000);
    return () => clearTimeout(setMountedTimeout);
  }, []);

  const [confirmModalText, setConfirmModalText] = useState<
    string | undefined
  >();

  const confirmModalResolveRef = useRef<
    ((value: boolean) => void) | undefined
  >();

  const showConfirmModal = useCallback(
    (message: string, resolve: (value: boolean) => void) => {
      setConfirmModalText(message);
      confirmModalResolveRef.current = resolve;
    },
    []
  );

  const resolve = useCallback(
    (value: boolean) => {
      confirmModalResolveRef.current?.(value);
      setConfirmModalText(undefined);
      confirmModalResolveRef.current = undefined;
    },
    [confirmModalResolveRef]
  );
  if (initError) {
    return <SystemError error={initError} />;
  }

  if (primaryError) {
    switch (primaryError) {
      case "service_provider_relationship_not_found":
        return (
          <GeneralError
            error="No account found"
            message="You must be invited to a LedgerAI organization to access this application."
          >
            <Group>
              <Button
                size="lg"
                radius="xl"
                variant="primary"
                loading={isLoggingOut}
                onClick={() => {
                  setIsLoggingOut(true);
                  logOut((url) => window.location.replace(url));
                }}
              >
                Logout
              </Button>
            </Group>
          </GeneralError>
        );
      default:
        return <SystemError error={primaryError} />;
    }
  }

  if (
    !user ||
    !serviceProvider ||
    !organizationUser ||
    typeof isAboveSmall === "undefined"
  ) {
    return (
      <Center h="100vh" w="100vw">
        <Stack ta="center" gap="xl">
          <OrganizationAvatar
            variant="filled"
            color={PrimaryColor}
            size={120}
            organization={{
              [pictureField.name]: "https://app.ledger.ai/images/logo.svg",
              [pictureBackgroundColorField.name]:
                computedColorScheme === "dark" ? "#000" : "#fff",
            }}
          />
          {isMounted ? null : <Loader mx="auto" opacity={0} />}
          <Transition mounted={isMounted} transition="fade" duration={1000}>
            {(styles) => <Loader mx="auto" style={styles} />}
          </Transition>
        </Stack>
      </Center>
    );
  }

  return (
    <ActiveStatesContext.Provider
      value={{
        adminIsOpen: isExpanded,
        attachedFiles,
        closeAdmin: () => setIsExpanded(false),
        customer,
        customers,
        isCustomerUser: !isServiceProviderUser,
        isServiceProvider:
          !customer ||
          (customer.entitlements as unknown as number[])?.includes(
            ENTITLEMENT_IS_SERVICE_PROVIDER
          ),
        isServiceProviderUser,
        messageControl,
        organizationRelationship,
        organizationRelationshipUser,
        organizationUser,
        serviceProvider,
        user,
        setAttachedFiles,
        setCustomer,
        setCustomers,
        setServiceProvider,
        setUser,
        showConfirmModal,
        shutdown,
      }}
    >
      {/** ADMIN SECTION */}
      {user && serviceProvider ? (
        <MantineProvider
          defaultColorScheme="auto"
          theme={theme("#101010", "#404040", true)}
        >
          {isServiceProviderUser && serviceProvider.slug === LEDGERAI_SLUG && (
            <AdminApp
              organization={serviceProvider}
              organizationUser={organizationUser}
              user={user}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            />
          )}
        </MantineProvider>
      ) : null}
      {/* END ADMIN SECTION */}
      <MantineProvider
        defaultColorScheme="auto"
        theme={theme(
          serviceProvider?.theme_primary_color || "#231F20",
          customer?.theme_primary_color || "#231F20",
          isServiceProviderUser
        )}
      >
        <Modal
          onClose={() => confirmModalResolveRef.current?.(false)}
          opened={!!confirmModalText}
          title="Confirm"
        >
          <MarkdownRenderer content={confirmModalText ?? ""} />
          <Group gap="xs" mt="md">
            <Button onClick={() => resolve(true)}>Confirm</Button>
            <Button onClick={() => resolve(false)} variant="outline">
              Cancel
            </Button>
          </Group>
        </Modal>
        <AsideProvider>
          <RouterProvider router={outerRouter} />
        </AsideProvider>
      </MantineProvider>
    </ActiveStatesContext.Provider>
  );
}

export function AppRoot() {
  return <>{appRoutes}</>;
}
