import { css } from "../../../utils/css.js";

interface ClassNames {
  drawerBody: string;
  drawerContentDark: string;
}

export default css<ClassNames>`
  .drawerBody {
    padding: 0;
  }
  .drawerContentDark {
    background-color: var(--mantine-color-dark-9);
  }
`;
