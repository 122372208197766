import { useCallback, useEffect, useState } from "react";
import {
  Entity,
  EntityCreateType,
  EntityUpdateType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../../common/types/entity.js";
import { Field, FieldMetadata } from "../../common/types/field.js";

export function useForm<T>(
  entity: Entity<FieldList, PrimaryKeyFieldTuple>,
  {
    errorComponent,
    initialFormData,
    onClose,
    onSubmit,
    opened,
    type,
  }: {
    errorComponent(message: string, isOverallError?: boolean): JSX.Element;
    initialFormData: T;
    onClose: () => void;
    onSubmit: (data: T) => Promise<boolean>;
    opened: boolean;
    type: "create" | "update";
  }
) {
  const [fieldErrors, setFieldErrors] = useState<Record<keyof T, string>>(
    {} as Record<keyof T, string>
  );
  const [overallErrors, setOverallErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState<T>(initialFormData);

  useEffect(() => {
    if (opened) {
      // Reset form data when modal is opened
      setData(initialFormData);
      setFieldErrors({} as Record<keyof T, string>);
      setOverallErrors([]);
    }
  }, [opened, initialFormData, setData, setFieldErrors, setOverallErrors]);

  // Handle input changes
  function handleInputChange(field: keyof T, value: T[keyof T]) {
    setData((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  // Handle form submission
  const handleSubmit = useCallback(async () => {
    let validationResult: {
      errors: Record<keyof T, string>;
      overallErrors: string[];
    };
    switch (type) {
      case "create":
        validationResult = entity.validateCreate(
          data as EntityCreateType<FieldList, PrimaryKeyFieldTuple>
        ) as typeof validationResult;
        break;
      case "update":
        validationResult = entity.validateUpdate(
          data as EntityUpdateType<FieldList, PrimaryKeyFieldTuple>
        ) as typeof validationResult;
        break;
    }

    const fieldErrorCount = Object.keys(validationResult.errors).length;
    if (validationResult.overallErrors.length === 0 && fieldErrorCount > 0) {
      validationResult.overallErrors.push(
        `Please fix ${fieldErrorCount} error${
          fieldErrorCount === 1 ? "" : "s"
        }: ${Object.values(validationResult.errors).join(", ")}.`
      );
    }

    setFieldErrors(validationResult.errors as Record<keyof T, string>);
    setOverallErrors(validationResult.overallErrors);

    if (Object.keys(validationResult.errors).length > 0) {
      return; // Stop if there are any field errors
    }

    if (validationResult.overallErrors.length > 0) {
      return;
    }

    setIsSubmitting(true);
    try {
      const continueWithClose = await onSubmit(data);
      if (continueWithClose) {
        onClose();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (typeof e?.field === "string") {
        setFieldErrors((prev) => ({
          ...prev,
          [e.field]: e.message,
        }));
      } else {
        setOverallErrors(e.errors ?? [e.message ?? e]);
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [data, entity, onClose, onSubmit, type]);

  async function submit(e: React.FormEvent) {
    e.preventDefault();
    await handleSubmit();
  }

  const fieldError = useCallback(
    (field: Field<FieldMetadata>): JSX.Element | null =>
      field.name in fieldErrors
        ? errorComponent(fieldErrors[field.name as keyof T])
        : null,
    [fieldErrors, errorComponent]
  );

  const overallError = useCallback(
    () => <>{overallErrors.map((error) => errorComponent(error, true))}</>,
    [overallErrors, errorComponent]
  );

  return {
    data,
    errors: fieldErrors,
    fieldError,
    handleInputChange,
    isSubmitting,
    overallErrors,
    overallError,
    setData,
    submit,
  };
}
