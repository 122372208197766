export const AI_PROVIDER_OPENAI = "openai";
export const AI_PROVIDER_AMAZON_BEDROCK = "amazon-bedrock";
export const AI_PROVIDER_XAI = "xai";
export const AI_PROVIDER_GOOGLE = "google";
export const AI_PROVIDER_ANTHROPIC = "anthropic";

export const SUPPORTED_AI_PROVIDERS = [
  AI_PROVIDER_OPENAI,
  AI_PROVIDER_GOOGLE,
  AI_PROVIDER_ANTHROPIC,
];

export const OPENAI_MODELS = {
  DEFAULT: "gpt-4o-mini",
} as const;
