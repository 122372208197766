import { StringField } from "../types/field";

export const tokenTypeField: StringField<{
  name: "token_type";
  required: true;
}> = {
  label: "Name",
  name: "token_type",
  required: true,
  type: "string",
};
