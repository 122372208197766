import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useRef } from "react";

export function useResponsiveDesign() {
  const theme = useMantineTheme();

  const lastNotUndefined = useRef<{
    isAboveXSmall?: boolean;
    isAboveSmall?: boolean;
    isAboveMedium?: boolean;
    isAboveLarge?: boolean;
    isAboveXLarge?: boolean;
  }>({});

  const isAboveXSmall =
    useMediaQuery(`(min-width: ${theme.breakpoints.xs})`) ??
    lastNotUndefined.current?.isAboveXSmall;
  const isAboveSmall =
    useMediaQuery(`(min-width: ${theme.breakpoints.sm})`) ??
    lastNotUndefined.current?.isAboveSmall;
  const isAboveMedium =
    useMediaQuery(`(min-width: ${theme.breakpoints.md})`) ??
    lastNotUndefined.current?.isAboveMedium;
  const isAboveLarge =
    useMediaQuery(`(min-width: ${theme.breakpoints.lg})`) ??
    lastNotUndefined.current?.isAboveLarge;
  const isAboveXLarge =
    useMediaQuery(`(min-width: ${theme.breakpoints.xl})`) ??
    lastNotUndefined.current?.isAboveXLarge;

  if (typeof isAboveXSmall !== "undefined") {
    lastNotUndefined.current.isAboveXSmall = isAboveXSmall;
  }
  if (typeof isAboveSmall !== "undefined") {
    lastNotUndefined.current.isAboveSmall = isAboveSmall;
  }
  if (typeof isAboveMedium !== "undefined") {
    lastNotUndefined.current.isAboveMedium = isAboveMedium;
  }
  if (typeof isAboveLarge !== "undefined") {
    lastNotUndefined.current.isAboveLarge = isAboveLarge;
  }
  if (typeof isAboveXLarge !== "undefined") {
    lastNotUndefined.current.isAboveXLarge = isAboveXLarge;
  }

  return {
    isAboveXSmall,
    isAboveSmall,
    isAboveMedium,
    isAboveLarge,
    isAboveXLarge,
  };
}
