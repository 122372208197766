import { StringField } from "../types/field";

export const encryptedRefreshTokenField: StringField<{
  name: "encrypted_refresh_token";
  required: true;
}> = {
  label: "Name",
  name: "encrypted_refresh_token",
  required: true,
  type: "string",
};
