import { IntegerField } from "../types/field";

export const accessTokenExpiresInField: IntegerField<{
  name: "access_token_expires_in";
  required: true;
}> = {
  label: "Access Token Expires In",
  name: "access_token_expires_in",
  required: true,
  type: "integer",
};
