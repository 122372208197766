import { StringField } from "../types/field";

export const keyField: StringField<{
  name: "key";
  required: true;
}> = {
  label: "Key",
  name: "key",
  required: true,
  type: "string",
};
