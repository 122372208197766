import {
  KV_ALL,
  KVEntity,
  KVEntityStore,
  KVStore,
} from "../../common/entities/kv.js";
import { OrganizationEntity } from "../../common/entities/organization.js";
import { userEntity, UserEntity } from "../../common/entities/user.js";
import { idField } from "../../common/fields/id.js";
import { userIdFieldRequired } from "../../common/fields/user_id.js";
import { EntityServiceResult } from "../../common/types/entity.js";
import { kvClient } from "../../common/utils/entityClient.js";
import {
  NetworkResponse,
  clearCacheByPattern,
} from "../../common/utils/network.js";
import { clientError } from "../utils/clientError.js";

const Null = "-1" as unknown as -1;

// Global cache of KV storage adapters
const adapterCache = new Map<
  string,
  { full: KVEntityStore; minimal: KVStore }
>();

// Clear the cache when unmounting to prevent stale data
export function clearKvCache(
  namespace: string,
  providerId?: string | number,
  customerId?: string | number
) {
  // If specific provider/customer IDs are provided, clear only that cache
  if (providerId && customerId) {
    const cacheKey = `${providerId}-${customerId}-${namespace}`;
    adapterCache.delete(cacheKey);
  } else if (providerId) {
    // Clear all caches for this provider and namespace
    for (const key of adapterCache.keys()) {
      if (key.startsWith(`${providerId}-`) && key.endsWith(`-${namespace}`)) {
        adapterCache.delete(key);
      }
    }
  } else {
    // Clear all caches for this namespace
    for (const key of adapterCache.keys()) {
      if (key.endsWith(`-${namespace}`)) {
        adapterCache.delete(key);
      }
    }
  }

  // Clear API cache as well
  clearCacheByPattern(`/api/kv`);
}

export function useKvStorage(
  namespace: string,
  options: {
    user: UserEntity;
    customer?: OrganizationEntity;
    provider: OrganizationEntity;
  }
): { full: KVEntityStore; minimal: KVStore } {
  const { user, customer, provider } = options;

  const kv = kvClient(clientError);

  const cacheKey = `${provider.id ?? Null}-${
    customer?.id ?? Null
  }-${namespace}`;

  if (adapterCache.has(cacheKey)) {
    return adapterCache.get(cacheKey)!;
  }

  function formatResponse<T>(
    response: NetworkResponse<EntityServiceResult<T>>
  ): EntityServiceResult<T> {
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error);
    }
  }

  // Create the entity store first
  const full: KVEntityStore = {
    clear: async () => {
      const response = await kv
        .item(provider.id!, customer?.id ?? Null, namespace, KV_ALL)
        .delete();
      if (!response.success) {
        throw new Error(response.error);
      }
      return formatResponse<undefined>({
        success: true,
        data: { data: undefined, success: true },
        status: response.status,
      });
    },

    getItem: async (key: string) => {
      const response = await kv
        .item(provider.id!, customer?.id ?? Null, namespace, key)
        .get();
      if (!response.success) {
        return {
          success: false,
          errors: [response.error],
          data: null,
          status: response.status,
        };
      }
      try {
        return formatResponse(response);
      } catch (error) {
        console.error(error);
        return {
          success: false,
          errors: [error instanceof Error ? error.message : "Unknown error"],
          data: null,
          status: response.status,
        };
      }
    },

    key: async (index: number) => {
      const response = await kv.itemAtIndex(index, {
        namespace: namespace,
        provider_id: provider.id,
        customer_id: customer?.id ?? -1,
      });
      return formatResponse(response);
    },

    list: async (from?: number, to?: number) => {
      const response = (await kv.list(
        {
          namespace: namespace,
          provider_id: provider.id,
          customer_id: customer?.id ?? -1,
        },
        {
          join: userEntity.name,
          from: idField.name,
          to: userIdFieldRequired.name,
        },
        {
          from,
          to,
        }
      )) as unknown as NetworkResponse<
        EntityServiceResult<(KVEntity & { User: UserEntity })[]>
      >;
      return formatResponse(response);
    },

    removeItem: async (key: string) => {
      const response = await kv
        .item(provider.id!, customer?.id ?? -1, namespace, key)
        .delete();
      return formatResponse(response);
    },

    setItem: async (key: string, value: string) => {
      const response = await kv
        .item(provider.id!, customer?.id ?? Null, namespace, key)
        .upsert({
          customer_id: customer?.id ?? -1,
          key,
          namespace,
          provider_id: provider.id,
          user_id: user.id,
          value,
        });
      return formatResponse<KVEntity>(response);
    },

    get length() {
      return new Promise<number>(async (resolve, reject) => {
        try {
          const response = await kv.length({
            namespace: namespace,
            provider_id: provider.id,
            customer_id: customer?.id ?? -1,
          });
          const result = formatResponse(response);
          if (result.success) {
            resolve(result.data);
          } else {
            reject(result.errors);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  };

  // Now create the KVStore facade over the entity store
  const minimal: KVStore = {
    clear: async () => {
      await full.clear();
    },

    getItem: async (key: string): Promise<string | null> => {
      const entityResult = await full.getItem(key);
      if (!entityResult.success) {
        if ("status" in entityResult && entityResult.status === 404) {
          return null;
        }
        throw new Error(entityResult.errors.join(", "));
      }
      const entity: KVEntity | null = entityResult.data;
      return entity?.value ?? null;
    },

    key: async (index: number): Promise<string | null> => {
      const entityResult = await full.key(index);
      if (!entityResult || !entityResult.success) {
        return null;
      }
      return entityResult.data?.key || null;
    },

    list: async (from?: number, to?: number): Promise<string[]> => {
      const entityResult = await full.list(from, to);
      if (!entityResult.success) {
        return [];
      }
      return entityResult.data.map((entity) => entity?.value || "");
    },

    removeItem: async (key: string) => {
      await full.removeItem(key);
    },

    setItem: async (key: string, value: string) => {
      await full.setItem(key, value);
    },

    // Length property can be directly passed through
    length: full.length,
  };

  const storeAdapter = {
    full: full,
    minimal,
  };

  adapterCache.set(`${provider.id}-${customer?.id}-${namespace}`, storeAdapter);

  return storeAdapter;
}
