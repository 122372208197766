import { Group, Loader, Stack, Table, Text } from "@mantine/core";

import {
  organizationEntity,
  OrganizationEntity,
} from "../../../common/entities/organization.js";
import { OrganizationRelationshipEntity } from "../../../common/entities/organizationRelationship.js";
import { OrganizationRelationshipUserEntity } from "../../../common/entities/organizationRelationshipUser.js";
import { DEFAULT_THEME_PRIMARY_COLOR } from "../../../common/fields/theme_primary_color.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";
import { OrganizationMenu } from "../Menus/OrganizationMenu.js";

export type OrgRelationship = OrganizationRelationshipEntity & {
  [organizationEntity.name]: OrganizationEntity;
};
interface OrganizationsTableProps {
  editingOrganization?: OrganizationEntity;
  hasEntitlementManageClients: boolean;
  isDeletingCustomerId?: number;
  onDelete: (organization: OrgRelationship) => void;
  organizations: OrgRelationship[];
  setEditingOrganization: (organization: OrganizationEntity) => void;
  onAddTeamMember: (
    relationship: OrgRelationship
  ) => Promise<OrganizationRelationshipUserEntity>;
}

export function OrganizationsTable({
  hasEntitlementManageClients,
  organizations: organizationRelationships,
  onDelete,
  isDeletingCustomerId,
  setEditingOrganization,
  onAddTeamMember,
}: OrganizationsTableProps) {
  const rows = organizationRelationships.map((relationship) => (
    <Table.Tr key={relationship.Organization.id}>
      <Table.Td pl="lg">
        <Group gap="sm">
          <OrganizationAvatar
            variant="filled"
            size={45}
            organization={relationship.Organization}
            color={
              relationship.Organization.theme_primary_color ??
              DEFAULT_THEME_PRIMARY_COLOR
            }
          />
          <Stack gap={0}>
            <Text fw={700}>{relationship.Organization.name}</Text>
            <Text fz="sm" c="dimmed">
              Client since{" "}
              {new Date(
                relationship.Organization.created_at ?? Date.now()
              ).toLocaleDateString(undefined, {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </Text>
          </Stack>
        </Group>
      </Table.Td>
      <Table.Td>{relationship.Organization.primary_domain}</Table.Td>
      <Table.Td>{relationship.Organization.primary_location}</Table.Td>
      {hasEntitlementManageClients ? (
        <Table.Td ta="right">
          {relationship.customer_id === isDeletingCustomerId ? (
            <Group gap="xs" justify="center">
              <Loader size="xs" />
              Removing
            </Group>
          ) : (
            <OrganizationMenu
              relationship={relationship}
              onAddTeamMember={onAddTeamMember}
              setEditingOrganization={setEditingOrganization}
              onDelete={onDelete}
            />
          )}
        </Table.Td>
      ) : null}
    </Table.Tr>
  ));

  return (
    <Table verticalSpacing="sm" highlightOnHover>
      <Table.Thead
        style={{
          position: "sticky",
          top: "70px",
          zIndex: 1,
        }}
      >
        <Table.Tr>
          <Table.Th pl="lg">Organization</Table.Th>
          <Table.Th>Domain</Table.Th>
          <Table.Th>Location</Table.Th>
          {hasEntitlementManageClients ? <Table.Th ta="right" /> : null}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
}
