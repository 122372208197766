import { css } from "../../utils/css.js";

interface ClassNames {
  wrapper: string;
  title: string;
  description: string;
  error: string;
}

export default css<ClassNames>`
  .wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: var(--mantine-spacing-xl) var(--mantine-spacing-xs);
    min-height: calc(100vh - 270px); /* Full viewport height */
  }

  .title {
    font-family: Greycliff CF, var(--mantine-font-family);
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .description {
    text-align: left;
    margin: var(--mantine-spacing-md) 0 var(--mantine-spacing-xl) 0;
  }

  .error {
    text-align: left;
    margin: var(--mantine-spacing-md) 0 var(--mantine-spacing-xl) 0;
    font-size: 18px;

    @media (max-width: var(--mantine-breakpoint-sm)) {
      font-size: 16px;
    }

    opacity: 0.65;
  }
`;
