import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Group,
  Loader,
  Menu,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconCircleArrowUpLeftFilled,
  IconDots,
  IconX,
} from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationRelationshipUserEntity } from "../../../common/entities/organizationRelationshipUser.js";
import {
  getUserName,
  userEntity,
  UserEntity,
} from "../../../common/entities/user.js";
import { OrganizationRelationshipUserEntitlementLabels } from "../../../common/fields/entitlements.js";
import { idField } from "../../../common/fields/id.js";
import { userIdFieldRequired } from "../../../common/fields/user_id.js";
import { virtualCustomerIdField } from "../../../common/fields/virtual_customer_id.js";
import { virtualProviderIdField } from "../../../common/fields/virtual_provider_id.js";
import {
  organizationRelationshipUserClient,
  userClient,
} from "../../../common/utils/entityClient.js";
import { useConfirmWithModal } from "../../hooks/useConfirmWithModal.js";
import { clientError } from "../../utils/clientError.js";
import { UserProfile } from "../Excelente/UserProfile.js";

interface Props {
  customer: OrganizationEntity;
  hasManageEntitlement: boolean;
  isCustomerUser: boolean;
  serviceProvider: OrganizationEntity;
}

type OrganizationRelationshipUser = OrganizationRelationshipUserEntity & {
  User: UserEntity;
};

export function OrganizationRelationshipUserManagement({
  customer,
  hasManageEntitlement,
  isCustomerUser,
  serviceProvider,
}: Props) {
  const [availableUsers, setAvailableUsers] = useState<UserEntity[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isLoadingAvailableUsers, setIsLoadingAvailableUsers] = useState(
    !isCustomerUser
  );
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isRemovingUserId, setIsRemovingUserId] = useState<number | undefined>(
    undefined
  );
  const [isRestoringUserId, setIsRestoringUserId] = useState<
    number | undefined
  >(undefined);
  const [relationshipUsers, setRelationshipUsers] = useState<
    OrganizationRelationshipUser[]
  >([]);
  const confirmWithModal = useConfirmWithModal();

  const availableAndNotAddedUsers = useMemo(() => {
    return availableUsers.filter(
      (user: UserEntity) =>
        !relationshipUsers.some(
          (u: OrganizationRelationshipUser) =>
            u.user_id === user.id && !u.archived_at
        )
    );
  }, [availableUsers, relationshipUsers]);

  const availableAndNotAddedUsersLabels = useMemo(() => {
    return availableAndNotAddedUsers.map((user: UserEntity) => ({
      value: user.id?.toString() || "",
      label: `${getUserName(user) ?? user?.id?.toString?.() ?? ""}  - ${
        user?.email ?? ""
      }`,
    }));
  }, [availableAndNotAddedUsers]);

  const activeUserCount = useMemo(() => {
    return relationshipUsers.filter((u) => typeof u.archived_at !== "string")
      .length;
  }, [relationshipUsers]);

  const isLastActiveUser = useCallback(() => {
    return activeUserCount < 2;
  }, [activeUserCount]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        console.log("fetchUsers with options", {
          join: userEntity.name,
          from: idField.name,
          to: userIdFieldRequired.name,
        });
        const response = await organizationRelationshipUserClient(
          clientError
        ).list(
          {
            provider_id: serviceProvider.id,
            customer_id: customer.id,
          },
          {
            join: userEntity.name,
            from: idField.name,
            to: userIdFieldRequired.name,
          }
        );

        if (response.success && "data" in response && "data" in response.data) {
          setRelationshipUsers(
            response.data.data as OrganizationRelationshipUser[]
          );
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        setIsLoadingUsers(false);
      }
    };

    fetchUsers();
  }, [serviceProvider.id, customer.id, availableUsers]);

  useEffect(() => {
    const fetchAvailableUsers = async () => {
      if (isCustomerUser) {
        return;
      }
      try {
        const response = await userClient(clientError).list({
          [virtualProviderIdField.name]: serviceProvider.id,
          [virtualCustomerIdField.name]: customer.id,
        });

        if (response.success && "data" in response && "data" in response.data) {
          setAvailableUsers(response.data.data as UserEntity[]);
        }
      } catch (err) {
        console.error("Error fetching available users:", err);
      } finally {
        setIsLoadingAvailableUsers(false);
      }
    };

    fetchAvailableUsers();
  }, [serviceProvider.id]);

  const handleRemoveUser = async (user: OrganizationRelationshipUserEntity) => {
    console.log("Remove user", user);
    setIsRemovingUserId(user.user_id!);
    try {
      const response = await organizationRelationshipUserClient(clientError)
        .item(serviceProvider.id!, customer.id!, user.user_id!)
        .delete();

      if (response.success && response.data.success) {
        setRelationshipUsers(
          relationshipUsers.map((u) =>
            u.user_id !== user.user_id
              ? u
              : response.data.success
              ? { ...response.data.data, User: u.User }
              : u
          ) as OrganizationRelationshipUser[]
        );
      }
    } catch (err) {
      console.error("Error removing user", err);
    } finally {
      setIsRemovingUserId(undefined);
    }
  };

  const handleRestoreUser = async (
    user: OrganizationRelationshipUserEntity
  ) => {
    setIsRestoringUserId(user.user_id!);
    try {
      const response = await organizationRelationshipUserClient(clientError)
        .item(serviceProvider.id!, customer.id!, user.user_id!)
        .upsert({
          archived_at: null as unknown as undefined,
          customer_id: customer.id,
          entitlements: [] as unknown as number, // array types aren't correct
          provider_id: serviceProvider.id,
          user_id: user.user_id,
        });

      if (response.success && response.data.success) {
        setRelationshipUsers(
          relationshipUsers.map((u) =>
            u.user_id !== user.user_id ? u : { ...u, archived_at: undefined }
          )
        );
      }
    } catch (err) {
      console.error("Error restoring user", err);
    } finally {
      setIsRestoringUserId(undefined);
    }
  };

  const handleAddUser = async (user: UserEntity) => {
    console.log("Add user", user);
    setIsAddingUser(true);
    try {
      const response = await organizationRelationshipUserClient(clientError)
        .item(serviceProvider.id!, customer.id!, user.id!)
        .upsert({
          archived_at: null as unknown as undefined,
          customer_id: customer.id,
          entitlements: [] as unknown as number, // array types aren't correct
          provider_id: serviceProvider.id,
          user_id: user.id,
        });
      if (
        response.success &&
        "data" in response &&
        "data" in response.data &&
        response.data.success
      ) {
        setRelationshipUsers([
          ...relationshipUsers,
          response.data.data as OrganizationRelationshipUser,
        ]);
        setSelectedUser(undefined);
        setAddUserModalOpened(false);
      }
    } catch (err) {
      console.error("Error adding user", err);
    } finally {
      setIsAddingUser(false);
    }
  };

  const [selectedUser, setSelectedUser] = useState<UserEntity | undefined>(
    undefined
  );

  const setSelectedUserByIdString = useCallback(
    (value: string | null) => {
      if (typeof value === "string") {
        const foundUser = availableUsers.find(
          (u) => u.id?.toString(10) === value
        );
        setSelectedUser(foundUser);
      } else if (value === null) {
        setSelectedUser(undefined);
      }
    },
    [availableUsers]
  );

  const [addUserModalOpened, setAddUserModalOpened] = useState(false);

  if (error) {
    return (
      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Error"
        color="#f44141"
        variant="filled"
      >
        {error}
      </Alert>
    );
  }

  const isLoading =
    isLoadingUsers || (isLoadingAvailableUsers && !isCustomerUser);

  const selectedUserExistingRelationship = relationshipUsers.find(
    (u) => u.user_id === selectedUser?.id
  );

  return (
    <Stack gap={0} style={{ height: "fit-content" }}>
      <Box py="md" px="lg">
        <Group justify="space-between" align="center">
          <Text>
            These are {serviceProvider.name} team members assigned to{" "}
            {customer.name}.
          </Text>
          {!isCustomerUser && hasManageEntitlement ? (
            <Button onClick={() => setAddUserModalOpened(true)}>
              Add a {serviceProvider.name} user
            </Button>
          ) : null}
        </Group>
      </Box>
      <Divider />

      <Modal
        opened={addUserModalOpened}
        onClose={() => setAddUserModalOpened(false)}
        title={`Add ${serviceProvider.name} User`}
      >
        <Modal.Body>
          <Stack gap="md">
            <Text>
              Select a {serviceProvider.name} user to add them to the
              relationship between {serviceProvider.name} and client,{" "}
              {customer.name}.
            </Text>
            <Alert variant="outline" title="Note">
              Users already added to this client are not displayed in the list.
            </Alert>
            {availableAndNotAddedUsers?.length > 0 ? (
              <Select
                clearable
                data={availableAndNotAddedUsersLabels}
                disabled={isAddingUser}
                onChange={setSelectedUserByIdString}
                placeholder="Select user to add..."
                searchable
                value={selectedUser?.id?.toString()}
              />
            ) : (
              <>
                <Text>No users without existing access found.</Text>
                <Link
                  to={"/people/overview"}
                  onClick={() => {
                    setAddUserModalOpened(false);
                    alert(
                      `Switch to the ${serviceProvider.name} organization in the top left to add a new user to this team.`
                    );
                  }}
                >
                  <Button>Add {serviceProvider.name} User</Button>
                </Link>
              </>
            )}
            {selectedUser && (
              <UserProfile
                withAvatar
                withArchived
                user={selectedUserExistingRelationship ?? selectedUser}
              />
            )}
            <Button
              disabled={
                !selectedUser || isAddingUser || isRestoringUserId !== undefined
              }
              onClick={async () => {
                if (selectedUserExistingRelationship) {
                  await handleRestoreUser(selectedUserExistingRelationship);
                } else {
                  await handleAddUser(selectedUser!);
                }
                setSelectedUser(undefined);
                setAddUserModalOpened(false);
              }}
            >
              {isAddingUser || isRestoringUserId !== undefined ? (
                <>
                  <Loader size="xs" />
                  {selectedUserExistingRelationship
                    ? " Restoring..."
                    : " Adding..."}
                </>
              ) : selectedUserExistingRelationship ? (
                "Restore User"
              ) : (
                "Add User"
              )}
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>

      <Paper shadow="xs" pos="relative">
        {relationshipUsers.length === 0 && !isLoading ? (
          <Text c="dimmed" p="lg">
            No {serviceProvider.name} users found for this relationship.
          </Text>
        ) : (
          <ScrollArea mah={`calc(100vh - 70px)`}>
            <Table highlightOnHover verticalSpacing="sm">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th pl="lg">User</Table.Th>
                  <Table.Th>Status</Table.Th>
                  <Table.Th>Email</Table.Th>
                  <Table.Th>Entitlements</Table.Th>
                  {!isCustomerUser && hasManageEntitlement ? (
                    <Table.Th ta="right"></Table.Th>
                  ) : null}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {relationshipUsers.map((relationshipUser) => (
                  <Table.Tr key={relationshipUser.user_id}>
                    <Table.Td pl="lg">
                      <Group gap="sm">
                        <UserProfile
                          withAvatar
                          withArchived
                          user={relationshipUser}
                        />
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <Badge
                        color={
                          relationshipUser.archived_at ? "#f44141" : "#54e382"
                        }
                        variant="light"
                      >
                        {relationshipUser.archived_at ? "Archived" : "Active"}
                      </Badge>
                    </Table.Td>
                    <Table.Td>
                      {relationshipUser.User?.email ?? "No email available"}
                    </Table.Td>
                    <Table.Td maw={200}>
                      {Array.isArray(relationshipUser.entitlements) &&
                      (relationshipUser.entitlements as unknown as number[])
                        .length > 0 ? (
                        (
                          relationshipUser.entitlements as unknown as number[]
                        ).map((entitlement: number) => (
                          <Badge
                            radius="xs"
                            key={entitlement}
                            variant="light"
                            mr="xs"
                            mb="xs"
                          >
                            {OrganizationRelationshipUserEntitlementLabels[
                              entitlement as keyof typeof OrganizationRelationshipUserEntitlementLabels
                            ] ?? `Unknown (${entitlement})`}
                          </Badge>
                        ))
                      ) : (
                        <Text size="sm" c="dimmed">
                          No entitlements
                        </Text>
                      )}
                    </Table.Td>
                    {!isCustomerUser && hasManageEntitlement ? (
                      <Table.Td p="lg" ta="right">
                        {isRemovingUserId !== relationshipUser.user_id &&
                        isRestoringUserId !== relationshipUser.user_id ? (
                          <Menu position="bottom-end" withArrow>
                            <Menu.Target>
                              <ActionIcon variant="default" size="md">
                                <IconDots size={15} />
                              </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                              {relationshipUser.archived_at ? (
                                <Menu.Item
                                  leftSection={
                                    <IconCircleArrowUpLeftFilled size={14} />
                                  }
                                  color="orange"
                                  onClick={() => {
                                    handleRestoreUser(relationshipUser);
                                  }}
                                >
                                  Restore Access
                                </Menu.Item>
                              ) : isLastActiveUser() ? (
                                <Menu.Item
                                  disabled={true}
                                  leftSection={<IconX size={14} />}
                                  color="#f44141"
                                >
                                  Remove
                                  <Text>
                                    Last active user cannot be removed.
                                  </Text>
                                </Menu.Item>
                              ) : (
                                <Menu.Item
                                  leftSection={<IconX size={14} />}
                                  color="#f44141"
                                  onClick={async () => {
                                    if (
                                      await confirmWithModal(
                                        `Are you sure you wish to remove ${getUserName(
                                          relationshipUser.User
                                        )} <${
                                          relationshipUser.User.email
                                        }> from ${serviceProvider.name}?`
                                      )
                                    ) {
                                      handleRemoveUser(relationshipUser);
                                    }
                                  }}
                                >
                                  Remove
                                </Menu.Item>
                              )}
                            </Menu.Dropdown>
                          </Menu>
                        ) : (
                          <Group gap="xs" justify="center">
                            <Loader size="xs" />
                            {isRestoringUserId === relationshipUser.user_id
                              ? "Restoring..."
                              : isRemovingUserId === relationshipUser.user_id
                              ? "Removing..."
                              : "Loading..."}
                          </Group>
                        )}
                      </Table.Td>
                    ) : null}
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        )}
        {isLoading ? (
          <Box
            style={{
              alignContent: "flex-end",
              alignItems: "center",
              display: "flex",
              height: "fit-content",
              justifyContent: "center",
              overflowY: "auto",
              padding: "100px",
            }}
          >
            <Loader type="oval" />
          </Box>
        ) : null}
        {!isLoading && relationshipUsers.length === 0 ? (
          <Box w="100%" m="xl" p="xl">
            <Center>
              <Title order={4}>No users.</Title>
            </Center>
          </Box>
        ) : null}
      </Paper>
    </Stack>
  );
}
