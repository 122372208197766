import { Box, Card, Grid, Group, Stack, Text, Title } from "@mantine/core";
import { IconChevronRight, IconExternalLink } from "@tabler/icons-react";
import { Link } from "react-router-dom";

import { getUserName } from "../../common/entities/user.js";
import { useAdmin } from "../contexts/AdminContext.js";
import { adminMenuItems } from "./menus/adminMainMenuUtils.js";

export function AdminHomePage() {
  const { user } = useAdmin();

  return (
    <Stack
      p="md"
      style={{
        flexGrow: 1,
        justifyContent: "center",
        maxWidth: "calc(100dvw - 40px)",
        margin: "0 auto",
        width: "960px",
      }}
    >
      <Title order={2} mb="md">
        Admin center
      </Title>
      <Box>
        <Text mb="xl">Welcome to the admin app, {getUserName(user)}!</Text>
      </Box>
      <Title order={3} mb="md">
        Quick links
      </Title>
      <Grid gutter="lg">
        {adminMenuItems.map((menuItem) => (
          <Grid.Col key={menuItem.to} span={{ base: 12, sm: 6, md: 4 }}>
            <Card
              component={Link}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.02)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
              padding="lg"
              withBorder
              target={menuItem.target}
              style={{
                cursor: "pointer",
                transition: "transform 0.2s",
                minHeight: "278px",
                boxSizing: "border-box",
                textDecoration: "none", // ensure link styling doesn't override card styles
              }}
              to={menuItem.to}
            >
              <Card.Section p="xl" mb="md">
                {menuItem.icon(42)}
              </Card.Section>
              <Group
                justify="space-between"
                mb="md"
                style={{
                  flexWrap: "nowrap",
                }}
              >
                <Title order={4}>{menuItem.label}</Title>

                {menuItem.target === "_blank" ? (
                  <IconExternalLink size={16} />
                ) : (
                  <IconChevronRight size={16} style={{ flexShrink: 0 }} />
                )}
              </Group>
              <Text size="sm" c="dimmed">
                {menuItem.subtitle}
              </Text>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </Stack>
  );
}
