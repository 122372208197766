import { IntegerField } from "../types/field";

export const refreshTokenExpiresInField: IntegerField<{
  name: "refresh_token_expires_in";
  required: true;
}> = {
  label: "Refresh Token Expires In",
  name: "refresh_token_expires_in",
  required: true,
  type: "integer",
};
