import { Card, Text } from "@mantine/core";
import { IconFileDescription, IconFolder } from "@tabler/icons-react";

import { FileItem, IconSizes } from "./types.js";
import { FileContextMenu } from "./FileContextMenu.js";
import { formatBytes } from "../../utils/formatters.js";

interface FileCardProps {
  item: FileItem;
  size: IconSizes;
  onClick: () => void;
  onOpen: () => void;
  onDownload: () => void;
  onRename: () => void;
  onDelete: () => void;
  onAddToChat?: () => void;
}

/**
 * FileCard component - displays a file or folder as a card in the grid view
 */
export function FileCard({
  item,
  size,
  onClick,
  onOpen,
  onDownload,
  onRename,
  onDelete,
  onAddToChat,
}: FileCardProps) {
  const iconSize = size === "small" ? 32 : 48;

  return (
    <Card
      p="sm"
      style={{
        width: size === "small" ? 100 : 150,
        cursor: "pointer",
        textAlign: "center",
        position: "relative", // for absolute positioning of menu
      }}
      onClick={onClick}
      withBorder
    >
      <div style={{ position: "absolute", top: 5, right: 5 }}>
        <FileContextMenu
          item={item}
          onOpen={onOpen}
          onDownload={onDownload}
          onRename={onRename}
          onDelete={onDelete}
          onAddToChat={onAddToChat}
          size="xs"
        />
      </div>

      {item.type === "folder" ? (
        <IconFolder
          size={iconSize}
          style={{ margin: "0 auto" }}
          color="#FFD43B"
        />
      ) : (
        <IconFileDescription
          size={iconSize}
          style={{ margin: "0 auto" }}
          color="#2196F3"
        />
      )}
      <Text size="sm" lineClamp={1} mt="xs">
        {item.name}
      </Text>
      {item.type === "file" && item.size && (
        <Text size="xs" color="dimmed">
          {formatBytes(item.size)}
        </Text>
      )}
    </Card>
  );
}
