import { css } from "../../utils/css.js";

interface ClassNames {
  alert: string;
}

export default css<ClassNames>`
  .alert::selection {
    background-color: var(--mantine-color-red-filled);
    color: var(--mantine-color-white);
  }
`;
