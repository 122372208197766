import {
  Box,
  Button,
  Center,
  Divider,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useCallback, useEffect, useState } from "react";

import { notifications } from "@mantine/notifications";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationUserEntity } from "../../../common/entities/organizationUser.js";
import {
  fields,
  primaryKeyFields,
  UserEntity,
  userEntity,
} from "../../../common/entities/user.js";
import { idField } from "../../../common/fields/id.js";
import { userIdFieldRequired } from "../../../common/fields/user_id.js";
import { virtualCustomerIdField } from "../../../common/fields/virtual_customer_id.js";
import { virtualProviderIdField } from "../../../common/fields/virtual_provider_id.js";
import {
  organizationUserClient,
  userClient,
} from "../../../common/utils/entityClient.js";
import { useCreateUserModal } from "../../hooks/useCreateUserModal.js";
import { clientError } from "../../utils/clientError.js";
import { StandardLoader } from "../Standard/StandardLoader.js";
import { UsersTable } from "../Tables/UsersTable.js";

interface UserManagementProps {
  customer?: OrganizationEntity;
  serviceProvider: OrganizationEntity;
  hasManageEntitlement: boolean;
  organizationId: number;
}

export type OrganizationUserWithUser = OrganizationUserEntity & {
  User: UserEntity;
};

export function UserManagement({
  customer,
  serviceProvider,
  hasManageEntitlement,
  organizationId,
}: UserManagementProps) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<OrganizationUserWithUser[] | null>(null);
  const [deletingUserIds, setDeletingUserIds] = useState<Set<number> | null>(
    null
  );
  const [sendingInvitationUserIds, setSendingInvitationUserIds] =
    useState<Set<number> | null>(null);
  const fetchUsers = useCallback(async () => {
    setLoading(true);
    const response = await organizationUserClient(clientError).list<
      typeof fields,
      typeof primaryKeyFields
    >(
      {
        provider_id: serviceProvider.id!,
        customer_id: customer?.id,
      },
      {
        join: userEntity.name,
        from: idField.name,
        to: userIdFieldRequired.name,
      }
    );
    if (response.success && response.data.success) {
      setUsers(response.data.data as OrganizationUserWithUser[]);
    }
    setLoading(false);
  }, [organizationId]);

  const organization = customer ?? serviceProvider;
  const { createModal, setCreateModalOpened } = useCreateUserModal(
    serviceProvider.id!,
    customer?.id,
    fetchUsers,
    organization
  );

  useEffect(() => {
    setUsers([]);
    fetchUsers();
  }, [fetchUsers, organization.id]);

  const updateUsersTable = useCallback(
    (updatedUser: OrganizationUserWithUser) => {
      setUsers(
        (latestUsers) =>
          latestUsers?.map((u) =>
            updatedUser.user_id === u.user_id &&
            updatedUser.organization_id === u.organization_id
              ? updatedUser
              : u
          ) ?? null
      );
    },
    []
  );

  const handleDelete = async (orgUser: OrganizationUserWithUser) => {
    if (!orgUser.User.id) {
      return;
    }
    setDeletingUserIds((x) => {
      if (!x) {
        return new Set([orgUser.User.id!]);
      }
      x.add(orgUser.User.id!);
      return new Set(x);
    });
    const response = await organizationUserClient(clientError)
      .item(organizationId, orgUser.User.id)
      .delete();
    if (response.success) {
      await fetchUsers();
    }
    setDeletingUserIds((x) => {
      if (!x || x.size === 0) {
        return null;
      }
      x.delete(orgUser.User.id!);
      return new Set(x);
    });
  };

  const handleSendInvitation = async (user: UserEntity) => {
    if (!user.id) {
      return;
    }
    setSendingInvitationUserIds((x) => {
      if (!x) {
        return new Set([user.id!]);
      }
      x.add(user.id!);
      return new Set(x);
    });
    const response = await userClient(clientError).create({
      email: user.email,
      [virtualCustomerIdField.name]: customer?.id,
      [virtualProviderIdField.name]: serviceProvider.id,
    });
    if (response.success) {
      notifications.show({
        title: "Invitation sent",
        message: `The invitation has been sent to ${user.email}.`,
        color: "green",
      });
    } else {
      notifications.show({
        title: "Invitation failed",
        message: `The invitation failed to send to ${user.email}.`,
        color: "red",
      });
    }
    setSendingInvitationUserIds((x) => {
      if (!x || x.size === 0) {
        return null;
      }
      x.delete(user.id!);
      return new Set(x);
    });
  };

  return (
    <Stack gap={0} style={{ height: "fit-content" }}>
      <Box py="md" px="lg">
        <Group justify="space-between" align="center">
          <Text>These are {organization.name} teammates.</Text>

          {hasManageEntitlement ? (
            <Button onClick={() => setCreateModalOpened(true)}>
              Invite a {organization.name} user
            </Button>
          ) : null}
        </Group>
      </Box>
      <Divider />

      {loading && !(Array.isArray(users) && users.length > 0) ? (
        <StandardLoader title="Loading team" />
      ) : Array.isArray(users) ? (
        <UsersTable
          users={users}
          onDelete={handleDelete}
          onSendInvitation={handleSendInvitation}
          hasManageEntitlement={hasManageEntitlement}
          deletingUserIds={deletingUserIds ?? undefined}
          sendingInvitationUserIds={sendingInvitationUserIds ?? undefined}
          updateUsersTable={updateUsersTable}
        />
      ) : (
        <Box
          style={{
            alignContent: "flex-end",
            alignItems: "center",
            display: "flex",
            height: "fit-content",
            justifyContent: "center",
            overflowY: "auto",
            padding: "100px",
          }}
        >
          <Loader type="oval" />
        </Box>
      )}

      {!loading && (!Array.isArray(users) || users.length === 0) ? (
        <Box w="100%" m="xl" p="xl">
          <Center>
            <Title order={4}>No users.</Title>
          </Center>
        </Box>
      ) : null}

      {createModal}
    </Stack>
  );
}
