import {
  Box,
  Center,
  Group,
  Paper,
  RingProgress,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  IconArrowDownRight,
  IconArrowUpRight,
  IconChartBar,
  IconCheck,
  IconClock,
  IconCoin,
  IconFile,
  IconFreezeRow,
  IconMessage,
  IconSettings,
  IconTrendingDown,
  IconTrendingUp,
  IconUser,
  IconUsers,
} from "@tabler/icons-react";

import { getUserName } from "../../../common/entities/user.js";
import { iconLibrary } from "../../../common/types/icons.js";
import { capitalizeFirstLetter } from "../../../common/utils/textUtils.js";
import { UserAvatar } from "../../components/Avatars/UserAvatar.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { StandardError } from "../../components/Standard/StandardError.js";
import { StandardLoader } from "../../components/Standard/StandardLoader.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { useMetrics } from "../../hooks/useMetrics.js";

const dashboardIcons: {
  [K in keyof typeof iconLibrary]: React.ElementType;
} = {
  chart: IconChartBar,
  clock: IconClock,
  down: IconArrowDownRight,
  file: IconFile,
  group: IconUsers,
  message: IconMessage,
  money: IconCoin,
  settings: IconSettings,
  trending_down: IconTrendingDown,
  trending_flat: IconFreezeRow,
  trending_up: IconTrendingUp,
  up: IconArrowUpRight,
  user: IconUser,
  verified: IconCheck,
};

export function DashboardOverview() {
  const { user, customer, serviceProvider } = useActiveStates();

  const greetingName = user ? getUserName(user) || "Hello" : "Guest";

  const { metrics, loading, error } = useMetrics({
    userId: user?.id,
    customerId: customer?.id,
    providerId: serviceProvider?.id,
  });

  const stats = metrics.map((metric) => {
    const Icon = dashboardIcons[metric.icon];
    return (
      <Paper withBorder radius="md" p="xs" key={metric.id}>
        <Group>
          <RingProgress
            size={80}
            roundCaps
            thickness={8}
            sections={[{ value: metric.progress!, color: metric.color! }]}
            label={
              <Center>
                <Icon size={20} />
              </Center>
            }
          />

          <div>
            <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
              {metric.label}
            </Text>
            <Text fw={700} size="xl">
              {metric.stats}
            </Text>
            {typeof (metric.data as { subLabel?: string })?.subLabel ===
              "string" &&
              (metric.data as { subLabel: string }).subLabel
                ?.split("\n")
                .map((line, index) => (
                  <Text c="dimmed" size="xs" my={5} key={index}>
                    {line}
                  </Text>
                ))}
          </div>
        </Group>
      </Paper>
    );
  });

  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />

      <Stack gap={10} p="xl">
        <Box p="md">
          <Group>
            <UserAvatar size={55} radius="xl" user={user} />
            <Stack>
              <Title order={2}>
                Good{" "}
                {new Date().getHours() < 12
                  ? "morning"
                  : new Date().getHours() < 18
                  ? "afternoon"
                  : "evening"}
                ,{" "}
                <Text component="span" inherit>
                  {capitalizeFirstLetter(greetingName)}!
                </Text>
                <Text size="sm" c="dimmed">
                  Here's your overview for{" "}
                  {customer?.name || serviceProvider?.name}
                </Text>
              </Title>
            </Stack>
          </Group>
        </Box>
        {loading && <StandardLoader title="Loading dashboard" />}
        {error && (
          <StandardError
            error={
              Array.isArray(error) ? error.join(", ") : error ?? "Unknown error"
            }
            title="Error loading dashboard"
          />
        )}
        <Box p="md">
          <SimpleGrid cols={{ base: 1, sm: 3 }}>{stats}</SimpleGrid>
        </Box>
      </Stack>
    </Layout>
  );
}
