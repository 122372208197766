import { IntegerField } from "../types/field";

export const INTEGRATION_QUICKBOOKS = 0 as const;
export const INTEGRATION_GURU = 1 as const;

export type IntegrationType =
  | typeof INTEGRATION_QUICKBOOKS
  | typeof INTEGRATION_GURU;

export const INTEGRATION_LABELS: Record<IntegrationType, string> = {
  [INTEGRATION_QUICKBOOKS]: "Quickbooks",
  [INTEGRATION_GURU]: "Guru",
};

export const integrationField: IntegerField<{
  final: true;
  name: "integration";
  required: true;
}> = {
  label: "Integration",
  final: true,
  name: "integration",
  required: true,
  type: "integer",
};
