import { archivedAtField } from "../fields/archived_at";
import { botIdField } from "../fields/bot_id";
import { colorField } from "../fields/color";
import { conversationIdField } from "../fields/conversation_id";
import { customerIdField } from "../fields/customer_id";
import { dataField } from "../fields/data";
import { iconField } from "../fields/icon";
import { idField } from "../fields/id";
import { labelField } from "../fields/label";
import { METRIC_LIBRARY_ID_USER_SIGN_IN, metricField } from "../fields/metric";
import { progressField } from "../fields/progress";
import { providerIdField } from "../fields/provider_id";
import { referenceIdField } from "../fields/reference_x_id";
import {
  REFERENCE_TYPE_USER,
  referenceTypeField,
} from "../fields/reference_x_type";
import { statsField } from "../fields/stats";
import { threadIdField } from "../fields/thread_id";
import { timestampField } from "../fields/timestamp";
import { userIdFieldOptional } from "../fields/user_id";
import { EntityDocs } from "../types/docs";
import {
  entity,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";
import { IconLibrary } from "../types/icons";

// Message fields
export const fields = [
  archivedAtField,
  botIdField,
  colorField,
  conversationIdField,
  customerIdField,
  dataField,
  iconField,
  idField,
  labelField,
  metricField,
  progressField,
  providerIdField,
  referenceIdField(0),
  referenceTypeField(0),
  statsField,
  threadIdField,
  timestampField,
  userIdFieldOptional,
] satisfies FieldList;

// Message primary key fields
export const primaryKeyFields = [idField] satisfies PrimaryKeyFieldTuple;

// Docs
export const docs: EntityDocs = {
  collection: {
    summary:
      "List metrics, which are stats about the system. These are displayed on the dashboard.",
  },
  item: { summary: "Perform operations on a metric." },
};

export const metricEntity = entity({
  api: {
    collectionPath: "metrics",
    itemPath: "metrics/:id",
  },
  docs,
  name: "Metric",
  fields,
  primaryKeyFields,
  tableName: "metrics",
});

export type MetricEntity = EntityType<
  typeof metricEntity.fields,
  typeof metricEntity.primaryKeyFields
> & { icon: IconLibrary };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleMetric: MetricEntity = {
  customer_id: 123,
  data: {},
  id: 0,
  metric: METRIC_LIBRARY_ID_USER_SIGN_IN,
  provider_id: 456,
  reference_0_id: 789,
  reference_0_type: REFERENCE_TYPE_USER,
  timestamp: new Date().toISOString(),
  user_id: 543,
  label: "User Sign In",
  stats: "100%",
  progress: 100,
  color: "#000000",
  icon: "up",
};
