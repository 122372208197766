import { Message } from "@ai-sdk/react";
import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Code,
  Group,
  Loader,
  Menu,
  Modal,
  Paper,
  ScrollArea,
  SimpleGrid,
  Stack,
  Tabs,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCheck,
  IconCode,
  IconCopy,
  IconDotsVertical,
  IconDownload,
  IconEye,
  IconFile,
  IconListNumbers,
  IconMoodEmpty,
  IconTable,
  IconVolume,
} from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";

import { MessageEntity } from "../../../common/entities/message.js";
import { OrganizationIntegrationTokenEntity } from "../../../common/entities/organizationIntegrationToken.js";
import { UserEntity } from "../../../common/entities/user.js";
import {
  INTEGRATION_LABELS,
  INTEGRATION_QUICKBOOKS,
} from "../../../common/fields/integration.js";
import { userIdFieldRequired } from "../../../common/fields/user_id.js";
import { MarkdownRenderer } from "../../pages/penny/MarkdownRenderer.js";
import { UserAvatar } from "../Avatars/UserAvatar.js";
import { VegaLiteChart } from "../Charts/VegaLiteChart.js";
import { downloadFile } from "../FileExplorer/fileOperations.js";
import { Weather, WeatherAtLocation } from "../Tools/Weather.js";
import { MessageContent, ToolInvocation } from "./MessageContent.js";

import classes from "./MessageDisplay.css.js";
import UploadedFile from "../PennyChat/UploadedFile.js";

const debug = false;

interface MessageDisplayProps {
  addToolResult?: (params: { toolCallId: string; result: any }) => void;
  message: Message | MessageEntity;
  streamFromStatic?: boolean;
  focused?: boolean;
  focusable?: boolean;
  read?: boolean;
  user?: UserEntity;
}

interface GuruResult {
  answer: string;
  cards: Array<{
    id: string;
    title: string;
    content: string;
    url: string;
  }>;
}

// Helper function to format bytes
function formatBytes(bytes: number): string {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export function MessageDisplay({
  addToolResult,
  message,
  streamFromStatic = false,
  focused = false,
  focusable = true,
  user,
}: MessageDisplayProps) {
  const [isCodeExpanded, setIsCodeExpanded] = useState(false);
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [isShowWorkExpanded, setIsShowWorkExpanded] = useState(false);
  const [hasShowWork, setHasShowWork] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [hasData, setHasData] = useState(false);
  const messageRef = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState(false);

  // Update state based on tool invocations
  useEffect(() => {
    const toolInvocations =
      (message as { toolInvocations?: ToolInvocation[] }).toolInvocations || [];
    toolInvocations.forEach((invocation) => {
      if (invocation.toolName === "quickbooksQuery" && invocation.result) {
        const queryResponse = (
          invocation.result as unknown as {
            data: { QueryResponse: Record<string, unknown> };
          }
        ).data?.QueryResponse;

        const totalCountLocal = (queryResponse?.totalCount as number) || 0;
        const hasDataLocal =
          queryResponse &&
          Object.keys(queryResponse).some(
            (key) =>
              Array.isArray(queryResponse[key]) && queryResponse[key].length > 0
          );

        if (hasData !== hasDataLocal) {
          setHasData(hasDataLocal);
          setTotalCount(totalCountLocal);
        }

        setHasShowWork(true);
      }

      if (invocation.toolName === "calculator" && invocation.result) {
        const { steps } = parseCalculatorResult(invocation.result);
        const hasShowWorkLocal = steps !== undefined;

        if (hasShowWork !== hasShowWorkLocal) {
          setHasShowWork(hasShowWorkLocal);
        }
      }
      if (invocation.toolName === "guruSearch" && invocation.result) {
        const { cards } = parseGuruResult(invocation.result ?? "");
        const hasShowWorkLocal = cards !== undefined;

        if (hasShowWork !== hasShowWorkLocal) {
          setHasShowWork(hasShowWorkLocal);
        }
      }
    });
  }, [message, hasShowWork, hasData]);

  // Helper function to parse calculator result
  const parseCalculatorResult = (result: string | undefined) => {
    if (!result) return { answer: "No result", steps: "" };

    const [answer, ...steps] = result.split("\n");
    return {
      answer,
      steps: steps.join("\n"),
    };
  };

  // Helper function to parse Guru result
  const parseGuruResult = (result: string): GuruResult => {
    try {
      return JSON.parse(result);
    } catch {
      return { answer: result, cards: [] };
    }
  };

  const renderToolInvocation = (invocation: ToolInvocation) => {
    debug &&
      console.log("renderToolInvocation called with:", {
        toolName: invocation.toolName,
        state: invocation.state,
        toolCallId: invocation.toolCallId,
        hasResult: !!invocation.result,
      });

    switch (invocation.toolName) {
      case "askForConfirmation":
        return (
          <Stack key={invocation.toolCallId} gap={0}>
            <Text c="dimmed" pt="xs">
              Asking confirmation...
            </Text>
            {String(invocation.args?.message)}
            <div>
              {invocation.state === "result" &&
              invocation.result === "Waiting for user confirmation..." ? (
                <>
                  <Button
                    variant="primary"
                    onClick={() =>
                      addToolResult?.({
                        toolCallId: invocation.toolCallId,
                        result: "Yes",
                      })
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() =>
                      addToolResult?.({
                        toolCallId: invocation.toolCallId,
                        result: "No",
                      })
                    }
                  >
                    No
                  </Button>
                </>
              ) : (
                <b>{invocation.result}</b>
              )}
            </div>
          </Stack>
        );

      case "guruSearch":
        if (invocation.state === "result") {
          const { answer, cards } = parseGuruResult(invocation.result ?? "");
          return (
            <Stack key={invocation.toolCallId}>
              <Text c="dimmed" pt="xs">
                Called the internal knowledge base API
              </Text>
              <Box ref={messageRef}>
                <MarkdownRenderer content={answer} />
                <Modal
                  size="fit-content"
                  opened={isShowWorkExpanded}
                  onClose={() => setIsShowWorkExpanded(false)}
                  title="Source Documents"
                  maw="90vw"
                >
                  {cards && cards.length > 0 && (
                    <Tabs defaultValue={cards[0].id.toString()}>
                      <ScrollArea>
                        <Tabs.List>
                          {cards.map((card) => (
                            <Tabs.Tab key={card.id} value={card.id}>
                              {card.title || "Source Document"}
                            </Tabs.Tab>
                          ))}
                        </Tabs.List>
                      </ScrollArea>

                      {cards.map((card) => (
                        <Tabs.Panel
                          key={card.id}
                          value={card.id.toString()}
                          p="md"
                          style={{
                            maxHeight: "75vh",
                            overflow: "auto",
                          }}
                        >
                          <Stack>
                            <Text size="lg" fw={700}>
                              {card.title}
                            </Text>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: card.content,
                              }}
                              className="guru-content"
                            />
                          </Stack>
                        </Tabs.Panel>
                      ))}
                    </Tabs>
                  )}
                </Modal>
              </Box>
            </Stack>
          );
        }
        if (invocation.state === "call") {
          return (
            <Box key={invocation.toolCallId}>
              <Group>
                <Text c="dimmed">Searching my internal knowledge base</Text>
                <Loader type="dots" size="xs" />
              </Group>
            </Box>
          );
        }
        if (invocation.state === "error") {
          return (
            <Box key={invocation.toolCallId}>
              <Stack>
                <Group>
                  <Text c="dimmed">Error searching Guru:</Text>
                  <Loader type="dots" size="xs" />
                </Group>
                <Text> {invocation.error}</Text>
              </Stack>
            </Box>
          );
        }
        break;

      case "calculator": {
        if (invocation.state === "call") {
          return (
            <Group key={invocation.toolCallId}>
              <Text c="dimmed" pt="xs">
                Grabbing my calculator
              </Text>
              <Loader type="dots" />
            </Group>
          );
        }
        const { answer, steps } = parseCalculatorResult(invocation.result);

        return (
          <Stack defaultValue="answer" key={invocation.toolCallId}>
            <Text c="dimmed" pt="xs">
              Used my handy lil calculator
            </Text>
            <Box ref={messageRef}>
              <Text size="lg" fw={700} c="blue">
                {answer}
              </Text>
            </Box>
            <Modal
              size="xl"
              opened={isShowWorkExpanded}
              onClose={() => setIsShowWorkExpanded(false)}
              title="Steps I took to get this result"
            >
              <Stack gap="xs">
                {steps.split("\n\n").map((step, index) => {
                  if (step.startsWith("Starting with:")) {
                    return (
                      <Text key={index} c="dimmed" fs="italic">
                        {step}
                      </Text>
                    );
                  }
                  if (step.startsWith("Expression is now:")) {
                    return (
                      <Text key={index} c="blue" fw={500}>
                        {step}
                      </Text>
                    );
                  }
                  if (step.startsWith("Solve")) {
                    const [operation, calculation] = step.split("\n");
                    return (
                      <div key={index}>
                        <Text size="sm" fw={500}>
                          {operation}
                        </Text>
                        <Code ml="md" style={{ fontSize: "1.1em" }}>
                          {calculation}
                        </Code>
                      </div>
                    );
                  }
                  return (
                    <Text key={index} size="sm">
                      {step}
                    </Text>
                  );
                })}
                <Card mt="md">
                  <Text>Final Answer</Text>
                  <Title order={3}>{answer.toLocaleString()}</Title>
                </Card>
              </Stack>
            </Modal>
          </Stack>
        );
      }
      case "quickbooksIsConnected": {
        if (invocation.state === "call") {
          return (
            <Group>
              <Text c="dimmed" pt="xs">
                Checking if Quickbooks is connected
              </Text>
              <Loader type="dots" />
            </Group>
          );
        }
        const token = invocation.result as OrganizationIntegrationTokenEntity;
        return (
          <Stack>
            <Text c="dimmed" pt="xs">
              Heard back from Quickbooks
            </Text>
            <Box ref={messageRef}>
              <MarkdownRenderer
                content={
                  "error" in token &&
                  "success" in token &&
                  token.success === false
                    ? (token.error as string)
                    : token
                    ? `Yes, ${
                        INTEGRATION_LABELS[
                          token.integration as keyof typeof INTEGRATION_LABELS
                        ]
                      } was connected on ${new Date(
                        token.updated_at!
                      ).toLocaleDateString()}. You can disconnect or connect to another account by going to the [Apps & Integrations page](/connect/overview).`
                    : `No, ${INTEGRATION_LABELS[INTEGRATION_QUICKBOOKS]} is not connected. You can connect it by going to the [Apps & Integrations page](/connect/overview).`
                }
              />
            </Box>
          </Stack>
        );
      }
      case "quickbooksQuery": {
        if (invocation.state === "call") {
          return (
            <Group>
              <Text c="dimmed" pt="xs">
                Asking Quickbooks
              </Text>
              <Loader type="dots" />
            </Group>
          );
        }
        debug && console.dir(invocation);

        const result = (
          invocation.result as unknown as {
            success: boolean;
            dataSummary: string;
            summary: string;
          }
        ).success ? (
          <>
            <MarkdownRenderer
              content={
                (
                  invocation.result as unknown as {
                    summary: string;
                  }
                ).summary
              }
            />
          </>
        ) : (
          <Group>
            <Badge color="#f44141">Error</Badge>
            <Text>
              {(invocation.result as unknown as { error: string }).error}
            </Text>
          </Group>
        );

        return (
          <Stack key={invocation.toolCallId}>
            <Text c="dimmed" pt="xs">
              Heard back from Quickbooks
            </Text>
            <Box ref={messageRef}>{result}</Box>

            <Modal
              size="xl"
              opened={isTableExpanded}
              onClose={() => setIsTableExpanded(false)}
              title={`Results: ${totalCount} rows`}
            >
              <MarkdownRenderer
                content={
                  (
                    invocation.result as unknown as {
                      dataSummary: string;
                    }
                  ).dataSummary
                }
              />
            </Modal>
            <Modal
              size="xl"
              opened={isShowWorkExpanded}
              onClose={() => setIsShowWorkExpanded(false)}
              title="Steps I took to get this result"
            >
              <Group>
                <Badge>Query</Badge>
                <Code>{(invocation.args as { query: string }).query}</Code>
              </Group>
            </Modal>
          </Stack>
        );
      }
      case "getWeather": {
        if (invocation.state === "call") {
          return (
            <Group>
              <Text c="dimmed" pt="xs">
                Calling a local meteorologist
              </Text>
              <Loader type="dots" size="xs" />
            </Group>
          );
        }
        return (
          <Stack key={invocation.toolCallId} gap={0}>
            <Text c="dimmed" pt="xs">
              Heard back from the weather man
            </Text>
            <Weather
              weatherAtLocation={
                invocation.result as unknown as WeatherAtLocation
              }
            />
          </Stack>
        );
      }
      case "teamProfiles": {
        if (invocation.state === "call") {
          return (
            <Box key={invocation.toolCallId}>
              <Group>
                <Text c="dimmed">Reading all our team profiles</Text>
                <Loader type="dots" size="xs" />
              </Group>
            </Box>
          );
        }
        if (invocation.state === "error") {
          return (
            <Box key={invocation.toolCallId}>
              <Stack>
                <Group>
                  <Text c="dimmed">Error getting team profiles:</Text>
                  <Loader type="dots" size="xs" />
                </Group>
                <Text> {invocation.error}</Text>
              </Stack>
            </Box>
          );
        }
        return (
          <Stack key={invocation.toolCallId}>
            <Text c="dimmed" pt="xs">
              Got some info back from our team profiles
            </Text>
            {invocation.result ? (
              <MarkdownRenderer
                key={invocation.toolCallId}
                content={invocation.result}
              />
            ) : (
              <Group p="xs" gap="xs" style={{ "--group-wrap": "none" }}>
                <IconMoodEmpty /> <Text c="dimmed">No result</Text>
              </Group>
            )}
          </Stack>
        );
      }
      case "chartGenerator": {
        if (invocation.state === "call") {
          return (
            <Group>
              <Text c="dimmed" pt="xs">
                Generating a chart
              </Text>
              <Loader type="dots" />
            </Group>
          );
        }

        const chartData = invocation.result as unknown as {
          type: "vega-lite";
          spec: any;
          width?: number | "container";
          height?: number | "container";
        };

        // Ensure the spec has the correct structure
        if (!chartData.spec || !chartData.spec.data) {
          debug && console.error("Invalid chart spec structure:", chartData);
          return <Text c="red">Error: Invalid chart specification</Text>;
        }

        return (
          <Stack key={invocation.toolCallId}>
            <Text c="dimmed" pt="xs">
              Generated a visualization
            </Text>
            <Box
              ref={messageRef}
              style={{
                padding: "0",
                position: "relative",
                display: "inline-block",
                overflow: "hidden",
              }}
            >
              <VegaLiteChart
                spec={chartData.spec}
                height={chartData.height || "container"}
              />
            </Box>
          </Stack>
        );
      }
      default:
        if (invocation.state === "call") {
          return (
            <Group>
              <Text c="dimmed">Tapping into my inner brain</Text>
              <Loader type="dots" />
            </Group>
          );
        }
        return (
          <Stack key={invocation.toolCallId}>
            <Text c="dimmed" pt="xs">
              Found some info in my inner brain
            </Text>
            <MarkdownRenderer
              content={
                typeof invocation.result === "object"
                  ? JSON.stringify(invocation.result)
                  : String(invocation.result ?? invocation.toolName)
              }
            />
          </Stack>
        );
    }
  };

  const handleCopy = () => {
    if (messageRef.current) {
      const range = document.createRange();
      range.selectNodeContents(messageRef.current);
      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);

      try {
        document.execCommand("copy");
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        debug && console.error("Failed to copy: ", err);
      }

      selection?.removeAllRanges();
    }
  };

  const handleReadAloud = () => {
    if (messageRef.current) {
      const text = messageRef.current.textContent;
      if (text) {
        const utterance = new SpeechSynthesisUtterance(text);
        window.speechSynthesis.speak(utterance);
      }
    }
  };

  const isAssistant =
    ((message?.data as { role?: string })?.role ??
      (message as Message)?.role) === "assistant";

  return (
    <Box className={classes.chatWrapper}>
      <Group
        align="flex-start"
        gap="sm"
        justify="flex-end"
        style={{ "--group-wrap": "none" }}
      >
        {((message?.data as { role?: string })?.role ??
          (message as Message)?.role) === "assistant" ? (
          <Avatar src="/images/penny-icon.svg" title="Penny">
            P
          </Avatar>
        ) : null}
        <Stack
          gap={0}
          className={`${classes.messageDisplay} ${
            focusable && focused ? classes.focused : ""
          } ${
            ((message?.data as { role?: string })?.role ??
              (message as Message)?.role) === "assistant"
              ? classes.botBubble
              : ""
          } ${
            ((message?.data as { role?: string })?.role ??
              (message as Message)?.role) === "user"
              ? classes.userBubble
              : ""
          }`}
        >
          <MessageContent
            message={message}
            streamFromStatic={streamFromStatic}
            addToolResult={(internal, toolCallId, result) => {
              if (internal) {
                debug &&
                  console.log("Internal tool result:", toolCallId, result);
              } else {
                addToolResult?.({ toolCallId, result });
              }
            }}
            messageRef={messageRef}
          />

          {/* Add attachments grid */}
          {((
            message?.data as {
              attachments?: { name: string; size: number; url: string }[];
            }
          )?.attachments?.length ?? 0) > 0 && (
            <Stack
              gap="xs"
              pt="xs"
              align={isAssistant ? "flex-start" : "flex-end"}
            >
              {(
                (
                  message?.data as {
                    attachments?: { name: string; size: number; url: string }[];
                  }
                )?.attachments ?? []
              ).map((attachment, index) => (
                <UploadedFile
                  key={index}
                  file={attachment}
                  actions={
                    <Menu position="bottom-end">
                      <Menu.Target>
                        <ActionIcon variant="subtle" size="sm">
                          <IconDotsVertical size={16} />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item disabled>
                          <Group>
                            <IconFile size={14} />
                            <Text size="sm">{attachment.name}</Text>
                          </Group>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          leftSection={<IconEye size={14} />}
                          onClick={() => {
                            window.open(attachment.url, "_blank");
                          }}
                        >
                          View
                        </Menu.Item>
                        <Menu.Item
                          leftSection={<IconDownload size={14} />}
                          onClick={() =>
                            downloadFile({
                              name: attachment.name,
                              url: attachment.url,
                              type: "file",
                              path: attachment.name, // Using filename as path since we don't have a real path
                            })
                          }
                        >
                          Download
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  }
                />
              ))}
            </Stack>
          )}

          {(message as { toolInvocations?: ToolInvocation[] }).toolInvocations
            ?.filter((x) => x.toolCallId !== "data")
            .map(renderToolInvocation)}

          {((message?.data as { role?: string })?.role ??
            (message as Message)?.role) === "assistant" ? (
            <Group gap={2} mt="sm" style={{ "--group-wrap": "none" }}>
              <Tooltip label="Read aloud" position="bottom" withArrow>
                <ActionIcon
                  size="md"
                  variant="subtle"
                  c="dimmed"
                  onClick={handleReadAloud}
                >
                  <IconVolume size={20} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Copy" position="bottom" withArrow>
                <ActionIcon
                  size="md"
                  variant="subtle"
                  c="dimmed"
                  onClick={handleCopy}
                >
                  {copied ? <IconCheck size={20} /> : <IconCopy size={20} />}
                </ActionIcon>
              </Tooltip>
              {hasData && (
                <Tooltip
                  label={`View ${totalCount} rows`}
                  position="bottom"
                  withArrow
                >
                  <ActionIcon
                    size="md"
                    variant="subtle"
                    c="dimmed"
                    onClick={() => setIsTableExpanded(!isTableExpanded)}
                  >
                    <IconTable size={20} />
                  </ActionIcon>
                </Tooltip>
              )}
              {hasShowWork && (
                <Tooltip label="See my work" position="bottom" withArrow>
                  <ActionIcon
                    size="md"
                    variant="subtle"
                    c="dimmed"
                    onClick={() => setIsShowWorkExpanded(!isShowWorkExpanded)}
                  >
                    <IconListNumbers size={20} />
                  </ActionIcon>
                </Tooltip>
              )}
              <Tooltip label="Debug" position="bottom" withArrow>
                <ActionIcon
                  size="md"
                  variant="subtle"
                  c="dimmed"
                  onClick={() => setIsCodeExpanded(!isCodeExpanded)}
                >
                  <IconCode size={20} />
                </ActionIcon>
              </Tooltip>
            </Group>
          ) : (
            ""
          )}
        </Stack>
        {((message?.data as { role?: string })?.role ??
          (message as Message)?.role) === "user" ? (
          userIdFieldRequired.name in message ? (
            <UserAvatar userId={message[userIdFieldRequired.name]} />
          ) : user ? (
            <UserAvatar user={user} />
          ) : (
            <Avatar>?</Avatar>
          )
        ) : null}
      </Group>

      {/* Debug info section */}
      <Modal
        size="xl"
        opened={isCodeExpanded}
        onClose={() => setIsCodeExpanded(false)}
        title="Debug info"
      >
        <Code block>{JSON.stringify(message, null, 2)}</Code>
      </Modal>
    </Box>
  );
}
