import { Text } from "@mantine/core";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import { CompanyOverview } from "../../components/Admin/CompanyOverview.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";

export function SettingsOverview() {
  const { customer, serviceProvider } = useActiveStates();

  // Use customer if available, otherwise use service provider
  const activeOrganization: OrganizationEntity | undefined = customer?.id
    ? customer
    : serviceProvider;

  if (!activeOrganization) {
    return (
      <Layout>
        <Text>No organization selected</Text>
      </Layout>
    );
  }

  const renderContent = () => {
    return <CompanyOverview activeOrganization={activeOrganization} />;
  };

  return (
    <Layout>
      <PageTitle title="Organization Profile" />
      {renderContent()}
    </Layout>
  );
}
