import { notifications } from "@mantine/notifications";
// Define our own interface for FileRejection
interface FileRejection {
  file: File;
  errors: Array<{ message: string }>;
}

import { UploadItem } from "./types.js";
import { filesClient } from "../../utils/filesClient.js";

interface HandleUploadOptions {
  currentPath: string;
  serviceProviderId: number | undefined;
  customerId: number | undefined;
  uploadQueue: UploadItem[];
  setUploadQueue: React.Dispatch<React.SetStateAction<UploadItem[]>>;
  onUploadComplete?: () => void;
  user?: { name?: string };
}

/**
 * Handle file upload, including validation and progress tracking
 */
export function handleUpload(
  files: File[],
  rejections: FileRejection[],
  {
    currentPath,
    serviceProviderId,
    customerId,
    uploadQueue,
    setUploadQueue,
    onUploadComplete,
    user,
  }: HandleUploadOptions
) {
  console.log("Handling upload", { files, rejections });

  // Handle rejected files
  if (rejections.length > 0) {
    for (const rejection of rejections) {
      console.error("File rejected:", rejection);
      const errorMessages = rejection.errors
        .map((err) => err.message)
        .join(", ");

      notifications.show({
        title: "File rejected",
        message: `${rejection.file.name}: ${errorMessages}`,
        color: "red",
      });
    }
  }

  // Exit if no valid files
  if (files.length === 0) {
    return;
  }

  // Ensure we have a service provider ID
  if (!serviceProviderId) {
    notifications.show({
      title: "Error",
      message: "Service provider ID is required to upload files",
      color: "red",
    });
    return;
  }

  // Add files to upload queue
  const newUploads = files.map((file) => ({
    id: Math.random().toString(36).substr(2, 9),
    file,
    progress: 0,
    status: "queued" as const,
  }));

  setUploadQueue([...uploadQueue, ...newUploads]);

  // Process each file sequentially to avoid overwhelming the backend
  processQueue(
    newUploads,
    currentPath,
    serviceProviderId,
    customerId,
    setUploadQueue,
    onUploadComplete,
    user
  );
}

/**
 * Process files in the upload queue sequentially
 */
async function processQueue(
  uploads: UploadItem[],
  currentPath: string,
  serviceProviderId: number,
  customerId: number | undefined,
  setUploadQueue: React.Dispatch<React.SetStateAction<UploadItem[]>>,
  onUploadComplete?: () => void,
  user?: { name?: string }
) {
  // Process each file sequentially
  for (const item of uploads) {
    try {
      // Create metadata for the upload
      const metadata = {
        uploadedBy: user?.name || "Unknown user",
        modifiedBy: user?.name || "Unknown user",
      };

      // Use progress tracking
      let progressInterval: NodeJS.Timeout | null = null;

      try {
        // Simulate progress for visual feedback
        progressInterval = setInterval(() => {
          setUploadQueue((prevQueue) =>
            prevQueue.map((queueItem) =>
              queueItem.id === item.id && queueItem.progress < 90
                ? { ...queueItem, progress: queueItem.progress + 10 }
                : queueItem
            )
          );
        }, 300);

        // Upload the file
        const result = await filesClient.uploadFile(
          item.file,
          currentPath ? `${currentPath}/` : "",
          metadata,
          serviceProviderId,
          customerId
        );

        // Clear the progress interval
        if (progressInterval) {
          clearInterval(progressInterval);
          progressInterval = null;
        }

        // Check for success
        if (!result.success) {
          throw new Error(result.error || "Failed to upload file");
        }

        // Mark as complete in queue
        setUploadQueue((prevQueue) =>
          prevQueue.map((queueItem) =>
            queueItem.id === item.id
              ? {
                  ...queueItem,
                  status: "completed",
                  progress: 100,
                  url: result.data?.url,
                }
              : queueItem
          )
        );

        // Show success notification
        notifications.show({
          title: "Upload complete",
          message: `${item.file.name} was uploaded successfully`,
          color: "green",
        });
      } finally {
        // Make sure we clear the interval if it exists
        if (progressInterval) {
          clearInterval(progressInterval);
        }
      }
    } catch (error) {
      console.error("Upload error for", item.file.name, error);

      // Mark as failed in queue
      setUploadQueue((prevQueue) =>
        prevQueue.map((queueItem) =>
          queueItem.id === item.id
            ? {
                ...queueItem,
                status: "error",
                error: error instanceof Error ? error.message : "Upload failed",
              }
            : queueItem
        )
      );

      // Show error notification
      notifications.show({
        title: "Upload failed",
        message: `${item.file.name} failed to upload: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        color: "red",
      });
    }
  }

  // After all uploads are processed, notify parent component
  if (onUploadComplete) {
    onUploadComplete();
  }
}
