import { notifications } from "@mantine/notifications";
import { useCallback, useEffect, useState } from "react";
import { KVEntity, KVEntityStore } from "../../common/entities/kv.js";
import { UserEntity } from "../../common/entities/user.js";
import { useActiveStates } from "../contexts/ActiveStatesContext.js";

export interface Note {
  id: string;
  text: string;
  createdAt: string;
  updatedAt: string;
  pinned: boolean;
  color: string;
  User: UserEntity;
}

export function useNotes(kv: KVEntityStore) {
  const { user } = useActiveStates();
  const [notes, setNotes] = useState<Note[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  // Load notes from KV store
  const loadNotes = useCallback(async () => {
    try {
      setLoading(true);
      const notesResult = await kv.list();
      if (notesResult.success) {
        setNotes(
          (notesResult.data as (KVEntity & { User: UserEntity })[]).map(
            (x: KVEntity & { User: UserEntity }) =>
              Object.assign(JSON.parse(x.value ?? "{}") as Note, {
                User: x.User,
              }) as unknown as Note
          ) as Note[]
        );
        setError(null);
      } else {
        setError(new Error(notesResult.errors.join(", ")));
      }
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to load notes"));
      console.error("Error loading notes:", err);
    } finally {
      setLoading(false);
    }
  }, [kv]);

  // Reload a single note
  const reloadNote = useCallback(
    async (id: string) => {
      try {
        const noteJson = await kv.getItem(id);
        if (noteJson.success) {
          const note = JSON.parse(noteJson.data?.value ?? "{}");
          setNotes((prev) => prev.map((n) => (n.id === id ? note : n)));
          return note;
        } else {
          return null;
        }
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("Failed to reload note")
        );
        console.error("Error reloading note:", err);
        return null;
      }
    },
    [kv]
  );

  // Add a new note
  const addNote = useCallback(
    async (text: string, color: string = "#ffeb3b") => {
      try {
        setSaving(true);
        const newNote: Note = {
          id: crypto.randomUUID(),
          text,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          pinned: false,
          color,
          User: user,
        };

        await kv.setItem(newNote.id, JSON.stringify(newNote));
        setNotes((prev) => [...prev, newNote]);
        return newNote;
      } catch (err) {
        setError(err instanceof Error ? err : new Error("Failed to add note"));
        console.error("Error adding note:", err);
        throw err;
      } finally {
        setSaving(false);
      }
    },
    [kv]
  );

  // Toggle pin status
  const togglePin = useCallback(
    async (id: string) => {
      try {
        const note = notes.find((n) => n.id === id);
        if (!note) throw new Error("Note not found");

        const updatedNote = {
          ...note,
          pinned: !note.pinned,
          updatedAt: new Date().toISOString(),
        };

        await kv.setItem(id, JSON.stringify(updatedNote));
        setNotes((prev) => prev.map((n) => (n.id === id ? updatedNote : n)));
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error("Failed to update note pin status")
        );
        console.error("Error updating note pin status:", err);
        throw err;
      }
    },
    [notes, kv]
  );

  // Update note color
  const updateNoteColor = useCallback(
    async (id: string, color: string) => {
      try {
        const note = notes.find((n) => n.id === id);
        if (!note) throw new Error("Note not found");

        const updatedNote = {
          ...note,
          color,
          updatedAt: new Date().toISOString(),
        };

        await kv.setItem(id, JSON.stringify(updatedNote));
        setNotes((prev) => prev.map((n) => (n.id === id ? updatedNote : n)));
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("Failed to update note color")
        );
        console.error("Error updating note color:", err);
        throw err;
      }
    },
    [notes, kv]
  );

  // Update an existing note
  const updateNote = useCallback(
    async (id: string, text: string) => {
      try {
        const note = notes.find((n) => n.id === id);
        if (!note) throw new Error("Note not found");

        const updatedNote = {
          ...note,
          text,
          updatedAt: new Date().toISOString(),
        };

        await kv.setItem(id, JSON.stringify(updatedNote));
        setNotes((prev) => prev.map((n) => (n.id === id ? updatedNote : n)));
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("Failed to update note")
        );
        console.error("Error updating note:", err);
        throw err;
      }
    },
    [notes, kv]
  );

  // Delete a note
  const deleteNote = useCallback(
    async (id: string) => {
      setError(null);
      try {
        await kv.removeItem(id);
        setNotes((prev) => prev.filter((note) => note.id !== id));
        notifications.show({
          title: "Note deleted",
          message: "The note was deleted successfully",
          color: "green",
        });
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("Failed to delete note")
        );
        console.error("Error deleting note:", err);
        throw err;
      }
    },
    [kv]
  );

  // Get sorted notes (pinned first)
  const getSortedNotes = useCallback(() => {
    return [...notes].sort((a, b) => {
      // Sort by pinned status first (pinned notes come first)
      if (a.pinned && !b.pinned) return -1;
      if (!a.pinned && b.pinned) return 1;

      // Then sort by updated date (newest first)
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });
  }, [notes]);

  // Load notes on initial mount
  useEffect(() => {
    loadNotes();
  }, [loadNotes]);

  return {
    notes: getSortedNotes(),
    loading,
    error,
    addNote,
    updateNote,
    deleteNote,
    togglePin,
    updateNoteColor,
    refreshNotes: loadNotes,
    reloadNote,
    saving,
  };
}
