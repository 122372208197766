import { AppShell, Button, Container, Group, Stack, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";

import { LEDGERAI_SLUG } from "../../common/fields/slug.js";
import { organizationUserClient } from "../../common/utils/entityClient.js";
import { capitalizeFirstLetter } from "../../common/utils/textUtils.js";
import { useActiveStates } from "../contexts/ActiveStatesContext.js";
import { ErrorBoundary } from "../ErrorBoundary.js";
import { useAside } from "../hooks/useAside.js";
import { useAuth } from "../hooks/useAuth.js";
import classes from "../pages/dashboard/welcome.css.js";
import { AccountPrimaryColor } from "../theme.js";
import { clientError } from "../utils/clientError.js";
import { AppHeader } from "./AppHeader.js";
import { AsideContentWrapper } from "./Aside/AsideContentWrapper.js";
import { DefaultAsideContent } from "./DefaultAsideContent.js";
import { Navbar } from "./Navbar/Navbar.js";

const debug = false;

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const {
    adminIsOpen,
    organizationUser,
    user,
    isCustomerUser,
    serviceProvider,
    customer,
  } = useActiveStates();
  const {
    opened,
    currentContent: asideContent,
    isPennyChatOpen,
    currentFolderPath,
  } = useAside();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const organization = customer || serviceProvider;
  const { logOut } = useAuth();

  const greetingName = user
    ? user.nickname || user.given_name || "Hello"
    : "Guest";

  // Add debug logging to check if the aside state is correctly detected
  debug &&
    console.log("Layout - aside state:", {
      opened,
      hasAsideContent: !!asideContent,
      isPennyChatOpen,
      currentFolderPath,
    });

  async function handleAcceptInvite(): Promise<void> {
    setIsLoading(true);
    try {
      const response = await organizationUserClient(clientError)
        .item(organizationUser.organization_id!, user!.id!)
        .update({
          is_invitation: false,
        });
      if (response.success) {
        location.reload();
      } else {
        notifications.show({
          title: "Error",
          message: "Unable to accept invitation.",
          color: "#f44141",
        });
      }
    } catch (error) {
      console.error("Failed to accept invitation:", error);
      notifications.show({
        title: "Error",
        message: "Unable to accept invitation.",
        color: "#f44141",
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (adminIsOpen) {
    return null;
  }

  if (organizationUser.is_invitation) {
    return (
      <AppShell withBorder header={{ height: 70 }}>
        <AppHeader />

        <AppShell.Main
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={classes.wrapper}>
            <Container>
              <Stack gap={10}>
                <h1 className={classes.title}>
                  Welcome{" "}
                  <Text component="span" c={AccountPrimaryColor} inherit>
                    {capitalizeFirstLetter(greetingName)}
                  </Text>
                  ,
                </h1>

                <Text className={classes.description} color="dimmed">
                  {organization?.name} uses LedgerAI to make teamwork smarter
                  and simpler. Accept this invite to join your team and get
                  started.
                </Text>

                <Group>
                  <Button
                    variant="filled"
                    size="lg"
                    radius="xl"
                    onClick={handleAcceptInvite}
                    loading={isLoading}
                  >
                    Accept invite
                  </Button>
                  {!isLoading && (
                    <Button
                      size="lg"
                      radius="xl"
                      variant="subtle"
                      loading={isLoggingOut}
                      onClick={() => {
                        setIsLoggingOut(true);
                        logOut((url) => window.location.replace(url));
                      }}
                    >
                      Logout, and don't accept invite
                    </Button>
                  )}
                </Group>
                <Text c="dimmed" fz="sm" mt="xl">
                  {isCustomerUser ? (
                    <>This service is provided by</>
                  ) : (
                    <>
                      You are navigating {organization?.name} as a team member
                      of{" "}
                    </>
                  )}{" "}
                  {serviceProvider.name}.{" "}
                  {serviceProvider.slug === LEDGERAI_SLUG ? (
                    <>(Platform Admin)</>
                  ) : null}
                </Text>
              </Stack>
            </Container>
          </div>
        </AppShell.Main>
      </AppShell>
    );
  }
  return (
    <AppShell
      withBorder
      navbar={{
        width: 75,
        breakpoint: "sm",
        collapsed: { mobile: true, desktop: false },
      }}
      header={{ height: 70 }}
      aside={{
        width: 450,
        breakpoint: "sm",
        collapsed: {
          mobile: !opened,
          desktop: !opened,
        },
      }}
    >
      <AppHeader />
      <AppShell.Navbar>
        <Navbar />
      </AppShell.Navbar>
      <AppShell.Main
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </AppShell.Main>
      <AppShell.Aside p={0}>
        <AsideContentWrapper>
          {asideContent ?? <DefaultAsideContent />}
        </AsideContentWrapper>
      </AppShell.Aside>
    </AppShell>
  );
};
