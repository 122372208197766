import { Badge, Button, Group } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";

import { OrganizationIntegrationTokenEntity } from "../../../common/entities/organizationIntegrationToken.js";
import { network } from "../../../common/utils/network.js";
import {
  useActiveStates,
  useUser,
} from "../../contexts/ActiveStatesContext.js";
import { useAuth } from "../../hooks/useAuth.js";
import { clientError } from "../../utils/clientError.js";

export function GuruButton() {
  const user = useUser();
  const [integrationToken, setIntegrationToken] =
    useState<OrganizationIntegrationTokenEntity>();
  const { session } = useAuth();
  const { customer, serviceProvider } = useActiveStates();

  const organization = customer ?? serviceProvider;

  useEffect(() => {
    if (!session) {
      return;
    }
    async function checkGuruConnection() {
      try {
        const response = await network.get<{
          token?: OrganizationIntegrationTokenEntity;
          state: string;
        }>(
          `/api/guru/status`,
          {
            params: { organization_id: organization.id },
            headers: {
              Authorization: `Bearer ${session?.accessToken}`,
            },
          },
          clientError
        );
        if ("data" in response) {
          setIntegrationToken(response.data.token);
        }
      } catch (error) {
        console.error("Error checking Guru connection:", error);
      }
    }
    checkGuruConnection().catch(clientError);
  }, [organization, session, user]);

  if (integrationToken) {
    return (
      <Group>
        <Badge
          variant="subtle"
          size="lg"
          radius="xs"
          leftSection={<IconCheck />}
        >
          Connected to AI knowledge base
        </Badge>
      </Group>
    );
  }

  return (
    <Button disabled={true} variant="primary">
      Ask an admin to connect
    </Button>
  );
}
